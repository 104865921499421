import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const InventoryProductProfileContainer = styled.div`
  ${css`
    .inventory-product-profile-title {
      font-size: 24px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.15px;
      margin-bottom: 25px;
    }

    .MuiInputBase-root.MuiAutocomplete-inputRoot {
      padding: 0;

      .MuiAutocomplete-input {
        padding-top: 4px;
        padding-bottom: 4px;
      }
    }
  `}
`;
