import { Formik, Form, Field } from 'formik';
import { Localization, useNotification, Theme } from 'connex-cds';
import Grid from '@mui/material/Grid';

import { Button, Theme as CaiMaterialTheme, InternationalAddress } from '@cai/material';

import { useUsers, useEntitySetups } from '../../../../api/hooks';
import { parseUsersFilteredByAdmin } from '../../../../api/adapters';
import { TextInputWithFormikField, SelectWithFormikField, PhoneNumberField } from '../../../../commons';
import { sanitizePhoneNumber } from '../../../../util/forms';
import useCompanyForm from './useCompanyForm';
import { validationSchema } from './form-config';
import { ContainerStyled } from './styles';
import UploadImageButton from '../../../../commons/upload-image-button';

export const CompanyForm = ({
  entitySetupData,
  isLoadingEntitySetup,
  companyName,
  isExistingData,
  handleUploadLogo,
}) => {
  const { themeName } = Theme.useThemeContext();
  const { List: useUsersList } = useUsers();
  const { data: users, isLoading: isLoadingUsers } = useUsersList({ adapter: parseUsersFilteredByAdmin });
  const { Update: useUpdateEntitySetup, Create: useCreateEntitySetup } = useEntitySetups();
  const { mutateAsync: updateEntitySetup } = useUpdateEntitySetup();
  const { mutateAsync: createEntitySetup } = useCreateEntitySetup();
  const notification = useNotification();
  const translateMessage = Localization.useTranslateMessage();

  const { initialValues, handleOnSubmit } = useCompanyForm(
    entitySetupData,
    users,
    createEntitySetup,
    updateEntitySetup,
    isExistingData,
    notification,
    translateMessage,
    companyName
  );

  const handlePhoneChange = (setFieldValue, name) => value => {
    setFieldValue(name, sanitizePhoneNumber(value));
  };

  if (isLoadingEntitySetup) return null;

  return (
    <ContainerStyled>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleOnSubmit}
        enableReinitialize
      >
        {({ isSubmitting, dirty, isValid, handleSubmit, setFieldValue, values }) => {
          return (
            <>
              <CaiMaterialTheme.ThemeProvider themeName={themeName}>
                <Form>
                  <Grid container spacing={2.5}>
                    <Grid item xs={6}>
                      <Field
                        fullWidth
                        component={TextInputWithFormikField}
                        token="company_form_companyName"
                        name="companyName"
                        variant="default"
                        required
                        disabled={true}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        fullWidth
                        token="company_form_displayName"
                        component={TextInputWithFormikField}
                        variant="default"
                        name="companyDisplayName"
                        disabled={isLoadingEntitySetup}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        fullWidth
                        token="company_form_primaryContact"
                        component={SelectWithFormikField}
                        name="companyPrimaryContact"
                        options={users}
                        disabled={isLoadingUsers}
                        testId="select-primary-contact"
                        required
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        fullWidth
                        token="company_form_phoneNumber"
                        component={PhoneNumberField}
                        variant="default"
                        name="companyPhone"
                        onChange={handlePhoneChange(setFieldValue, 'companyPhone')}
                        disabled={isLoadingEntitySetup}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        fullWidth
                        component={InternationalAddress}
                        name="companyAddress"
                        disabled={isLoadingEntitySetup}
                        countryCode="US"
                        allowCountryCodeChange
                      />
                    </Grid>
                    <Grid item container xs={12}>
                      <Grid item xs={6}>
                        <UploadImageButton
                          imgSrc={entitySetupData?.entityImages?.logoDefault?.data}
                          onClick={handleUploadLogo}
                          disabled={isLoadingEntitySetup}
                        />
                      </Grid>
                      <Grid item xs={6} container alignItems="flex-end" justifyContent={'flex-end'}>
                        <Button
                          type="submit"
                          variant="primary"
                          token="general_dialogAction_save"
                          disabled={isSubmitting || !dirty || !isValid}
                          onClick={handleSubmit}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Form>
              </CaiMaterialTheme.ThemeProvider>
            </>
          );
        }}
      </Formik>
    </ContainerStyled>
  );
};

CompanyForm.defaultProps = {
  entitySetupData: {},
  isLoadingEntitySetup: true,
  isExistingData: false,
};

export default CompanyForm;
