import React from 'react';
import { FieldArray, Form } from 'formik';
import { Localization } from 'connex-cds';
import { InputAdornment } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import { array, bool, func } from 'prop-types';

import FormTooltip from '../../../../commons/form-tooltip';
import TranslatedCell from '../../../../commons/translated-cell/TranslatedCell';
import InventoryProductLocationItem from './InventoryProductLocationItem';
import InventoryProductLocationsNoData from '../inventory-product-locations/InventoryProductLocationsNoData';
import { InventoryProductSupplyContainer } from './styles';
import { HeaderRow, HeaderCell, LocationsContainer } from '../inventory-product-locations/styles';

const InventoryProductSupplyTab = ({
  inventoryLocations,
  isInventoryProductLocationsDataLoading,
  partnerOptions,
  isLoadingPartners,
  setFieldValue,
}) => {
  const translateMessage = Localization.useTranslateMessage();

  const renderColumnHeader = (labelStringId, descriptionStringId) => (
    <>
      <TranslatedCell stringId={labelStringId} />
      <FormTooltip arrow title={translateMessage(descriptionStringId)}>
        <InputAdornment position="end">
          <InfoOutlined />
        </InputAdornment>
      </FormTooltip>
    </>
  );

  return (
    <InventoryProductSupplyContainer>
      <h3 className="inventory-product-supply-title">
        <Localization.Translate stringId="general_supply" />
      </h3>
      <Form>
        <FieldArray
          name="inventoryLocations"
          render={() => (
            <>
              <HeaderRow>
                <HeaderCell>
                  <TranslatedCell stringId={'general_location'} />
                </HeaderCell>
                <HeaderCell>
                  {renderColumnHeader(
                    'inventoryProducts_supply_safetyLevel',
                    'inventoryProducts_supply_safetyLevelDescription'
                  )}
                </HeaderCell>
                <HeaderCell>
                  {renderColumnHeader(
                    'inventoryProducts_supply_reorderLevel',
                    'inventoryProducts_supply_reorderLevelDescription'
                  )}
                </HeaderCell>
                <HeaderCell>
                  {renderColumnHeader(
                    'inventoryProducts_supply_maxLevel',
                    'inventoryProducts_supply_maxLevelDescription'
                  )}
                </HeaderCell>
              </HeaderRow>
              <LocationsContainer>
                {inventoryLocations && inventoryLocations.length > 0 ? (
                  inventoryLocations.map((inventoryLocation, index) => (
                    <InventoryProductLocationItem
                      inventoryLocation={inventoryLocation}
                      inventoryLocationIndex={index}
                      isInventoryProductLocationsDataLoading={isInventoryProductLocationsDataLoading}
                      isLoadingPartners={isLoadingPartners}
                      partnerOptions={partnerOptions}
                      key={index}
                      setFieldValue={setFieldValue}
                    />
                  ))
                ) : (
                  <InventoryProductLocationsNoData />
                )}
              </LocationsContainer>
            </>
          )}
        />
      </Form>
    </InventoryProductSupplyContainer>
  );
};

InventoryProductSupplyTab.propTypes = {
  inventoryLocations: array,
  isInventoryProductLocationsDataLoading: bool,
  partnerOptions: array.isRequired,
  isLoadingPartners: bool,
  setFieldValue: func.isRequired,
};

export default InventoryProductSupplyTab;
