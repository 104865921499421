import { Grid } from '@mui/material';
import { Localization } from 'connex-cds';

import { ActionButton, ImagePreview } from '../../../../../commons';

const CurrentImageView = ({ initialImage, onCreate, onDelete, isLoadingDeleteImg }) => {
  return (
    <Grid container direction="column" alignItems="center" spacing={4}>
      <Grid container item justifyContent="center">
        <Grid item alignItems="center" justifyContent="center" xs={8} sx={{ textAlign: 'center', mt: 4 }}>
          <Localization.Translate stringId="companyLogoModal_description" />
        </Grid>
      </Grid>
      <Grid sx={{ mb: 4 }} item>
        <ImagePreview size="large" defaultIcon="businessOutlinedIcon" src={initialImage} />
      </Grid>
      <Grid container item justifyContent="center" spacing={2}>
        <Grid item>
          <ActionButton
            icon="createIcon"
            label={
              <Localization.Translate
                stringId={`${initialImage ? 'companyLogoModal_change_btn' : 'companyLogoModal_add_btn'}`}
              />
            }
            onClick={onCreate}
            disabled={isLoadingDeleteImg}
          />
        </Grid>
        <Grid item>
          <ActionButton
            icon="deleteOutlineIcon"
            label={<Localization.Translate stringId="default_uploadImage_delete_btn" />}
            onClick={onDelete}
            disabled={isLoadingDeleteImg || !initialImage}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CurrentImageView;
