import React, { useMemo, useState } from 'react';
import { useEntity, useAppContext } from 'connex-cds';
import { find, isEmpty } from 'lodash';

import { useEntitySetups } from '../../api/hooks';
import CompanySummary from './components/company-summary';
import CompanyForm from './components/company-form';

import { StackStyled } from './styles';
import { DialogManager } from '../../commons';
import { DIALOG_TYPES } from '../../constants';
import UploadLogoImageDialog from './upload-logo-image-dialog';

const Company = () => {
  const { GetEntitySetups: useGetEntitySetup } = useEntitySetups();
  const { data: entitySetup, isLoading: isLoadingEntitySetup } = useGetEntitySetup();
  const { entityRef } = useEntity();
  const { appEntities } = useAppContext();
  const isExistingData = useMemo(() => !isEmpty(entitySetup), [entitySetup]);
  const [dialog, setDialog] = useState(DIALOG_TYPES.NONE);

  const companyName = useMemo(() => {
    return find(appEntities, { entityRef }).name;
  }, [entityRef, appEntities]);

  return (
    <StackStyled>
      <CompanySummary
        companyName={companyName}
        companyDescription={entitySetup?.description}
        isLoadingEntitySetup={isLoadingEntitySetup}
      />
      <CompanyForm
        entitySetupData={entitySetup}
        isLoadingEntitySetup={isLoadingEntitySetup}
        companyName={companyName}
        isExistingData={isExistingData}
        handleUploadLogo={() => setDialog(DIALOG_TYPES.UPLOAD_PHOTO)}
      />
      <DialogManager screen={dialog}>
        <UploadLogoImageDialog
          currentLogo={entitySetup?.entityImages?.logoDefault?.data}
          identifier={DIALOG_TYPES.UPLOAD_PHOTO}
          onClose={() => setDialog(DIALOG_TYPES.NONE)}
          open={true}
        />
      </DialogManager>
    </StackStyled>
  );
};

export default Company;
