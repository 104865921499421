import React, { useState } from 'react';
import { Localization, useNotification } from 'connex-cds';
import { func } from 'prop-types';
import { useLocations, usePartners, useInventoryProducts, useUom } from '../../../../api/hooks';
import InventoryProductFormView from '../inventory-product-form-view';
import { DIALOG_TYPES, STATUS_OPTIONS } from '../../../../constants';
import { getInventoryProductLocationsDataToSubmit } from '../../utils';
import { INVENTORY_SOURCE_OPTIONS, initialValues } from '../inventory-product-form-view/form-config';
import { parseLocations, parsePartnerOptions, parseUomOptions } from '../../../../api/adapters';

const AddInventoryProductDialog = ({ onClose }) => {
  const [isOpen, setIsOpen] = useState(true);
  const { List: useListUom } = useUom();
  const { data: uomOptions, isLoading: isLoadingUom } = useListUom({ adapter: parseUomOptions });
  const { List: useListLocations } = useLocations();
  const { data: locationOptions, isLoading: isLoadingLocations } = useListLocations({
    adapter: parseLocations,
    queryParams: { inventoryOnly: true },
  });
  const { List: listPartners } = usePartners();
  const { data: partnerOptions, isLoading: isLoadingPartners } = listPartners({ adapter: parsePartnerOptions });
  const { Create: useCreateInventoryProduct } = useInventoryProducts();
  const { mutateAsync: createInventoryProduct } = useCreateInventoryProduct();
  const notification = useNotification();
  const translateMessage = Localization.useTranslateMessage();

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  const handleLocationsChange = (setFieldValue, value) => {
    const inventoryLocations =
      value?.map(location => ({
        ...location,
        safetyStock: '',
        reorderStock: '',
        maxStock: '',
      })) ?? [];
    setFieldValue('inventoryLocations', inventoryLocations);
  };

  const handleOnSubmit = async (values, formikBag) => {
    const {
      inventoryProductId,
      inventoryProductName,
      inventoryProductStatus,
      inventoryProductUom,
      locations,
      inventorySource,
      inventoryLocations,
    } = values;

    const locationsObject = locations.reduce((accumulator, location) => {
      return { ...accumulator, [location.value]: true };
    }, {});

    formikBag.setSubmitting(true);

    try {
      const inventoryProductLocationsList = getInventoryProductLocationsDataToSubmit(
        inventoryLocations,
        partnerOptions
      );
      const inventoryProduct = {
        id: inventoryProductId,
        name: inventoryProductName,
        inventoryUomCode: inventoryProductUom,
        locations: locations.length === locationOptions.length ? { all: true } : locationsObject,
        status: inventoryProductStatus,
        inventorySource: inventorySource,
        inventoryProductLocations: inventoryProductLocationsList,
      };

      await createInventoryProduct({ inventoryProduct });
      notification.success(translateMessage('inventoryProducts_notification_added'));

      formikBag.setSubmitting(false);
      formikBag.resetForm();
      handleClose();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <InventoryProductFormView
      open={isOpen}
      onClose={handleClose}
      mode={DIALOG_TYPES.ADD}
      initialValues={initialValues}
      onSubmit={handleOnSubmit}
      uomOptions={uomOptions}
      isLoadingUom={isLoadingUom}
      locationOptions={locationOptions}
      isLoadingLocations={isLoadingLocations}
      inventoryProductStatusOptions={STATUS_OPTIONS}
      inventorySourceOptions={INVENTORY_SOURCE_OPTIONS}
      onLocationsChange={handleLocationsChange}
      partnerOptions={partnerOptions}
      isLoadingPartners={isLoadingPartners}
    />
  );
};

AddInventoryProductDialog.propTypes = {
  onClose: func,
};

AddInventoryProductDialog.defaultProps = {
  onClose: () => {},
};

export default AddInventoryProductDialog;
