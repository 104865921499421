import { Localization } from 'connex-cds';

import { useComponents } from '../../api/hooks';
import { getMixComponentListColumns } from './tableConfig';
import AddMixComponentDialog from './components/add-mix-component-dialog';
import EditMixComponentDialog from './components/edit-mix-component-dialog';
import DeleteMixComponentDialog from './components/delete-mix-component-dialog';
import { SearchFilter, TableLayout, EntityTable, ActionButton, DialogManager } from '../../commons';
import { DIALOG_TYPES } from '../../constants';
import { useTableLogic } from '../../hooks';

const MixComponents = () => {
  const filteredPropsArr = ['id', 'name'];
  const {
    setQuery,
    currentEntity,
    dialog,
    setDialog,
    isLoading,
    filteredData,
    entityColumns,
    resetDialogs,
    handleOnRowClick,
    setIsOnEditDialog,
  } = useTableLogic(useComponents, getMixComponentListColumns, filteredPropsArr);

  return (
    <TableLayout.ListLayoutContainer>
      <TableLayout.TableControls>
        <SearchFilter onSearchChange={setQuery} disabled={isLoading} resultsCount={filteredData.length} />
        <ActionButton
          icon="addOutlinedIcon"
          label={<Localization.Translate stringId="mixComponents_addMixComponent_title" />}
          variant="outlined"
          onClick={() => setDialog(DIALOG_TYPES.ADD)}
        />
      </TableLayout.TableControls>
      <TableLayout.TableBody>
        <EntityTable
          checkboxSelection={false}
          isLoading={isLoading}
          columns={entityColumns}
          rows={filteredData}
          onRowClick={handleOnRowClick}
          getRowId={row => row.crn}
        />
      </TableLayout.TableBody>
      <DialogManager screen={dialog}>
        <AddMixComponentDialog identifier={DIALOG_TYPES.ADD} onClose={resetDialogs} />
        <EditMixComponentDialog
          identifier={DIALOG_TYPES.EDIT}
          handleOnDelete={() => {
            setIsOnEditDialog(true);
            setDialog(DIALOG_TYPES.DELETE);
          }}
          mixData={currentEntity}
          onClose={resetDialogs}
        />
        <DeleteMixComponentDialog
          identifier={DIALOG_TYPES.DELETE}
          onClose={resetDialogs}
          mixComponentData={currentEntity}
        />
      </DialogManager>
    </TableLayout.ListLayoutContainer>
  );
};

export default MixComponents;
