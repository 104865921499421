import * as yup from 'yup';

import { STATUS_OPTIONS } from '../../../../constants';

export const validationSchema = yup.object().shape({
  contactReasonId: yup.string().required('required'),
  contactReasonName: yup.string().required('required'),
  contactReasonStatus: yup.string(),
});

export const initialValues = {
  contactReasonId: '',
  contactReasonName: '',
  contactReasonStatus: STATUS_OPTIONS[0].value,
};
