import { TranslatedCell } from '../../../../commons';
import { CURRENCY_CODE } from '../../../../constants';

export const getProductListColumns = ({ translateMessage, getCurrency }) => [
  {
    field: 'id',
    flex: 1,
    renderHeader: () => <TranslatedCell stringId={'productsTable_header_productId'} />,
  },
  {
    field: 'name',
    flex: 1,
    renderHeader: () => <TranslatedCell stringId={'productsTable_header_productName'} />,
    valueGetter: ({ row }) => row?.productDetail?.name,
  },
  {
    field: 'uomCode',
    flex: 1,
    renderHeader: () => <TranslatedCell stringId={'productsTable_header_uom'} />,
    valueGetter: ({ row }) => row?.productDetail?.uomCode,
  },
  {
    field: 'price',
    flex: 1,
    renderHeader: () => <TranslatedCell stringId={'general_tableHeader_price'} />,
    valueGetter: ({ row }) => getCurrency(row?.price?.value, CURRENCY_CODE.USD),
  },
  {
    field: 'taxable',
    flex: 1,
    renderHeader: () => <TranslatedCell stringId={'general_taxable'} />,
    renderCell: ({ row }) => {
      const taxable = row?.productDetail?.taxable ? translateMessage('yes') : translateMessage('no');
      return <span title={taxable}>{taxable}</span>;
    },
  },
];
