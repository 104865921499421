import dayjs from 'dayjs';

import {
  TIME_ZONES,
  COUNTRIES,
  PRODUCT_TYPE_OPTIONS,
  STATUS_OPTIONS,
  BOOLEAN_VALUES,
  ORDER_RESTRICTIONS_OPTIONS,
} from '../../constants';
import { DAYS_FOR_HOURS_TAB, initialValues, TAX_BASIS_OPTIONS } from './components/location-form-view/form-config';

export const mapperToGetTimezoneOptions = countryCode => {
  return TIME_ZONES.filter(value => value.countryCode === countryCode).map(timeZone => ({
    value: timeZone.label,
    label: timeZone.label,
  }));
};

const mapperToGetCountry = timeZone => {
  const timeZoneSelected = TIME_ZONES.filter(value => value.label === timeZone);
  return COUNTRIES.filter(country => country.value === timeZoneSelected[0].countryCode)[0].value;
};

export const mapperToGetInitialValues = (locationData, priceBookOptions) => {
  if (!locationData) return null;

  const {
    id,
    name,
    description,
    primaryProductType,
    status,
    address,
    trackInventory,
    sendMixDesignEachTicket,
    automaticallyPrintTicketPDF,
    dexaMapping,
    timeZone,
    hours,
    costBookRef,
    taxBasis,
    restrictToPriceBook,
    scheduleTimes,
    dotCertification,
    availableVehicles = '',
  } = locationData;

  const newHours = hours
    ? DAYS_FOR_HOURS_TAB.reduce((acc, cur) => {
        const day = hours[cur.id];
        return day
          ? {
              ...acc,
              [cur.id]: {
                open: dayjs()
                  .set('hour', parseInt(day.open.split(':')[0]))
                  .set('minute', parseInt(day.open.split(':')[1])),
                close: dayjs()
                  .set('hour', parseInt(day.close.split(':')[0]))
                  .set('minute', parseInt(day.close.split(':')[1])),
                switch: true,
              },
            }
          : { ...acc, [cur.id]: initialValues.hours[cur.id] };
      }, {})
    : initialValues.hours;

  const newScheduleTimes = scheduleTimes
    ? {
        waitToLoadMinutes: scheduleTimes.waitToLoadMinutes?.toString() || '',
        waitToLeaveMinutes: scheduleTimes.waitToLeaveMinutes?.toString() || '',
        loadMinutes: scheduleTimes.loadMinutes?.toString() || '',
      }
    : initialValues.scheduleTimes;

  const values = {
    locationId: id,
    locationName: name || '',
    description: description || '',
    primaryProductType: PRODUCT_TYPE_OPTIONS.find(item => item?.value === primaryProductType)?.value,
    status: STATUS_OPTIONS.find(item => item.value === status)?.value,
    streetAddress: address?.address1 || '',
    complement: address?.address2 || '',
    city: address?.city || '',
    state: address?.countrySubDivision || '',
    postalCode: address?.postalCode || '',
    trackInventory: trackInventory === BOOLEAN_VALUES.Yes,
    sendMixDesignEachTicket: sendMixDesignEachTicket || false,
    automaticallyPrintTicketPdf: automaticallyPrintTicketPDF || false,
    mixSyncing: dexaMapping?.useMixSyncing || false,
    country: timeZone ? mapperToGetCountry(timeZone) : '',
    timeZone: timeZone || '',
    hours: newHours,
    costBookRef: priceBookOptions.find(item => item?.value === costBookRef)?.value,
    taxBasis: TAX_BASIS_OPTIONS.find(item => item.value === taxBasis)?.value,
    restrictToPriceBook: ORDER_RESTRICTIONS_OPTIONS.find(item => item.value === restrictToPriceBook)?.value,
    scheduleTimes: newScheduleTimes,
    dotCertification: dotCertification || '',
    availableVehicles: availableVehicles?.toString() || '',
  };

  return values;
};

export const mapperToCreateLocation = values => {
  const {
    locationId,
    description,
    primaryProductType,
    locationName,
    status,
    streetAddress,
    complement,
    city,
    state,
    postalCode,
    trackInventory,
    sendMixDesignEachTicket,
    automaticallyPrintTicketPdf,
    mixSyncing,
    timeZone,
    hours,
    costBookRef,
    taxBasis,
    restrictToPriceBook,
    scheduleTimes,
    dotCertification,
    availableVehicles,
  } = values;

  const newHours = Object.keys(hours).reduce((acc, cur) => {
    const day = hours[cur];
    const open = day.open ? dayjs(day.open).format('HH:mm') : null;
    const close = day.close ? dayjs(day.close).format('HH:mm') : null;
    return open && close ? { ...acc, [cur]: { open, close } } : acc;
  }, {});

  const newScheduleTimes = {
    waitToLoadMinutes: scheduleTimes.waitToLoadMinutes ? Number(scheduleTimes.waitToLoadMinutes) : 0,
    waitToLeaveMinutes: scheduleTimes.waitToLeaveMinutes ? Number(scheduleTimes.waitToLeaveMinutes) : 0,
    loadMinutes: scheduleTimes.loadMinutes ? Number(scheduleTimes.loadMinutes) : 0,
  };

  const location = {
    id: locationId,
    name: locationName,
    description: description,
    status: status,
    primaryProductType: primaryProductType,
    address: {
      address1: streetAddress,
      address2: complement,
      city,
      countrySubDivision: state,
      postalCode,
    },
    trackInventory: trackInventory ? BOOLEAN_VALUES.Yes : BOOLEAN_VALUES.No,
    sendMixDesignEachTicket,
    automaticallyPrintTicketPDF: automaticallyPrintTicketPdf,
    dexaMapping: {
      useMixSyncing: mixSyncing,
    },
    timeZone: timeZone || undefined,
    hours: Object.keys(newHours).length !== 0 ? newHours : undefined,
    costBookRef: costBookRef || undefined,
    taxBasis: taxBasis || undefined,
    restrictToPriceBook: restrictToPriceBook || undefined,
    scheduleTimes: newScheduleTimes,
    dotCertification,
    availableVehicles: availableVehicles ? Number(availableVehicles) : 0,
  };

  return location;
};

export const mapperToEditLocation = (values, locationData) => {
  const { dexaMapping = {} } = locationData;

  const {
    description,
    primaryProductType,
    locationName,
    status,
    streetAddress,
    complement,
    city,
    state,
    postalCode,
    trackInventory,
    sendMixDesignEachTicket,
    automaticallyPrintTicketPdf,
    mixSyncing,
    timeZone,
    hours,
    costBookRef,
    taxBasis,
    restrictToPriceBook,
    scheduleTimes,
    dotCertification,
    availableVehicles,
  } = values;

  const newHours = Object.keys(hours).reduce((acc, cur) => {
    const day = hours[cur];
    const open = day.open ? dayjs(day.open).format('HH:mm') : null;
    const close = day.close ? dayjs(day.close).format('HH:mm') : null;
    return open && close && day.switch ? { ...acc, [cur]: { open, close } } : acc;
  }, {});

  const newScheduleTimes = Object.keys(scheduleTimes).reduce((acc, cur) => {
    const value = scheduleTimes[cur] ? Number(scheduleTimes[cur]) : 0;
    return { ...acc, [cur]: value };
  }, {});

  return {
    name: locationName,
    description: description,
    status: status,
    primaryProductType: primaryProductType,
    address: {
      address1: streetAddress,
      address2: complement,
      city,
      countrySubDivision: state,
      postalCode,
    },
    trackInventory: trackInventory ? BOOLEAN_VALUES.Yes : BOOLEAN_VALUES.No,
    sendMixDesignEachTicket,
    automaticallyPrintTicketPDF: automaticallyPrintTicketPdf,
    dexaMapping: {
      ...dexaMapping,
      useMixSyncing: mixSyncing,
    },
    timeZone: timeZone || undefined,
    hours: Object.keys(newHours).length !== 0 ? newHours : undefined,
    costBookRef: costBookRef || undefined,
    taxBasis: taxBasis || undefined,
    restrictToPriceBook: restrictToPriceBook || undefined,
    scheduleTimes: newScheduleTimes,
    dotCertification,
    availableVehicles: availableVehicles ? Number(availableVehicles) : 0,
  };
};
