import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  taxProvider: yup.string().required('required'),
  taxPercentage: yup
    .number()
    .required('required')
    .min(0.0, 'billing_tax_Percentage_min_error')
    .max(999.99, 'billing_tax_Percentage_max_error'),
  taxPerProduct: yup.bool().required('required'),
});
