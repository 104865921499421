import { http } from 'connex-cds';

export default {
  listInventoryProductLocations: ({ entityRef, inventoryProductRef }) => {
    if (!entityRef || !inventoryProductRef) {
      return null;
    }
    return http.get({
      apiName: 'company-admin',
      path: `/${entityRef}/inventory-products/${inventoryProductRef}/inventory-product-locations`,
    });
  },
};
