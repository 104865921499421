import { STATUS_OPTIONS } from '../../../../constants';

export const TAB_IDS = {
  AddLocationProfile: 0,
  hours: 1,
  scheduling: 2,
  pricingTab: 3,
  ticketSettings: 4,
};

export const initialValues = {
  locationId: '',
  description: '',
  primaryProductType: '',
  locationName: '',
  status: STATUS_OPTIONS[0].value,
  streetAddress: '',
  complement: '',
  city: '',
  state: '',
  postalCode: '',
  trackInventory: false,
  sendMixDesignEachTicket: false,
  automaticallyPrintTicketPdf: false,
  mixSyncing: false,
  country: '',
  timeZone: '',
  hours: {
    sunday: { open: null, close: null, switch: false },
    monday: { open: null, close: null, switch: false },
    tuesday: { open: null, close: null, switch: false },
    wednesday: { open: null, close: null, switch: false },
    thursday: { open: null, close: null, switch: false },
    friday: { open: null, close: null, switch: false },
    saturday: { open: null, close: null, switch: false },
  },
  costBookRef: '',
  restrictToPriceBook: '',
  taxBasis: '',
  scheduleTimes: {
    waitToLoadMinutes: '',
    waitToLeaveMinutes: '',
    loadMinutes: '',
  },
  dotCertification: '',
  availableVehicles: '',
};

export const validation = values => {
  const errors = {};

  if (!values?.locationId) {
    errors.locationId = 'required';
  }

  if (!values.description) {
    errors.description = 'required';
  }

  if (!values.primaryProductType) {
    errors.primaryProductType = 'required';
  }

  if (!values.locationName) {
    errors.locationName = 'required';
  }

  if (!values.status) {
    errors.status = 'required';
  }

  if (!values.streetAddress) {
    errors.streetAddress = 'required';
  }

  if (!values.city) {
    errors.city = 'required';
  }

  if (!values.postalCode) {
    errors.postalCode = 'required';
  }

  if (values.country && !values.timeZone) {
    errors.timeZone = 'required';
  }

  // Validation for hours
  DAYS_FOR_HOURS_TAB.forEach(item => {
    const day = values.hours[item.id];
    let hoursError = {};

    if (day.switch && !day.open) {
      hoursError = { open: 'required' };
    }

    if (day.switch && !day.close) {
      hoursError = { ...hoursError, close: 'required' };
    }

    if (day.switch && (!day.open || !day.close)) {
      errors.hours = { ...errors.hours, [item.id]: hoursError };
    }
  });

  return errors;
};

export const DAYS_FOR_HOURS_TAB = [
  { id: 'sunday', labelStringId: 'general_day_sunday' },
  { id: 'monday', labelStringId: 'general_day_monday' },
  { id: 'tuesday', labelStringId: 'general_day_tuesday' },
  { id: 'wednesday', labelStringId: 'general_day_wednesday' },
  { id: 'thursday', labelStringId: 'general_day_thursday' },
  { id: 'friday', labelStringId: 'general_day_friday' },
  { id: 'saturday', labelStringId: 'general_day_saturday' },
];

export const TAX_BASIS_OPTIONS = [
  { value: 'location', translationKey: 'locations_pricingTab_taxBasis_locations_option' },
  { value: 'address', translationKey: 'locations_pricingTab_taxBasis_address_option' },
];
