import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const InventoryProductInventoryContainer = styled.div`
  ${css`
    .inventory-product-inventory-title {
      font-size: 24px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.15px;
      margin-bottom: 25px;
    }
  `}
`;

export const ContainersSection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-bottom: 24px;
  align-items: center;

  & > .MuiTextField-root {
    width: auto;
  }
`;

export const ContainersSummary = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  flex-wrap: wrap;

  & > div {
    max-width: 300px;
  }
`;

export const AccordionSummaryIconWrapper = styled.div`
  display: flex;

  svg {
    margin: 4px 0;
  }
`;
