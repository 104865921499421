import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const LocationHoursFormContainer = styled.div`
  ${({ theme }) => css`
    .first-section {
      padding: 4px 0 20px 22px;
    }

    .second-section {
      padding: 0 0 0 32px;

      &.MuiGrid-root > .MuiGrid-item {
        &:nth-child(1) {
          padding-left: 0;
        }

        &:nth-child(2),
        &:nth-child(3) {
          padding-left: 10px;
        }
      }
    }

    .column-name {
      color: ${theme.validateMode({ light: theme.colorsCA.neutral[40], dark: theme.colorsCA.neutral[70] })};
      font-family: Work Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      padding-left: 6px;
    }
  `}
`;
