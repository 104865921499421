import { Localization } from 'connex-cds';
import { Field, Form } from 'formik';
import { Grid } from '@mui/material';
import { LocationSchedulingFormContainer } from './styles';
import { TextInputWithFormikField, NumberInputWithFormikField } from '../../../../commons';

const LocationSchedulingTab = () => {
  return (
    <LocationSchedulingFormContainer>
      <Form>
        <h3 className="tab-title">
          <Localization.Translate stringId="locations_schedulingTab_orderEntry_title" />
        </h3>
        <p className="tab-description">
          <Localization.Translate stringId="locations_schedulingTab_orderEntry_description" />
        </p>
        <Grid container spacing={5} alignItems="baseline">
          <Grid item xs={12} md={6}>
            <Field
              fullWidth
              labelStringId="locations_schedulingTab_timeToPreload_field_label"
              tooltipInfoStringId="locations_schedulingTab_timeToPreload_field_tooltip"
              component={NumberInputWithFormikField}
              name="scheduleTimes.waitToLoadMinutes"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              fullWidth
              labelStringId="locations_schedulingTab_timeToDepart_field_label"
              tooltipInfoStringId="locations_schedulingTab_timeToDepart_field_tooltip"
              component={NumberInputWithFormikField}
              name="scheduleTimes.waitToLeaveMinutes"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              fullWidth
              labelStringId="locations_schedulingTab_timeToLoad_field_label"
              tooltipInfoStringId="locations_schedulingTab_timeToLoad_field_tooltip"
              component={NumberInputWithFormikField}
              name="scheduleTimes.loadMinutes"
            />
          </Grid>
        </Grid>
        <h3 className="tab-title margin-top">
          <Localization.Translate stringId="locations_schedulingTab_driver_callIn_title" />
        </h3>
        <p className="tab-description">
          <Localization.Translate stringId="locations_schedulingTab_driver_callIn_description" />
        </p>
        <Grid container spacing={5} alignItems="baseline">
          <Grid item xs={12} md={6}>
            <Field
              fullWidth
              labelStringId="locations_schedulingTab_dotCertification_field_label"
              component={TextInputWithFormikField}
              name="dotCertification"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              fullWidth
              labelStringId="locations_schedulingTab_vehicleCount_field_label"
              tooltipInfoStringId="locations_schedulingTab_vehicleCount_field_tooltip"
              component={NumberInputWithFormikField}
              name="availableVehicles"
            />
          </Grid>
        </Grid>
      </Form>
    </LocationSchedulingFormContainer>
  );
};

export default LocationSchedulingTab;
