import * as yup from 'yup';

import { STATUS_OPTIONS } from '../../../../constants';

export const TAB_IDS = {
  InventoryProductProfile: 0,
  InventoryProductInventory: 1,
  InventoryProductSupply: 2,
};

export const INVENTORY_SOURCE_OPTIONS = [
  { value: 'CLOUD', label: 'Command Alkon Cloud' },
  { value: 'CB', label: 'Command Batch' },
];

export const INVENTORY_PRODUCT_LOCATION_STATUSES = {
  Active: 'ACTIVE',
  Inactive: 'INACTIVE',
  Deleted: 'DELETED',
};

yup.addMethod(yup.string, 'uniqueInArray', function (message) {
  return this.test('uniqueInArray', message, function (item) {
    if (!Array.isArray(this.parent)) {
      return true;
    }
    return this.parent.filter(x => x?.toLowerCase() === item?.toLowerCase()).length === 1;
  });
});

export const validationSchema = yup.object().shape({
  inventoryProductId: yup.string().required('required'),
  inventoryProductName: yup.string().required('required'),
  inventoryProductUom: yup.string().required('required'),
  locations: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string(),
        label: yup.string(),
      })
    )
    .min(1, 'required'),
  inventoryProductStatus: yup.string().required('required'),
  inventorySource: yup.string().required('required'),
  inventoryLocations: yup.array().of(
    yup.object().shape({
      safetyStock: yup.number().min(0, 'general_minimumZero').required('required'),
      reorderStock: yup
        .number()
        .min(0, 'general_minimumZero')
        .required('required')
        .when('safetyStock', (stock, schema) => {
          return stock >= 0 ? schema.moreThan(yup.ref('safetyStock'), 'general_tooSmallValue') : schema;
        }),
      maxStock: yup
        .number()
        .min(0)
        .when('reorderStock', (stock, schema) => {
          return stock >= 0 ? schema.moreThan(yup.ref('reorderStock'), 'general_tooSmallValue') : schema;
        }),
      productMappingList: yup.array().of(
        yup.object().shape(
          {
            partner: yup.string().required('required'),
            projects: yup
              .array()
              .of(
                yup.object().shape({
                  value: yup.string(),
                  label: yup.string(),
                  id: yup.string(),
                })
              )
              .min(1, 'required'),
            products: yup
              .array()
              .of(
                yup.object().shape({
                  value: yup.string(),
                  label: yup.string(),
                  id: yup.string(),
                })
              )
              .min(1, 'required'),
            purchaseOrders: yup.string(),
          },
          [['products', 'purchaseOrders']]
        )
      ),
      inventoryContainers: yup.array().of(yup.string().required('required').uniqueInArray('general_unique')),
    })
  ),
});

export const initialValues = {
  inventoryProductId: '',
  inventoryProductName: '',
  inventoryProductUom: '',
  locations: [],
  inventoryProductStatus: STATUS_OPTIONS[0].value,
  inventorySource: '',
  inventoryLocations: [],
};
