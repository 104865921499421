import { useState } from 'react';
import { Localization, useNotification } from 'connex-cds';
import { func } from 'prop-types';

import { DIALOG_TYPES, STATUS_OPTIONS, BOOLEAN_VALUES } from '../../../../constants';
import { useOrderStatuses } from '../../../../api/hooks';
import OrderStatusFormView from '../order-status-form-view';
import { initialValues } from '../order-status-form-view/form-config';

const AddOrderStatusDialog = ({ onClose }) => {
  const notification = useNotification();
  const translateMessage = Localization.useTranslateMessage();
  const [isOpen, setIsOpen] = useState(true);
  const { Create: useCreateOrderStatus } = useOrderStatuses();
  const { mutateAsync: createOrderStatus } = useCreateOrderStatus();

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  const handleOnSubmit = async (values, formikBag) => {
    formikBag.setSubmitting(true);
    const { id, name, status, defaultValue, displayInPlanning, enableTicketing } = values;

    try {
      const orderStatus = {
        id,
        name,
        status,
        defaultValue,
        enableTicketing,
        displayInPlanningGraphs: displayInPlanning ? BOOLEAN_VALUES.Yes : BOOLEAN_VALUES.No,
      };
      await createOrderStatus({ data: orderStatus });
      notification.success(translateMessage('orderStatuses_notification_orderStatusAdded'));
      formikBag.setSubmitting(false);
      formikBag.resetForm();
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <OrderStatusFormView
      open={isOpen}
      onClose={handleClose}
      mode={DIALOG_TYPES.ADD}
      initialValues={initialValues}
      onSubmit={handleOnSubmit}
      statusOptions={STATUS_OPTIONS}
    />
  );
};

AddOrderStatusDialog.propTypes = {
  onClose: func.isRequired,
};

AddOrderStatusDialog.defaultProps = {
  onClose: () => {},
};

export default AddOrderStatusDialog;
