import { useNavigate } from 'react-router-dom';
import { Localization } from 'connex-cds';
import { bool, oneOf, string } from 'prop-types';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import { CustomIcon } from '..';
import { CardStyled } from './styles';

const SETTING_STATES = {
  notStarted: {
    label: 'general_notStarted',
    icon: 'nonStarted',
    button: 'general_start',
  },
  started: {
    label: 'general_started',
    icon: 'started',
    button: 'general_resume',
  },
  completed: {
    label: 'general_completed',
    icon: 'completed',
    button: 'general_view',
  },
};

const CustomSettingCard = ({ iconName, titleStringId, descriptionStringId, navigateTo, state, displaySubHeader }) => {
  const translateMessage = Localization.useTranslateMessage();
  const navigate = useNavigate();

  const handleClick = () => navigate(navigateTo);

  const subHeader = displaySubHeader ? (
    <Stack className="state-info">
      <CustomIcon iconName={SETTING_STATES[state]?.icon} />
      <Typography>{translateMessage(SETTING_STATES[state]?.label)}</Typography>
    </Stack>
  ) : null;

  return (
    <CardStyled>
      <CardHeader
        avatar={<CustomIcon iconName={iconName} className="title-icon" />}
        title={<Typography>{translateMessage(titleStringId)}</Typography>}
        subheader={subHeader}
      />
      <CardContent>
        <Typography className="description">{translateMessage(descriptionStringId)}</Typography>
      </CardContent>
      <CardActions>
        <Button variant="outlined" onClick={handleClick}>
          {translateMessage(SETTING_STATES[state]?.button)}
        </Button>
      </CardActions>
    </CardStyled>
  );
};

CustomSettingCard.propTypes = {
  iconName: string.isRequired,
  titleStringId: string.isRequired,
  descriptionStringId: string.isRequired,
  navigateTo: string.isRequired,
  state: oneOf(['notStarted', 'started', 'completed']).isRequired,
  displaySubHeader: bool,
};

CustomSettingCard.defaultProps = {
  displaySubHeader: true,
};

export default CustomSettingCard;
