import DeleteIcon from '@mui/icons-material/Delete';

import { TranslatedCell } from '../../commons';
import { KebabOption } from '../../commons/entity-table';

export const getProductTypesListColumns = ({ setDeleteEntity: setDeleteProductType, translateMessage }) => [
  {
    field: 'id',
    flex: 1,
    renderHeader: () => <TranslatedCell stringId={'general_tableHeader_id'} />,
  },
  {
    field: 'name',
    flex: 1,
    renderHeader: () => <TranslatedCell stringId={'general_tableHeader_name'} />,
  },
  {
    field: 'status',
    flex: 1,
    renderHeader: () => <TranslatedCell stringId={'general_tableHeader_status'} />,
    valueGetter: ({ row }) => translateMessage(row.status.toLowerCase()),
  },
  {
    field: 'actions',
    type: 'actions',
    sortable: false,
    width: 80,
    cellClassName: 'table-actions',
    getActions: params => [
      <KebabOption
        icon={<DeleteIcon />}
        label={translateMessage('delete')}
        onClick={setDeleteProductType(params.row)}
        showInMenu
      />,
    ],
  },
];
