import { useState, useMemo } from 'react';
import { Localization, useNotification } from 'connex-cds';
import { func, shape, string, bool, number } from 'prop-types';

import VehicleTypeFormView from '../vehicle-type-form-view';
import { useVehicleTypes, useUom } from '../../../../api/hooks';
import { parseUomOptions } from '../../../../api/adapters';
import { DIALOG_TYPES, STATUS_OPTIONS } from '../../../../constants';

const EditVehicleTypeDialog = ({ onClose, vehicleTypeData, handleOnDelete }) => {
  const [isOpen, setIsOpen] = useState(true);
  const notification = useNotification();
  const { Update: useUpdateVehicleType } = useVehicleTypes();
  const { mutateAsync: updateVehicleType } = useUpdateVehicleType();
  const translateMessage = Localization.useTranslateMessage();
  const { List: useListUom } = useUom();
  const { data: uomOptions, isLoading: isLoadingUomOptions } = useListUom({
    adapter: parseUomOptions,
  });

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  const handleOnSubmit = async (values, formikBag) => {
    const {
      vehicleTypeName,
      vehicleTypeStatus,
      defaultValue,
      defaultLoadSize,
      defaultLoadSizeUOM,
      maxLoadSize,
      maxLoadSizeUOM,
      trackingColor,
    } = values;

    formikBag.setSubmitting(true);

    try {
      const vehicleType = {
        name: vehicleTypeName,
        status: vehicleTypeStatus,
        defaultValue,
        defaultLoadSize: parseInt(defaultLoadSize),
        defaultLoadSizeUOM: defaultLoadSizeUOM,
        maxLoadSize: parseInt(maxLoadSize),
        maxLoadSizeUOM: maxLoadSizeUOM,
        trackingColor,
      };
      await updateVehicleType({ data: vehicleType, vehicleTypeRef: vehicleTypeData.crn });
      notification.success(translateMessage('notification_changesSaved'));
      formikBag.setSubmitting(false);
      formikBag.resetForm();
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  const getInitialValues = useMemo(() => {
    if (!vehicleTypeData) return null;

    const { id, name, defaultValue, defaultLoadSize, defaultLoadSizeUOM, maxLoadSize, maxLoadSizeUOM, trackingColor } =
      vehicleTypeData;

    const values = {
      vehicleTypeId: id,
      vehicleTypeName: name,
      vehicleTypeStatus: STATUS_OPTIONS.find(status => status.value === vehicleTypeData.status).value,
      defaultValue,
      defaultLoadSize: defaultLoadSize?.toString(),
      defaultLoadSizeUOM: uomOptions.find(uomItem => uomItem.value === defaultLoadSizeUOM)?.value,
      maxLoadSize: maxLoadSize?.toString(),
      maxLoadSizeUOM: uomOptions.find(uomItem => uomItem.value === maxLoadSizeUOM)?.value,
      trackingColor: trackingColor || '',
    };

    return values;
  }, [vehicleTypeData, uomOptions]);

  return (
    <VehicleTypeFormView
      open={isOpen}
      onClose={handleClose}
      mode={DIALOG_TYPES.EDIT}
      initialValues={getInitialValues}
      onSubmit={handleOnSubmit}
      statusOptions={STATUS_OPTIONS}
      uomOptions={uomOptions}
      isLoadingUomOptions={isLoadingUomOptions}
      handleOnDelete={handleOnDelete}
    />
  );
};

EditVehicleTypeDialog.propTypes = {
  handleOnDelete: func,
  onClose: func.isRequired,
  vehicleTypeData: shape({
    crn: string.isRequired,
    id: string.isRequired,
    name: string.isRequired,
    status: string.isRequired,
    defaultValue: bool,
    defaultLoadSize: number.isRequired,
    defaultLoadSizeUOM: string.isRequired,
    maxLoadSize: number.isRequired,
    maxLoadSizeUOM: string.isRequired,
    trackingColor: string,
  }),
};

EditVehicleTypeDialog.defaultProps = {
  onClose: () => {},
  vehicleTypeData: {},
};

export default EditVehicleTypeDialog;
