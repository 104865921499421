import { Field } from 'formik';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { uomConversions } from '../../../../util/uomConversions';
import { TranslatedCell, NumberInputWithFormikField } from '../../../../commons';

export const getMixComponentColumns = ({
  deleteRow,
  calcInventoryValue,
  setFieldValue,
  quantities,
  getNumber,
  translateMessage,
}) => [
  {
    field: 'sequence',
    flex: 1,
    maxWidth: 150,
    sortable: false,
    renderHeader: () => <TranslatedCell stringId={'mixDesigns_tableHeader_sequence'} />,
    renderCell: ({ row }) => row?.index + 1,
  },
  {
    field: 'label',
    flex: 1,
    maxWidth: 300,
    sortable: false,
    renderHeader: () => <TranslatedCell stringId={'mixDesigns_tableHeader_mixComponent'} />,
    renderCell: ({ row }) => (
      <>{`${row.label}${row.batchUomCode ? ` (${translateMessage(`uom_${row.batchUomCode}`)})` : ''}`}</>
    ),
  },
  {
    field: 'quantity',
    flex: 1,
    maxWidth: 150,
    sortable: false,
    renderHeader: () => <TranslatedCell stringId={'mixDesigns_tableHeader_quantity'} />,
    renderCell: ({ row }) => {
      return (
        <Field
          component={NumberInputWithFormikField}
          name={`mixDesignComponentsQuantities.${row.value}`}
          customOnChange={calcInventoryValue(setFieldValue)}
          labelStringId="mixDesigns_tableHeader_addQuantity"
          type="decimal"
        />
      );
    },
  },
  {
    field: 'uomCode',
    flex: 1,
    sortable: false,
    renderHeader: () => <TranslatedCell stringId={'mixDesigns_tableHeader_uomCode'} />,
    renderCell: ({ row }) => {
      const num =
        (quantities[row.value] || 0) *
        (uomConversions[row.batchUomCode] && uomConversions[row.batchUomCode][row.uomCode]
          ? uomConversions[row.batchUomCode][row.uomCode]
          : 0);
      return (
        <span className="consumed-inventory-col">{`${getNumber(Math.round(num * 100) / 100)} ${translateMessage(
          `uom_${row.uomCode}`
        )}`}</span>
      );
    },
  },
  {
    field: 'delete',
    flex: 1,
    maxWidth: 80,
    headerName: '',
    sortable: false,
    cellClassName: 'mix-component-delete-button',
    renderCell: row => (
      <IconButton onClick={() => deleteRow(row)} aria-label="delete" size="small">
        <DeleteIcon fontSize="small" />
      </IconButton>
    ),
    valueGetter: ({ row }) => row?.value,
  },
];
