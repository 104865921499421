import { useState } from 'react';
import { Localization, useNotification } from 'connex-cds';
import { func } from 'prop-types';

import {
  useVehicles,
  useVehicleTypes,
  useLocations,
  useCarriers,
  useDrivers,
  useTrailers,
} from '../../../../api/hooks';
import {
  parseVehicleTypesOptions,
  parseLocations,
  parseCarriersOptions,
  parseDriversOptions,
  parseTrailersOptions,
} from '../../../../api/adapters';
import { DIALOG_TYPES, STATUS_OPTIONS } from '../../../../constants';
import { initialValues } from '../vehicle-form-view/form-config';
import VehicleFormView from '../vehicle-form-view';

const AddVehicleDialog = ({ onClose }) => {
  const [isOpen, setIsOpen] = useState(true);
  const notification = useNotification();
  const translateMessage = Localization.useTranslateMessage();
  const { Create: useCreateVehicle } = useVehicles();
  const { mutateAsync: createVehicle } = useCreateVehicle();
  const { List: useListVehicleTypes } = useVehicleTypes();
  const { List: useDriversList } = useDrivers();
  const { List: useTrailerList } = useTrailers();

  const { data: vehicleTypesOptions, isLoading: isLoadingVehicleTypesOptions } = useListVehicleTypes({
    adapter: parseVehicleTypesOptions,
  });
  const { List: useListLocations } = useLocations();
  const { data: locationsOptions, isLoading: isLoadingLocationsOptions } = useListLocations({
    adapter: parseLocations,
  });
  const { List: useListCarriers } = useCarriers();
  const { data: carriersOptions, isLoading: isLoadingCarriersOptions } = useListCarriers({
    adapter: parseCarriersOptions,
  });
  const { data: driversOptions, isLoading: isLoadingDriversOptions } = useDriversList({
    adapter: parseDriversOptions,
  });

  const { data: trailersOptions, isLoading: isLoadingTrailersOptions } = useTrailerList({
    adapter: parseTrailersOptions,
  });

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  const handleOnSubmit = async (values, formikBag) => {
    const {
      vehicleId,
      vehicleName,
      vehicleStatus,
      carrier,
      vehicleType,
      vehicleHomeLocation,
      maxGrossVehicleWeight,
      targetGrossVehicleWeight,
      targetNetVehicleWeight,
      driver,
      trailer,
    } = values;

    formikBag.setSubmitting(true);

    try {
      const vehicle = {
        id: vehicleId,
        name: vehicleName,
        status: vehicleStatus,
        homeLocation: { locationRef: vehicleHomeLocation },
        carrierParty: { carrierRef: carrier },
        vehicleType: { vehicleTypeRef: vehicleType },
        maxGrossVehicleWeight,
        targetGrossVehicleWeight,
        targetNetVehicleWeight,
        configuration: {
          driver: { driverRef: driver },
          trailer: { trailerRef: trailer },
        },
      };

      await createVehicle({ vehicle });
      notification.success(translateMessage('vehicles_notification_vehicleAdded'));

      formikBag.setSubmitting(false);
      formikBag.resetForm();
      handleClose();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <VehicleFormView
      open={isOpen}
      onClose={handleClose}
      mode={DIALOG_TYPES.ADD}
      initialValues={initialValues}
      onSubmit={handleOnSubmit}
      statusOptions={STATUS_OPTIONS}
      vehicleTypesOptions={vehicleTypesOptions}
      isLoadingVehicleTypesOptions={isLoadingVehicleTypesOptions}
      locationsOptions={locationsOptions}
      isLoadingLocationsOptions={isLoadingLocationsOptions}
      carriersOptions={carriersOptions}
      isLoadingCarriersOptions={isLoadingCarriersOptions}
      driversOptions={driversOptions}
      isLoadingDriversOptions={isLoadingDriversOptions}
      trailersOptions={trailersOptions}
      isLoadingTrailersOptions={isLoadingTrailersOptions}
    />
  );
};

AddVehicleDialog.propTypes = {
  onClose: func.isRequired,
};

AddVehicleDialog.defaultProps = {
  onClose: () => {},
};

export default AddVehicleDialog;
