export const getMappedInventoryProductLocations = (locations, inventoryProductLocations, partnerOptions) =>
  locations
    ?.filter(x => !!x.trackInventory)
    ?.map(location => {
      const inventoryProductLocation = inventoryProductLocations.find(x => x.locationRef === location.value);

      let productMappingList;
      if (inventoryProductLocation?.productMapping?.length > 0) {
        productMappingList = inventoryProductLocation.productMapping.map(p => ({
          partner: partnerOptions?.find(partner => partner.value === p.supplierRef)?.value,
          projects: p.projects?.map(project => ({
            value: project.projectRef,
            label: project.projectName,
            id: project.projectId,
          })),
          products: p.products?.map(product => ({
            value: product.productRef,
            label: product.productName,
            id: product.productId,
          })),
          purchaseOrders: p.purchaseOrders?.join(',') ?? '',
          overrideSupplierId: p.overrideSupplierId ?? '',
          overrideCarrierId: p.overrideCarrierId ?? '',
          overrideVehicleId: p.overrideVehicleId ?? '',
          overridePurchaseOrder: p.overridePurchaseOrder ?? '',
          useVendorAsPurchaseOrder: !!p.useVendorAsPurchaseOrder,
        }));
      }

      return {
        ...location,
        safetyStock: inventoryProductLocation?.safetyStock?.toString() ?? '',
        reorderStock: inventoryProductLocation?.reorderStock?.toString() ?? '',
        maxStock: inventoryProductLocation?.maxStock?.toString() ?? '',
        productMappingList,
        inventoryContainers: inventoryProductLocation?.inventoryContainers ?? [],
      };
    }) ?? [];

export const getEmptyProductMappingEntry = () => ({
  partner: '',
  projects: [],
  products: [],
  purchaseOrders: '',
  overrideSupplierId: '',
  overrideCarrierId: '',
  overrideVehicleId: '',
  overridePurchaseOrder: '',
  useVendorAsPurchaseOrder: false,
});

const getStockValue = (inventoryLocation, stockPropKey) =>
  inventoryLocation?.[stockPropKey] || inventoryLocation?.[stockPropKey] === 0
    ? +inventoryLocation[stockPropKey]
    : undefined;

export const getInventoryProductLocationsDataToSubmit = (
  inventoryLocations,
  partnerOptions,
  existingInventoryProductLocations = undefined,
  status = undefined
) => {
  if (!inventoryLocations?.length) {
    return [];
  }

  return inventoryLocations.map(inventoryLocation => {
    const locationInventoryData = existingInventoryProductLocations?.find(
      x => x.locationRef === inventoryLocation.value
    );

    let productMapping = undefined;
    if (inventoryLocation?.productMappingList?.length > 0) {
      productMapping = inventoryLocation.productMappingList.map(p => ({
        supplierRef: p.partner,
        supplierName: partnerOptions?.find(partner => partner.value === p.partner)?.label,
        projects: p.projects?.length
          ? p.projects.map(pj => ({ projectRef: pj.value, projectId: pj.id, projectName: pj.label }))
          : undefined,
        products: p.products?.length
          ? p.products.map(pd => ({ productRef: pd.value, productId: pd.id, productName: pd.label }))
          : undefined,
        purchaseOrders: p.purchaseOrders?.length
          ? p.purchaseOrders
              .split(',')
              .map(po => po.trim())
              .filter(x => x)
          : undefined,
        overrideSupplierId: p.overrideSupplierId,
        overrideCarrierId: p.overrideCarrierId,
        overrideVehicleId: p.overrideVehicleId,
        overridePurchaseOrder: p.overridePurchaseOrder,
        useVendorAsPurchaseOrder: p.useVendorAsPurchaseOrder,
      }));
    } else if (locationInventoryData?.productMapping?.length > 0) {
      productMapping = [];
    }

    const inventoryProductLocation = {
      ...locationInventoryData,
      locationRef: inventoryLocation.value,
      safetyStock: getStockValue(inventoryLocation, 'safetyStock'),
      reorderStock: getStockValue(inventoryLocation, 'reorderStock'),
      maxStock: getStockValue(inventoryLocation, 'maxStock'),
      productMapping,
      inventoryContainers: inventoryLocation.inventoryContainers,
      status,
    };
    return inventoryProductLocation;
  });
};
