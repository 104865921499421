import * as yup from 'yup';
import { STATUS_OPTIONS } from '../../../../constants';

export const TAB_IDS = {
  AddMixComponentProfile: 0,
  AddMixComponentBatchCode: 1,
};

export const validationSchema = yup.object().shape({
  mixComponentId: yup.string().required('required'),
  mixComponentName: yup.string().required('required'),
  mixComponentStatus: yup.string().required('required'),
  mixComponentBatchUOM: yup.string().required('required'),
  mixComponentInventoryUOM: yup.string().required('required'),
});

export const initialValues = {
  mixComponentId: '',
  mixComponentName: '',
  mixComponentStatus: STATUS_OPTIONS[0].value,
  mixComponentBatchUOM: '',
  mixComponentInventoryUOM: '',
  mixComponentProduct: '',
  mixComponentCosts: [],
};
