import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { EntityTable } from '../../commons';

export const EntityTableStyled = styled(EntityTable)`
  ${({ theme, rowHeight }) => css`
    & > * {
      font-size: 14px;
      line-height: 130%;
    }
    box-shadow: ${theme.shadowCA(1)};
    border-radius: 10px;
    .MuiDataGrid-main {
      border: 1px solid ${theme.colorsCA.neutral[40]};
      border-radius: 10px 10px 0 0;
      .MuiDataGrid-columnHeaders {
        .MuiDataGrid-columnHeader {
          box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
          background-color: #f8fafd;
          background: ${theme.validateMode({ light: '#f8fafd', dark: theme.colorsCA.neutral[30] })};
        }
      }
      .MuiDataGrid-virtualScroller {
        padding: 0;
        padding-bottom: 1px;
        max-height: calc(${rowHeight} * 5 * 1px);
      }
      .MuiDataGrid-row {
        font-weight: 400;
        border: none;
        border-top: 1px solid ${theme.colorsCA.neutral[40]};
        border-radius: 0;
        padding-bottom: 10px;
        width: calc(100% - 2px);
        box-shadow: none;
        background: none;
      }
    }
    .MuiDataGrid-footerContainer {
      border: 1px solid ${theme.colorsCA.neutral[40]};
      border-top: none;
      border-radius: 0 0 10px 10px;
      min-height: 38px;
      background: ${theme.validateMode({ light: '#f8fafd', dark: theme.colorsCA.neutral[30] })};

      .table-row-count {
        padding-right: 58px;
      }
    }
  `}
`;

export const EntityTableBorderlessHeaderStyled = styled(EntityTable)`
  ${({ theme, rowHeight }) => css`
    .MuiDataGrid-main {
      padding: 1px 6px 6px 6px;

      .MuiDataGrid-columnHeaders {
        margin-bottom: 18px;
        padding-left: 10px;

        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }

      .MuiDataGrid-virtualScroller {
        border-radius: 12px;
        box-shadow: ${theme.shadowCA(2)};
        padding: 0 20px;
        max-height: calc(${rowHeight} * 5 * 1px);
      }

      .MuiDataGrid-row {
        box-shadow: none;
        border: none;
        border-radius: 0;
        width: calc(100% - 42px);
        background: none;

        &:not(:last-child) {
          border-bottom: 1px solid ${theme.colorsCA.neutral[80]};
        }

        .MuiDataGrid-cell {
          padding-left: 0;

          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
        }
      }
    }
    .MuiDataGrid-footerContainer {
      border: none;
    }
  `}
`;
