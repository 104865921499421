import * as yup from 'yup';
import { STATUS_OPTIONS } from '../../../../constants';

export const TAB_IDS = {
  AddCarrierProfile: 0,
};

export const validationSchema = yup.object().shape({
  carrierId: yup.string().required('required'),
  carrierName: yup.string().required('required'),
  carrierStatus: yup.string().required('required'),
  carrierCompanyFleet: yup.boolean(),
});

export const initialValues = {
  carrierId: '',
  carrierName: '',
  carrierStatus: STATUS_OPTIONS[0].value,
  carrierCompanyFleet: false,
};
