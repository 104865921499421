import styled from '@emotion/styled';
import { css } from '@emotion/react';

import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
} from '@mui/material';

export const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 0px 16px 16px 64px;
  margin: 0 8px;
`;

export const HeaderCell = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
`;

export const LocationLabel = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
`;

export const LocationsContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    padding: 0 16px;
    margin: 0 8px 8px 8px;
    border-radius: 12px;
    box-shadow: ${theme.shadowCA(2)};
  `}
`;

export const Accordion = styled(MuiAccordion)`
  ${({ theme }) => css`
    box-shadow: none;

    &::before {
      background-color: ${theme.colorsCA.neutral[80]};
    }

    &.Mui-expanded::before {
      opacity: 1;
      background-color: ${theme.colorsCA.neutral[80]};
    }
  `}
`;

export const AccordionSummary = styled(MuiAccordionSummary)`
  flex-direction: row-reverse;
  gap: 16px;
  padding: 0;
  align-items: flex-start;

  &.Mui-focusVisible {
    background-color: transparent;
  }

  .MuiAccordionSummary-expandIconWrapper {
    padding: 16px 0;

    svg {
      height: 24px;
      width: 24px;
    }
  }

  .MuiAccordionSummary-content {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
    gap: 20px;
    padding: 16px 0;
    margin: 0;

    & > div {
      flex: 1;
    }

    & > .MuiTextField-root > .MuiInputBase-root {
      max-width: 120px;
    }
  }
`;

export const AccordionDetails = styled(MuiAccordionDetails)`
  ${({ theme }) => css`
    border-top: 1px solid ${theme.colorsCA.neutral[80]};
    padding: 10px 0 0 0;
  `}
`;

export const ListHeader = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-bottom: 16px;

    & > span {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    }

    & > button {
      padding: 0;

      svg {
        height: 20px;
        width: 20px;
        color: ${theme.validateMode({ light: theme.colorsCA.blue[50], dark: theme.colorsCA.blue[60] })};
      }
    }
  `}
`;

export const SectionIndex = styled.div`
  ${({ theme }) => css`
    width: 34px;
    height: 34px;
    border-radius: 17px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: ${theme.validateMode({ light: theme.surfaceCA(5), dark: theme.colorsCA.aqua[30] })};

    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  `}
`;

export const NoDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 68px;
  align-items: center;
`;

export const NoDataHeader = styled.div`
  font-size: 22px;
  font-weight: 600;
`;

export const NoDataText = styled.div`
  font-size: 16px;
  font-weight: 400;
  text-align: center;
`;
