import { useState } from 'react';
import { Localization, useNotification } from 'connex-cds';
import { func } from 'prop-types';

import { DIALOG_TYPES, STATUS_OPTIONS, PRODUCT_TYPE_OPTIONS, ORDER_RESTRICTIONS_OPTIONS } from '../../../../constants';
import { useLocations, usePriceBooks } from '../../../../api/hooks';
import LocationFormView from '../location-form-view';
import { mapperToCreateLocation } from '../../mappers';
import { parsePriceBookOptions } from '../../../../api/adapters';
import { TAX_BASIS_OPTIONS, initialValues } from '../location-form-view/form-config';

const AddLocationDialog = ({ onClose }) => {
  const [isOpen, setIsOpen] = useState(true);

  const { Create: useCreateLocation } = useLocations();
  const { mutateAsync: createLocation } = useCreateLocation();

  const { List: useListPriceBooks } = usePriceBooks();
  const { data: priceBookOptions, isLoading: isLoadingPriceBookOptions } = useListPriceBooks({
    adapter: parsePriceBookOptions,
    queryParams: { showProductDetail: true },
  });

  const translateMessage = Localization.useTranslateMessage();
  const notification = useNotification();

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  const handleOnSubmit = async (values, formikBag) => {
    formikBag.setSubmitting(true);

    try {
      const data = mapperToCreateLocation(values);
      await createLocation({ data });
      notification.success(translateMessage('locations_addLocationsNotification_success_msg'));
      formikBag.setSubmitting(false);
      formikBag.resetForm();
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <LocationFormView
      open={isOpen}
      onClose={handleClose}
      mode={DIALOG_TYPES.ADD}
      initialValues={initialValues}
      onSubmit={handleOnSubmit}
      locationStatusOptions={STATUS_OPTIONS}
      productTypesOptions={PRODUCT_TYPE_OPTIONS}
      priceBookOptions={priceBookOptions}
      isLoadingPriceBookOptions={isLoadingPriceBookOptions}
      restrictToPricebookOptions={ORDER_RESTRICTIONS_OPTIONS}
      taxBasisOptions={TAX_BASIS_OPTIONS}
    />
  );
};

AddLocationDialog.propTypes = {
  onClose: func.isRequired,
};

AddLocationDialog.defaultProps = {
  onClose: () => {},
};

export default AddLocationDialog;
