import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const MixComponentsTabContainer = styled.div`
  ${({ theme }) => css`
    .mix-components-form {
      .MuiAutocomplete-root {
        .MuiOutlinedInput-root {
          min-height: 48px;
          height: auto;
          width: 100%;
          padding: 0 9px;
        }

        .MuiAutocomplete-input {
          padding: 0;
        }
      }
      .consumed-inventory-col {
        padding-left: 10px;
      }
    }
    .mix-component-delete-button {
      button {
        color: ${theme.validateMode({ light: theme.colorsCA.neutral[40], dark: theme.colorsCA.neutral[70] })};
      }
    }
  `}
`;
