import { Localization } from 'connex-cds';
import { Field, Form } from 'formik';
import Grid from '@mui/material/Grid';

import { CheckboxWithFormikField } from '../../../../commons';
import { LocationProfileFormContainer } from './styles';

const LocationTicketSettingsTab = () => {
  return (
    <LocationProfileFormContainer>
      <h3 className="tab-title">
        <Localization.Translate stringId="general_ticketSettingTab_title" />
      </h3>

      <Form>
        <Grid container spacing={2} alignItems="baseline" className="form-content">
          <Grid item md={12} className="custom-form-control-checkbox">
            <Field
              fullWidth
              labelStringId="locations_ticketSettingTab_sendMixDesignEachTicket"
              component={CheckboxWithFormikField}
              name="sendMixDesignEachTicket"
            />
          </Grid>
          <Grid item md={12} className="custom-form-control-checkbox">
            <Field
              fullWidth
              labelStringId="locations_ticketSettingTab_automaticallyPrintTicketPdf"
              component={CheckboxWithFormikField}
              name="automaticallyPrintTicketPdf"
              infoTooltipStringId="locations_ticketSettingTab_automaticallyPrintTicketPdf_tooltip"
            />
          </Grid>
          <Grid item md={12} className="custom-form-control-checkbox">
            <Field
              fullWidth
              labelStringId="locations_ticketSettingTab_mixSyncing"
              component={CheckboxWithFormikField}
              name="mixSyncing"
              infoTooltipStringId="locations_ticketSettingTab_mixSyncing_tooltip"
            />
          </Grid>
        </Grid>
      </Form>
    </LocationProfileFormContainer>
  );
};

export default LocationTicketSettingsTab;
