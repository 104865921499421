import { CustomIcon, TranslatedCell } from '../../commons';
import { KebabOption } from '../../commons/entity-table';

export const getMixListColumns = ({ setDeleteEntity: handleOnDeleteClick, translateMessage }) => [
  {
    field: 'id',
    flex: 1,
    renderHeader: () => <TranslatedCell stringId={'general_tableHeader_id'} />,
  },
  {
    field: 'name',
    flex: 1,
    renderHeader: () => <TranslatedCell stringId={'general_tableHeader_name'} />,
  },
  {
    field: 'formulas',
    flex: 1,
    renderHeader: () => <TranslatedCell stringId={'mixDesigns_tableHeader_hasFormulas'} />,
    renderCell: ({ row }) => {
      const hasFormulas = row?.formulas && row?.formulas.length ? translateMessage('yes') : translateMessage('no');
      return <span title={hasFormulas}>{hasFormulas}</span>;
    },
  },
  {
    field: 'status',
    flex: 1,
    renderHeader: () => <TranslatedCell stringId={'general_tableHeader_status'} />,
    valueGetter: ({ row }) => translateMessage(row.status.toLowerCase()),
  },
  {
    field: 'actions',
    type: 'actions',
    sortable: false,
    width: 80,
    cellClassName: 'table-actions',
    getActions: params => [
      <KebabOption
        icon={<CustomIcon iconName="deleteIcon" />}
        label={translateMessage('delete')}
        onClick={handleOnDeleteClick(params.row)}
        showInMenu
      />,
    ],
  },
];
