export const trimWithDots = (text, charLength = 100) => {
  let trimmedText = text;

  if (Array.isArray(trimmedText)) {
    trimmedText = trimmedText.join('');
  }

  if (trimmedText.length > charLength) {
    return `${trimmedText.slice(0, charLength)}...`;
  }
  return trimmedText;
};

export const getInitials = (firstName, lastName) => {
  const firstNameInitial = firstName ? firstName[0] : '';
  const lastNameInitial = lastName ? lastName[0] : '';

  return `${firstNameInitial}${lastNameInitial}`.trim().toUpperCase();
};
