import { Localization } from 'connex-cds';
import { Stack, Typography } from '@mui/material';

// COMMENTED UNTIL FEATURE IS REQUIRED
// import { CustomButton } from '../../commons';
import { CustomSettingCard } from '../../commons';
import BillingAdminStyled from './styles';
import { CARD_OPTIONS } from './config';

const Billing = () => {
  const translateMessage = Localization.useTranslateMessage();

  return (
    <BillingAdminStyled className="billing-admin-main">
      <header>
        <Stack className="text-content">
          <Typography variant="h3">{translateMessage('billing_home_title')}</Typography>
          {/* COMMENTED UNTIL COPY IS DEFINED */}
          {/* <Typography>{translateMessage('billing_home_description')}</Typography> */}
        </Stack>
        {/* COMMENTED UNTIL FEATURE IS REQUIRED */}
        {/* <CustomButton>{translateMessage('billing_home_button')}</CustomButton> */}
      </header>
      <main>
        <Stack className="config-options">
          {CARD_OPTIONS.map(card => (
            <CustomSettingCard
              key={card.id}
              titleStringId={card.title}
              descriptionStringId={card.description}
              iconName={card.icon}
              navigateTo={card.navigateTo}
              state={card.state}
              displaySubHeader={false}
            />
          ))}
        </Stack>
      </main>
    </BillingAdminStyled>
  );
};

export default Billing;
