import React, { useMemo, useState } from 'react';
import { Localization, useNotification } from 'connex-cds';

import CustomerFormView from '../customer-form-view';
import { DIALOG_TYPES, STATUS_OPTIONS, ORDER_RESTRICTIONS_OPTIONS } from '../../../../constants';
import { TAX_STATUS_OPTIONS } from '../customer-form-view/form-config';
import { useCustomers, useCreditCodes, usePriceBooks } from '../../../../api/hooks';
import { parsePriceBookOptions, parseCreditCodesOptions } from '../../../../api/adapters';
import { mapperYesNo, yesNoToBoolean } from '../../../../util/forms';

const EditCustomerDialog = ({ onClose, customerData, handleOnDelete }) => {
  const [isOpen, setIsOpen] = useState(true);

  const { Update: useUpdateCustomer } = useCustomers();
  const { mutateAsync: updateCustomer } = useUpdateCustomer();

  const { List: useListPriceBooks } = usePriceBooks();
  const { data: priceBookOptions, isLoading: isLoadingPriceBookOptions } = useListPriceBooks({
    adapter: parsePriceBookOptions,
    queryParams: { showProductDetail: true },
  });

  const { List: useListCreditCodes } = useCreditCodes();
  const { data: creditCodeOptions, isLoading: isLoadingCreditCodeOptions } = useListCreditCodes({
    adapter: parseCreditCodesOptions,
  });

  const translateMessage = Localization.useTranslateMessage();
  const notification = useNotification();

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  const handleOnSubmit = async (values, formikBag) => {
    const {
      name,
      status,
      streetAddress,
      complement,
      city,
      state,
      postalCode,
      printPaperTickets,
      printWeights,
      showPricing,
      priceBook,
      taxable,
      creditCode,
      orderRestriction,
    } = values;

    formikBag.setSubmitting(true);

    try {
      const data = {
        name,
        status: status,
        address: {
          address1: streetAddress,
          address2: complement,
          city,
          countrySubDivision: state,
          postalCode,
        },
        pricingOptions: {
          showPricing: mapperYesNo(showPricing),
        },
        ticketOptions: {
          printPaperTickets: mapperYesNo(printPaperTickets),
          printWeights: mapperYesNo(printWeights),
        },
        taxStatus: taxable || undefined,
        costBookRef: priceBook || undefined,
        creditCodeRef: creditCode || undefined,
        restrictToPriceBook: orderRestriction || undefined,
      };

      await updateCustomer({ data, customerRef: customerData.crn });
      notification.success(translateMessage('notification_changesSaved'));

      formikBag.setSubmitting(false);
      formikBag.resetForm();
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  const getInitialValues = useMemo(() => {
    if (!customerData) return null;

    const {
      id,
      name,
      status,
      address,
      pricingOptions,
      ticketOptions,
      costBookRef,
      creditCodeRef,
      restrictToPriceBook,
      taxStatus,
    } = customerData;

    const values = {
      id: id,
      name: name || '',
      status: STATUS_OPTIONS.find(item => item.value === status).value,
      streetAddress: address?.address1 || '',
      complement: address?.address2 || '',
      city: address?.city || '',
      state: address?.countrySubDivision || '',
      postalCode: address?.postalCode || '',
      printPaperTickets: yesNoToBoolean(ticketOptions?.printPaperTickets),
      printWeights: yesNoToBoolean(ticketOptions?.printWeights),
      showPricing: yesNoToBoolean(pricingOptions?.showPricing),
      priceBook: priceBookOptions.find(item => item?.value === costBookRef)?.value,
      taxable: TAX_STATUS_OPTIONS.find(item => item?.value === taxStatus)?.value,
      creditCode: creditCodeOptions.find(item => item?.value === creditCodeRef)?.value,
      orderRestriction: ORDER_RESTRICTIONS_OPTIONS.find(item => item?.value === restrictToPriceBook)?.value,
    };

    return values;
  }, [creditCodeOptions, customerData, priceBookOptions]);

  return (
    <CustomerFormView
      open={isOpen}
      onClose={handleClose}
      mode={DIALOG_TYPES.EDIT}
      initialValues={getInitialValues}
      onSubmit={handleOnSubmit}
      customerStatusOptions={STATUS_OPTIONS}
      handleOnDelete={handleOnDelete}
      priceBookOptions={priceBookOptions}
      isLoadingPriceBookOptions={isLoadingPriceBookOptions}
      creditCodeOptions={creditCodeOptions}
      isLoadingCreditCodeOptions={isLoadingCreditCodeOptions}
      taxableOptions={TAX_STATUS_OPTIONS}
      orderRestrictionOptions={ORDER_RESTRICTIONS_OPTIONS}
    />
  );
};

export default EditCustomerDialog;
