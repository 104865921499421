import * as yup from 'yup';

import { STATUS_OPTIONS } from '../../../../constants';
import { PHONE_REGEX } from '../../../../util/forms';

export const validationSchema = yup.object().shape({
  driverId: yup.string().required('required'),
  driverFirstName: yup.string().required('required'),
  driverLastName: yup.string().required('required'),
  driverMobile: yup.string().matches(PHONE_REGEX, 'error_invalidPhone'),
  driverPhone: yup.string().matches(PHONE_REGEX, 'error_invalidPhone'),
  driverEmail: yup.string().email('error_invalidEmailFormat'),
  driverStatus: yup.string().required('required'),
});

export const initialValues = {
  driverId: '',
  driverFirstName: '',
  driverLastName: '',
  driverMobile: '',
  driverPhone: '',
  driverEmail: '',
  driverStatus: STATUS_OPTIONS[0].value,
};
