import * as yup from 'yup';
import { STATUS_OPTIONS } from '../../../../constants';

export const TAB_IDS = {
  AddMixProfile: 0,
  AddMixComponents: 1,
};

export const validationSchema = yup.object().shape({
  mixDesignId: yup.string().required('required'),
  mixDesignName: yup.string().required('required'),
  mixDesignStatus: yup.string().required('required'),
  maxBatchSize: yup.number().required('required'),
  maxBatchUom: yup.string().required('required'),
  mixFormulaId: yup.string().required('required'),
  mixComponents: yup.array().of(
    yup.object().shape({
      id: yup.string(),
      label: yup.string(),
      name: yup.string(),
      uomCode: yup.string(),
      value: yup.string(),
      weight: yup.number(),
      batchUomCode: yup.string(),
    })
  ),
});

export const initialValues = {
  mixDesignId: '',
  mixDesignName: '',
  mixDesignStatus: STATUS_OPTIONS[0].value,
  maxBatchSize: '',
  maxBatchUom: '',
  mixFormulaId: '',
  mixComponents: [],
  mixDesignComponentsQuantities: {},
};
