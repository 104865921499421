import { useState } from 'react';
import { Formik } from 'formik';
import { Localization } from 'connex-cds';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import DialogContent from '@mui/material/DialogContent';
import WorkOutlineRoundedIcon from '@mui/icons-material/WorkOutlineRounded';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import DriveFileRenameOutlineRoundedIcon from '@mui/icons-material/DriveFileRenameOutlineRounded';
import Button from '@mui/material/Button';

import { initialValues, validationSchema } from './form-config';
import RoleTab from '../role-tab';
import AddUserStepper from './AddUserStepper';
import ContactInfoTab from '../contact-info-tab';
import { useUsers } from '../../../../api/hooks';

import { DialogTitle, DialogActions } from '../../../../commons';
import { DialogStyled } from './styles';

const DetailsDialog = ({ open = false, onClose, roleList = [] }) => {
  const [currentTab, setCurrentTab] = useState(0);
  const { Invite: useUsersInvite } = useUsers();
  const { mutateAsync: inviteUser } = useUsersInvite();

  const ADD_USER_TABS = [
    {
      stringId: 'addUserDialog_tabs_contactInfo',
      component: <ContactInfoTab />,
      icon: <DriveFileRenameOutlineRoundedIcon />,
    },
    {
      stringId: 'general_role',
      component: <RoleTab roleList={roleList} />,
      icon: <WorkOutlineRoundedIcon />,
    },
  ];

  const handleClose = () => {
    onClose();
    setCurrentTab(0);
  };

  const handleTabChange = (_, tabValue) => {
    setCurrentTab(tabValue);
  };

  const goToStep = stepIndex => () => {
    setCurrentTab(stepIndex);
  };

  const handleAddUser = async values => {
    try {
      await inviteUser(values);
      handleClose();
    } catch (error) {
      /**
       * TODO: handle error
       */
      console.error(error);
    }
  };

  const handleCloseDialog = (e, reason) => {
    if (reason === 'escapeKeyDown') {
      handleClose();
    }
  };

  return (
    <DialogStyled className="add-user-dialog" open={open} maxWidth="md" fullWidth onClose={handleCloseDialog}>
      <DialogTitle className="dialog-title" onClose={handleClose}>
        <PersonAddAltOutlinedIcon className="person-icon" />
        <Localization.Translate stringId="addUserDialog_addAUser" />
      </DialogTitle>
      <Formik initialValues={initialValues} onSubmit={handleAddUser} validationSchema={validationSchema}>
        {({ values, isSubmitting, dirty, isValid, handleSubmit }) => {
          return (
            <>
              <Tabs className="add-user-dialog-tabs" value={currentTab} onChange={handleTabChange} variant="fullWidth">
                {ADD_USER_TABS.map(({ stringId, icon }) => (
                  <Tab key={stringId} icon={icon} label={<Localization.Translate stringId={stringId} />} />
                ))}
              </Tabs>
              <DialogContent className="dialog-content" dividers>
                <div className="stepper">
                  <AddUserStepper formValues={values} activeStep={currentTab} onStepClick={handleTabChange} />
                </div>
                <div className="tab-content">{ADD_USER_TABS[currentTab].component}</div>
              </DialogContent>
              <DialogActions
                lefSide={
                  currentTab !== 0 && (
                    <Button onClick={goToStep(currentTab - 1)} className="step-control">
                      <Localization.Translate stringId="general_dialogAction_back" />
                    </Button>
                  )
                }
                rightSide={
                  <>
                    {currentTab < ADD_USER_TABS.length - 1 && (
                      <Button onClick={goToStep(currentTab + 1)} className="step-control">
                        <Localization.Translate stringId="general_dialogAction_next" />
                      </Button>
                    )}
                    {currentTab === ADD_USER_TABS.length - 1 && (
                      <Button
                        type="submit"
                        variant="contained"
                        disabled={isSubmitting || !dirty || !isValid}
                        onClick={handleSubmit}
                      >
                        <Localization.Translate stringId="general_dialogAction_save" />
                      </Button>
                    )}
                  </>
                }
              />
            </>
          );
        }}
      </Formik>
    </DialogStyled>
  );
};

export default DetailsDialog;
