import { TranslatedCell, CustomIcon } from '../../commons';
import { KebabOption } from '../../commons/entity-table';

export const getVehiclesListColumns = ({ setDeleteEntity: setDeleteVehicle, translateMessage }) => [
  {
    field: 'carrier',
    flex: 1,
    renderHeader: () => <TranslatedCell stringId={'general_tableHeader_carrier'} />,
    valueGetter: params => params.row.carrierParty?.name || '',
  },
  {
    field: 'id',
    flex: 1,
    renderHeader: () => <TranslatedCell stringId={'general_tableHeader_id'} />,
  },
  {
    field: 'name',
    flex: 1,
    renderHeader: () => <TranslatedCell stringId={'general_tableHeader_name'} />,
  },
  {
    field: 'vehicleType',
    flex: 1,
    renderHeader: () => <TranslatedCell stringId={'general_tableHeader_vehicleType'} />,
    valueGetter: params => params.row.vehicleType?.name || '',
  },
  {
    field: 'status',
    flex: 1,
    renderHeader: () => <TranslatedCell stringId={'general_tableHeader_status'} />,
    valueGetter: params => translateMessage(params.row.status.toLowerCase()),
  },
  {
    field: 'actions',
    type: 'actions',
    sortable: false,
    width: 80,
    cellClassName: 'table-actions',
    getActions: params => [
      <KebabOption
        icon={<CustomIcon iconName="deleteIcon" />}
        label={translateMessage('delete')}
        onClick={setDeleteVehicle(params.row)}
        showInMenu
      />,
    ],
  },
];
