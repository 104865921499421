import React from 'react';
import { Field, FieldArray } from 'formik';
import IconButton from '@mui/material/IconButton';
import { Localization } from 'connex-cds';
import { ExpandMore, AddCircle } from '@mui/icons-material';
import { array, bool, func, number, shape, string } from 'prop-types';

import { TextInputWithFormikField } from '../../../../commons';
import { getEmptyProductMappingEntry } from '../../utils';
import ProductMappingItem from './ProductMappingItem';
import {
  LocationLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ListHeader,
} from '../inventory-product-locations/styles';
import { AccordionSummaryIconWrapper } from './styles';

const InventoryProductLocationItem = ({
  inventoryLocation,
  inventoryLocationIndex,
  isInventoryProductLocationsDataLoading,
  partnerOptions,
  isLoadingPartners,
  setFieldValue,
}) => {
  const renderLevelCell = (name, labelStringId, disabled) => (
    <Field
      labelStringId={labelStringId}
      component={TextInputWithFormikField}
      variant="outlined"
      name={name}
      type="number"
      inputProps={{ min: 0 }}
      disabled={disabled}
      onClick={e => {
        e.stopPropagation();
      }}
      InputLabelProps={{ shrink: true }}
    />
  );

  return (
    <Accordion key={inventoryLocation.value} disableGutters>
      <AccordionSummary
        expandIcon={
          <AccordionSummaryIconWrapper>
            <ExpandMore />
          </AccordionSummaryIconWrapper>
        }
      >
        <LocationLabel>{inventoryLocation.label}</LocationLabel>
        {renderLevelCell(
          `inventoryLocations.${inventoryLocationIndex}.safetyStock`,
          'inventoryProducts_supply_safetyLevel',
          isInventoryProductLocationsDataLoading
        )}
        {renderLevelCell(
          `inventoryLocations.${inventoryLocationIndex}.reorderStock`,
          'inventoryProducts_supply_reorderLevel',
          isInventoryProductLocationsDataLoading
        )}
        {renderLevelCell(
          `inventoryLocations.${inventoryLocationIndex}.maxStock`,
          'inventoryProducts_supply_maxLevel',
          isInventoryProductLocationsDataLoading
        )}
      </AccordionSummary>
      <FieldArray
        name={`inventoryLocations.${inventoryLocationIndex}.productMappingList`}
        render={({ push, remove }) => (
          <AccordionDetails>
            <ListHeader>
              <span>
                <Localization.Translate stringId="inventoryProducts_supply_productMapping" />
                {inventoryLocation?.productMappingList && inventoryLocation.productMappingList.length > 0 && (
                  <span>&nbsp;({inventoryLocation.productMappingList.length})</span>
                )}
              </span>
              <IconButton onClick={() => push(getEmptyProductMappingEntry())}>
                <AddCircle />
              </IconButton>
            </ListHeader>
            {inventoryLocation?.productMappingList &&
              inventoryLocation.productMappingList.length > 0 &&
              inventoryLocation.productMappingList.map((productMappingItem, productMappingIndex) => (
                <ProductMappingItem
                  partnerOptions={partnerOptions}
                  isLoadingPartners={isLoadingPartners}
                  key={productMappingIndex}
                  inventoryLocationIndex={inventoryLocationIndex}
                  productMappingIndex={productMappingIndex}
                  productMappingItem={productMappingItem}
                  setFieldValue={setFieldValue}
                  removeItem={() => remove(productMappingIndex)}
                />
              ))}
          </AccordionDetails>
        )}
      />
    </Accordion>
  );
};

InventoryProductLocationItem.propTypes = {
  inventoryLocation: shape({
    label: string,
    value: string,
    productMappingList: array,
  }).isRequired,
  inventoryLocationIndex: number.isRequired,
  isInventoryProductLocationsDataLoading: bool,
  partnerOptions: array.isRequired,
  isLoadingPartners: bool,
  setFieldValue: func.isRequired,
};

export default InventoryProductLocationItem;
