import { http } from 'connex-cds';

export default {
  listWorkTypes: ({ entityRef, queryParams }) =>
    http.get({ apiName: 'company-admin', path: `/${entityRef}/work-types`, queryParams }),
  createWorkType: ({ entityRef, data }) =>
    http.post({ apiName: 'company-admin', path: `/${entityRef}/work-types`, data }),
  updateWorkType: ({ entityRef, workTypeRef, data }) =>
    http.patch({ apiName: 'company-admin', path: `/${entityRef}/work-types/${workTypeRef}`, data }),
};
