import { useState } from 'react';
import { Localization, useNotification } from 'connex-cds';
import { func } from 'prop-types';

import MixComponentFormView from '../mix-component-form-view';
import { useComponents, useProducts, useUom } from '../../../../api/hooks';
import { DIALOG_TYPES, STATUS_OPTIONS } from '../../../../constants';
import { initialValues } from '../mix-component-form-view/form-config';
import { parseUomOptions, parseProductOptions } from '../../../../api/adapters';

const AddMixComponentDialog = ({ onClose }) => {
  const [isOpen, setIsOpen] = useState(true);
  const notification = useNotification();
  const { Create: useCreateComponent } = useComponents();
  const { mutateAsync: createComponent } = useCreateComponent();
  const translateMessage = Localization.useTranslateMessage();
  const { List: useListUom } = useUom();
  const { data: uomOptions, isLoading: isLoadingUom } = useListUom({ adapter: parseUomOptions });
  const { List: useListProducts } = useProducts();
  const { data: productOptions, isLoading: isLoadingProduct } = useListProducts({ adapter: parseProductOptions });

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  const handleOnSubmit = async (values, formikBag) => {
    const {
      mixComponentId,
      mixComponentInventoryUOM,
      mixComponentName,
      mixComponentStatus,
      mixComponentBatchUOM,
      mixComponentProduct,
      mixComponentCosts,
    } = values;

    formikBag.setSubmitting(true);

    try {
      const mixComponent = {
        id: mixComponentId,
        name: mixComponentName,
        batchUomCode: mixComponentBatchUOM,
        uomCode: mixComponentInventoryUOM,
        productRef: mixComponentProduct,
        costs: mixComponentCosts,
        status: mixComponentStatus,
      };
      await createComponent({ mixComponent });
      notification.success(translateMessage('mixComponents_notification_mixComponentAdded'));
      formikBag.setSubmitting(false);
      formikBag.resetForm();
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <MixComponentFormView
      open={isOpen}
      onClose={handleClose}
      mode={DIALOG_TYPES.ADD}
      initialValues={initialValues}
      onSubmit={handleOnSubmit}
      mixComponentStatusOptions={STATUS_OPTIONS}
      uomOptions={uomOptions}
      isLoadingUom={isLoadingUom}
      productOptions={productOptions}
      isLoadingProduct={isLoadingProduct}
    />
  );
};

AddMixComponentDialog.propTypes = {
  onClose: func.isRequired,
};

AddMixComponentDialog.defaultProps = {
  onClose: () => {},
};

export default AddMixComponentDialog;
