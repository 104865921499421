import { Localization } from 'connex-cds';

import { getProductListColumns } from './tableConfig';
import AddProductDialog from './components/add-product-dialog';
import DeleteProductDialog from './components/delete-product-dialog';
import { EntityTable, TableLayout, ActionButton, SearchFilter } from '../../commons';
import { useProducts } from '../../api/hooks';
import EditProductDialog from './components/edit-product-dialog';
import { DialogManager } from '../../commons';
import { DIALOG_TYPES } from '../../constants';
import { useTableLogic } from '../../hooks';

const Products = () => {
  const filteredPropsArr = ['name', 'id', 'type'];
  const {
    setQuery,
    currentEntity,
    dialog,
    setDialog,
    isLoading,
    filteredData,
    entityColumns,
    resetDialogs,
    handleOnRowClick,
    setIsOnEditDialog,
  } = useTableLogic(useProducts, getProductListColumns, filteredPropsArr);

  return (
    <TableLayout.ListLayoutContainer>
      <TableLayout.TableControls>
        <SearchFilter onSearchChange={setQuery} disabled={isLoading} resultsCount={filteredData.length} />
        <ActionButton
          icon="addOutlinedIcon"
          label={<Localization.Translate stringId="products_addProduct_title" />}
          variant="outlined"
          onClick={() => setDialog(DIALOG_TYPES.ADD)}
        />
      </TableLayout.TableControls>
      <TableLayout.TableBody>
        <EntityTable
          checkboxSelection={false}
          getRowId={row => row.crn}
          rows={filteredData}
          columns={entityColumns}
          onRowClick={handleOnRowClick}
          isLoading={isLoading}
        />
      </TableLayout.TableBody>
      <DialogManager screen={dialog}>
        <AddProductDialog identifier={DIALOG_TYPES.ADD} onClose={resetDialogs} />
        <EditProductDialog
          identifier={DIALOG_TYPES.EDIT}
          handleOnDelete={() => {
            setIsOnEditDialog(true);
            setDialog(DIALOG_TYPES.DELETE);
          }}
          productData={currentEntity}
          onClose={resetDialogs}
        />
        ;
        <DeleteProductDialog identifier={DIALOG_TYPES.DELETE} productData={currentEntity} onClose={resetDialogs} />;
      </DialogManager>
    </TableLayout.ListLayoutContainer>
  );
};

export default Products;
