import { useState } from 'react';
import { Localization, useNotification } from 'connex-cds';
import { func } from 'prop-types';

import {
  useInventoryProducts,
  useLocations,
  useMixes,
  useProductTypes,
  useProducts,
  useUom,
  useConcreteClasses,
} from '../../../../api/hooks';
import ProductFormView from '../product-form-view';
import {
  BOOLEAN_OPTIONS,
  DIALOG_TYPES,
  STATUS_OPTIONS,
  CONCRETE_CLASS_TYPE_AIR,
  CONCRETE_CLASS_TYPE_CONSISTENCE,
  CONCRETE_CLASS_TYPE_SLUMP_LOSS_RATE,
  CONCRETE_CLASS_TYPE_TEMPERATURE,
} from '../../../../constants';
import { initialValues } from '../product-form-view/form-config';
import {
  parseInventoryProductOptions,
  parseLocations,
  parseMixOptions,
  parseProductTypesOptions,
  parseUomOptions,
  parseConcreteClassesOptions,
} from '../../../../api/adapters';
import { getCosts, isProductTypeConcrete } from '../../util';

const AddProductDialog = ({ onClose }) => {
  const [isOpen, setIsOpen] = useState(true);
  const { List: useListProductTypes } = useProductTypes();
  const { data: productTypesOptions, isLoading: isLoadingProductTypes } = useListProductTypes({
    adapter: parseProductTypesOptions,
  });
  const { List: listMixes } = useMixes();
  const { List: useListUom } = useUom();
  const { data: uomOptions, isLoading: isLoadingUom } = useListUom({ adapter: parseUomOptions });
  const { List: useListLocations } = useLocations();
  const { data: locationOptions, isLoading: isLoadingLocations } = useListLocations({ adapter: parseLocations });
  const { List: useListInventoryProducts } = useInventoryProducts();
  const { data: inventoryProductOptions, isLoading: isLoadingInventoryProducts } = useListInventoryProducts({
    adapter: parseInventoryProductOptions,
  });
  const { Create: useCreateProduct } = useProducts();
  const { mutateAsync: createProduct } = useCreateProduct();
  const notification = useNotification();
  const translateMessage = Localization.useTranslateMessage();
  const { data: mixesOptions, isLoading: isLoadingMixes } = listMixes({
    queryParams: { activeOnly: true },
    adapter: parseMixOptions,
  });
  const { List: useListConcreteClasses } = useConcreteClasses();
  const { data: concreteClassesOptions, isLoading: isLoadingConcreteClasses } = useListConcreteClasses({
    adapter: parseConcreteClassesOptions,
  });
  const consistenceClassOptions = concreteClassesOptions.filter(
    option => option.classType === CONCRETE_CLASS_TYPE_CONSISTENCE
  );
  const slumpLossClassOptions = concreteClassesOptions.filter(
    option => option.classType === CONCRETE_CLASS_TYPE_SLUMP_LOSS_RATE
  );
  const temperatureClassOptions = concreteClassesOptions.filter(
    option => option.classType === CONCRETE_CLASS_TYPE_TEMPERATURE
  );
  const airClassOptions = concreteClassesOptions.filter(option => option.classType === CONCRETE_CLASS_TYPE_AIR);

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  const handleOnSubmit = async (values, formikBag) => {
    const {
      productId,
      productName,
      productStatus,
      productType,
      uom,
      locations,
      inventoryProduct,
      consistenceClass,
      slumpLossClass,
      temperatureClass,
      airClass,
      defaultPrice,
      taxable,
      costs,
      mix,
    } = values;

    const locationsObject = locations.reduce((accumulator, location) => {
      return { ...accumulator, [location.value]: true };
    }, {});

    const costObject = getCosts(costs, locationsObject);

    formikBag.setSubmitting(true);

    try {
      const product = {
        id: productId,
        name: productName,
        type: productType,
        uomCode: uom,
        locations: locations.length === locationOptions.length ? { all: true } : locationsObject,
        inventoryProductRef: inventoryProduct,
        status: productStatus,
        consistenceRef: '',
        slumpLossRateRef: '',
        temperatureRef: '',
        airRef: '',
        costs: costObject,
        mix: { mixRef: '' },
      };

      if (defaultPrice !== '') {
        product.unitPrice = Number(defaultPrice);
      }

      if (taxable !== '') {
        product.taxable = taxable;
      }

      if (isProductTypeConcrete(productType)) {
        product.consistenceRef = consistenceClass;
        product.slumpLossRateRef = slumpLossClass;
        product.temperatureRef = temperatureClass;
        product.airRef = airClass;
        product.mix = { mixRef: mix };
      }

      await createProduct({ product });
      notification.success(translateMessage('productNotification_productAdded'));
    } catch (error) {
      console.error(error);
    }

    formikBag.setSubmitting(false);
    formikBag.resetForm();
    handleClose();
  };

  return (
    <ProductFormView
      open={isOpen}
      onClose={handleClose}
      mode={DIALOG_TYPES.ADD}
      initialValues={initialValues}
      onSubmit={handleOnSubmit}
      productTypesOptions={productTypesOptions}
      isLoadingProductTypes={isLoadingProductTypes}
      mixesOptions={mixesOptions}
      isLoadingMixes={isLoadingMixes}
      uomOptions={uomOptions}
      isLoadingUom={isLoadingUom}
      locationOptions={locationOptions}
      isLoadingLocations={isLoadingLocations}
      productStatusOptions={STATUS_OPTIONS}
      inventoryProductOptions={inventoryProductOptions}
      isLoadingInventoryProducts={isLoadingInventoryProducts}
      isLoadingConcreteClasses={isLoadingConcreteClasses}
      consistenceClassOptions={consistenceClassOptions}
      slumpLossClassOptions={slumpLossClassOptions}
      temperatureClassOptions={temperatureClassOptions}
      airClassOptions={airClassOptions}
      taxableOptions={BOOLEAN_OPTIONS}
    />
  );
};

AddProductDialog.propTypes = {
  onClose: func,
};

AddProductDialog.defaultProps = {
  onClose: () => {},
};

export default AddProductDialog;
