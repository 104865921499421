import { Localization } from 'connex-cds';

import { getLocationsListColumns } from './tableConfig';
import { useLocations } from '../../api/hooks';
import { TableLayout, DialogManager, SearchFilter, EntityTable, ActionButton } from '../../commons';
import { DIALOG_TYPES } from '../../constants';
import AddLocationDialog from './components/add-new-location-dialog';
import EditLocationDialog from './components/edit-location-dialog';
import DeleteLocationDialog from './components/delete-location-dialog';
import { useTableLogic } from '../../hooks';

const Locations = () => {
  const filteredPropsArr = ['id', 'name'];
  const {
    setQuery,
    currentEntity,
    dialog,
    setDialog,
    isLoading,
    filteredData,
    entityColumns,
    resetDialogs,
    handleOnRowClick,
    setIsOnEditDialog,
  } = useTableLogic(useLocations, getLocationsListColumns, filteredPropsArr, { showPriceBookDetail: true });

  return (
    <TableLayout.ListLayoutContainer>
      <TableLayout.TableControls>
        <SearchFilter onSearchChange={setQuery} disabled={isLoading} resultsCount={filteredData.length} />
        <ActionButton
          icon="addOutlinedIcon"
          label={<Localization.Translate stringId="locations_addLocations_title" />}
          variant="outlined"
          onClick={() => setDialog(DIALOG_TYPES.ADD)}
        />
      </TableLayout.TableControls>
      <TableLayout.TableBody>
        <EntityTable
          checkboxSelection={false}
          getRowId={row => row.crn}
          rows={filteredData}
          columns={entityColumns}
          isLoading={isLoading}
          onRowClick={handleOnRowClick}
        />
      </TableLayout.TableBody>
      <DialogManager screen={dialog}>
        <AddLocationDialog identifier={DIALOG_TYPES.ADD} onClose={resetDialogs} />
        <EditLocationDialog
          identifier={DIALOG_TYPES.EDIT}
          handleOnDelete={() => {
            setIsOnEditDialog(true);
            setDialog(DIALOG_TYPES.DELETE);
          }}
          locationData={currentEntity}
          onClose={resetDialogs}
        />
        <DeleteLocationDialog identifier={DIALOG_TYPES.DELETE} onClose={resetDialogs} locationData={currentEntity} />
      </DialogManager>
    </TableLayout.ListLayoutContainer>
  );
};

export default Locations;
