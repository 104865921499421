import { useState } from 'react';
import { Localization } from 'connex-cds';
import { Formik } from 'formik';
import { Button } from '@mui/material';

import { DIALOG_TYPES } from '../../../../constants';
import {
  CustomIcon,
  CustomTabsControl,
  CustomTabsView,
  DialogActions,
  DialogContainer,
  DialogContent,
  DialogTitle,
} from '../../../../commons';
import { TAB_IDS, validationSchema } from './form-config';
import CustomerProfileTab from '../customer-profile-tab';
import PricingTab from '../pricing-tab';
import TicketSettingsTab from '../ticket-settings-tab';

const CustomerFormView = ({
  open,
  initialValues,
  onClose,
  onSubmit,
  mode,
  customerStatusOptions,
  handleOnDelete,
  priceBookOptions,
  isLoadingPriceBookOptions,
  taxableOptions,
  creditCodeOptions,
  isLoadingCreditCodeOptions,
  orderRestrictionOptions,
}) => {
  const [currentTab, setCurrentTab] = useState(TAB_IDS.CustomerProfile);

  const handleTabChange = (_, value) => {
    setCurrentTab(value);
  };

  const goToStep = stepIndex => () => {
    setCurrentTab(stepIndex);
  };

  const handleClose = () => {
    onClose();
    setCurrentTab(TAB_IDS.CustomerProfile);
  };

  const handleCloseDialog = (e, reason) => {
    if (reason === 'escapeKeyDown') {
      handleClose();
    }
  };

  const renderHeader = mode => {
    let icon;
    let stringId;

    switch (mode) {
      case DIALOG_TYPES.ADD:
        icon = 'addOutlinedIcon';
        stringId = 'customers_addCustomer_title';
        break;

      case DIALOG_TYPES.EDIT:
        icon = 'editOutlinedIcon';
        stringId = 'customers_editCustomer_title';
        break;

      default:
        icon = '';
        stringId = '';
        break;
    }

    return (
      <>
        <CustomIcon iconName={icon} size={'medium'} />
        <Localization.Translate stringId={stringId} />
      </>
    );
  };

  const TABS = [
    {
      tabIdentifier: TAB_IDS.CustomerProfile,
      label: <Localization.Translate stringId={'customers_addCustomerProfile_tab'} />,
      icon: <CustomIcon iconName={'driveFileRenameOutlineRounded'} />,
    },
    {
      tabIdentifier: TAB_IDS.Pricing,
      label: <Localization.Translate stringId={'general_pricing'} />,
      icon: <CustomIcon iconName={'priceChangeOutlined'} />,
    },
    {
      tabIdentifier: TAB_IDS.TicketSettings,
      label: <Localization.Translate stringId={'general_ticketSettingTab_title'} />,
      icon: <CustomIcon iconName={'settingsOutlinedIcon'} />,
    },
  ];

  return (
    <DialogContainer open={open} maxWidth="md" fullWidth onClose={handleCloseDialog}>
      <DialogTitle onDelete={handleOnDelete} onClose={handleClose}>
        {renderHeader(mode)}
      </DialogTitle>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} enableReinitialize>
        {({ isSubmitting, dirty, isValid, handleSubmit, values }) => {
          return (
            <>
              <CustomTabsControl tabs={TABS} currentTab={currentTab} handleTabChange={handleTabChange} />
              <DialogContent>
                <CustomTabsView tab={currentTab}>
                  <CustomerProfileTab
                    mode={mode}
                    tabIdentifier={TAB_IDS.CustomerProfile}
                    customerStatusOptions={customerStatusOptions}
                  />
                  <PricingTab
                    tabIdentifier={TAB_IDS.Pricing}
                    priceBookOptions={priceBookOptions}
                    isLoadingPriceBookOptions={isLoadingPriceBookOptions}
                    taxableOptions={taxableOptions}
                    creditCodeOptions={creditCodeOptions}
                    isLoadingCreditCodeOptions={isLoadingCreditCodeOptions}
                    orderRestrictionOptions={orderRestrictionOptions}
                    currentPriceBook={values?.priceBook}
                  />
                  <TicketSettingsTab tabIdentifier={TAB_IDS.TicketSettings} />
                </CustomTabsView>
              </DialogContent>
              <DialogActions
                lefSide={
                  currentTab !== 0 && (
                    <Button onClick={goToStep(currentTab - 1)} className="step-control">
                      <Localization.Translate stringId="general_dialogAction_back" />
                    </Button>
                  )
                }
                rightSide={
                  <>
                    {currentTab < TABS.length - 1 && (
                      <Button onClick={goToStep(currentTab + 1)} className="step-control">
                        <Localization.Translate stringId="general_dialogAction_next" />
                      </Button>
                    )}
                    {currentTab === TABS.length - 1 && (
                      <Button
                        type="submit"
                        variant="contained"
                        disabled={isSubmitting || !dirty || !isValid}
                        onClick={handleSubmit}
                      >
                        <Localization.Translate stringId="general_dialogAction_save" />
                      </Button>
                    )}
                  </>
                }
              />
            </>
          );
        }}
      </Formik>
    </DialogContainer>
  );
};

export default CustomerFormView;
