import { useMemo, useState } from 'react';
import { Localization, useNotification } from 'connex-cds';
import { func, shape, string } from 'prop-types';

import { DIALOG_TYPES, STATUS_OPTIONS, PRODUCT_TYPE_OPTIONS, ORDER_RESTRICTIONS_OPTIONS } from '../../../../constants';
import { useLocations, usePriceBooks } from '../../../../api/hooks';
import LocationFormView from '../location-form-view';
import { mapperToGetInitialValues, mapperToEditLocation } from '../../mappers';
import { parsePriceBookOptions } from '../../../../api/adapters';
import { TAX_BASIS_OPTIONS } from '../location-form-view/form-config';

const EditLocationDialog = ({ onClose, locationData, handleOnDelete }) => {
  const [isOpen, setIsOpen] = useState(true);

  const { Update: useUpdateLocation } = useLocations();
  const { mutateAsync: updateLocation } = useUpdateLocation();

  const { List: useListPriceBooks } = usePriceBooks();
  const { data: priceBookOptions, isLoading: isLoadingPriceBookOptions } = useListPriceBooks({
    adapter: parsePriceBookOptions,
    queryParams: { showProductDetail: true },
  });

  const translateMessage = Localization.useTranslateMessage();
  const notification = useNotification();

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  const handleOnSubmit = async (values, formikBag) => {
    formikBag.setSubmitting(true);

    try {
      const data = mapperToEditLocation(values, locationData);
      await updateLocation({ data, locationRef: locationData.crn });
      notification.success(translateMessage('notification_changesSaved'));
      formikBag.setSubmitting(false);
      formikBag.resetForm();
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  const initialValues = useMemo(
    () => mapperToGetInitialValues(locationData, priceBookOptions),
    [locationData, priceBookOptions]
  );

  return (
    <LocationFormView
      handleOnDelete={handleOnDelete}
      open={isOpen}
      onClose={handleClose}
      mode={DIALOG_TYPES.EDIT}
      initialValues={initialValues}
      onSubmit={handleOnSubmit}
      locationStatusOptions={STATUS_OPTIONS}
      productTypesOptions={PRODUCT_TYPE_OPTIONS}
      priceBookOptions={priceBookOptions}
      isLoadingPriceBookOptions={isLoadingPriceBookOptions}
      restrictToPricebookOptions={ORDER_RESTRICTIONS_OPTIONS}
      taxBasisOptions={TAX_BASIS_OPTIONS}
    />
  );
};

EditLocationDialog.propTypes = {
  onClose: func.isRequired,
  handleOnDelete: func,
  locationData: shape({
    crn: string.isRequired,
    id: string.isRequired,
    name: string.isRequired,
    description: string,
    primaryProductType: string,
    status: string.isRequired,
    address: shape({}),
  }),
};

EditLocationDialog.defaultProps = {
  onClose: () => {},
  locationData: {},
};

export default EditLocationDialog;
