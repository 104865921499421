import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const TicketSettingsContainer = styled.div`
  ${css`
    .form-content {
      padding-left: 12px;
    }
  `}
`;
