import React, { useState } from 'react';
import { Localization, useNotification } from 'connex-cds';
import PropTypes from 'prop-types';

import { useProducts } from '../../../../api/hooks';
import { ConfirmationDialog } from '../../../../commons';
import { DELETED_STATUS } from '../../../../constants/common';
import { trimWithDots } from '../../../../util/strings';

const DeleteProductDialog = ({ productData, onClose }) => {
  const [isOpen, setIsOpen] = useState(true);
  const notification = useNotification();
  const { Update: useUpdateProduct } = useProducts();
  const { mutateAsync: updateProduct } = useUpdateProduct();
  const translateMessage = Localization.useTranslateMessage();

  const onConfirmDialog = async () => {
    try {
      if (productData?.crn) {
        const product = {
          status: DELETED_STATUS,
        };

        await updateProduct({ product, productRef: productData?.crn });

        notification.success(translateMessage('productNotification_productDeleted'));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnClose = (isDeleteSuccess = false) => {
    setIsOpen(false);
    onClose(isDeleteSuccess);
  };

  return (
    <ConfirmationDialog
      open={isOpen}
      onClose={handleOnClose}
      onConfirm={onConfirmDialog}
      confirmText="delete"
      color="delete"
    >
      <h3 className="delete-title">
        <Localization.Translate stringId="productsDialog_deleteProduct_title" />
      </h3>
      <p className="delete-description">
        <Localization.Translate
          stringId="productsDialog_deleteProduct_description"
          values={{
            strong: chunks => <strong>{trimWithDots(chunks, 100)}</strong>,
            name: productData?.name || '',
          }}
        />
      </p>
    </ConfirmationDialog>
  );
};

DeleteProductDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  productData: PropTypes.shape({
    crn: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
};

export default DeleteProductDialog;
