import React, { useState } from 'react';
import { Formik } from 'formik';
import { Localization } from 'connex-cds';
import { Button } from '@mui/material';
import { TAB_IDS, validationSchema } from './form-config';
import { isProductTypeConcrete } from '../../util';
import {
  DialogTitle,
  CustomTabsControl,
  CustomTabsView,
  CustomIcon,
  DialogContent,
  DialogActions,
  DialogContainer,
} from '../../../../commons';
import { DIALOG_TYPES } from '../../../../constants';
import ProductProfileTab from '../product-profile-tab';
import ProductPricingTab from '../product-pricing-tab';
import ProductConcreteClassesTab from '../product-concrete-classes-tab';

const ProductFormView = ({
  open,
  onClose,
  mode,
  initialValues,
  onSubmit,
  productTypesOptions,
  isLoadingProductTypes,
  mixesOptions,
  isLoadingMixes,
  uomOptions,
  isLoadingUom,
  locationOptions,
  isLoadingLocations,
  productStatusOptions,
  inventoryProductOptions,
  isLoadingInventoryProducts,
  handleOnDelete,
  isLoadingConcreteClasses,
  consistenceClassOptions,
  slumpLossClassOptions,
  temperatureClassOptions,
  airClassOptions,
  taxableOptions,
}) => {
  const [currentTab, setCurrentTab] = useState(TAB_IDS.AddProductProfile);

  const handleTabChange = (_, value) => {
    setCurrentTab(value);
  };

  const goToStep = stepIndex => () => {
    setCurrentTab(stepIndex);
  };

  const handleClose = () => {
    onClose();
    setCurrentTab(0);
  };

  const handleCloseDialog = (e, reason) => {
    if (reason === 'escapeKeyDown') {
      handleClose();
    }
  };

  const renderHeader = mode => {
    let icon;
    let stringId;

    switch (mode) {
      case DIALOG_TYPES.ADD:
        icon = 'addOutlinedIcon';
        stringId = 'products_addProduct_title';
        break;
      case DIALOG_TYPES.EDIT:
        icon = 'editOutlinedIcon';
        stringId = 'products_editProduct_title';
        break;

      default:
        icon = '';
        stringId = '';
        break;
    }

    return (
      <>
        <CustomIcon iconName={icon} size={'medium'} />
        <Localization.Translate stringId={stringId} />
      </>
    );
  };

  return (
    <DialogContainer open={open} maxWidth="md" fullWidth onClose={handleCloseDialog}>
      <DialogTitle onDelete={handleOnDelete} onClose={handleClose}>
        {renderHeader(mode)}
      </DialogTitle>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} enableReinitialize>
        {({ values, isSubmitting, dirty, isValid, handleSubmit, setFieldValue }) => {
          const { productType, locations } = values;

          const TABS = [
            {
              tabIdentifier: TAB_IDS.AddProductProfile,
              label: <Localization.Translate stringId={'products_addProduct_productProfile'} />,
              icon: <CustomIcon iconName={'driveFileRenameOutlineRounded'} />,
            },
            {
              tabIdentifier: TAB_IDS.Pricing,
              label: <Localization.Translate stringId={'general_pricing'} />,
              icon: <CustomIcon iconName={'priceChangeOutlined'} />,
            },
            ...(isProductTypeConcrete(productType)
              ? [
                  {
                    tabIdentifier: TAB_IDS.ConcreteClasses,
                    label: <Localization.Translate stringId={'products_concreteClassesTab_title'} />,
                    icon: <CustomIcon iconName={'layersOutlinedIcon'} />,
                  },
                ]
              : []),
          ];

          return (
            <>
              <CustomTabsControl tabs={TABS} currentTab={currentTab} handleTabChange={handleTabChange} />
              <DialogContent>
                <CustomTabsView tab={currentTab}>
                  <ProductProfileTab
                    tabIdentifier={TAB_IDS.AddProductProfile}
                    isLoadingProductTypes={isLoadingProductTypes}
                    productTypesOptions={productTypesOptions}
                    isLoadingMixes={isLoadingMixes}
                    mixesOptions={mixesOptions}
                    isLoadingUom={isLoadingUom}
                    uomOptions={uomOptions}
                    locationOptions={locationOptions}
                    isLoadingLocations={isLoadingLocations}
                    productType={productType}
                    productStatusOptions={productStatusOptions}
                    inventoryProductOptions={inventoryProductOptions}
                    isLoadingInventoryProducts={isLoadingInventoryProducts}
                    mode={mode}
                  />
                  <ProductPricingTab
                    tabIdentifier={TAB_IDS.Pricing}
                    taxableOptions={taxableOptions}
                    setFieldValue={setFieldValue}
                    locations={locations}
                  />
                  <ProductConcreteClassesTab
                    tabIdentifier={TAB_IDS.ConcreteClasses}
                    isLoadingConcreteClasses={isLoadingConcreteClasses}
                    consistenceClassOptions={consistenceClassOptions}
                    slumpLossClassOptions={slumpLossClassOptions}
                    temperatureClassOptions={temperatureClassOptions}
                    airClassOptions={airClassOptions}
                  />
                </CustomTabsView>
              </DialogContent>
              <DialogActions
                lefSide={
                  currentTab !== 0 && (
                    <Button onClick={goToStep(currentTab - 1)} className="step-control">
                      <Localization.Translate stringId="general_dialogAction_back" />
                    </Button>
                  )
                }
                rightSide={
                  <>
                    {currentTab < TABS.length - 1 && (
                      <Button onClick={goToStep(currentTab + 1)} className="step-control">
                        <Localization.Translate stringId="general_dialogAction_next" />
                      </Button>
                    )}
                    {currentTab === TABS.length - 1 && (
                      <Button
                        type="submit"
                        variant="contained"
                        disabled={isSubmitting || !dirty || !isValid}
                        onClick={handleSubmit}
                      >
                        <Localization.Translate stringId="general_dialogAction_save" />
                      </Button>
                    )}
                  </>
                }
              />
            </>
          );
        }}
      </Formik>
    </DialogContainer>
  );
};

export default ProductFormView;
