import { useState, useMemo } from 'react';
import { Localization, useNotification } from 'connex-cds';
import { array, func, object, bool } from 'prop-types';

import { usePriceBooks } from '../../../../api/hooks';
import { DIALOG_TYPES, STATUS_OPTIONS } from '../../../../constants';
import PriceBookFormView from '../price-book-form-view';
import { getPricesFromObject, pricesMapper } from '../../../../util/pricesMapper';

const EditPriceBookDialog = ({ onClose, priceBookData, handleOnDelete, productList, isProductListLoading }) => {
  const [isOpen, setIsOpen] = useState(true);
  const { Update: useUpdatePriceBook } = usePriceBooks();
  const { mutateAsync: updatePriceBook } = useUpdatePriceBook();
  const notification = useNotification();
  const translateMessage = Localization.useTranslateMessage();

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  const handleOnSubmit = async (values, formikBag) => {
    const { priceBookName, priceBookStatus, priceBookProductPrice } = values;

    const newProductPrices = getPricesFromObject(priceBookProductPrice);

    const prices = pricesMapper(productList, newProductPrices);

    formikBag.setSubmitting(true);

    try {
      const priceBook = {
        name: priceBookName,
        status: priceBookStatus,
        prices: prices,
      };

      await updatePriceBook({ priceBook, priceBookRef: priceBookData?.crn });
      notification.success(translateMessage('notification_changesSaved'));

      formikBag.setSubmitting(false);
      formikBag.resetForm();
      handleClose();
    } catch (error) {
      console.error(error);
    }
  };

  const getInitialValues = useMemo(() => {
    if (!priceBookData) return null;
    const { id, name } = priceBookData;

    const productsPrices = productList?.reduce((accumulator, product) => {
      const matchingPrice = priceBookData?.prices?.find(productPrice => productPrice.productRef === product.crn);
      return matchingPrice ? { ...accumulator, [product.crn]: matchingPrice?.price?.value } : accumulator;
    }, {});

    const editValues = {
      priceBookId: id,
      priceBookName: name,
      priceBookStatus: STATUS_OPTIONS.find(status => status.value === priceBookData.status).value,
      priceBookProductPrice: productsPrices,
    };

    return editValues;
  }, [priceBookData, productList]);

  return (
    <PriceBookFormView
      open={isOpen}
      onClose={handleClose}
      mode={DIALOG_TYPES.EDIT}
      initialValues={getInitialValues}
      onSubmit={handleOnSubmit}
      priceBookStatusOptions={STATUS_OPTIONS}
      handleOnDelete={handleOnDelete}
      productList={productList}
      isProductListLoading={isProductListLoading}
      priceBookData={priceBookData}
    />
  );
};

EditPriceBookDialog.propTypes = {
  priceBookData: object,
  onClose: func.isRequired,
  handleOnDelete: func,
  productList: array,
  isProductListLoading: bool,
};

EditPriceBookDialog.defaultProps = {
  onClose: () => {},
};

export default EditPriceBookDialog;
