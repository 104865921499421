import React from 'react';
import { Field, Form } from 'formik';
import { Localization } from 'connex-cds';
import { Grid } from '@mui/material';
import { array, bool, func, string } from 'prop-types';

import { AutocompleteWithFormikField, TextInputWithFormikField, SelectWithFormikField } from '../../../../commons';
import { DIALOG_TYPES } from '../../../../constants';
import { InventoryProductProfileContainer } from './styles';

const InventoryProductProfileTab = ({
  mode,
  uomOptions,
  isLoadingUom,
  locationOptions,
  isLoadingLocations,
  inventoryProductStatusOptions,
  setFieldValue,
  onLocationsChange,
}) => {
  const handleLocationsChange = setFieldValue => (name, value) => {
    setFieldValue(name, value);
    if (onLocationsChange) {
      onLocationsChange(setFieldValue, value);
    }
  };

  return (
    <InventoryProductProfileContainer>
      <h3 className="inventory-product-profile-title">
        <Localization.Translate stringId="general_profile" />
      </h3>
      <Form>
        <Grid container spacing={5}>
          <Grid item md={6} xs={12} order={{ xs: 1, md: 1 }}>
            <Field
              fullWidth
              labelStringId="inventoryProducts_profileTab_inventoryProductId"
              component={TextInputWithFormikField}
              variant="outlined"
              name="inventoryProductId"
              required
              disabled={mode === DIALOG_TYPES.EDIT}
            />
          </Grid>
          <Grid item md={6} xs={12} order={{ xs: 2, md: 3 }}>
            <Field
              fullWidth
              labelStringId="inventoryProducts_profileTab_inventoryProductName"
              component={TextInputWithFormikField}
              variant="outlined"
              name="inventoryProductName"
              required
            />
          </Grid>
          <Grid item md={6} xs={12} order={{ xs: 3, md: 2 }}>
            <Field
              fullWidth
              labelStringId="inventoryProducts_profileTab_inventoryProductUom"
              component={SelectWithFormikField}
              name="inventoryProductUom"
              disabled={isLoadingUom}
              options={uomOptions}
              required
            />
          </Grid>
          <Grid item md={6} xs={12} order={{ xs: 4, md: 5 }}>
            <Field
              fullWidth
              labelStringId="locations"
              component={AutocompleteWithFormikField}
              name="locations"
              disabled={isLoadingLocations}
              options={locationOptions}
              customOnChange={handleLocationsChange(setFieldValue)}
              testId="autocomplete-locations"
            />
          </Grid>
          <Grid item md={6} xs={12} order={{ xs: 5, md: 4 }}>
            <Field
              fullWidth
              labelStringId="status"
              component={SelectWithFormikField}
              name="inventoryProductStatus"
              options={inventoryProductStatusOptions}
              required
            />
          </Grid>
        </Grid>
      </Form>
    </InventoryProductProfileContainer>
  );
};

InventoryProductProfileTab.propTypes = {
  mode: string.isRequired,
  uomOptions: array.isRequired,
  isLoadingUom: bool.isRequired,
  locationOptions: array.isRequired,
  isLoadingLocations: bool.isRequired,
  inventoryProductStatusOptions: array.isRequired,
  setFieldValue: func.isRequired,
  onLocationsChange: func,
};

export default InventoryProductProfileTab;
