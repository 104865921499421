import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { inventoryProductLocations } from '../queries';

export const useInventoryProductLocations = () => {
  const { entityRef } = useParams();

  const List = inventoryProductRef => {
    const query = useQuery({
      queryKey: ['inventory-product-locations', entityRef, inventoryProductRef],
      queryFn: () => inventoryProductLocations.listInventoryProductLocations({ entityRef, inventoryProductRef }),
      staleTime: Infinity,
    });

    return query;
  };

  if (!entityRef) {
    console.trace();
    return undefined;
  }

  return { List };
};
