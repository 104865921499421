import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { Field, Form } from 'formik';
import { bool, array, func, object } from 'prop-types';
import Box from '@mui/material/Box';
import { Localization } from 'connex-cds';

import { AutocompleteWithFormikField, DialogTable } from '../../../../commons';
import { getMixComponentColumns } from './tableConfig';
import { MixComponentsTabContainer } from './styles';

const MixComponentsTab = ({
  isLoadingMixComponents,
  mixComponentsOptions,
  setFieldValue,
  selectedComponents,
  isSubmitting,
  quantities,
}) => {
  const { getNumber } = Localization.useNumber();
  const translateMessage = Localization.useTranslateMessage();
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    setSelectedOptions(selectedComponents);
  }, [selectedComponents]);

  const handleMixComponentChange = setFieldValue => (name, value) => {
    setSelectedOptions(value);
    setFieldValue(name, value);
  };

  const deleteRow = useCallback(
    row => {
      const newOptions = selectedOptions.filter(option => option.value !== row.value);
      setSelectedOptions(newOptions);
      setFieldValue('mixComponents', newOptions);
      setFieldValue(`mixDesignComponentsQuantities.${row.value}`);
    },
    [selectedOptions, setSelectedOptions, setFieldValue]
  );

  const calcInventoryValue = setFieldValue => (name, value) => {
    setFieldValue(name, value);
  };

  const columns = useMemo(
    () =>
      getMixComponentColumns({ deleteRow, calcInventoryValue, setFieldValue, quantities, getNumber, translateMessage }),
    [deleteRow, setFieldValue, quantities, getNumber, translateMessage]
  );

  const handleOnReorderChange = params => {
    const rowsClone = [...selectedOptions];
    const row = rowsClone.splice(params.oldIndex, 1)[0];
    rowsClone.splice(params.targetIndex, 0, row);
    setSelectedOptions(rowsClone);
    setFieldValue('mixComponents', rowsClone);
  };

  return (
    <MixComponentsTabContainer>
      <Form className="mix-components-form">
        <Field
          testId="autocomplete-mix-components"
          labelStringId="mixDesigns_addComponents"
          component={AutocompleteWithFormikField}
          name="mixComponents"
          disabled={isLoadingMixComponents || isSubmitting}
          options={mixComponentsOptions}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          customOnChange={handleMixComponentChange(setFieldValue)}
        />
        <Box mt={3}>
          <DialogTable
            checkboxSelection={false}
            isLoading={isLoadingMixComponents}
            columns={columns}
            getRowId={row => row.value}
            rows={selectedOptions?.map((row, index) => ({ ...row, index }))}
            rowHeight={68}
            rowReordering
            variant="borderless-header"
            onRowOrderChange={handleOnReorderChange}
          />
        </Box>
      </Form>
    </MixComponentsTabContainer>
  );
};

MixComponentsTab.propTypes = {
  isLoadingMixComponents: bool.isRequired,
  mixComponentsOptions: array.isRequired,
  setFieldValue: func.isRequired,
  selectedComponents: array,
  isSubmitting: bool,
  quantities: object,
};

export default MixComponentsTab;
