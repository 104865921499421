import PropTypes from 'prop-types';

import { CustomIcon } from '..';
import { ImagePreviewStyled } from './styles';

const ImagePreview = props => {
  const { defaultIcon, ...otherProps } = props;
  return (
    <ImagePreviewStyled alt="Image preview" {...otherProps}>
      <CustomIcon iconName={defaultIcon} />
    </ImagePreviewStyled>
  );
};

export default ImagePreview;

ImagePreview.propTypes = {
  defaultIcon: PropTypes.string,
  src: PropTypes.string,
  size: PropTypes.oneOf(['xsmall', 'small', 'medium', 'large']),
};

ImagePreview.defaultProps = {
  defaultIcon: 'photoOutlinedIcon',
};
