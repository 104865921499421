import React, { useMemo, useState, useCallback } from 'react';
import { Localization } from 'connex-cds';

import { getInventoryProductsListColumns } from './tableConfig';
import { useInventoryProducts } from '../../api/hooks';
import { TableLayout, DialogManager, SearchFilter, EntityTable, ActionButton } from '../../commons';
import { getFilteredDataByProperties } from '../../commons/search-filter';
import { DIALOG_TYPES } from '../../constants';
import AddInventoryProductDialog from './components/add-inventory-product-dialog';
import EditInventoryProductDialog from './components/edit-inventory-product-dialog';
import DeleteInventoryProductDialog from './components/delete-inventory-product-dialog';

const InventoryProducts = () => {
  const translateMessage = Localization.useTranslateMessage();
  const [query, setQuery] = useState('');
  const [currentInventoryProduct, setCurrentInventoryProduct] = useState(null);
  const [dialog, setDialog] = useState(DIALOG_TYPES.NONE);
  const [isOnEditDialog, setIsOnEditDialog] = useState(false);
  const { List: useListInventoryProducts } = useInventoryProducts();
  const { data: inventoryProducts, isLoading } = useListInventoryProducts({ queryParams: { activeOnly: false } });

  const filteredData = useMemo(
    () => getFilteredDataByProperties({ data: inventoryProducts, query, properties: ['id', 'name'] }),
    [inventoryProducts, query]
  );

  const setDelete = useCallback(
    inventoryProduct => () => {
      setDialog(DIALOG_TYPES.DELETE);
      setCurrentInventoryProduct(inventoryProduct);
    },
    []
  );

  const columns = useMemo(
    () => getInventoryProductsListColumns({ setDeleteInventoryProduct: setDelete, translateMessage }),
    [setDelete, translateMessage]
  );

  const resetDialogs = () => {
    if (isOnEditDialog) {
      setDialog(DIALOG_TYPES.EDIT);
      setIsOnEditDialog(false);
      return;
    }
    setCurrentInventoryProduct(null);
    setDialog(DIALOG_TYPES.NONE);
  };

  const handleOnRowClick = params => {
    setCurrentInventoryProduct(params.row);
    setDialog(DIALOG_TYPES.EDIT);
  };

  const handleOnDelete = () => {
    setIsOnEditDialog(true);
    setDialog(DIALOG_TYPES.DELETE);
  };

  return (
    <TableLayout.ListLayoutContainer>
      <TableLayout.TableControls>
        <SearchFilter onSearchChange={setQuery} disabled={isLoading} resultsCount={filteredData.length} />
        <ActionButton
          icon="addOutlinedIcon"
          label={<Localization.Translate stringId="inventoryProducts_addInventoryProduct_title" />}
          variant="outlined"
          onClick={() => setDialog(DIALOG_TYPES.ADD)}
        />
      </TableLayout.TableControls>
      <TableLayout.TableBody>
        <EntityTable
          checkboxSelection={false}
          getRowId={row => row.crn}
          rows={filteredData}
          columns={columns}
          isLoading={isLoading}
          onRowClick={handleOnRowClick}
        />
      </TableLayout.TableBody>
      <DialogManager screen={dialog}>
        <AddInventoryProductDialog identifier={DIALOG_TYPES.ADD} onClose={resetDialogs} />
        <EditInventoryProductDialog
          identifier={DIALOG_TYPES.EDIT}
          inventoryProductData={currentInventoryProduct}
          onClose={resetDialogs}
          onDelete={handleOnDelete}
        />
        <DeleteInventoryProductDialog
          identifier={DIALOG_TYPES.DELETE}
          onClose={resetDialogs}
          inventoryProductData={currentInventoryProduct}
        />
      </DialogManager>
    </TableLayout.ListLayoutContainer>
  );
};

export default InventoryProducts;
