import { css } from '@emotion/react';

const backgroundStyles = ({ theme }) => css`
  .main-container {
    background-image: url(${theme.backgroundImageMode});
    background-size: cover;
    position: relative;
    z-index: 1;
    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 80px;
      position: absolute;
      top: 0;
      left: 0;
      background: ${theme.validateMode({ light: 'rgba(251, 251, 251, 0.7)', dark: '#272629' })};
      filter: blur(17px);
      backdrop-filter: blur(5px);
      z-index: -1;
    }
  }
`;

const GlobalStyles = ({ theme, hasBackground }) => css`
  ${hasBackground && backgroundStyles({ theme })}

  .main-container > .content {
    padding: 0 20px 0 10px;
  }
`;

export default GlobalStyles;
