import React from 'react';
import { Localization } from 'connex-cds';
import { Typography } from '@mui/material';

import { SwitchLabelStyled } from './styles';

const SwitchLabel = ({ labelStringId }) => {
  const translateMessage = Localization.useTranslateMessage();

  return (
    <SwitchLabelStyled>
      <Typography>{translateMessage(labelStringId)}</Typography>
    </SwitchLabelStyled>
  );
};

export default SwitchLabel;
