import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Localization } from 'connex-cds';
import * as S from './styles';
import { Stack } from '@mui/material';

/**
 * Custom PhoneNumberField component that integrates with form handling utilities.
 *
 * @param props - The properties for the PhoneNumberField.
 *
 * @returns A form-aware PhoneNumberField component.
 */
const PhoneNumberField = props => {
  const translateMessage = Localization.useTranslateMessage();
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  return (
    <Stack onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <S.ReactPhoneInput
        {...props}
        country={props.countryCode ? props.countryCode : 'us'}
        specialLabel={translateMessage(props.token)}
        value={props.field.value}
        enableLongNumbers={true}
        onFocus={() => setIsInputFocused(true)}
        onBlur={() => setIsInputFocused(false)}
        isInputFocused={isInputFocused}
        hover={isHovered}
        error={props.error}
      />
    </Stack>
  );
};

PhoneNumberField.propTypes = {
  error: PropTypes.bool,
  token: PropTypes.string,
  countryCode: PropTypes.string,
  field: PropTypes.object,
};

export default PhoneNumberField;
