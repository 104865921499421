import { Localization } from 'connex-cds';
import { Field, Form } from 'formik';
import { array } from 'prop-types';
import Grid from '@mui/material/Grid';

import { SelectWithFormikField, DialogTable, NumberInputWithFormikField } from '../../../../commons';
import { getLocationsListColumns } from './tableConfig';

import { ProductPricingContainer } from './styles';

const ProductPricingTab = ({ taxableOptions, locations }) => {
  const columns = getLocationsListColumns();

  return (
    <ProductPricingContainer>
      <h3 className="tab-title">
        <Localization.Translate stringId="general_pricing" />
      </h3>
      <Form>
        <Grid container columnSpacing={5} rowSpacing={3.5}>
          <Grid item xs={6}>
            <Field
              fullWidth
              labelStringId="products_pricingTab_defaultPrice"
              component={NumberInputWithFormikField}
              name="defaultPrice"
              type="currency"
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              fullWidth
              testId="select-taxable"
              labelStringId="general_taxable"
              infoTooltipStringId="products_pricingTab_taxable_tooltip"
              component={SelectWithFormikField}
              name="taxable"
              options={taxableOptions}
            />
          </Grid>
          <Grid item xs={12}>
            <DialogTable
              variant={'borderless-header'}
              checkboxSelection={false}
              isLoading={false}
              columns={columns}
              rows={locations}
              getRowId={row => row.value}
              rowHeight={80}
              hideFooter={true}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'label', sort: 'asc' }],
                },
              }}
            />
          </Grid>
        </Grid>
      </Form>
    </ProductPricingContainer>
  );
};

ProductPricingTab.propTypes = {
  taxableOptions: array.isRequired,
  locations: array.isRequired,
};

export default ProductPricingTab;
