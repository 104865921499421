import * as yup from 'yup';
import { STATUS_OPTIONS } from '../../../../constants';

export const TAB_IDS = {
  CustomerProfile: 0,
  Pricing: 1,
  TicketSettings: 2,
};

export const TAX_STATUS_OPTIONS = [
  { value: 'TAXABLE', translationKey: 'yes' },
  { value: 'EXEMPT', translationKey: 'no' },
];

export const validationSchema = yup.object().shape({
  id: yup.string().required('required'),
  name: yup.string().required('required'),
  status: yup.string().required('required'),
  streetAddress: yup.string().required('required'),
  complement: yup.string(),
  city: yup.string().required('required'),
  state: yup.string().required('required'),
  postalCode: yup.string().required('required'),
});

export const initialValues = {
  id: '',
  name: '',
  status: STATUS_OPTIONS[0].value,
  streetAddress: '',
  complement: '',
  city: '',
  state: '',
  postalCode: '',
  printPaperTickets: false,
  printWeights: false,
  showPricing: false,
  priceBook: '',
  taxable: '',
  creditCode: '',
  orderRestriction: '',
};
