import SvgIcon from '@mui/material/SvgIcon';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import CategoryOutlined from '@mui/icons-material/CategoryOutlined';
import Delete from '@mui/icons-material/Delete';
import EditOutlined from '@mui/icons-material/EditOutlined';
import DriveFileRenameOutlineRounded from '@mui/icons-material/DriveFileRenameOutlineRounded';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import InventoryOutlined from '@mui/icons-material/InventoryOutlined';
import PowerInputOutlined from '@mui/icons-material/PowerInputOutlined';
import PriceChangeOutlined from '@mui/icons-material/PriceChangeOutlined';
import CodeOutlined from '@mui/icons-material/CodeOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import { LocalAtmOutlined, InfoOutlined, ArrowBack } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import PermIdentityOutlined from '@mui/icons-material/PermIdentityOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';
import ScheduleOutlinedIcon from '@mui/icons-material/ScheduleOutlined';
import PhotoOutlinedIcon from '@mui/icons-material/PhotoOutlined';
import FilterIcon from '@mui/icons-material/Filter';
import CropRotateIcon from '@mui/icons-material/CropRotate';
import CropOriginalIcon from '@mui/icons-material/CropOriginal';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import ComputerIcon from '@mui/icons-material/Computer';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';

import { ReactComponent as NonStarted } from '../../assets/icons/nonStarted.svg';
import { ReactComponent as Started } from '../../assets/icons/started.svg';
import { ReactComponent as Completed } from '../../assets/icons/completed.svg';

const iconNames = {
  addOutlinedIcon: props => <AddOutlinedIcon {...props} />,
  personAddAltOutlinedIcon: props => <PersonAddAltOutlinedIcon {...props} />,
  categoryOutlined: props => <CategoryOutlined {...props} />,
  deleteIcon: props => <Delete {...props} />,
  editOutlinedIcon: props => <EditOutlined {...props} />,
  driveFileRenameOutlineRounded: props => <DriveFileRenameOutlineRounded {...props} />,
  highlightOffOutlinedIcon: props => <HighlightOffOutlinedIcon {...props} />,
  inventoryOutlined: props => <InventoryOutlined {...props} />,
  powerInputOutlined: props => <PowerInputOutlined {...props} />,
  priceChangeOutlined: props => <PriceChangeOutlined {...props} />,
  nonStarted: props => <SvgIcon inheritViewBox component={NonStarted} {...props} />,
  started: props => <SvgIcon inheritViewBox component={Started} {...props} />,
  completed: props => <SvgIcon inheritViewBox component={Completed} {...props} />,
  taxation: props => <LocalAtmOutlined {...props} />,
  codeOutlined: props => <CodeOutlined {...props} />,
  alert: props => <InfoOutlined {...props} />,
  arrowBack: props => <ArrowBack {...props} />,
  closeIcon: props => <CloseIcon {...props} />,
  businessOutlinedIcon: props => <BusinessOutlinedIcon {...props} />,
  cameraAltOutlinedIcon: props => <CameraAltOutlinedIcon {...props} />,
  permIdentityOutlined: props => <PermIdentityOutlined {...props} />,
  settingsOutlinedIcon: props => <SettingsOutlinedIcon {...props} />,
  layersOutlinedIcon: props => <LayersOutlinedIcon {...props} />,
  scheduleOutlinedIcon: props => <ScheduleOutlinedIcon {...props} />,
  photoOutlinedIcon: props => <PhotoOutlinedIcon {...props} />,
  filterIcon: props => <FilterIcon {...props} />,
  cropRotateIcon: props => <CropRotateIcon {...props} />,
  cropOriginalIcon: props => <CropOriginalIcon {...props} />,
  computerIcon: props => <ComputerIcon {...props} />,
  personOutlineOutlinedIcon: props => <PersonOutlineOutlinedIcon {...props} />,
  workOutlineOutlinedIcon: props => <WorkOutlineOutlinedIcon {...props} />,
  listAltOutlinedIcon: props => <ListAltOutlinedIcon {...props} />,
  calendarMonthOutlinedIcon: props => <CalendarMonthOutlinedIcon {...props} />,
  fileDownloadOutlinedIcon: props => <FileDownloadOutlinedIcon {...props} />,
  uploadFileOutlinedIcon: props => <UploadFileOutlinedIcon {...props} />,
};
export default iconNames;
