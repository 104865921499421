import { CURRENCY_CODE } from '../constants';

export const pricesMapper = (productList, newProductPrices) => {
  const productsWithPrices = productList.reduce((accum, product) => {
    return newProductPrices.hasOwnProperty(product.crn)
      ? [...accum, { ...product, price: newProductPrices[product.crn] }]
      : accum;
  }, []);

  const prices = productsWithPrices.map(product => {
    return {
      id: product.id,
      productRef: product.crn,
      price: {
        currencyCode: CURRENCY_CODE.USD,
        value: product.price,
        uomCode: product.uomCode,
      },
    };
  });

  return prices;
};

export const getPricesFromObject = obj => {
  return Object.fromEntries(
    Object.entries(obj)
      .filter(([_, value]) => {
        return value !== '' && !isNaN(Number(value));
      })
      .map(([key, value]) => [key, Number(value)])
  );
};
