import { Localization } from 'connex-cds';

import { useMixes } from '../../api/hooks';
import { TableLayout, EntityTable, SearchFilter, ActionButton, DialogManager } from '../../commons';
import { getMixListColumns } from './tableConfig';
import { DIALOG_TYPES } from '../../constants';
import AddMixDesignDialog from './components/add-mix-dialog';
import EditMixDesignDialog from './components/edit-mix-dialog';
import DeleteMixDesignDialog from './components/delete-mix-dialog';
import { useTableLogic } from '../../hooks';

const Mixes = () => {
  const filteredPropsArr = ['id', 'name'];
  const {
    setQuery,
    currentEntity,
    dialog,
    setDialog,
    isLoading,
    filteredData,
    entityColumns,
    resetDialogs,
    handleOnRowClick,
    setIsOnEditDialog,
  } = useTableLogic(useMixes, getMixListColumns, filteredPropsArr);

  return (
    <TableLayout.ListLayoutContainer>
      <TableLayout.TableControls>
        <SearchFilter onSearchChange={setQuery} disabled={isLoading} resultsCount={filteredData.length} />
        <ActionButton
          icon="addOutlinedIcon"
          label={<Localization.Translate stringId="mixDesigns_addMixDesign" />}
          variant="outlined"
          onClick={() => setDialog(DIALOG_TYPES.ADD)}
        />
      </TableLayout.TableControls>
      <TableLayout.TableBody>
        <EntityTable
          checkboxSelection={false}
          isLoading={isLoading}
          columns={entityColumns}
          rows={filteredData}
          onRowClick={handleOnRowClick}
          getRowId={row => row.crn}
        />
      </TableLayout.TableBody>
      <DialogManager screen={dialog}>
        <AddMixDesignDialog identifier={DIALOG_TYPES.ADD} onClose={resetDialogs} />
        <EditMixDesignDialog
          identifier={DIALOG_TYPES.EDIT}
          handleOnDelete={() => {
            setIsOnEditDialog(true);
            setDialog(DIALOG_TYPES.DELETE);
          }}
          mixData={currentEntity}
          onClose={resetDialogs}
        />
        <DeleteMixDesignDialog identifier={DIALOG_TYPES.DELETE} mixData={currentEntity} onClose={resetDialogs} />
      </DialogManager>
    </TableLayout.ListLayoutContainer>
  );
};

export default Mixes;
