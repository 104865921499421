import React from 'react';
import { Field, FieldArray, Form } from 'formik';
import { Localization } from 'connex-cds';
import { Grid } from '@mui/material';
import { array } from 'prop-types';

import { SelectWithFormikField } from '../../../../commons';
import TranslatedCell from '../../../../commons/translated-cell/TranslatedCell';
import InventoryProductLocationsNoData from '../inventory-product-locations/InventoryProductLocationsNoData';
import { InventoryProductInventoryContainer } from './styles';
import { HeaderRow, HeaderCell, LocationsContainer } from '../inventory-product-locations/styles';
import InventoryProductLocationInventoryItem from './InventoryProductLocationInventoryItem';

const InventoryProductInventoryTab = ({ inventorySourceOptions, inventoryLocations }) => {
  return (
    <InventoryProductInventoryContainer>
      <h3 className="inventory-product-inventory-title">
        <Localization.Translate stringId="general_inventory" />
      </h3>
      <Form>
        <Grid container spacing={5}>
          <Grid item md={6} xs={12} order={{ xs: 1, md: 1 }}>
            <Field
              fullWidth
              labelStringId="inventoryProducts_profileTab_inventorySource"
              component={SelectWithFormikField}
              name="inventorySource"
              options={inventorySourceOptions}
              required
            />
          </Grid>
          <Grid item md={12} xs={12} order={{ xs: 2, md: 2 }}>
            <FieldArray
              name="inventoryLocations"
              render={() => (
                <>
                  <HeaderRow>
                    <HeaderCell>
                      <TranslatedCell stringId={'general_location'} />
                    </HeaderCell>
                    <HeaderCell>
                      <TranslatedCell stringId="inventoryProducts_inventory_inventoryContainers" />
                    </HeaderCell>
                  </HeaderRow>
                  <LocationsContainer>
                    {inventoryLocations && inventoryLocations.length > 0 ? (
                      inventoryLocations.map((inventoryLocation, index) => (
                        <InventoryProductLocationInventoryItem
                          inventoryLocation={inventoryLocation}
                          inventoryLocationIndex={index}
                          key={index}
                        />
                      ))
                    ) : (
                      <InventoryProductLocationsNoData />
                    )}
                  </LocationsContainer>
                </>
              )}
            />
          </Grid>
        </Grid>
      </Form>
    </InventoryProductInventoryContainer>
  );
};

InventoryProductInventoryTab.propTypes = {
  inventorySourceOptions: array.isRequired,
  inventoryLocations: array,
};

export default InventoryProductInventoryTab;
