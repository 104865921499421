import Icon from '../../commons/custom-icon';
import { TranslatedCell } from '../../commons';
import { IconCell, KebabOption } from '../../commons/entity-table';

export const getUserListColumns = ({ setUserDelete = null }) => [
  {
    field: 'displayName',
    flex: 1,
    renderHeader: () => <TranslatedCell stringId={'usersTable_header_displayName'} />,
    renderCell: ({ row }) => {
      const { firstName, lastName } = row;
      const fullName = `${firstName || ''} ${lastName || ''}`;
      if (!fullName.trim()) return null;
      return <IconCell value={fullName} profileImage={row?.profileImage?.link} avatarInfo={{ firstName, lastName }} />;
    },
    valueGetter: params => `${params.row.firstName || ''} ${params.row.lastName || ''}`,
  },
  {
    field: 'email',
    flex: 1,
    renderHeader: () => <TranslatedCell stringId={'usersTable_header_email'} />,
  },
  {
    field: 'role',
    flex: 1,
    renderHeader: () => <TranslatedCell stringId={'usersTable_header_role'} />,
    valueGetter: params => params.row.role?.name || '',
  },
  {
    field: 'products',
    sortable: false,
    flex: 1,
    renderHeader: () => <TranslatedCell stringId={'usersTable_header_products'} />,
    valueGetter: params => {
      const apps = params.row.role?.appRefs || [];
      return apps.join(', ');
    },
  },
  {
    field: 'actions',
    type: 'actions',
    sortable: false,
    width: 80,
    cellClassName: 'table-actions',
    getActions: params => [
      <KebabOption
        icon={<Icon iconName="deleteIcon" />}
        label="Delete"
        onClick={setUserDelete(params.row)}
        showInMenu
      />,
    ],
  },
];
