import { Field } from 'formik';

import { TranslatedCell, NumberInputWithFormikField } from '../../../../commons';

export const getLocationsListColumns = () => [
  {
    field: 'label',
    flex: 1,
    sortable: false,
    renderHeader: () => <TranslatedCell stringId={'general_location'} />,
  },
  {
    field: 'value',
    flex: 1,
    sortable: false,
    maxWidth: 200,
    renderHeader: () => <TranslatedCell stringId={'products_pricingTab_overrideCost'} />,
    renderCell: row => (
      <Field
        labelStringId="general_formPlaceholder_addPrice"
        component={NumberInputWithFormikField}
        name={`costs.${row.value}`}
        type="currency"
      />
    ),
  },
];
