import { useMemo, useState } from 'react';
import { defaults, pick } from 'lodash';
import PropTypes from 'prop-types';
import { Localization, useNotification } from 'connex-cds';

import { categoryOptions, initialValues as defaultInitialValues } from '../product-type-form-view/form-config';
import { useProductTypes } from '../../../../api/hooks';
import ProductTypeFormView from '../product-type-form-view';
import { DIALOG_TYPES, STATUS_OPTIONS } from '../../../../constants/common';

const EditProductTypeDialog = ({ onClose, productType, handleOnDelete }) => {
  const [isOpen, setIsOpen] = useState(true);
  const notification = useNotification();
  const translateMessage = Localization.useTranslateMessage();
  const { Update: useUpdateProductType } = useProductTypes();
  const { mutateAsync: updateProductType } = useUpdateProductType();

  const initialValues = useMemo(() => {
    let values = {};

    if (productType) {
      values = pick(productType, ['id', 'name', 'status', 'category']);
      values.status = STATUS_OPTIONS.find(status => status.value === values.status).value;
      values.category = categoryOptions.find(category => category.value === values.category)?.value;
    }

    return defaults(values, defaultInitialValues);
  }, [productType]);

  const handleEditProductType = async values => {
    const data = { name: values.name, category: values.category, status: values.status };
    await updateProductType({ productTypeRef: productType.crn, productType: data });
    onClose();
    notification.success(translateMessage('notification_changesSaved'));
  };

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  return (
    <ProductTypeFormView
      handleOnDelete={handleOnDelete}
      open={isOpen}
      onClose={handleClose}
      mode={DIALOG_TYPES.EDIT}
      initialValues={initialValues}
      onSubmit={handleEditProductType}
    />
  );
};

EditProductTypeDialog.propTypes = {
  onClose: PropTypes.func,
  handleOnDelete: PropTypes.func,
  productType: PropTypes.shape({
    crn: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    status: PropTypes.oneOf(['ACTIVE', 'INACTIVE']),
    category: PropTypes.string,
  }),
};

EditProductTypeDialog.defaultProps = {
  open: false,
  onClose: () => null,
};

export default EditProductTypeDialog;
