import { useState, useMemo } from 'react';
import { Localization, useNotification } from 'connex-cds';
import { func, object } from 'prop-types';

import { useConcreteClasses } from '../../../../api/hooks';
import {
  DIALOG_TYPES,
  STATUS_OPTIONS,
  CONCRETE_CLASS_TYPE_AIR,
  CONCRETE_CLASS_TYPE_CONSISTENCE,
  CONCRETE_CLASS_TYPE_SLUMP_LOSS_RATE,
  CONCRETE_CLASS_TYPE_TEMPERATURE,
} from '../../../../constants';
import {
  CONCRETE_CLASS_TYPE_OPTIONS,
  CONCRETE_CLASS_SUBTYPE_OPTIONS,
  UOM_TEMPERATURE_OPTIONS,
  UOM_LENGTH_OPTIONS,
} from '../concrete-class-form-view/form-config';
import ConcreteClassFormView from '../concrete-class-form-view';

const EditConcreteClassDialog = ({ onClose, concreteClassData, handleOnDelete }) => {
  const [isOpen, setIsOpen] = useState(true);
  const { Update: useUpdateConcreteClass } = useConcreteClasses();
  const { mutateAsync: updateConcreteClass } = useUpdateConcreteClass();
  const notification = useNotification();
  const translateMessage = Localization.useTranslateMessage();

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  const handleOnSubmit = async (values, formikBag) => {
    const {
      concreteClassName,
      concreteClassStatus,
      concreteClassType,
      concreteClassSubtype,
      concreteClassesDefaultSettings,
      concreteClassNominalValue,
      concreteClassMinimumValue,
      concreteClassMaximumValue,
      concreteClassUOM,
    } = values;

    formikBag.setSubmitting(true);

    try {
      const concreteClass = {
        name: concreteClassName,
        status: concreteClassStatus,
        classType: concreteClassType,
        isDefault: concreteClassesDefaultSettings,
        classSubType: '',
        nominalValue: '',
        minimumValue: '',
        maximumValue: '',
      };

      switch (concreteClassType) {
        case CONCRETE_CLASS_TYPE_AIR:
          concreteClass.nominalValue = concreteClassNominalValue || '';
          concreteClass.minimumValue = concreteClassMinimumValue || '';
          concreteClass.maximumValue = concreteClassMaximumValue || '';
          break;
        case CONCRETE_CLASS_TYPE_CONSISTENCE:
          concreteClass.classSubType = concreteClassSubtype;
          concreteClass.uomCode = concreteClassUOM;
          concreteClass.nominalValue = concreteClassNominalValue || '';
          concreteClass.minimumValue = concreteClassMinimumValue || '';
          concreteClass.maximumValue = concreteClassMaximumValue || '';
          break;
        case CONCRETE_CLASS_TYPE_SLUMP_LOSS_RATE:
          concreteClass.uomCode = concreteClassUOM;
          concreteClass.nominalValue = concreteClassNominalValue || '';
          break;
        case CONCRETE_CLASS_TYPE_TEMPERATURE:
          concreteClass.uomCode = concreteClassUOM;
          concreteClass.minimumValue = concreteClassMinimumValue || '';
          concreteClass.maximumValue = concreteClassMaximumValue || '';
          break;
        default:
          break;
      }

      await updateConcreteClass({ data: concreteClass, concreteClassRef: concreteClassData?.crn });
      notification.success(translateMessage('notification_changesSaved'));

      formikBag.setSubmitting(false);
      formikBag.resetForm();
      handleClose();
    } catch (error) {
      console.error(error);
    }
  };

  const getInitialValues = useMemo(() => {
    if (!concreteClassData) return null;

    const { id, name, classType, classSubType, isDefault, nominalValue, minimumValue, maximumValue, uomCode } =
      concreteClassData;

    let uomOptions = [];
    if ([CONCRETE_CLASS_TYPE_CONSISTENCE, CONCRETE_CLASS_TYPE_SLUMP_LOSS_RATE].includes(classType)) {
      uomOptions = UOM_LENGTH_OPTIONS;
    } else if (classType === CONCRETE_CLASS_TYPE_TEMPERATURE) {
      uomOptions = UOM_TEMPERATURE_OPTIONS;
    }

    const editValues = {
      concreteClassId: id,
      concreteClassName: name,
      concreteClassStatus: STATUS_OPTIONS.find(status => status.value === concreteClassData.status).value,
      concreteClassType: CONCRETE_CLASS_TYPE_OPTIONS.find(option => option.value === classType)?.value,
      concreteClassSubtype: CONCRETE_CLASS_SUBTYPE_OPTIONS.find(option => option.value === classSubType)?.value,
      concreteClassesDefaultSettings: isDefault,
      concreteClassNominalValue: nominalValue || '',
      concreteClassMinimumValue: minimumValue || '',
      concreteClassMaximumValue: maximumValue || '',
      concreteClassUOM: uomOptions.find(uomItem => uomItem.value === uomCode)?.value,
    };

    return editValues;
  }, [concreteClassData]);

  return (
    <ConcreteClassFormView
      open={isOpen}
      onClose={handleClose}
      mode={DIALOG_TYPES.EDIT}
      initialValues={getInitialValues}
      onSubmit={handleOnSubmit}
      concreteClassesStatusOptions={STATUS_OPTIONS}
      uomTemperatureOptions={UOM_TEMPERATURE_OPTIONS}
      uomLengthOptions={UOM_LENGTH_OPTIONS}
      concreteClassesTypeOptions={CONCRETE_CLASS_TYPE_OPTIONS}
      concreteClassesSubtypeOptions={CONCRETE_CLASS_SUBTYPE_OPTIONS}
      handleOnDelete={handleOnDelete}
    />
  );
};

EditConcreteClassDialog.propTypes = {
  concreteClassData: object,
  onClose: func.isRequired,
  handleOnDelete: func,
};

EditConcreteClassDialog.defaultProps = {
  onClose: () => {},
};

export default EditConcreteClassDialog;
