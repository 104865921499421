import * as yup from 'yup';

import { STATUS_OPTIONS } from '../../../../constants';

export const TAB_IDS = {
  VehicleProfile: 0,
  VehicleSpecifications: 1,
};

export const validationSchema = yup.object().shape({
  vehicleId: yup.string().required('required'),
  vehicleName: yup.string().required('required'),
  vehicleStatus: yup.string().required('required'),
  vehicleType: yup.string().required('required'),
  vehicleHomeLocation: yup.string().required('required'),
  carrier: yup.string().required('required'),
  maxGrossVehicleWeight: yup.number(),
  targetGrossVehicleWeight: yup.number(),
  targetNetVehicleWeight: yup.number(),
  driver: yup.string(),
  trailer: yup.string(),
});

export const initialValues = {
  vehicleId: '',
  vehicleName: '',
  vehicleStatus: STATUS_OPTIONS[0].value,
  vehicleType: '',
  vehicleHomeLocation: '',
  carrier: '',
  maxGrossVehicleWeight: '',
  targetGrossVehicleWeight: '',
  targetNetVehicleWeight: '',
  driver: '',
  trailer: '',
};
