import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const DragArea = styled.div`
  ${({ theme, isDragActive }) => css`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${
      isDragActive
        ? theme.validateMode({
            light: theme.colorsCA.blue[95],
            dark: theme.colorsCA.blue[10],
          })
        : ''
    };

    .instructions {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .icon-container {
        border: 1px solid ${theme.colorsCA.blue[30]};
        width: 120px;
        height: 120px;
        border-radius: 50%;
        padding: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: ${theme.validateMode({
          light: `linear-gradient(180deg, ${theme.colorsCA.blue[95]} 0%, ${theme.colorsCA.neutral[100]} 100%);`,
          dark: `transparent;`,
        })} 
        margin-bottom: 20px;
        .icon {
          color: ${theme.colorsCA.darkBlue[60]};
        }
      }

      .drag-description {
        font-size: 32px;
        font-weight: 500;
        margin: 0px;
      }

      .drag-description_or {
        font-size: 32px;
        font-weight: 400;
        margin: 0px;
        margin-bottom: 12px;
      }
    }
  `}
`;

export const LoadImageProfileContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex: 1 1 auto;

    .drag-container {
      width: 100%;
      height: 100%;
    }
  `}
`;
