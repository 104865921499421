import * as yup from 'yup';
import {
  STATUS_OPTIONS,
  CONCRETE_CLASS_TYPE_AIR,
  CONCRETE_CLASS_TYPE_CONSISTENCE,
  CONCRETE_CLASS_TYPE_SLUMP_LOSS_RATE,
  CONCRETE_CLASS_TYPE_TEMPERATURE,
} from '../../../../constants';

export const validationSchema = yup.object().shape({
  concreteClassId: yup.string().required('required'),
  concreteClassName: yup.string().required('required'),
  concreteClassStatus: yup.string().required('required'),
  concreteClassType: yup.string().required('required'),
  concreteClassUOM: yup.string().when('concreteClassType', (concreteClassType, schema) => {
    if (concreteClassType !== CONCRETE_CLASS_TYPE_AIR) {
      return schema.required('required');
    }
    return schema;
  }),
});

export const initialValues = {
  concreteClassId: '',
  concreteClassName: '',
  concreteClassStatus: STATUS_OPTIONS[0].value,
  concreteClassUOM: '',
  concreteClassType: '',
  concreteClassSubtype: '',
  concreteClassesDefaultSettings: false,
  concreteClassNominalValue: '',
  concreteClassMinimumValue: '',
  concreteClassMaximumValue: '',
};

export const CONCRETE_CLASS_TYPE_OPTIONS = [
  { value: CONCRETE_CLASS_TYPE_AIR, translationKey: 'general_air' },
  { value: CONCRETE_CLASS_TYPE_CONSISTENCE, translationKey: 'general_consistence' },
  { value: CONCRETE_CLASS_TYPE_SLUMP_LOSS_RATE, translationKey: 'general_slumpLossRate' },
  { value: CONCRETE_CLASS_TYPE_TEMPERATURE, translationKey: 'general_temperature' },
];

export const CONCRETE_CLASS_SUBTYPE_OPTIONS = [
  { value: 'flow', translationKey: 'general_flow' },
  { value: 'slump', translationKey: 'general_slump' },
  { value: 'spread', translationKey: 'general_spread' },
];

export const UOM_TEMPERATURE_OPTIONS = [
  { value: 'CEL', translationKey: 'general_degreesCelsius' },
  { value: 'FAH', translationKey: 'general_degreesFahrenheit' },
];

export const UOM_LENGTH_OPTIONS = [
  { value: 'INH', translationKey: 'general_inches' },
  { value: 'MMT', translationKey: 'general_millimeters' },
];
