import React, { useState } from 'react';
import { Localization, User, cacheItem, getCachedItem } from 'connex-cds';
import { Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
// import { InventoryOutlined, MonetizationOnOutlined, AccountBalance, AppsOutlined } from '@mui/icons-material';

import WelcomeDialog from './components/welcome-dialog';
// import materialsImage from '../../assets/materials-inventory.svg';
// import billingImage from '../../assets/billing-invoicing.svg';
// import cloudImage from '../../assets/ca-cloud-applications.svg';

import { HomeStyled, MessageSectionStyled, FilterSectionStyled, CustomTooltipStyled } from './styles';
import { Theme } from 'connex-cds';
import { Button, Theme as CaiMaterialTheme } from '@cai/material';

const Home = () => {
  const { themeName } = Theme.useThemeContext();
  const [openModal, setOpenModal] = useState(!JSON.parse(getCachedItem('company_admin_welcome_modal')));
  const [tooltip, setTooltip] = useState(!JSON.parse(getCachedItem('company_admin_onboarding_tooltip')));
  const { user } = User.useUserContext();
  const translateMessage = Localization.useTranslateMessage();

  return (
    <HomeStyled className="home">
      <MessageSectionStyled>
        <h3>{`${translateMessage('home_header_title')} Command Alkon Cloud, ${user?.firstName}`}</h3>
        <p>{translateMessage('home_header_content')}</p>
      </MessageSectionStyled>
      <FilterSectionStyled>
        <CustomTooltipStyled
          arrow
          open={tooltip}
          placement="right"
          title={
            <div style={{ display: 'flex', flexDirection: 'row', paddingLeft: 10 }}>
              <Typography color="inherit" fontSize={16} fontWeight={600} marginY={'10px'}>
                {translateMessage('home_filters_tooltip')}
              </Typography>
              <div>
                <IconButton
                  onClick={() => {
                    setTooltip(false);
                    cacheItem('company_admin_onboarding_tooltip', JSON.parse(true));
                  }}
                >
                  <CloseIcon fontSize="inherit" sx={{ color: '#fff' }}></CloseIcon>
                </IconButton>
              </div>
            </div>
          }
        >
          <CaiMaterialTheme.ThemeProvider themeName={themeName}>
            <Button
              variant="primary"
              token="home_filters_button"
              onClick={() => {
                setTooltip(false);
                cacheItem('company_admin_onboarding_tooltip', JSON.parse(true));
              }}
            />
          </CaiMaterialTheme.ThemeProvider>
        </CustomTooltipStyled>
      </FilterSectionStyled>
      {/*
      Hide the Cards temporarily
      <CardSectionStyled>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <MediaCard
              HeaderIcon={InventoryOutlinedIcon}
              headerTitle="Materials Inventory"
              isNew
              isClosableCard
              imageSrc={materialsImage}
              buttonText={translateMessage('home_cards_button_learnMore')}
              buttonTextOutlined={translateMessage('home_cards_button_buy')}
              buttonTextContained={translateMessage('home_cards_button_try')}
            />
          </Grid>
          <Grid item xs={6}>
            <MediaCard
              HeaderIcon={MonetizationOnOutlinedIcon}
              headerTitle="Billing & Invoicing"
              isNew
              isClosableCard
              imageSrc={billingImage}
              buttonText={translateMessage('home_cards_button_learnMore')}
              buttonTextContained={translateMessage('home_cards_button_activate')}
            />
          </Grid>
          <Grid item xs={6}>
            <TextCard HeaderIcon={AccountBalanceIcon} headerTitle="Your Balance"
              buttonText={translateMessage('home_cards_button_learnMore')}
              buttonTextOutlined={translateMessage('home_cards_button_buy')}
              buttonTextContained={translateMessage('home_cards_button_try')}
              />
          </Grid>
          <Grid item xs={6}>
            <MediaCard
              HeaderIcon={AppsOutlinedIcon}
              headerTitle="Command Alkon Cloud Applications"
              imageSrc={cloudImage}
              buttonText={translateMessage('home_cards_button_learnMore')}
              buttonTextContained={translateMessage('home_cards_button_activate')}
            />
          </Grid>
        </Grid>
      </CardSectionStyled> */}
      <WelcomeDialog
        open={openModal}
        onClose={() => {
          setOpenModal(false);
          cacheItem('company_admin_welcome_modal', JSON.parse(true));
        }}
      />
    </HomeStyled>
  );
};

export default Home;
