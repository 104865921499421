import common from '../common';
import errors from './errors';
import themes from './theme';

export default {
  ...common,
  button: 'Текст кнопки',
  save: 'Зберегти',
  text: 'ISO 3166-1 (Коди для представлення назв країн та їх підрозділів – Частина 1: Коди країн) є стандартом, що визначає коди для назв країн, залежних територій та особливих територій, що представляють географічний інтерес. Це перша частина стандарту ISO 3166, опублікованого Міжнародною організацією зі стандартизації.',

  username: "Ім'я користувача",
  password: 'Пароль',
  login: 'Увійти',

  required: 'вимагається',
  rememberMe: "Пам'ятай мене",

  eula: 'EULA',
  copyright: '© {year} - Command Alkon, Inc.',
  privacyPolicy: 'Політика конфіденційності',

  confirmed: 'Підтверджено',
  isBillable: 'Оплата',
  dispatchDateTime: 'Дата',
  orderId: 'Замовити',
  ticketId: 'Квиток',
  location: 'Місцезнаходження',
  project: 'Демонструвати',
  vehicleTypeName: 'Транспортний засіб',
  product: 'Продукт',

  light: 'Світло',
  dark: 'Темний',

  showAll: 'Показати все',
  hideAll: 'Приховати все',
  undoChanges: 'Скасувати зміни',
  resetColumns: 'Скинути стовпці',

  displayRowCount: 'Відображення {count} рядків',
  columnFilters: 'Фільтри стовпців',

  visible: 'Видно',
  hidden: 'Прихований',

  itemDetail: 'Деталь елемента',

  pdfTicketForm: 'PDF шаблон квитка',
  pdfTicketForm_download: 'Завантажувати',
  pdfTicketForm_import_ticket: 'Імпортний квиток',
  pdfTicketForm_no_preview: 'Ще нічого не переглянуто',
  pdfTicketForm_preview: 'Попередній перегляд',
  pdfTicketForm_lang_selector: 'Вибір мови',
  pdfTicketForm_tableHeader_name: 'Назва шаблону',
  pdfTicketForm_tableHeader_language: 'Мова',
  pdfTicketForm_tableHeader_vehicleTypes: 'Тип транспортного засобу',

  themes,
  errors,
};
