import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import queryString from 'query-string';

import { inventoryProducts } from '../queries';

export const useInventoryProducts = () => {
  const { entityRef } = useParams();
  const queryClient = useQueryClient();
  const queryKey = ['inventory-products', entityRef];

  const List = ({ queryParams = {}, adapter = null } = {}) => {
    const queryResult = useQuery({
      queryKey: ['inventory-products', entityRef, queryString.stringify(queryParams)],
      queryFn: () => inventoryProducts.listInventoryProducts({ entityRef, queryParams }),
      staleTime: Infinity,
    });

    if (!adapter) {
      return queryResult;
    }

    const { data } = queryResult;
    return { ...queryResult, data: adapter(data) };
  };

  const Create = () =>
    useMutation({
      mutationFn: ({ inventoryProduct }) => inventoryProducts.createInventoryProduct({ entityRef, inventoryProduct }),
      onError: context => {
        queryClient.setQueryData(queryKey, context.previousItem);
      },
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey });
      },
    });

  const Update = () =>
    useMutation({
      mutationFn: ({ inventoryProductRef, inventoryProduct }) =>
        inventoryProducts.updateInventoryProduct({ entityRef, inventoryProductRef, inventoryProduct }),
      onError: context => {
        queryClient.setQueryData(queryKey, context.previousItem);
      },
      onSettled: (_data, _error, variables) => {
        queryClient.invalidateQueries({ queryKey });
        queryClient.invalidateQueries({
          queryKey: ['inventory-product-locations', entityRef, variables.inventoryProductRef],
        });
      },
    });

  return { List, Create, Update };
};
