import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const ControlsContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;

    .slider-container {
      display: flex;
      align-items: center;
      gap: 20px;
      width: 100%;
    }

    .MuiSlider-root {
      color: ${theme.colorsCA.blue[60]};
    }
  `}
`;

export const EditImageViewContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex: 1 1 auto;
    .crop-area {
      flex-wrap: nowrap;
      width: 100%;
      height: 70%;
      position: relative;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  `}
`;
