import React, { useState } from 'react';
import { Localization } from 'connex-cds';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker as MuiTimePicker } from '@mui/x-date-pickers/TimePicker';
import { omit } from 'lodash';
import { shape, oneOfType, func, string, number, object } from 'prop-types';
import { getIn } from 'formik';

import { withFormikField } from '../../hoc';

const TimePicker = ({
  name,
  value,
  onChange,
  labelStringId,
  defaultLabel,
  errors,
  touched,
  setFieldTouched,
  ...otherProps
}) => {
  const translateMessage = Localization.useTranslateMessage();
  const [timePickerLabel, setTimePickerLabel] = useState(value ? translateMessage(labelStringId) : defaultLabel);

  const handleOnChange = newValue => {
    onChange(name, newValue);
  };

  const hasError = !!getIn(errors, name) && getIn(touched, name);
  const errorText = hasError ? getIn(errors, name) : '';

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MuiTimePicker
        name={name}
        label={timePickerLabel}
        value={value}
        onChange={handleOnChange}
        onOpen={() => {
          setTimePickerLabel(translateMessage(labelStringId));
        }}
        onClose={() => {
          value === null && setTimePickerLabel(defaultLabel);
          setFieldTouched(name);
        }}
        slotProps={{
          textField: {
            onFocus: () => {
              setTimePickerLabel(translateMessage(labelStringId));
            },
            onBlur: () => {
              value === null && setTimePickerLabel(defaultLabel);
              setFieldTouched(name);
            },
            error: hasError,
            helperText: translateMessage(errorText),
          },
        }}
        {...omit(otherProps, ['setFieldTouched', 'setFieldValue'])}
      />
    </LocalizationProvider>
  );
};

TimePicker.propTypes = {
  defaultLabel: string,
  errors: shape({}).isRequired,
  labelStringId: string.isRequired,
  name: string.isRequired,
  onChange: func.isRequired,
  setFieldTouched: func.isRequired,
  touched: shape({}).isRequired,
  value: oneOfType(number, object).isRequired,
};

TimePicker.defaultProps = {
  defaultLabel: '',
};

export default withFormikField(TimePicker);
