import enUS from './en-US';
import uk from './uk';
import de from './de';
import esCO from './es-CO';

export default {
  'en-US': enUS,
  'es-CO': esCO,
  de,
  uk,
  'fr-FR': {},
};
