import { useState, useMemo } from 'react';
import { Localization, useNotification } from 'connex-cds';
import { func, object } from 'prop-types';

import {
  useVehicles,
  useVehicleTypes,
  useLocations,
  useCarriers,
  useDrivers,
  useTrailers,
} from '../../../../api/hooks';
import {
  parseVehicleTypesOptions,
  parseLocations,
  parseCarriersOptions,
  parseDriversOptions,
  parseTrailersOptions,
} from '../../../../api/adapters';
import { DIALOG_TYPES, STATUS_OPTIONS } from '../../../../constants';
import VehicleFormView from '../vehicle-form-view';

const EditVehicleDialog = ({ onClose, vehicleData, handleOnDelete }) => {
  const [isOpen, setIsOpen] = useState(true);
  const notification = useNotification();
  const translateMessage = Localization.useTranslateMessage();
  const { Update: useUpdateVehicle } = useVehicles();
  const { mutateAsync: updateVehicle } = useUpdateVehicle();
  const { List: useListVehicleTypes } = useVehicleTypes();
  const { List: useDriversList } = useDrivers();
  const { List: useTrailerList } = useTrailers();

  const { data: vehicleTypesOptions, isLoading: isLoadingVehicleTypesOptions } = useListVehicleTypes({
    adapter: parseVehicleTypesOptions,
  });
  const { List: useListLocations } = useLocations();
  const { data: locationsOptions, isLoading: isLoadingLocationsOptions } = useListLocations({
    adapter: parseLocations,
  });
  const { List: useListCarriers } = useCarriers();
  const { data: carriersOptions, isLoading: isLoadingCarriersOptions } = useListCarriers({
    adapter: parseCarriersOptions,
  });
  const { data: driversOptions, isLoading: isLoadingDriversOptions } = useDriversList({
    adapter: parseDriversOptions,
  });

  const { data: trailersOptions, isLoading: isLoadingTrailersOptions } = useTrailerList({
    adapter: parseTrailersOptions,
  });

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  const handleOnSubmit = async (values, formikBag) => {
    const {
      vehicleName,
      vehicleStatus,
      carrier,
      vehicleType,
      vehicleHomeLocation,
      maxGrossVehicleWeight,
      targetGrossVehicleWeight,
      targetNetVehicleWeight,
      driver,
      trailer,
    } = values;

    formikBag.setSubmitting(true);

    try {
      const vehicle = {
        name: vehicleName,
        status: vehicleStatus,
        homeLocation: { locationRef: vehicleHomeLocation },
        carrierParty: { carrierRef: carrier },
        vehicleType: { vehicleTypeRef: vehicleType },
        maxGrossVehicleWeight: maxGrossVehicleWeight?.toString(),
        targetGrossVehicleWeight: targetGrossVehicleWeight?.toString(),
        targetNetVehicleWeight: targetNetVehicleWeight?.toString(),
        configuration: {
          driver: { driverRef: driver },
          trailer: { trailerRef: trailer },
        },
      };

      await updateVehicle({ vehicle, vehicleRef: vehicleData?.crn });
      notification.success(translateMessage('notification_changesSaved'));

      formikBag.setSubmitting(false);
      formikBag.resetForm();
      handleClose();
    } catch (error) {
      console.error(error);
    }
  };

  const getInitialValues = useMemo(() => {
    if (!vehicleData) return null;
    const {
      id,
      name,
      carrierParty,
      vehicleType,
      homeLocation,
      maxGrossVehicleWeight,
      targetGrossVehicleWeight,
      targetNetVehicleWeight,
      configuration,
    } = vehicleData;

    const editValues = {
      vehicleId: id,
      vehicleName: name,
      vehicleStatus: STATUS_OPTIONS.find(status => status.value === vehicleData.status).value,
      carrier: carriersOptions.find(item => item.value === carrierParty?.carrierRef)?.value,
      vehicleType: vehicleTypesOptions.find(item => item.value === vehicleType?.vehicleTypeRef)?.value,
      vehicleHomeLocation: locationsOptions.find(item => item.value === homeLocation?.locationRef)?.value,
      maxGrossVehicleWeight: maxGrossVehicleWeight?.toString(),
      targetGrossVehicleWeight: targetGrossVehicleWeight?.toString(),
      targetNetVehicleWeight: targetNetVehicleWeight?.toString(),
      driver: driversOptions.find(item => item.value === configuration?.driver?.driverRef)?.value,
      trailer: trailersOptions.find(item => item.value === configuration?.trailer?.trailerRef)?.value,
    };

    return editValues;
  }, [carriersOptions, locationsOptions, vehicleData, vehicleTypesOptions, driversOptions, trailersOptions]);

  return (
    <VehicleFormView
      open={isOpen}
      onClose={handleClose}
      mode={DIALOG_TYPES.EDIT}
      initialValues={getInitialValues}
      onSubmit={handleOnSubmit}
      statusOptions={STATUS_OPTIONS}
      vehicleTypesOptions={vehicleTypesOptions}
      isLoadingVehicleTypesOptions={isLoadingVehicleTypesOptions}
      locationsOptions={locationsOptions}
      isLoadingLocationsOptions={isLoadingLocationsOptions}
      carriersOptions={carriersOptions}
      isLoadingCarriersOptions={isLoadingCarriersOptions}
      handleOnDelete={handleOnDelete}
      driversOptions={driversOptions}
      isLoadingDriversOptions={isLoadingDriversOptions}
      trailersOptions={trailersOptions}
      isLoadingTrailersOptions={isLoadingTrailersOptions}
    />
  );
};

EditVehicleDialog.propTypes = {
  vehicleData: object,
  onClose: func.isRequired,
  handleOnDelete: func,
};

EditVehicleDialog.defaultProps = {
  onClose: () => {},
};

export default EditVehicleDialog;
