import React, { useState, useMemo, useCallback, forwardRef } from 'react';
import { Localization } from 'connex-cds';
import { TextField, InputAdornment } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { omit } from 'lodash';
import { getIn } from 'formik';
import { func, string, bool, shape, number, oneOf } from 'prop-types';

import { withFormikField } from '../../hoc';
import { CURRENCY_CODE } from '../../constants';
import { FormTooltip } from '..';

const DEFAULT_CURRENCY_VALUE = '0.00';
const DEFAULT_DECIMAL_SEPARATOR = '.';
const DEFAULT_THOUSANDS_SEPARATOR = ',';

const TooltipIcon = forwardRef((props, ref) => <InfoOutlinedIcon {...props} ref={ref} className="info-icon" />);

const NumberInput = ({
  name,
  value,
  disabled,
  testId,
  onChange,
  onBlur,
  isSubmitting,
  labelStringId,
  errors,
  innerRef,
  touched,
  maxNumberOfDigits,
  currency,
  isCurrency,
  type,
  tooltipInfoStringId,
  ...otherProps
}) => {
  const [isFieldInitialized, setIsFieldInitialized] = useState(false);
  const [previousValue, setPreviousValue] = useState('');
  const translateMessage = Localization.useTranslateMessage();
  const { getCurrencyParts } = Localization.useCurrency();
  const { userLocale } = Localization.useLocalizationContext();

  const { decimalSeparator, thousandsSeparator, currencySymbol } = getCurrencyParts({ currency });
  const isFloat = type === 'decimal' || type === 'currency';

  const handleTransformToFloat = useCallback(
    value => {
      return value
        ?.replaceAll(thousandsSeparator, '')
        .replaceAll(DEFAULT_THOUSANDS_SEPARATOR, DEFAULT_DECIMAL_SEPARATOR);
    },
    [thousandsSeparator]
  );

  const valueFloatType = useMemo(() => {
    if (!isFieldInitialized && !Boolean(value)) {
      return DEFAULT_CURRENCY_VALUE.replaceAll(DEFAULT_DECIMAL_SEPARATOR, decimalSeparator);
    }

    if (!Boolean(value)) {
      return value;
    }

    const parsedValue = parseFloat(value).toLocaleString(userLocale);
    const valueWithDecimal = `${parsedValue}${decimalSeparator}`;

    const hasInitialValue = !isFieldInitialized && Boolean(value);
    const initialValueHasNoDecimal = !value.includes(DEFAULT_DECIMAL_SEPARATOR);
    const initialValueHasOneDecimal = value.slice(-2).includes(DEFAULT_DECIMAL_SEPARATOR);

    const lastValueIsDecimalSeparator = value.endsWith(DEFAULT_DECIMAL_SEPARATOR);
    const hasDecimalWithZero = value.endsWith('.0');
    const hasDecimalWithTwoZeros = value.endsWith('.00');
    const hasDecimalWithNumberAndZero = Array.isArray(value.slice(-3).match(/^\.\d{1}0{1}?$/g));

    if (lastValueIsDecimalSeparator) {
      return valueWithDecimal;
    }

    if (hasDecimalWithZero) {
      return `${valueWithDecimal}0`;
    }

    if (hasDecimalWithTwoZeros || (hasInitialValue && initialValueHasNoDecimal)) {
      return `${valueWithDecimal}00`;
    }

    if (hasDecimalWithNumberAndZero || (hasInitialValue && initialValueHasOneDecimal)) {
      return `${parsedValue}0`;
    }
    return parsedValue;
  }, [value, decimalSeparator, userLocale, isFieldInitialized]);

  const handleOnChangeFloatType = useCallback(
    ({ target: { value } }) => {
      const newValue = handleTransformToFloat(value);
      const FLOAT_REGEX = new RegExp(`^\\d{1,${maxNumberOfDigits}}(?:\\.\\d{1,2})?$`);
      const lastValueIsDecimalSeparator = newValue.endsWith(DEFAULT_DECIMAL_SEPARATOR);
      const haveOneDecimalSeparator =
        !(newValue.split(DEFAULT_DECIMAL_SEPARATOR).length - 2) && newValue !== DEFAULT_DECIMAL_SEPARATOR;
      const validDecimalSeparator = lastValueIsDecimalSeparator && haveOneDecimalSeparator;
      const lastPrevValueIsDecimalSeparator = previousValue.endsWith(DEFAULT_DECIMAL_SEPARATOR);
      const isDifferentValue =
        previousValue.replaceAll(lastPrevValueIsDecimalSeparator ? '' : DEFAULT_DECIMAL_SEPARATOR, '') !== newValue;

      if ((FLOAT_REGEX.test(newValue) && isDifferentValue) || validDecimalSeparator || !newValue) {
        setPreviousValue(newValue);
        onChange(name, newValue);
      }
    },
    [maxNumberOfDigits, name, onChange, handleTransformToFloat, previousValue]
  );

  const handleOnBlurFloatType = useCallback(
    e => {
      const value = handleTransformToFloat(e.target.value);
      const splittedValue = value.split(DEFAULT_DECIMAL_SEPARATOR);

      if (splittedValue.length === 1) {
        onChange(name, `${value}.00`);
      }

      if (splittedValue.length === 2 && !splittedValue[1].length) {
        onChange(name, `${value}00`);
      }

      if (splittedValue.length === 2 && splittedValue[1].length === 1) {
        onChange(name, `${value}0`);
      }

      onBlur(e);
    },
    [handleTransformToFloat, onBlur, name, onChange]
  );

  const handleOnFocusFloatType = useCallback(() => {
    const shouldBeDefaultValue = !isFieldInitialized && !Boolean(value);
    const newValue = handleTransformToFloat(valueFloatType);
    onChange(name, shouldBeDefaultValue ? DEFAULT_CURRENCY_VALUE : newValue);
    setPreviousValue(shouldBeDefaultValue ? DEFAULT_CURRENCY_VALUE : newValue);
    !isFieldInitialized && setIsFieldInitialized(true);
  }, [isFieldInitialized, name, onChange, handleTransformToFloat, value, valueFloatType]);

  const valueIntegerType = useMemo(() => {
    const newValue = !Boolean(value) ? '' : parseInt(value).toLocaleString(userLocale);
    return newValue;
  }, [value, userLocale]);

  const handleOnChangeIntegerType = useCallback(
    ({ target: { value } }) => {
      const newValue = value?.replaceAll(thousandsSeparator, '');
      const INTEGER_REGEX = new RegExp(`^\\d{1,${maxNumberOfDigits}}?$`);

      if (INTEGER_REGEX.test(newValue)) {
        const parsedValue = parseFloat(newValue).toString();
        onChange(name, parsedValue);
      } else if (!Boolean(value)) {
        onChange(name, newValue);
      }
    },
    [maxNumberOfDigits, name, onChange, thousandsSeparator]
  );

  const hasError = !!getIn(errors, name) && getIn(touched, name);
  const errorText = hasError ? getIn(errors, name) : '';

  return (
    <TextField
      name={name}
      label={translateMessage(labelStringId)}
      data-testid={testId}
      value={isFloat ? valueFloatType : valueIntegerType}
      ref={innerRef}
      disabled={disabled || isSubmitting}
      type="text"
      error={hasError}
      helperText={translateMessage(errorText)}
      onChange={isFloat ? handleOnChangeFloatType : handleOnChangeIntegerType}
      onBlur={isFloat ? handleOnBlurFloatType : onBlur}
      onFocus={isFloat ? handleOnFocusFloatType : null}
      inputProps={{ autoComplete: 'off' }}
      InputProps={{
        startAdornment: type === 'currency' && <InputAdornment position="start">{currencySymbol}</InputAdornment>,
        endAdornment: tooltipInfoStringId && (
          <FormTooltip
            arrow
            title={translateMessage(tooltipInfoStringId)}
            placement="top-start"
            PopperProps={{ disablePortal: true }}
          >
            <TooltipIcon />
          </FormTooltip>
        ),
        sx: type === 'currency' ? [{ '.MuiOutlinedInput-input': { paddingLeft: 0 } }] : [],
      }}
      {...omit(otherProps, ['setFieldTouched', 'setFieldValue'])}
    />
  );
};

NumberInput.propTypes = {
  currency: string,
  disabled: bool,
  errors: shape({}).isRequired,
  innerRef: shape({}),
  isCurrency: bool,
  isSubmitting: bool,
  labelStringId: string,
  maxNumberOfDigits: number,
  name: string.isRequired,
  onBlur: func.isRequired,
  onChange: func.isRequired,
  testId: string,
  touched: shape({}).isRequired,
  type: oneOf(['integer', 'decimal', 'currency']),
  value: string,
  tooltipInfoStringId: string,
};

NumberInput.defaultProps = {
  currency: CURRENCY_CODE.USD,
  disabled: false,
  isCurrency: false,
  isSubmitting: false,
  maxNumberOfDigits: 9,
  type: 'integer',
};

export default withFormikField(NumberInput);
