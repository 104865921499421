import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Avatar } from '@mui/material';

const SIZES = {
  xsmall: '32px',
  small: '40px',
  medium: '130px',
  large: '200px',
};

const style = ({ theme, size }) => css`
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${SIZES[size] || SIZES.medium};
  height: ${SIZES[size] || SIZES.medium};
  border-radius: 50%;
  border: ${SIZES[size] === SIZES.xsmall ? '1px' : '2px'} solid ${theme.colorsCA.darkBlue[60]};
  box-shadow: ${SIZES[size] === SIZES.xsmall ? theme.shadowCA(8) : theme.shadowCA(4)};

  .MuiSvgIcon-root {
    color: ${theme.validateMode({
      light: theme.colorsCA.darkBlue[20],
      dark: theme.colorsCA.darkBlue[80],
    })};
    font-size: 100px;
  }
`;

export const ImagePreviewStyled = styled(Avatar)`
  ${style}
`;
