import React from 'react';
import { func, string, bool, shape, array } from 'prop-types';
import { Localization } from 'connex-cds';
import { omit } from 'lodash';
import { getIn } from 'formik';

import { withFormikField } from '../../hoc';

import { SelectField } from '@cai/material';

const Select = ({
  name,
  value,
  disabled,
  required,
  clearable,
  testId,
  onChange,
  isSubmitting,
  labelStringId,
  errors,
  options,
  infoTooltipStringId,
  setFieldTouched,
  touched,
  ...otherProps
}) => {
  const translateMessage = Localization.useTranslateMessage();

  const handleOnChange = e => {
    onChange(name, e.target.value);
  };

  const hasError = !!getIn(errors, name) && getIn(touched, name);
  const errorText = hasError ? errors[name] : '';

  return (
    <SelectField
      name={name}
      value={value}
      data-testid={testId}
      error={hasError}
      helperText={translateMessage(errorText)}
      disabled={disabled || isSubmitting}
      required={required}
      onChange={handleOnChange}
      token={labelStringId}
      options={options}
      infoTooltipStringId={infoTooltipStringId}
      {...omit(otherProps, ['setFieldTouched', 'setFieldValue'])}
    />
  );
};

Select.propTypes = {
  errors: shape({}).isRequired,
  disabled: bool,
  required: bool,
  clearable: bool,
  infoTooltipStringId: string,
  isSubmitting: bool,
  labelStringId: string.isRequired,
  name: string.isRequired,
  onChange: func.isRequired,
  options: array.isRequired,
  testId: string,
  value: string.isRequired,
  setFieldTouched: func.isRequired,
  touched: shape({}).isRequired,
};

Select.defaultProps = {
  disabled: false,
  required: false,
  clearable: false,
  infoTooltipStringId: '',
  options: [],
  isSubmitting: false,
  value: '',
};

export default withFormikField(Select);
