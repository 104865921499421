import { HeaderAndSidebar, useDefaultRoute } from 'connex-cds';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import {
  AuthenticatedRoutes,
  Carriers,
  Drivers,
  Home,
  InventoryProducts,
  Locations,
  MixComponents,
  PriceBooks,
  Customers,
  Products,
  ProductTypes,
  RedirectToEntity,
  RegisterRoutes,
  Roles,
  Users,
  UsersHome,
  Vehicles,
  VehicleTypes,
  ConcreteClasses,
  ContactReasons,
  ContactTypes,
  Company,
  Mixes,
  Billing,
  Taxation,
  Trailers,
  UserRoles,
  PdfTicketForm,
  ShippingMethods,
  OrderStatuses,
  CancelReasons,
  WorkTypes,
} from './views';

export const AppRoutes = () => {
  const defaultRoute = useDefaultRoute('home');

  return (
    <Routes>
      <Route path="/register/:entityRef/:data/*" element={<RegisterRoutes />} />
      <Route element={<HeaderAndSidebar />}>
        <Route path="" element={<AuthenticatedRoutes />}>
          <Route path="" element={<RedirectToEntity />}>
            <Route path=":entityRef/*">
              <Route path="home" element={<Home />} />
              <Route path="users">
                <Route path="roles" element={<UserRoles />} />
                <Route path="users-home" element={<UsersHome />} />
                <Route path="active-users" element={<Users />} />
              </Route>
              <Route path="company" element={<Company />} />
              <Route path="masterdata">
                <Route path="products" element={<Products />} />
                <Route path="product-types" element={<ProductTypes />} />
                <Route path="mix-components" element={<MixComponents />} />
                <Route path="inventory-products" element={<InventoryProducts />} />
                <Route path="locations" element={<Locations />} />
                <Route path="price-books" element={<PriceBooks />} />
                <Route path="customers" element={<Customers />} />
                <Route path="vehicles" element={<Vehicles />} />
                <Route path="vehicle-types" element={<VehicleTypes />} />
                <Route path="carriers" element={<Carriers />} />
                <Route path="mixes" element={<Mixes />} />
                <Route path="drivers" element={<Drivers />} />
                <Route path="concrete-classes" element={<ConcreteClasses />} />
                <Route path="contact-reasons" element={<ContactReasons />} />
                <Route path="contact-types" element={<ContactTypes />} />
                <Route path="trailers" element={<Trailers />} />
                <Route path="pdf-ticket-form" element={<PdfTicketForm />} />
                <Route path="shipping-methods" element={<ShippingMethods />} />
                <Route path="order-statuses" element={<OrderStatuses />} />
                <Route path="cancel-reasons" element={<CancelReasons />} />
                <Route path="work-types" element={<WorkTypes />} />
              </Route>
              <Route path="roles/*" element={<Roles />} />
              <Route path="billing" element={<Billing />} />
              <Route path="billing/taxation" element={<Taxation />} />
              <Route path="*" element={<Navigate to={defaultRoute} />} />
            </Route>
          </Route>
        </Route>
      </Route>
    </Routes>
  );
};
