import { css } from '@emotion/react';
import styled from '@emotion/styled';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';

export const ContainerStyled = styled('div')`
  ${({ theme }) => css`
    height: 100%;
    display: flex;
    flex-direction: column;
    font-family: 'Work Sans';

    .dialog-title {
      gap: 10px;

      .edit-icon {
        font-size: 38px;
      }
    }

    .edit-user-dialog-tabs {
      border-top: 1px solid ${theme.validateMode({ light: 'rgba(0, 0, 0, 0.12)', dark: '#938F99' })};
      flex-shrink: 0;
    }

    .MuiTab-root.MuiTab-labelIcon {
      font-size: 14px;

      .MuiTab-iconWrapper {
        font-size: 24px;
      }
    }
  `}
`;

export const DialogContentStyled = styled(MuiDialogContent)`
  ${({ theme }) => css`
    padding: 40px;
    display: flex;
    justify-content: center;
    ${theme.validateMode({ dark: 'border-top: 1px solid #938F99;' })}
    ${theme.validateMode({ dark: 'border-bottom: 1px solid #938F99;' })}
  `}
`;

export const DialogActionsStyled = styled(MuiDialogActions)`
  ${({ theme }) => css`
    display: flex;
    justify-content: end;
    align-items: center;
    padding: 16px 27px 20px;

    button {
      font-size: 16px;
      padding: 14px 24px;
      line-height: 20px;
    }
  `}
`;
