import { useState } from 'react';
import { Localization, useNotification } from 'connex-cds';

import CancelReasonFormView from '../cancel-reason-form-view';
import { DIALOG_TYPES, STATUS_OPTIONS } from '../../../../constants';
import { CANCEL_REASON_TYPES_OPTIONS, initialValues } from '../cancel-reason-form-view/form-config';
import { useCancelReasons } from '../../../../api/hooks';

const AddCancelReasonDialog = ({ onClose }) => {
  const [isOpen, setIsOpen] = useState(true);
  const notification = useNotification();
  const { Create: useCancelReason } = useCancelReasons();
  const { mutateAsync: createCancelReason } = useCancelReason();
  const translateMessage = Localization.useTranslateMessage();

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  const handleOnSubmit = async (values, formikBag) => {
    const { id, name, status, type } = values;

    formikBag.setSubmitting(true);

    try {
      const data = {
        id,
        name,
        status: status || undefined,
        type: type || undefined,
      };

      await createCancelReason({ data });
      notification.success(translateMessage('cancelReasons_notification_cancelReasonAdded'));

      formikBag.setSubmitting(false);
      formikBag.resetForm();
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <CancelReasonFormView
      open={isOpen}
      onClose={handleClose}
      mode={DIALOG_TYPES.ADD}
      initialValues={initialValues}
      onSubmit={handleOnSubmit}
      statusOptions={STATUS_OPTIONS}
      cancelReasonTypesOptions={CANCEL_REASON_TYPES_OPTIONS}
    />
  );
};

export default AddCancelReasonDialog;
