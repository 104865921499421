import React, { useMemo, Fragment } from 'react';
import { Localization } from 'connex-cds';
import { Field, Form } from 'formik';
import Grid from '@mui/material/Grid';

import { SwitchWithFormikField, TimePickerWithFormikField, SelectWithFormikField } from '../../../../commons';
import SwitchLabel from '../switch-label';
import { DAYS_FOR_HOURS_TAB } from '../location-form-view/form-config';
import { COUNTRIES } from '../../../../constants';
import { mapperToGetTimezoneOptions } from '../../mappers';

import { LocationHoursFormContainer } from './styles';

const HoursTab = ({ setFieldValue, hours, country }) => {
  const timeZoneOptions = useMemo(() => mapperToGetTimezoneOptions(country), [country]);

  const onCountryChange = setFieldValue => (name, value) => {
    setFieldValue(name, value);
    setFieldValue('timeZone', '');
  };

  return (
    <LocationHoursFormContainer>
      <Form>
        <Grid container spacing={4} alignItems="top" className="first-section">
          <Grid item xs={12} md={4}>
            <Field
              fullWidth
              labelStringId="general_country"
              component={SelectWithFormikField}
              name="country"
              options={COUNTRIES}
              customOnChange={onCountryChange(setFieldValue)}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Field
              fullWidth
              labelStringId="timeZone"
              component={SelectWithFormikField}
              name="timeZone"
              options={timeZoneOptions}
              disabled={!country}
              required={Boolean(country)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="top" className="second-section">
          <Grid item xs={12} md={3}>
            <p className="column-name">
              <Localization.Translate stringId="general_daysOfTheWeek" />
            </p>
          </Grid>
          <Grid item xs={12} md={4}>
            <p className="column-name">
              <Localization.Translate stringId="general_open" />
            </p>
          </Grid>
          <Grid item xs={12} md={4}>
            <p className="column-name">
              <Localization.Translate stringId="general_close" />
            </p>
          </Grid>
          {DAYS_FOR_HOURS_TAB.map((value, index) => {
            return (
              <Fragment key={index}>
                <Grid item xs={12} md={3}>
                  <Field
                    component={SwitchWithFormikField}
                    name={`hours.${value.id}.switch`}
                    labelPlacement="end"
                    customLabel={<SwitchLabel labelStringId={value.labelStringId} />}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Field
                    fullWidth
                    labelStringId="general_time"
                    component={TimePickerWithFormikField}
                    name={`hours.${value.id}.open`}
                    format="hh:mm A"
                    defaultLabel="--:-- --"
                    disabled={!hours[value.id].switch}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Field
                    fullWidth
                    labelStringId="general_time"
                    component={TimePickerWithFormikField}
                    name={`hours.${value.id}.close`}
                    format="hh:mm A"
                    defaultLabel="--:-- --"
                    disabled={!hours[value.id].switch}
                  />
                </Grid>
              </Fragment>
            );
          })}
        </Grid>
      </Form>
    </LocationHoursFormContainer>
  );
};

export default HoursTab;
