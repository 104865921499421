import React from 'react';
import { CancelReasonProfileTabContainer } from './styles';
import { Field, Form } from 'formik';
import { Grid } from '@mui/material';
import { SelectWithFormikField, TextInputWithFormikField } from '../../../../commons';
import { DIALOG_TYPES } from '../../../../constants';

const CancelReasonProfileTab = ({ mode, statusOptions, cancelReasonTypesOptions }) => {
  return (
    <CancelReasonProfileTabContainer>
      <Form>
        <Grid container spacing={5}>
          <Grid item xs={6}>
            <Field
              fullWidth
              labelStringId="cancelReasons_profileTab_fieldCancelReasonId_label"
              component={TextInputWithFormikField}
              variant="outlined"
              name="id"
              tooltipInfoStringId="shippingMethods_profileTab_id_label_tooltip"
              disabled={mode === DIALOG_TYPES.EDIT}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              fullWidth
              labelStringId="cancelReasons_profileTab_fieldCancelReasonType_label"
              component={SelectWithFormikField}
              name="type"
              options={cancelReasonTypesOptions}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              fullWidth
              labelStringId="cancelReasons_profileTab_fieldCancelReasonName_label"
              component={TextInputWithFormikField}
              variant="outlined"
              name="name"
              required
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              fullWidth
              labelStringId="cancelReasons_profileTab_fieldCancelReasonStatus_label"
              component={SelectWithFormikField}
              name="status"
              options={statusOptions}
            />
          </Grid>
        </Grid>
      </Form>
    </CancelReasonProfileTabContainer>
  );
};

export default CancelReasonProfileTab;
