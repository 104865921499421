import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const ContainerStyled = styled('div')`
  ${({ theme }) => css`
    width: 100%;

    .contact-info-title {
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.15px;
      margin-bottom: 25px;
    }

    .contact-info-form {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 40px;
      column-gap: 40px;
    }
  `}
`;
