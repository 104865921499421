import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const InventoryProductSupplyContainer = styled.div`
  ${css`
    .inventory-product-supply-title {
      font-size: 24px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.15px;
      margin-bottom: 25px;
    }
  `}
`;

export const MappingSection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-bottom: 24px;
`;

export const MappingSectionControls = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;

    & > button {
      padding: 5px;

      svg {
        height: 24px;
        width: 24px;
        color: ${theme.validateMode({ light: theme.colorsCA.neutral[40], dark: theme.colorsCA.neutral[95] })};
      }
    }
  `}
`;

export const MappingData = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex: 1;
  flex-wrap: wrap;
`;

export const MappingDataSection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex: 1;
  flex-wrap: wrap;

  & > div {
    flex: 1 1 45%;

    .MuiAutocomplete-inputRoot {
      padding: 0;

      .MuiAutocomplete-input {
        padding-top: 4px;
        padding-bottom: 4px;
      }
    }
  }

  & > label {
    width: 50%;
  }
`;

export const MappingDataSectionLabel = styled.span`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  width: 100%;
`;

export const AccordionSummaryIconWrapper = styled.div`
  display: flex;

  svg {
    margin: 12px 0;
  }
`;
