import React from 'react';
import { Field, Form } from 'formik';
import { string, array, bool } from 'prop-types';
import Grid from '@mui/material/Grid';
import { DIALOG_TYPES, STATUS_OPTIONS } from '../../../../constants';
import { TextInputWithFormikField, SelectWithFormikField } from '../../../../commons';
import { WorkTypeProfileFormContainer } from './styles';

const WorkTypeProfileTab = ({ mode, productTypesOptions, isLoadingProductTypes }) => {
  return (
    <WorkTypeProfileFormContainer>
      <Form>
        <Grid container spacing={5} alignItems="baseline">
          <Grid item xs={12} md={6}>
            <Field
              fullWidth
              labelStringId="workTypes_profileTab_fieldWorkTypeId_label"
              tooltipInfoStringId="workTypes_profileTab_fieldWorkTypeId_tooltip"
              component={TextInputWithFormikField}
              name="id"
              required
              disabled={mode === DIALOG_TYPES.EDIT}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              fullWidth
              labelStringId="workTypes_profileTab_fieldWorkTypeProductType_label"
              infoTooltipStringId="workTypes_profileTab_fieldWorkTypeProductType_tooltip"
              component={SelectWithFormikField}
              name="productTypeRef"
              options={productTypesOptions}
              disabled={isLoadingProductTypes}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              fullWidth
              labelStringId="workTypes_profileTab_fieldWorkTypeName_label"
              component={TextInputWithFormikField}
              name="name"
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              fullWidth
              labelStringId="workTypes_profileTab_fieldWorkTypeStatus_label"
              infoTooltipStringId="workTypes_profileTab_fieldWorkTypeStatus_tooltip"
              component={SelectWithFormikField}
              name="status"
              options={STATUS_OPTIONS}
            />
          </Grid>
        </Grid>
      </Form>
    </WorkTypeProfileFormContainer>
  );
};

WorkTypeProfileTab.propTypes = {
  mode: string.isRequired,
  productTypesOptions: array,
  isLoadingProductTypes: bool,
};

export default WorkTypeProfileTab;
