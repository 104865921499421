import { useState } from 'react';
import PropTypes from 'prop-types';
import { Localization, useNotification } from 'connex-cds';

import { initialValues } from '../product-type-form-view/form-config';
import { useProductTypes } from '../../../../api/hooks';
import ProductTypeFormView from '../product-type-form-view';
import { DIALOG_TYPES } from '../../../../constants/common';

const AddProductTypeDialog = ({ onClose }) => {
  const [isOpen, setIsOpen] = useState(true);
  const notification = useNotification();
  const translateMessage = Localization.useTranslateMessage();
  const { Create: useCreateProductType } = useProductTypes();
  const { mutateAsync: createProductType } = useCreateProductType();

  const handleAddProductType = async values => {
    const productType = { ...values, status: values.status };
    await createProductType(productType);
    onClose();
    notification.success(translateMessage('notification_productTypeAdded'));
  };

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  return (
    <ProductTypeFormView
      open={isOpen}
      onClose={handleClose}
      mode={DIALOG_TYPES.ADD}
      initialValues={initialValues}
      onSubmit={handleAddProductType}
    />
  );
};

AddProductTypeDialog.propTypes = {
  onClose: PropTypes.func,
};

AddProductTypeDialog.defaultProps = {
  onClose: () => null,
};

export default AddProductTypeDialog;
