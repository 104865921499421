import { Localization } from 'connex-cds';

import { getContactTypesListColumns } from './tableConfig';
import { TableLayout, EntityTable, SearchFilter, DialogManager, ActionButton } from '../../commons';
import { useContactTypes } from '../../api/hooks';
import { DIALOG_TYPES } from '../../constants';
import AddContactTypeDialog from './components/add-contact-type-dialog';
import EditContactTypeDialog from './components/edit-contact-type-dialog';
import DeleteContactTypeDialog from './components/delete-contact-type-dialog';
import { useTableLogic } from '../../hooks';

const ContactTypes = () => {
  const filteredPropsArr = ['id', 'name'];
  const {
    setQuery,
    currentEntity,
    dialog,
    setDialog,
    isLoading,
    filteredData,
    entityColumns,
    resetDialogs,
    handleOnRowClick,
    setIsOnEditDialog,
  } = useTableLogic(useContactTypes, getContactTypesListColumns, filteredPropsArr);

  return (
    <TableLayout.ListLayoutContainer>
      <TableLayout.TableControls>
        <SearchFilter onSearchChange={setQuery} disabled={isLoading} resultsCount={filteredData.length} />
        <ActionButton
          icon="addOutlinedIcon"
          label={<Localization.Translate stringId="contactTypeList_addContactType" />}
          variant="outlined"
          onClick={() => setDialog(DIALOG_TYPES.ADD)}
        />
      </TableLayout.TableControls>
      <TableLayout.TableBody>
        <EntityTable
          checkboxSelection={false}
          isLoading={isLoading}
          columns={entityColumns}
          rows={filteredData}
          getRowId={row => row.crn}
          onRowClick={handleOnRowClick}
        />
      </TableLayout.TableBody>
      <DialogManager screen={dialog}>
        <AddContactTypeDialog identifier={DIALOG_TYPES.ADD} onClose={resetDialogs} />
        <EditContactTypeDialog
          identifier={DIALOG_TYPES.EDIT}
          onClose={resetDialogs}
          contactTypeData={currentEntity}
          handleOnDelete={() => {
            setIsOnEditDialog(true);
            setDialog(DIALOG_TYPES.DELETE);
          }}
        />
        <DeleteContactTypeDialog
          identifier={DIALOG_TYPES.DELETE}
          onClose={resetDialogs}
          contactTypeData={currentEntity}
        />
      </DialogManager>
    </TableLayout.ListLayoutContainer>
  );
};

export default ContactTypes;
