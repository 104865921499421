import React, { useMemo } from 'react';
import { Localization, useNotification } from 'connex-cds';

import TaxDisclaimer from './components/tax-disclaimer';
import TaxForm from './components/tax-form';
import { useBilling } from '../../api/hooks';
import { OPTIONS, ZIP_TAX_OPTION, VAT_RATE_OPTION } from './constants';

import { TaxationStyled } from './styles';

const Taxation = () => {
  const translateMessage = Localization.useTranslateMessage();
  const notification = useNotification();

  const { Get: useGetSettings, Update: useUpdateSettings } = useBilling();
  const { data: settings, isLoading } = useGetSettings();
  const { mutateAsync: updateSettings } = useUpdateSettings();

  const getInitialValues = useMemo(() => {
    if (!settings) return {};

    const {
      taxPercentage = 0,
      taxPerProduct = false,
      taxProvider: { type = ZIP_TAX_OPTION },
    } = settings;

    const apiProvider = OPTIONS.find(option => option.value === type).value;
    const initialProvider =
      taxPercentage > 0 ? OPTIONS.find(option => option.value === VAT_RATE_OPTION).value : apiProvider;

    const values = {
      taxProvider: initialProvider,
      taxPercentage: initialProvider !== VAT_RATE_OPTION ? 0 : taxPercentage,
      taxPerProduct,
    };

    return values;
  }, [settings]);

  const onSubmit = async (values, formikBag) => {
    const { taxProvider, taxPercentage, taxPerProduct } = values;

    formikBag.setSubmitting(true);

    try {
      const payload = {
        taxProvider: {
          type: taxProvider === VAT_RATE_OPTION ? ZIP_TAX_OPTION : taxProvider,
        },
        taxPercentage: taxProvider === VAT_RATE_OPTION ? parseFloat(taxPercentage) : 0,
        taxPerProduct,
      };

      await updateSettings({ payload });
      notification.success(translateMessage('notification_changesSaved'));
      formikBag.setSubmitting(false);
      formikBag.resetForm();
    } catch (error) {
      notification.error(translateMessage('generic_error'));
    }
  };

  return (
    <TaxationStyled>
      <TaxDisclaimer />
      <TaxForm
        initialValues={getInitialValues}
        isLoading={isLoading}
        taxProviderOptions={OPTIONS}
        onSubmit={onSubmit}
      />
    </TaxationStyled>
  );
};

export default Taxation;
