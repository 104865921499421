import { useCallback, useState, useMemo } from 'react';
import { Localization } from 'connex-cds';

import { DIALOG_TYPES } from '../constants';
import { getFilteredDataByProperties } from '../commons/search-filter';

export const useTableLogic = (useEntity, getListColumns, filteredPropsArr, addQueryParams = {}) => {
  const [isOnEditDialog, setIsOnEditDialog] = useState(false);
  const translateMessage = Localization.useTranslateMessage();
  const { getNumber } = Localization.useNumber();
  const [query, setQuery] = useState('');
  const [currentEntity, setCurrentEntity] = useState(null);
  const [dialog, setDialog] = useState(DIALOG_TYPES.NONE);
  const { List: useList } = useEntity();
  const { data, isLoading } = useList({ queryParams: { activeOnly: false, ...addQueryParams } });

  const filteredData = useMemo(
    () => getFilteredDataByProperties({ data, query, properties: filteredPropsArr }),
    [data, query, filteredPropsArr]
  );

  const setDelete = useCallback(
    entity => () => {
      setDialog(DIALOG_TYPES.DELETE);
      setCurrentEntity(entity);
    },
    []
  );

  const entityColumns = useMemo(
    () => getListColumns({ setDeleteEntity: setDelete, translateMessage, getNumber }),
    [setDelete, translateMessage, getListColumns, getNumber]
  );

  const resetDialogs = (isDeleteSuccess = false) => {
    if (isOnEditDialog && !isDeleteSuccess) {
      setDialog(DIALOG_TYPES.EDIT);
      setIsOnEditDialog(false);
      return;
    }
    setCurrentEntity(null);
    setDialog(DIALOG_TYPES.NONE);
  };

  const handleOnRowClick = params => {
    setCurrentEntity(params.row);
    setDialog(DIALOG_TYPES.EDIT);
  };

  return {
    setQuery,
    currentEntity,
    setCurrentEntity,
    dialog,
    setDialog,
    data,
    isLoading,
    filteredData,
    entityColumns,
    resetDialogs,
    handleOnRowClick,
    isOnEditDialog,
    setIsOnEditDialog,
  };
};
