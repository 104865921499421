import { useState } from 'react';
import { Localization, useNotification } from 'connex-cds';
import { func } from 'prop-types';

import ConcreteClassesComponentFormView from '../concrete-class-form-view';
import { useConcreteClasses } from '../../../../api/hooks';
import {
  DIALOG_TYPES,
  STATUS_OPTIONS,
  CONCRETE_CLASS_TYPE_AIR,
  CONCRETE_CLASS_TYPE_CONSISTENCE,
  CONCRETE_CLASS_TYPE_SLUMP_LOSS_RATE,
  CONCRETE_CLASS_TYPE_TEMPERATURE,
} from '../../../../constants';
import {
  initialValues,
  CONCRETE_CLASS_TYPE_OPTIONS,
  CONCRETE_CLASS_SUBTYPE_OPTIONS,
  UOM_TEMPERATURE_OPTIONS,
  UOM_LENGTH_OPTIONS,
} from '../concrete-class-form-view/form-config';

const AddConcreteClassesComponentDialog = ({ onClose }) => {
  const [isOpen, setIsOpen] = useState(true);
  const notification = useNotification();
  const { Create: useCreateConcreteClasses } = useConcreteClasses();
  const { mutateAsync: createConcreteClass } = useCreateConcreteClasses();
  const translateMessage = Localization.useTranslateMessage();

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  const getConcreteClass = values => {
    const {
      concreteClassId,
      concreteClassName,
      concreteClassStatus,
      concreteClassType,
      concreteClassSubtype,
      concreteClassesDefaultSettings,
      concreteClassNominalValue,
      concreteClassMinimumValue,
      concreteClassUOM,
      concreteClassMaximumValue,
    } = values;

    let concreteClass = {
      id: concreteClassId,
      name: concreteClassName,
      status: concreteClassStatus,
      classType: concreteClassType,
      isDefault: concreteClassesDefaultSettings ? true : false,
    };

    switch (concreteClassType) {
      case CONCRETE_CLASS_TYPE_AIR:
        concreteClass['nominalValue'] = concreteClassNominalValue;
        concreteClass['minimumValue'] = concreteClassMinimumValue;
        concreteClass['maximumValue'] = concreteClassMaximumValue;
        break;
      case CONCRETE_CLASS_TYPE_CONSISTENCE:
        concreteClass['classSubType'] = concreteClassSubtype;
        concreteClass['uomCode'] = concreteClassUOM;
        concreteClass['nominalValue'] = concreteClassNominalValue;
        concreteClass['minimumValue'] = concreteClassMinimumValue;
        concreteClass['maximumValue'] = concreteClassMaximumValue;
        break;
      case CONCRETE_CLASS_TYPE_SLUMP_LOSS_RATE:
        concreteClass['uomCode'] = concreteClassUOM;
        concreteClass['nominalValue'] = concreteClassNominalValue;
        break;
      case CONCRETE_CLASS_TYPE_TEMPERATURE:
        concreteClass['uomCode'] = concreteClassUOM;
        concreteClass['minimumValue'] = concreteClassMinimumValue;
        concreteClass['maximumValue'] = concreteClassMaximumValue;
        break;
      default:
        break;
    }
    return concreteClass;
  };

  const handleOnSubmit = async (values, formikBag) => {
    formikBag.setSubmitting(true);

    try {
      const concreteClass = getConcreteClass(values);
      await createConcreteClass({ concreteClass });
      notification.success(translateMessage('concreteClasses_notification_concreteClassAdded'));
      formikBag.setSubmitting(false);
      formikBag.resetForm();
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <ConcreteClassesComponentFormView
      open={isOpen}
      onClose={handleClose}
      mode={DIALOG_TYPES.ADD}
      initialValues={initialValues}
      onSubmit={handleOnSubmit}
      concreteClassesStatusOptions={STATUS_OPTIONS}
      uomTemperatureOptions={UOM_TEMPERATURE_OPTIONS}
      uomLengthOptions={UOM_LENGTH_OPTIONS}
      concreteClassesTypeOptions={CONCRETE_CLASS_TYPE_OPTIONS}
      concreteClassesSubtypeOptions={CONCRETE_CLASS_SUBTYPE_OPTIONS}
    />
  );
};

AddConcreteClassesComponentDialog.propTypes = {
  onClose: func.isRequired,
};

AddConcreteClassesComponentDialog.defaultProps = {
  onClose: () => {},
};

export default AddConcreteClassesComponentDialog;
