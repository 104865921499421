import React, { useImperativeHandle, useState } from 'react';
import { Localization } from 'connex-cds';
import { Grid, Slider } from '@mui/material';
import Cropper from 'react-easy-crop';

import { ControlsContainer, EditImageViewContainer } from './styles';
import { getCroppedImg } from '../../../../../util/cropImage';
import { ActionButton, CustomIcon } from '../../../../../commons';

const EditImageView = React.forwardRef(({ url, onComplete, isLoading }, ref) => {
  const [crop, setCrop] = React.useState({ x: 0, y: 0 });
  const [zoom, setZoom] = React.useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [rotation, setRotation] = useState(0);

  const onCropChange = crop => {
    setCrop(crop);
  };

  const onZoomChange = zoom => {
    setZoom(zoom);
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  useImperativeHandle(ref, () => ({
    showCroppedImage: async () => {
      try {
        const croppedImage = await getCroppedImg(url, croppedAreaPixels, rotation);
        onComplete(croppedImage);
      } catch (e) {
        console.error(e);
      }
    },
  }));

  return (
    <EditImageViewContainer>
      <Grid container flexWrap="nowrap" direction="column" alignItems="center" spacing={2} sx={{ margin: '0px' }}>
        <Grid className="crop-area" item>
          <Cropper
            image={url}
            crop={crop}
            rotation={rotation}
            zoom={zoom}
            aspect={1}
            cropShape="round"
            showGrid={true}
            onCropChange={onCropChange}
            onCropComplete={onCropComplete}
            onZoomChange={onZoomChange}
          />
        </Grid>
        <Grid
          sx={{
            width: '100%',
            position: 'relative',
            top: 0,
            left: 0,
            right: 0,
            bottom: '80px',
          }}
          item
        >
          <ControlsContainer>
            <div className="slider-container">
              <CustomIcon iconName="zoomInIcon" />
              <Slider
                value={zoom}
                min={1}
                max={3}
                step={0.1}
                aria-labelledby="Zoom"
                onChange={(e, zoom) => onZoomChange(zoom)}
              />
              <CustomIcon iconName="zoomOutIcon" />
            </div>
            <ActionButton
              icon="cropRotateIcon"
              label={<Localization.Translate stringId="default_uploadImage_rotate_btn" />}
              onClick={() => setRotation(prev => prev + 90)}
            />
          </ControlsContainer>
        </Grid>
      </Grid>
    </EditImageViewContainer>
  );
});

export default EditImageView;
