import { useMemo } from 'react';
import { Localization } from 'connex-cds';
import { Field, Form } from 'formik';
import { array, bool, string } from 'prop-types';
import Grid from '@mui/material/Grid';

import { DialogTable, SelectWithFormikField } from '../../../../commons';
import { getProductListColumns } from './tableConfig';

const PricingTab = ({
  priceBookOptions,
  isLoadingPriceBookOptions,
  taxableOptions,
  creditCodeOptions,
  isLoadingCreditCodeOptions,
  orderRestrictionOptions,
  currentPriceBook,
}) => {
  const translateMessage = Localization.useTranslateMessage();
  const { getCurrency } = Localization.useCurrency();

  const priceBookProducts = useMemo(
    () => priceBookOptions.find(item => item.value === currentPriceBook)?.products || [],
    [priceBookOptions, currentPriceBook]
  );

  return (
    <Form>
      <Grid container spacing={5} alignItems="baseline" className="form-content">
        <Grid item xs={6}>
          <Field
            fullWidth
            labelStringId="general_priceBook"
            component={SelectWithFormikField}
            name="priceBook"
            options={priceBookOptions}
            disabled={isLoadingPriceBookOptions}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            fullWidth
            labelStringId="general_taxable"
            infoTooltipStringId="customers_taxable_field_tooltip"
            component={SelectWithFormikField}
            name="taxable"
            options={taxableOptions}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            fullWidth
            labelStringId="customers_creditCode_field"
            infoTooltipStringId="customers_creditCode_field_tooltip"
            component={SelectWithFormikField}
            name="creditCode"
            options={creditCodeOptions}
            disabled={isLoadingCreditCodeOptions}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            fullWidth
            labelStringId="general_orderRestrictions"
            infoTooltipStringId="customers_orderRestrictions_field_tooltip"
            component={SelectWithFormikField}
            name="orderRestriction"
            options={orderRestrictionOptions}
          />
        </Grid>
        <Grid item xs={12}>
          {priceBookProducts?.length > 0 && (
            <DialogTable
              checkboxSelection={false}
              getRowId={row => row.productRef}
              rows={priceBookProducts}
              columns={getProductListColumns({ translateMessage, getCurrency })}
              isLoading={isLoadingPriceBookOptions}
            />
          )}
        </Grid>
      </Grid>
    </Form>
  );
};

PricingTab.propTypes = {
  priceBookOptions: array,
  isLoadingPriceBookOptions: bool,
  taxableOptions: array,
  creditCodeOptions: array,
  isLoadingCreditCodeOptions: bool,
  orderRestrictionOptions: array,
  currentPriceBook: string,
};

export default PricingTab;
