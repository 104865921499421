import * as yup from 'yup';

import { STATUS_OPTIONS } from '../../../../constants';

export const validationSchema = yup.object().shape({
  contactTypeId: yup.string().required('required'),
  contactTypeName: yup.string().required('required'),
  contactTypeStatus: yup.string().required('required'),
});

export const initialValues = {
  contactTypeId: '',
  contactTypeName: '',
  contactTypeStatus: STATUS_OPTIONS[0].value,
};
