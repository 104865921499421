import common from '../common';
import errors from './errors';
import themes from './theme';

export default {
  ...common,

  button: 'Schaltflächentext',
  save: 'Speichern',
  text: 'ISO 3166-1 (Codes für die Darstellung von Namen von Ländern und ihren Unterteilungen – Teil 1: Ländercodes) ist ein Standard, der Codes für die Namen von Ländern, abhängigen Gebieten und besonderen geografischen Interessengebieten definiert. Es ist der erste Teil des ISO 3166-Standards, der von der International Organization for Standardization veröffentlicht wurde.',

  username: 'Nutzername',
  password: 'Passwort',
  login: 'Anmeldung',
  required: 'Erforderlich',

  light: 'Licht',
  dark: 'Dunkel',

  rememberMe: 'Mich erinnern',

  eula: 'EULA',
  copyright: '© {year} - Command Alkon, Inc.',
  privacyPolicy: 'Datenschutz-Bestimmungen',

  customer: 'Kunde',
  project: 'Projekt',

  isBillable: 'Abrechenbar',
  dispatchDateTime: 'Datum',
  orderId: 'Befehl',
  ticketId: 'Fahrkarte',
  location: 'Ort',
  vehicleTypeName: 'Fahrzeug',
  product: 'Produkt',
  salesTax: 'Mehrwertsteuer',
  totalPrice: 'Gesamtpreis',
  extendedPrice: 'Erweiterter Preis',
  destination: 'Reiseziel',
  dispatchTime: 'Abfertigungszeit',

  showAll: 'Zeige alles',
  hideAll: 'Versteck alles',
  undoChanges: 'Änderungen rückgängig machen',
  resetColumns: 'Spalten zurücksetzen',

  displayRowCount: `{count, plural, 
    =0 {Anzeige von # Zeilen} 
    one {# Zeile anzeigen} 
    other {Anzeige von # Zeilen} 
  }`,

  columnFilters: 'Spaltenfilter',

  visible: 'Sichtbar',
  hidden: 'Versteckt',

  topSection: 'German Top Section',

  itemDetail: 'Artikeldetail',

  quantity: 'Anzahl',

  unloadingMethod: 'Entlademethode',

  time: 'Zeit',
  eventStatus: 'Ereignisstatus',

  MTQ: `{value, plural,
    =0 {0 m\u00B3}
    one {1 m\u00B3}
    other {# m\u00B3}
  }`,

  LTR: `{value, plural,
    =0 {0 Liter}
    one {1 Liter}
    other {# Liter}
  }`,

  TO: `{value, plural,
    =0 {0 Tonnen}
    one {1 Tonne}
    other {# Tonnen}
  }`,

  YDQ_short: `{value, plural,
    =0 {0 CY}
    one {1 CY}
    other {# CY}
  }`,

  mixIdLabel: 'Mischung/Beschreibung',
  orderedQtyLabel: 'Bestellte Menge',
  loadedQtyLabel: 'Geladene Menge',

  END_UNLOADING: 'Entladen beenden',
  IN_YARD: 'Im Hof',
  TO_JOB: 'Zur Jobbörse',
  LEAVE_JOB: 'Baustelle verlassen',
  CREATED: 'Erstellt',
  UNLOADING: 'Entladung',
  ARRIVE_JOB: 'Baustelle ankommen',

  plantName: 'Pflanzenname',
  plantAddress: 'Werksadresse',
  loadingScheduleStart: 'Datum und Uhrzeit des geplanten Starts werden geladen',

  deliveryAddress: 'Lieferadresse',
  phoneNumber: 'Telefonnummer',
  unloadingScheduleStart: 'Geplantes Startdatum und Uhrzeit des Entladens',
  startToFinishUnloadingTime: 'Entladebeginn - Enddatum und Uhrzeit',

  deliveryInformation: 'Lieferinformationen',
  next: 'kommend',

  material: 'Stoff',
  loadingPoint: 'Lade-Punkt',
  unloadingPoint: 'Entladestelle',

  generalDelivery: 'Allgemeine Lieferinformationen',
  materialInfo: 'Materialinformationen',

  water: 'Wasser',
  materialService: 'Materialien / Service',
  returnedMaterial: 'Zurückgegebenes Material',

  waterAdded: 'Wasser hinzugefügt',
  concreteOnTruck: 'Beton auf LKW',
  reason: 'Grund',
  customerAddedWater: 'Kunde hat Wasser hinzugefügt',
  addWater: 'Wasser hinzufügen',
  addedBy: 'Hinzugefügt von',

  addMaterialService: 'Material/Dienstleistung hinzufügen',
  customerAddedProduct: 'Vom Kunden hinzugefügtes Produkt',

  addReturnedMaterial: 'Retourniertes Material hinzufügen',
  leftover: 'Rest',

  submitAcceptance: 'Annahme übermitteln',
  customerAcceptance: 'Kundenakzeptanz',

  materialSummary: 'Materialzusammenfassung',
  mix: 'Mischen',
  ordered: 'Bestellt',
  loaded: 'Geladen',

  addedByWhen: `Added By: {addedBy} at {time}`,

  driverComment: 'Fahrerkommentare',
  customerComment: 'Kundenkommentar',
  editComment: 'Kommentar bearbeiten',
  addComment: 'Einen Kommentar hinzufügen',
  additionalInfo: 'Zusätzliche Information',
  onBoard: 'Am Bord',
  driver: 'Treiber',
  commentInstruction: 'Kommentaranweisung',
  signatureInstruction: 'Signaturanweisung',
  customerSignature: 'Unterschrift des Kunden',
  comment: 'Kommentar',

  status: 'Status',
  updateStatusTime: 'Statuszeit aktualisieren',
  delete: 'Löschen',
  cancel: 'Absagen',
  back: 'Der Rücken',
  add: 'Hinzufügen',

  provideSignature: 'Unterschrift bereitstellen',
  ticket: 'Fahrkarte',
  details: 'Einzelheiten',
  qrCode: 'QR',
  driverAcceptanceReason: 'Grund',
  driverAcceptance: 'Fahrerakzeptanz',

  'payment-method': 'Zahlungsarten',
  'add-payment-method': 'Zahlungsmethode hinzufügen',

  'cancel-reason-code': 'Stornierungsgründe',
  'add-cancel-reason-code': 'Grund hinzufügen',

  services: 'Dienstleistungen',
  'add-service': 'Dienst hinzufügen',
  serviceEditor: 'Service Editor',

  pdfTicketForm: 'PDF -Ticketvorlage',
  pdfTicketForm_download: 'Herunterladen',
  pdfTicketForm_import_ticket: 'Ticket importieren',
  pdfTicketForm_no_preview: 'Noch nichts in der Vorschau',
  pdfTicketForm_preview: 'Vorschau',
  pdfTicketForm_lang_selector: 'Sprachauswahl',
  pdfTicketForm_tableHeader_name: 'Vorlagenname',
  pdfTicketForm_tableHeader_language: 'Sprache',
  pdfTicketForm_tableHeader_vehicleTypes: 'Fahrzeugtyp',

  errors,
  themes,
};
