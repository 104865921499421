import { useState } from 'react';
import { Formik } from 'formik';
import { Localization } from 'connex-cds';
import { Button } from '@mui/material';
import { array, bool, func, string, object } from 'prop-types';

import {
  DialogTitle,
  CustomTabsControl,
  CustomTabsView,
  CustomIcon,
  DialogContent,
  DialogActions,
  DialogContainer,
} from '../../../../commons';
import { DIALOG_TYPES } from '../../../../constants';
import { TAB_IDS, validationSchema } from './form-config';
import VehicleProfileTab from '../vehicle-profile-tab';
import VehicleSpecificationsTab from '../vehicle-specifications-tab';

const VehicleFormView = ({
  open,
  onClose,
  mode,
  initialValues,
  onSubmit,
  statusOptions,
  vehicleTypesOptions,
  isLoadingVehicleTypesOptions,
  locationsOptions,
  isLoadingLocationsOptions,
  carriersOptions,
  isLoadingCarriersOptions,
  handleOnDelete,
  driversOptions,
  isLoadingDriversOptions,
  trailersOptions,
  isLoadingTrailersOptions,
}) => {
  const [currentTab, setCurrentTab] = useState(TAB_IDS.VehicleProfile);

  const goToStep = stepIndex => () => {
    setCurrentTab(stepIndex);
  };

  const handleTabChange = (_e, value) => {
    setCurrentTab(value);
  };

  const handleClose = () => {
    onClose();
    setCurrentTab(TAB_IDS.VehicleProfile);
  };

  const handleCloseDialog = (_e, reason) => {
    if (reason === 'escapeKeyDown') {
      handleClose();
    }
  };

  const TABS = [
    {
      tabIdentifier: TAB_IDS.VehicleProfile,
      label: <Localization.Translate stringId={'vehicles_profileTab_title'} />,
      icon: <CustomIcon iconName={'driveFileRenameOutlineRounded'} />,
    },
    {
      tabIdentifier: TAB_IDS.VehicleSpecifications,
      label: <Localization.Translate stringId={'vehicles_specificationsTab_title'} />,
      icon: <CustomIcon iconName={'listAltOutlinedIcon'} />,
    },
  ];

  const renderHeader = mode => {
    let icon;
    let stringId;

    switch (mode) {
      case DIALOG_TYPES.ADD:
        icon = 'addOutlinedIcon';
        stringId = 'vehicles_addVehicle_title';
        break;
      case DIALOG_TYPES.EDIT:
        icon = 'editOutlinedIcon';
        stringId = 'vehicles_editVehicle_title';
        break;

      default:
        icon = '';
        stringId = '';
        break;
    }

    return (
      <>
        <CustomIcon iconName={icon} size={'medium'} />
        <Localization.Translate stringId={stringId} />
      </>
    );
  };

  return (
    <DialogContainer open={open} maxWidth="md" fullWidth onClose={handleCloseDialog}>
      <DialogTitle onDelete={handleOnDelete} onClose={handleClose}>
        {renderHeader(mode)}
      </DialogTitle>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} enableReinitialize>
        {({ isSubmitting, dirty, isValid, handleSubmit }) => {
          return (
            <>
              <CustomTabsControl tabs={TABS} currentTab={currentTab} handleTabChange={handleTabChange} />
              <DialogContent>
                <CustomTabsView tab={currentTab}>
                  <VehicleProfileTab
                    tabIdentifier={TAB_IDS.VehicleProfile}
                    statusOptions={statusOptions}
                    mode={mode}
                    vehicleTypesOptions={vehicleTypesOptions}
                    isLoadingVehicleTypesOptions={isLoadingVehicleTypesOptions}
                    locationsOptions={locationsOptions}
                    isLoadingLocationsOptions={isLoadingLocationsOptions}
                    carriersOptions={carriersOptions}
                    isLoadingCarriersOptions={isLoadingCarriersOptions}
                  />
                  <VehicleSpecificationsTab
                    tabIdentifier={TAB_IDS.VehicleSpecifications}
                    driversOptions={driversOptions}
                    isLoadingDriversOptions={isLoadingDriversOptions}
                    trailersOptions={trailersOptions}
                    isLoadingTrailersOptions={isLoadingTrailersOptions}
                  />
                </CustomTabsView>
              </DialogContent>
              <DialogActions
                lefSide={
                  currentTab !== 0 && (
                    <Button onClick={goToStep(currentTab - 1)} className="step-control">
                      <Localization.Translate stringId="general_dialogAction_back" />
                    </Button>
                  )
                }
                rightSide={
                  <>
                    {currentTab < TABS.length - 1 && (
                      <Button onClick={goToStep(currentTab + 1)} className="step-control">
                        <Localization.Translate stringId="general_dialogAction_next" />
                      </Button>
                    )}
                    {currentTab === TABS.length - 1 && (
                      <Button
                        type="submit"
                        variant="contained"
                        disabled={isSubmitting || !dirty || !isValid}
                        onClick={handleSubmit}
                      >
                        <Localization.Translate stringId="general_dialogAction_save" />
                      </Button>
                    )}
                  </>
                }
              />
            </>
          );
        }}
      </Formik>
    </DialogContainer>
  );
};

VehicleFormView.propTypes = {
  handleOnDelete: func,
  open: bool.isRequired,
  onClose: func.isRequired,
  mode: string.isRequired,
  initialValues: object.isRequired,
  onSubmit: func.isRequired,
  statusOptions: array.isRequired,
  vehicleTypesOptions: array.isRequired,
  isLoadingVehicleTypesOptions: bool.isRequired,
  locationsOptions: array.isRequired,
  isLoadingLocationsOptions: bool.isRequired,
  carriersOptions: array.isRequired,
  isLoadingCarriersOptions: bool.isRequired,
  driversOptions: array.isRequired,
  isLoadingDriversOptions: bool.isRequired,
  trailersOptions: array.isRequired,
  isLoadingTrailersOptions: bool.isRequired,
};

export default VehicleFormView;
