import { useState, useMemo } from 'react';
import { Localization, useNotification } from 'connex-cds';
import { func, number, shape, string } from 'prop-types';

import { DIALOG_TYPES, STATUS_OPTIONS } from '../../../../constants';
import MixFormView from '../mix-form-view';
import { useUom, useMixes, useComponents } from '../../../../api/hooks';
import { parseUomOptions, parseMixComponentsOptions } from '../../../../api/adapters';
import { componentsMapper } from '../../../../util/componentsMapper';

const EditMixDialog = ({ onClose, mixData, handleOnDelete }) => {
  const [isOpen, setIsOpen] = useState(true);
  const { Update: useEditMix } = useMixes();
  const { mutateAsync: updateMix } = useEditMix();
  const { List: useListUom } = useUom();
  const { data: uomOptions, isLoading: isLoadingUom } = useListUom({ adapter: parseUomOptions });
  const { List: useListMixComponents } = useComponents();
  const { data: mixComponentsOptions, isLoading: isLoadingMixComponents } = useListMixComponents({
    adapter: parseMixComponentsOptions,
  });
  const translateMessage = Localization.useTranslateMessage();
  const notification = useNotification();

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  const handleOnSubmit = async (values, formikBag) => {
    const {
      mixDesignName,
      mixDesignStatus,
      maxBatchSize,
      maxBatchUom,
      mixFormulaId,
      mixComponents,
      mixDesignComponentsQuantities,
    } = values;

    const components = componentsMapper(mixComponents, mixDesignComponentsQuantities);

    formikBag.setSubmitting(true);

    try {
      const data = {
        name: mixDesignName,
        status: mixDesignStatus,
        maxBatchSize: parseInt(maxBatchSize),
        maxBatchSizeUOM: maxBatchUom,
        jobMixFormulaId: mixFormulaId,
        components: components,
      };

      await updateMix({ data, mixRef: mixData.crn });
      notification.success(translateMessage('notification_changesSaved'));

      formikBag.setSubmitting(false);
      formikBag.resetForm();
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  const getInitialValues = useMemo(() => {
    if (!mixData) return null;

    const { id, name, maxBatchSize, jobMixFormulaId, components } = mixData;

    const newMixComponents = [];

    components?.forEach(item1 => {
      const matchingComponent = mixComponentsOptions.find(item2 => item2.value === item1.componentRef);
      if (matchingComponent) {
        newMixComponents.push(matchingComponent);
      }
    });

    const mixDesignComponentsQuantities = {};
    components?.forEach(item => {
      mixDesignComponentsQuantities[item.componentRef] = item.quantity.value.toString();
    });

    const values = {
      mixDesignId: id,
      mixDesignName: name,
      mixDesignStatus: STATUS_OPTIONS.find(status => status.value === mixData.status).value,
      maxBatchSize: maxBatchSize.toString(),
      maxBatchUom: uomOptions.find(uomItem => uomItem.value === mixData?.maxBatchSizeUOM)?.value,
      mixFormulaId: jobMixFormulaId || '',
      mixComponents: newMixComponents,
      mixDesignComponentsQuantities: mixDesignComponentsQuantities,
    };

    return values;
  }, [mixData, uomOptions, mixComponentsOptions]);

  return (
    <MixFormView
      handleOnDelete={handleOnDelete}
      open={isOpen}
      onClose={handleClose}
      mode={DIALOG_TYPES.EDIT}
      initialValues={getInitialValues}
      onSubmit={handleOnSubmit}
      mixDesignStatusOptions={STATUS_OPTIONS}
      uomOptions={uomOptions}
      isLoadingUom={isLoadingUom}
      mixComponentsOptions={mixComponentsOptions}
      isLoadingMixComponents={isLoadingMixComponents}
    />
  );
};

EditMixDialog.propTypes = {
  onClose: func.isRequired,
  handleOnDelete: func,
  mixData: shape({
    crn: string.isRequired,
    id: string.isRequired,
    name: string.isRequired,
    maxBatchSize: number,
    jobMixFormulaId: string,
    maxBatchSizeUOM: string,
    status: string.isRequired,
  }),
};

EditMixDialog.defaultProps = {
  onClose: () => {},
  mixData: {},
};

export default EditMixDialog;
