export const componentsMapper = (mixComponents, componentsQuantities) => {
  const components = mixComponents.map((component, index) => {
    return {
      batchSequence: index,
      componentRef: component?.value,
      quantity: {
        value: parseInt(componentsQuantities[component.value], 10) || 0,
        uomCode: component?.uomCode,
      },
    };
  });

  return components;
};
