import { TranslatedCell, TextInputWithFormikField } from '../../../../commons';
import { Field } from 'formik';

export const getProductListColumns = ({ translateMessage, handlePriceChange, setFieldValue }) => [
  {
    field: 'name',
    flex: 1,
    renderHeader: () => <TranslatedCell stringId={'general_tableHeader_product'} />,
  },
  {
    field: 'type',
    flex: 1,
    renderHeader: () => <TranslatedCell stringId={'general_tableHeader_productType'} />,
  },
  {
    field: 'price',
    flex: 1,
    maxWidth: 150,
    renderHeader: () => <TranslatedCell stringId={'general_tableHeader_price'} />,
    renderCell: ({ row }) => {
      return (
        <Field
          component={TextInputWithFormikField}
          variant="outlined"
          name={`priceBookProductPrice.${row.crn}`}
          token={'general_formPlaceholder_addPrice'}
          customOnChange={handlePriceChange(setFieldValue)}
        />
      );
    },
  },
  {
    field: 'uomCode',
    flex: 1,
    maxWidth: 150,
    renderHeader: () => <TranslatedCell stringId={'general_tableHeader_uom'} />,
  },
];
