import { http } from 'connex-cds';

export default {
  getEntitySetup: ({ entityRef }) =>
    http.get({
      apiName: 'company-admin',
      path: `/${entityRef}/entity-setup`,
      queryParams: { expand: 'image-logo-default' },
    }),
  createEntitySetup: ({ entityRef, data }) =>
    http.post({ apiName: 'company-admin', path: `/${entityRef}/entity-setup`, data }),
  updateEntitySetup: ({ entityRef, data }) =>
    http.patch({ apiName: 'company-admin', path: `/${entityRef}/entity-setup`, data }),
  createEntitySetupLogo: ({ entityRef, data }) =>
    http.post({ apiName: 'company-admin', path: `/${entityRef}/entity-setup/logo`, data }),
  updateEntitySetupLogo: ({ entityRef, data }) =>
    http.patch({ apiName: 'company-admin', path: `/${entityRef}/entity-setup/logo`, data }),
  deleteEntitySetupLogo: ({ entityRef }) =>
    http.delete({ apiName: 'company-admin', path: `/${entityRef}/entity-setup/logo` }),
};
