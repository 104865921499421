import { Localization } from 'connex-cds';
import { Formik } from 'formik';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';

import { CustomIcon, DialogActions, DialogContainer, DialogContent, DialogTitle } from '../../../../commons';
import { validationSchema, initialValues as defaultInitialValues, categoryOptions } from './form-config';
import ProductTypeProfileTab from '../product-type-profile-tab/ProductTypeProfileTab';
import { DIALOG_TYPES, STATUS_OPTIONS } from '../../../../constants/common';

const ProductTypeFormView = ({ open, onClose, mode, initialValues, onSubmit, handleOnDelete }) => {
  const handleCloseDialog = (e, reason) => {
    if (reason === 'escapeKeyDown') {
      onClose();
    }
  };

  const handleClose = () => {
    onClose();
  };

  const renderHeader = mode => {
    let icon;
    let stringId;

    switch (mode) {
      case DIALOG_TYPES.ADD:
        icon = 'addOutlinedIcon';
        stringId = 'productTypes_addProductType';
        break;
      case DIALOG_TYPES.EDIT:
        icon = 'editOutlinedIcon';
        stringId = 'productTypes_editProductType';
        break;

      default:
        icon = '';
        stringId = '';
        break;
    }

    return (
      <>
        <CustomIcon iconName={icon} size={'medium'} />
        <Localization.Translate stringId={stringId} />
      </>
    );
  };

  return (
    <DialogContainer open={open} maxWidth="md" fullWidth onClose={handleCloseDialog}>
      <DialogTitle onDelete={handleOnDelete} onClose={handleClose}>
        {renderHeader(mode)}
      </DialogTitle>
      <Formik enableReinitialize initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ handleSubmit, dirty, isValid, isSubmitting }) => (
          <>
            <DialogContent>
              <ProductTypeProfileTab mode={mode} statusOptions={STATUS_OPTIONS} categoryOptions={categoryOptions} />
            </DialogContent>
            <DialogActions
              rightSide={
                <Button
                  type="submit"
                  variant="contained"
                  disabled={isSubmitting || !dirty || !isValid}
                  onClick={handleSubmit}
                >
                  <Localization.Translate stringId="general_save" />
                </Button>
              }
            />
          </>
        )}
      </Formik>
    </DialogContainer>
  );
};

ProductTypeFormView.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  handleOnDelete: PropTypes.func,
  mode: PropTypes.oneOf([DIALOG_TYPES.ADD, DIALOG_TYPES.EDIT]),
  initialValues: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    category: PropTypes.string,
    status: PropTypes.string,
  }),
  onSubmit: PropTypes.func.isRequired,
};

ProductTypeFormView.defaultProps = {
  open: false,
  onClose: () => null,
  mode: 'ADD',
  initialValues: defaultInitialValues,
};

export default ProductTypeFormView;
