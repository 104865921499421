import { Field, Form } from 'formik';
import Grid from '@mui/material/Grid';

import { CheckboxWithFormikField } from '../../../../commons';
import { TicketSettingsContainer } from './styles';

const TicketSettingsTab = () => {
  return (
    <TicketSettingsContainer>
      <Form>
        <Grid container spacing={2} alignItems="baseline" className="form-content">
          <Grid item md={12} className="custom-form-control-checkbox">
            <Field
              fullWidth
              labelStringId="customers_ticketSettingTab_printPaperTickets"
              component={CheckboxWithFormikField}
              name="printPaperTickets"
            />
          </Grid>
          <Grid item md={12} className="custom-form-control-checkbox">
            <Field
              fullWidth
              labelStringId="customers_ticketSettingTab_printWeights"
              component={CheckboxWithFormikField}
              name="printWeights"
            />
          </Grid>
          <Grid item md={12} className="custom-form-control-checkbox">
            <Field
              fullWidth
              labelStringId="customers_ticketSettingTab_showPricing"
              component={CheckboxWithFormikField}
              name="showPricing"
            />
          </Grid>
        </Grid>
      </Form>
    </TicketSettingsContainer>
  );
};

export default TicketSettingsTab;
