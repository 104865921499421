import { Localization } from 'connex-cds';

import { useDrivers } from '../../api/hooks';
import { getDriversListColumns } from './tableConfig';
import { TableLayout, EntityTable, SearchFilter, DialogManager, ActionButton } from '../../commons';
import { DIALOG_TYPES } from '../../constants';
import AddDriverDialog from './components/add-driver-dialog';
import EditDriverDialog from './components/edit-driver-dialog';
import DeleteDriverDialog from './components/delete-driver-dialog';
import { useTableLogic } from '../../hooks';

const Drivers = () => {
  const filteredPropsArr = ['id', 'firstName', 'lastName', 'mobile', 'phone', 'email'];
  const {
    setQuery,
    currentEntity,
    dialog,
    setDialog,
    isLoading,
    filteredData,
    entityColumns,
    resetDialogs,
    handleOnRowClick,
    setIsOnEditDialog,
  } = useTableLogic(useDrivers, getDriversListColumns, filteredPropsArr);

  return (
    <TableLayout.ListLayoutContainer>
      <TableLayout.TableControls>
        <SearchFilter onSearchChange={setQuery} disabled={isLoading} resultsCount={filteredData.length} />
        <ActionButton
          icon="addOutlinedIcon"
          label={<Localization.Translate stringId="drivers_addDriver_title" />}
          variant="outlined"
          onClick={() => setDialog(DIALOG_TYPES.ADD)}
        />
      </TableLayout.TableControls>
      <TableLayout.TableBody>
        <EntityTable
          checkboxSelection={false}
          isLoading={isLoading}
          columns={entityColumns}
          rows={filteredData}
          getRowId={row => row.crn}
          onRowClick={handleOnRowClick}
        />
      </TableLayout.TableBody>
      <DialogManager screen={dialog}>
        <AddDriverDialog identifier={DIALOG_TYPES.ADD} onClose={resetDialogs} />
        <EditDriverDialog
          identifier={DIALOG_TYPES.EDIT}
          handleOnDelete={() => {
            setIsOnEditDialog(true);
            setDialog(DIALOG_TYPES.DELETE);
          }}
          onClose={resetDialogs}
          driverData={currentEntity}
        />
        <DeleteDriverDialog identifier={DIALOG_TYPES.DELETE} onClose={resetDialogs} driverData={currentEntity} />
      </DialogManager>
    </TableLayout.ListLayoutContainer>
  );
};

export default Drivers;
