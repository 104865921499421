import { useMemo, useState } from 'react';
import { Localization, useNotification } from 'connex-cds';
import { func, object } from 'prop-types';

import { DIALOG_TYPES, STATUS_OPTIONS } from '../../../../constants';
import { useContactTypes } from '../../../../api/hooks';
import ContactTypeFormView from '../contact-type-form-view';

const EditContactTypeDialog = ({ onClose, contactTypeData, handleOnDelete }) => {
  const [isOpen, setIsOpen] = useState(true);
  const { Update: useUpdateContactTypes } = useContactTypes();
  const { mutateAsync: updateContactType } = useUpdateContactTypes();
  const notification = useNotification();
  const translateMessage = Localization.useTranslateMessage();

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  const handleOnSubmit = async (values, formikBag) => {
    const { contactTypeName, contactTypeStatus } = values;

    formikBag.setSubmitting(true);

    try {
      const contactType = {
        name: contactTypeName,
        status: contactTypeStatus,
      };

      await updateContactType({ contactType, contactTypeRef: contactTypeData?.crn });
      notification.success(translateMessage('notification_changesSaved'));

      formikBag.setSubmitting(false);
      formikBag.resetForm();
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  const getInitialValues = useMemo(() => {
    if (!contactTypeData) return null;
    const { id, name, status } = contactTypeData;

    const editValues = {
      contactTypeId: id,
      contactTypeName: name,
      contactTypeStatus: STATUS_OPTIONS.find(option => option.value === status).value,
    };

    return editValues;
  }, [contactTypeData]);

  return (
    <ContactTypeFormView
      handleOnDelete={handleOnDelete}
      open={isOpen}
      onClose={handleClose}
      mode={DIALOG_TYPES.EDIT}
      initialValues={getInitialValues}
      onSubmit={handleOnSubmit}
      statusOptions={STATUS_OPTIONS}
    />
  );
};

EditContactTypeDialog.propTypes = {
  contactTypeData: object,
  onClose: func.isRequired,
  handleOnDelete: func,
};

EditContactTypeDialog.defaultProps = {
  onClose: () => {},
};

export default EditContactTypeDialog;
