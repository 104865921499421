import { TranslatedCell, CustomIcon } from '../../commons';
import { KebabOption } from '../../commons/entity-table';

export const getListColumns =
  ({ download }) =>
  ({ translateMessage }) =>
    [
      {
        field: 'name',
        flex: 1,
        renderHeader: () => <TranslatedCell stringId={'pdfTicketForm_tableHeader_name'} />,
      },
      {
        field: 'languages',
        flex: 1,
        renderHeader: () => <TranslatedCell stringId={'pdfTicketForm_tableHeader_language'} />,
        valueGetter: ({ row }) => translateMessage(`languages.${row.language}`),
      },
      {
        field: 'vehicleTypes',
        flex: 1,
        renderHeader: () => <TranslatedCell stringId={'pdfTicketForm_tableHeader_vehicleTypes'} />,
        valueGetter: ({ row }) => row?.vehicleType,
      },
      {
        field: 'actions',
        type: 'actions',
        sortable: false,
        width: 80,
        cellClassName: 'table-actions',
        getActions: params => [
          <KebabOption
            icon={<CustomIcon iconName="fileDownloadOutlinedIcon" />}
            label={translateMessage('pdfTicketForm_download')}
            onClick={() => download(params?.row?.link)}
            showInMenu
          />,
        ],
      },
    ];
