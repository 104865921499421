import { TranslatedCell } from '../../commons';

export const getRolesColumns = () => [
  {
    field: 'name',
    flex: 1,
    renderHeader: () => <TranslatedCell stringId={'general_role_name'} />,
  },
  {
    field: 'apps',
    flex: 2,
    sortable: false,
    renderHeader: () => <TranslatedCell stringId={'apps'} />,
    valueGetter: ({ row }) => row?.apps?.join(', '),
  },
];
