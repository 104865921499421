import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { entitySetups } from '../queries';

export const useEntitySetups = () => {
  const { entityRef } = useParams();
  const queryClient = useQueryClient();
  const queryKey = ['entity-setup', entityRef];

  const GetEntitySetups = () => {
    const queryResult = useQuery({
      queryKey,
      queryFn: () => entitySetups.getEntitySetup({ entityRef }),
      staleTime: Infinity,
    });

    return queryResult;
  };

  const Update = () =>
    useMutation({
      mutationFn: ({ data }) => entitySetups.updateEntitySetup({ entityRef, data }),
      onError: context => {
        queryClient.setQueryData(queryKey, context.previousItem);
      },
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey });
      },
    });

  const Create = () =>
    useMutation({
      mutationFn: ({ data }) => entitySetups.createEntitySetup({ entityRef, data }),
      onError: context => {
        queryClient.setQueryData(queryKey, context.previousItem);
      },
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey });
      },
    });

  const CreateLogo = () =>
    useMutation({
      mutationFn: data => entitySetups.createEntitySetupLogo({ entityRef, data }),
      onError: context => {
        queryClient.setQueryData(queryKey, context.previousItem);
      },
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey });
      },
    });

  const UpdateLogo = () =>
    useMutation({
      mutationFn: data => entitySetups.updateEntitySetupLogo({ entityRef, data }),
      onError: context => {
        queryClient.setQueryData(queryKey, context.previousItem);
      },
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey });
      },
    });

  const DeleteLogo = () =>
    useMutation({
      mutationFn: () => entitySetups.deleteEntitySetupLogo({ entityRef }),
      onError: context => {
        queryClient.setQueryData(queryKey, context.previousItem);
      },
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey });
      },
    });

  return { GetEntitySetups, Create, Update, CreateLogo, UpdateLogo, DeleteLogo };
};
