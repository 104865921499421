export const uomConversions = {
  GLL: {
    GLL: 1,
    OZA: 1 / 128,
    PT: 1 / 8,
    QT: 1 / 4,
    LBR: 1 / 8.345,
  },
  MLT: {
    LTR: 1 / 1000,
    MLT: 1,
  },
  LTR: {
    LTR: 1,
    MLT: 1000,
  },
  LBR: {
    LBR: 1,
    ONZ: 1 / 16,
    STN: 1 / 2000,
    GLL: 8.345,
    KGM: 1 / 2.20462,
  },
  KGM: {
    KGM: 1,
    GRM: 1000,
    MTN: 1 / 1000,
    LBR: 2.20462,
  },
  MTN: {
    STN: 1 / 0.907185,
    KGM: 1000,
    LBR: 2204.62,
    MTN: 1,
  },
  STN: {
    LBR: 2000,
    MTN: 0.907185,
    KGM: 907.185,
  },
  YDQ: {
    MTQ: 0.764555,
    YDQ: 1,
  },
  MTQ: {
    YDQ: 1 / 0.764555,
    MTQ: 1,
  },
};
