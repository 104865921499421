import { useState } from 'react';
import { Localization, useNotification } from 'connex-cds';

import { ConfirmationDialog } from '../../../../commons';
import { useShippingMethods } from '../../../../api/hooks';
import { DELETED_STATUS } from '../../../../constants';
import { trimWithDots } from '../../../../util/strings';

const DeleteShippingMethodsDialog = ({ onClose, itemData }) => {
  const [isOpen, setIsOpen] = useState(true);
  const notification = useNotification();
  const translateMessage = Localization.useTranslateMessage();
  const { Update: useUpdateShippingMethods } = useShippingMethods();
  const { mutateAsync: updateCreateShippingMethods } = useUpdateShippingMethods();

  const handleDelete = async () => {
    try {
      if (itemData?.crn) {
        await updateCreateShippingMethods({ shippingMethodRef: itemData.crn, data: { status: DELETED_STATUS } });
        notification.success(translateMessage('shippingMethods_notification_shippingMethodDeleted'));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleOnClose = (isDeleteSuccess = false) => {
    setIsOpen(false);
    onClose(isDeleteSuccess);
  };

  return (
    <ConfirmationDialog
      open={isOpen}
      onConfirm={handleDelete}
      onClose={handleOnClose}
      confirmText="delete"
      color="delete"
    >
      <h3 className="delete-title">
        <Localization.Translate stringId="shippingMethods_deleteDialog_title" />
      </h3>
      <p className="delete-description">
        <Localization.Translate
          stringId="shippingMethods_deleteDialog_description"
          values={{
            strong: chunks => <strong>{trimWithDots(chunks, 100)}</strong>,
            name: itemData?.name,
          }}
        />
      </p>
    </ConfirmationDialog>
  );
};

export default DeleteShippingMethodsDialog;
