import { useState } from 'react';
import { Localization, useNotification } from 'connex-cds';
import { func } from 'prop-types';

import { DIALOG_TYPES, STATUS_OPTIONS } from '../../../../constants';
import { useWorkTypes, useProductTypes } from '../../../../api/hooks';
import WorkTypeFormView from '../work-type-form-view';
import { initialValues } from '../work-type-form-view/form-config';
import { parseProductTypesOptionsWithRef } from '../../../../api/adapters';

const AddWorkTypeDialog = ({ onClose }) => {
  const notification = useNotification();
  const translateMessage = Localization.useTranslateMessage();
  const [isOpen, setIsOpen] = useState(true);
  const { Create: useCreateWorkType } = useWorkTypes();
  const { mutateAsync: createWorkType } = useCreateWorkType();
  const { List: useListProductTypes } = useProductTypes();
  const { data: productTypesOptions, isLoading: isLoadingProductTypes } = useListProductTypes({
    adapter: parseProductTypesOptionsWithRef,
  });

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  const handleOnSubmit = async (values, formikBag) => {
    formikBag.setSubmitting(true);
    const { id, name, status, productTypeRef } = values;
    try {
      const workType = {
        id,
        name,
        status,
        productTypeRef,
      };
      await createWorkType({ data: workType });
      notification.success(translateMessage('workTypes_notification_workTypeAdded'));
      formikBag.setSubmitting(false);
      formikBag.resetForm();
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <WorkTypeFormView
      open={isOpen}
      onClose={handleClose}
      mode={DIALOG_TYPES.ADD}
      initialValues={initialValues}
      onSubmit={handleOnSubmit}
      statusOptions={STATUS_OPTIONS}
      productTypesOptions={productTypesOptions}
      isLoadingProductTypes={isLoadingProductTypes}
    />
  );
};

AddWorkTypeDialog.propTypes = {
  onClose: func.isRequired,
};

AddWorkTypeDialog.defaultProps = {
  onClose: () => {},
};

export default AddWorkTypeDialog;
