import { Localization, User } from 'connex-cds';

import { useUsers, useRoles } from '../../api/hooks';
import { CustomSettingCard } from '../../commons';
import { H2Styled, ParagraphStyled, CardContainerStyled } from './styles';

const UsersHome = () => {
  const translateMessage = Localization.useTranslateMessage();
  const { user } = User.useUserContext();
  const { List: useUsersList } = useUsers();
  const { data: usersData } = useUsersList();
  const { List: useRolesList } = useRoles();
  const { data: rolesData } = useRolesList();

  const CARD_OPTIONS = [
    {
      title: 'activeUsers',
      description: 'usersHome_activeUsers_card_description',
      icon: 'personOutlineOutlinedIcon',
      navigateTo: '../active-users',
      state: 'notStarted',
      displaySubHeader:
        usersData?.length === 0 || (usersData?.length === 1 && usersData[0]?.profileRef === user?.profileRef),
    },
    {
      title: 'general_roles',
      description: 'usersHome_roles_card_description',
      icon: 'workOutlineOutlinedIcon',
      navigateTo: '../roles',
      state: 'notStarted',
      displaySubHeader: rolesData?.length === 0,
    },
  ];

  return (
    <>
      <H2Styled>{translateMessage('usersHome_title')}</H2Styled>
      <ParagraphStyled>{translateMessage('usersHome_description')}</ParagraphStyled>
      <CardContainerStyled>
        {CARD_OPTIONS.map((card, index) => (
          <CustomSettingCard
            key={index}
            iconName={card.icon}
            titleStringId={card.title}
            descriptionStringId={card.description}
            navigateTo={card.navigateTo}
            state={card.state}
            displaySubHeader={card.displaySubHeader}
          />
        ))}
      </CardContainerStyled>
    </>
  );
};

export default UsersHome;
