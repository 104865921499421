import { useState } from 'react';
import { func, shape, string } from 'prop-types';
import { Localization, useNotification } from 'connex-cds';

import { ConfirmationDialog } from '../../../../commons';
import { useWorkTypes } from '../../../../api/hooks';
import { DELETED_STATUS } from '../../../../constants/common';
import { trimWithDots } from '../../../../util/strings';

const DeleteWorkTypeDialog = ({ onClose, workTypeData }) => {
  const notification = useNotification();
  const translateMessage = Localization.useTranslateMessage();
  const [isOpen, setIsOpen] = useState(true);
  const { Update: useUpdateWorkType } = useWorkTypes();
  const { mutateAsync: updateWorkType } = useUpdateWorkType();

  const handleDelete = async () => {
    try {
      if (workTypeData?.crn) {
        await updateWorkType({ workTypeRef: workTypeData.crn, data: { status: DELETED_STATUS } });
        notification.success(translateMessage('workTypes_notification_workTypeDeleted'));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleOnClose = (isDeleteSuccess = false) => {
    setIsOpen(false);
    onClose(isDeleteSuccess);
  };

  return (
    <ConfirmationDialog
      open={isOpen}
      onConfirm={handleDelete}
      onClose={handleOnClose}
      confirmText="delete"
      color="delete"
    >
      <h3 className="delete-title">
        <Localization.Translate stringId="workTypes_deleteDialog_title" />
      </h3>
      <p className="delete-description">
        <Localization.Translate
          stringId="workTypes_deleteDialog_description"
          values={{
            strong: chunks => <strong>{trimWithDots(chunks, 100)}</strong>,
            name: workTypeData?.name,
          }}
        />
      </p>
    </ConfirmationDialog>
  );
};

DeleteWorkTypeDialog.propTypes = {
  onClose: func.isRequired,
  workTypeData: shape({
    crn: string.isRequired,
    name: string.isRequired,
  }),
};

export default DeleteWorkTypeDialog;
