import React, { useEffect, useRef, useState } from 'react';
import { Localization, useNotification } from 'connex-cds';
import { Button } from '@mui/material';

import EditImageComponent from './step-views/edit-image-view';
import LoadImageDialog from './step-views/load-image-view';
import CurrentImageActions from './step-views/current-image-view';

import { CustomIcon, DialogActions, DialogContainer, DialogContent, DialogTitle } from '../../../commons';
import { useEntitySetups } from '../../../api/hooks';
import { UPLOAD_IMAGE_DISPLAY } from '../../../constants';

const UploadLogoImageDialog = ({ open, onClose, currentLogo }) => {
  const [screen, setScreen] = useState(UPLOAD_IMAGE_DISPLAY.CURRENT);
  const notify = useNotification();
  const translateMessage = Localization.useTranslateMessage();

  const [newImage, setNewImage] = useState(null);
  const fileInputRef = useRef();
  const { mutateAsync: createEntitySetupLogo, isLoading: isLoadingCreateLogo } = useEntitySetups().CreateLogo();
  const { mutateAsync: deleteEntitySetupLogo, isLoading: isLoadingDeleteLogo } = useEntitySetups().DeleteLogo();
  const { mutateAsync: updateEntitySetupLogo, isLoading: isLoadingUpdateLogo } = useEntitySetups().UpdateLogo();

  const editImageComponentRef = useRef();

  const handleOnClose = () => {
    onClose();
  };

  useEffect(() => {
    if (newImage) {
      setScreen(UPLOAD_IMAGE_DISPLAY.EDIT);
    }
  }, [newImage]);

  const handleAddClick = () => {
    fileInputRef.current.click();
  };

  const handleUploadImage = async ({ image, width, height }) => {
    const changeHandler = currentLogo ? updateEntitySetupLogo : createEntitySetupLogo;
    try {
      changeHandler({ data: image, width, height })
        .then(() => {
          notify.success(translateMessage('default_uploadImage_update_sucess_msg'));
          setNewImage(null);
          onClose();
        })
        .catch(() => {
          notify.error(translateMessage('default_uploadImage_update_error_msg'));
          setScreen(UPLOAD_IMAGE_DISPLAY.CURRENT);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnDeleteImage = async () => {
    await deleteEntitySetupLogo()
      .then(() => {
        notify.success(translateMessage('default_uploadImage_delete_sucess_msg'));
      })
      .catch(() => {
        notify.error(translateMessage('default_uploadImage_delete_error_msg'));
      })
      .finally(() => {
        handleOnClose();
        setNewImage(null);
      });
  };

  const handleOnBack = () => {
    switch (screen) {
      case UPLOAD_IMAGE_DISPLAY.CURRENT:
        handleOnClose();
        break;
      case UPLOAD_IMAGE_DISPLAY.PICK:
        setScreen(UPLOAD_IMAGE_DISPLAY.CURRENT);
        break;
      case UPLOAD_IMAGE_DISPLAY.EDIT:
        setNewImage(null);
        setScreen(UPLOAD_IMAGE_DISPLAY.PICK);
        break;

      default:
        break;
    }
    setScreen(UPLOAD_IMAGE_DISPLAY.CURRENT);
  };

  const renderView = screen => {
    switch (screen) {
      case UPLOAD_IMAGE_DISPLAY.CURRENT:
        return (
          <CurrentImageActions
            initialImage={currentLogo}
            onCreate={() => setScreen(UPLOAD_IMAGE_DISPLAY.PICK)}
            onDelete={handleOnDeleteImage}
            isLoadingDeleteImg={isLoadingDeleteLogo}
          />
        );
      case UPLOAD_IMAGE_DISPLAY.PICK:
        return (
          <LoadImageDialog handleAddClick={handleAddClick} setNewImage={setNewImage} fileInputRef={fileInputRef} />
        );

      case UPLOAD_IMAGE_DISPLAY.EDIT:
        return (
          <EditImageComponent
            isLoading={isLoadingCreateLogo}
            ref={editImageComponentRef}
            onComplete={handleUploadImage}
            url={newImage}
          />
        );

      default:
        break;
    }
  };

  const renderModalTitle = screen => {
    let stringId;
    switch (screen) {
      case UPLOAD_IMAGE_DISPLAY.EDIT:
        stringId = 'companyLogoModal_title_edit';
        break;
      default:
        stringId = 'companyLogoModal_title';
        break;
    }

    return <Localization.Translate stringId={stringId} />;
  };

  return (
    <DialogContainer open={open} maxWidth="md" fullWidth onClose={handleOnClose}>
      <DialogTitle onClose={handleOnClose}>
        <CustomIcon size="medium" iconName="cropOriginalIcon" />
        {renderModalTitle(screen)}
      </DialogTitle>
      <DialogContent>{renderView(screen)}</DialogContent>
      <DialogActions
        lefSide={
          <Button disabled={isLoadingCreateLogo || isLoadingDeleteLogo || isLoadingUpdateLogo} onClick={handleOnBack}>
            <Localization.Translate stringId="general_dialogAction_back" />
          </Button>
        }
        rightSide={
          screen === UPLOAD_IMAGE_DISPLAY.EDIT && (
            <Button
              variant="contained"
              disabled={isLoadingCreateLogo || isLoadingUpdateLogo}
              onClick={() => editImageComponentRef?.current?.showCroppedImage()}
            >
              <Localization.Translate stringId="save" />
            </Button>
          )
        }
      />
    </DialogContainer>
  );
};

export default UploadLogoImageDialog;
