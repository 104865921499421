import React from 'react';
import { Field, FieldArray } from 'formik';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import { Localization } from 'connex-cds';
import { ExpandMore, AddCircle, Delete } from '@mui/icons-material';
import { array, number, shape, string } from 'prop-types';

import { TextInputWithFormikField } from '../../../../commons';
import {
  LocationLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ListHeader,
  SectionIndex,
} from '../inventory-product-locations/styles';
import { ContainersSection, ContainersSummary, AccordionSummaryIconWrapper } from './styles';

const InventoryProductLocationInventoryItem = ({ inventoryLocation, inventoryLocationIndex }) => {
  return (
    <Accordion key={inventoryLocation.value} disableGutters>
      <AccordionSummary
        expandIcon={
          <AccordionSummaryIconWrapper>
            <ExpandMore />
          </AccordionSummaryIconWrapper>
        }
      >
        <LocationLabel>{inventoryLocation.label}</LocationLabel>
        <ContainersSummary>
          {inventoryLocation.inventoryContainers &&
            inventoryLocation.inventoryContainers.length > 0 &&
            inventoryLocation.inventoryContainers
              .filter(x => x)
              .map((containerName, containerIndex) => <Chip label={containerName} key={containerIndex} />)}
        </ContainersSummary>
      </AccordionSummary>
      <FieldArray
        name={`inventoryLocations.${inventoryLocationIndex}.inventoryContainers`}
        render={({ push, remove }) => (
          <AccordionDetails>
            <ListHeader>
              <span>
                <Localization.Translate stringId="inventoryProducts_inventory_inventoryContainers" />
                {inventoryLocation.inventoryContainers && inventoryLocation.inventoryContainers.length > 0 && (
                  <span>&nbsp;({inventoryLocation.inventoryContainers.length})</span>
                )}
              </span>
              <IconButton onClick={() => push('')}>
                <AddCircle />
              </IconButton>
            </ListHeader>
            {inventoryLocation.inventoryContainers &&
              inventoryLocation.inventoryContainers.length > 0 &&
              inventoryLocation.inventoryContainers.map((_, inventoryContainerIndex) => (
                <ContainersSection key={inventoryContainerIndex}>
                  <SectionIndex>{inventoryContainerIndex + 1}</SectionIndex>
                  <Field
                    labelStringId="name"
                    component={TextInputWithFormikField}
                    variant="outlined"
                    name={`inventoryLocations.${inventoryLocationIndex}.inventoryContainers.${inventoryContainerIndex}`}
                    required
                    onClick={e => {
                      e.stopPropagation();
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                  <IconButton onClick={() => remove(inventoryContainerIndex)}>
                    <Delete />
                  </IconButton>
                </ContainersSection>
              ))}
          </AccordionDetails>
        )}
      />
    </Accordion>
  );
};

InventoryProductLocationInventoryItem.propTypes = {
  inventoryLocation: shape({
    label: string,
    value: string,
    inventoryContainers: array,
  }).isRequired,
  inventoryLocationIndex: number.isRequired,
};

export default InventoryProductLocationInventoryItem;
