import { useTableLogic } from '../../hooks';
import { EntityTable, SearchFilter, TableLayout } from '../../commons';
import { getRolesColumns } from './tableConfig';
import { useRoles } from '../../api/hooks';

const UserRoles = () => {
  const filteredPropsArr = ['name', 'apps'];
  const { setQuery, isLoading, filteredData, entityColumns, handleOnRowClick } = useTableLogic(
    useRoles,
    getRolesColumns,
    filteredPropsArr
  );

  return (
    <TableLayout.ListLayoutContainer>
      <TableLayout.TableControls>
        <SearchFilter onSearchChange={setQuery} disabled={isLoading} resultsCount={filteredData.length} />
      </TableLayout.TableControls>
      <TableLayout.TableBody>
        <EntityTable
          checkboxSelection={false}
          getRowId={row => row.crn}
          rows={filteredData}
          columns={entityColumns}
          onRowClick={handleOnRowClick}
          isLoading={isLoading}
        />
      </TableLayout.TableBody>
    </TableLayout.ListLayoutContainer>
  );
};

export default UserRoles;
