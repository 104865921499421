import { Grid } from '@mui/material';
import { Field, Form } from 'formik';

import { SelectWithFormikField, TextInputWithFormikField } from '../../../../commons';
import { ShippingMethodsProfileTabContainer } from './styles';
import { DIALOG_TYPES } from '../../../../constants';

const ShippingMethodsProfileTab = ({ mode, statusOptions }) => {
  return (
    <ShippingMethodsProfileTabContainer>
      <Form>
        <Grid container alignItems="flex-start">
          <Grid xs={6} item container gap={5}>
            <Grid item xs={12}>
              <Field
                fullWidth
                labelStringId="shippingMethods_profileTab_id_label"
                component={TextInputWithFormikField}
                variant="outlined"
                name="id"
                tooltipInfoStringId="shippingMethods_profileTab_id_label_tooltip"
                disabled={mode === DIALOG_TYPES.EDIT}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                fullWidth
                labelStringId="shippingMethods_profileTab_name_label"
                component={TextInputWithFormikField}
                variant="outlined"
                name="name"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                fullWidth
                labelStringId="shippingMethods_profileTab_status_label"
                component={SelectWithFormikField}
                name="status"
                options={statusOptions}
                infoTooltipStringId="shippingMethods_profileTab_status_label_tooltip"
                required
              />
            </Grid>
          </Grid>
        </Grid>
      </Form>
    </ShippingMethodsProfileTabContainer>
  );
};

export default ShippingMethodsProfileTab;
