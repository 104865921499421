import { Localization } from 'connex-cds';
import { Field, Form } from 'formik';
import { array, string, bool } from 'prop-types';
import Grid from '@mui/material/Grid';

import { TextInputWithFormikField, SelectWithFormikField } from '../../../../commons';
import { DIALOG_TYPES } from '../../../../constants';
import { MixComponentProfileContainer } from './styles';

const MixComponentProfileTab = ({
  mixComponentStatusOptions,
  mode,
  isLoadingUom,
  uomOptions,
  productOptions,
  isLoadingProduct,
}) => {
  return (
    <MixComponentProfileContainer>
      <h3 className="mix-component-profile-title">
        <Localization.Translate stringId="mixComponents_profileTab_title" />
      </h3>

      <Form className="mix-component-profile-form">
        <Grid container spacing={5}>
          <Grid item md={6} xs={12} order={{ xs: 1, md: 1 }}>
            <Field
              fullWidth
              labelStringId="mixComponents_profileTab_mixComponentId"
              component={TextInputWithFormikField}
              variant="outlined"
              name="mixComponentId"
              tooltipInfoStringId="mixComponents_profileTab_mixComponentId_tooltip"
              required
              disabled={mode === DIALOG_TYPES.EDIT}
            />
          </Grid>
          <Grid item md={6} xs={12} order={{ xs: 3, md: 2 }}>
            <Field
              fullWidth
              testId="select-batch-uom"
              labelStringId="mixComponents_profileTab_mixComponentUOM"
              component={SelectWithFormikField}
              name="mixComponentBatchUOM"
              disabled={isLoadingUom}
              options={uomOptions}
              required
            />
          </Grid>
          <Grid item md={6} xs={12} order={{ xs: 2, md: 3 }}>
            <Field
              fullWidth
              labelStringId="mixComponents_profileTab_mixComponentName"
              component={TextInputWithFormikField}
              variant="outlined"
              name="mixComponentName"
              required
            />
          </Grid>
          <Grid item md={6} xs={12} order={{ xs: 5, md: 4 }}>
            <Field
              fullWidth
              testId="select-product"
              labelStringId="general_tableHeader_product"
              component={SelectWithFormikField}
              name="mixComponentProduct"
              disabled={isLoadingProduct}
              clearable={true}
              options={productOptions}
            />
          </Grid>
          <Grid item md={6} xs={12} order={{ xs: 4, md: 6 }}>
            <Field
              fullWidth
              labelStringId="mixComponents_profileTab_mixComponentStatus"
              component={SelectWithFormikField}
              name="mixComponentStatus"
              options={mixComponentStatusOptions}
              infoTooltipStringId="mixComponents_profileTab_mixComponentStatus_tooltip"
              testId="select-mix-componenet-status"
              required
            />
          </Grid>
          <Grid item md={6} xs={12} order={{ xs: 6, md: 5 }}>
            <Field
              fullWidth
              testId="select-inventory-uom"
              labelStringId="mixComponents_profileTab_mixComponentInvUOM"
              component={SelectWithFormikField}
              name="mixComponentInventoryUOM"
              disabled={isLoadingUom}
              options={uomOptions}
              required
            />
          </Grid>
        </Grid>
      </Form>
    </MixComponentProfileContainer>
  );
};

MixComponentProfileTab.propTypes = {
  mixComponentStatusOptions: array.isRequired,
  mode: string.isRequired,
  isLoadingUom: bool.isRequired,
  uomOptions: array.isRequired,
  productOptions: array,
  isLoadingProduct: bool,
};

export default MixComponentProfileTab;
