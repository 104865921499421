import { useState } from 'react';
import { func, shape, string } from 'prop-types';
import { Localization, useNotification } from 'connex-cds';

import { ConfirmationDialog } from '../../../../commons';
import { usePriceBooks } from '../../../../api/hooks';
import { DELETED_STATUS } from '../../../../constants/common';
import { trimWithDots } from '../../../../util/strings';

const DeletePriceBookDialog = ({ onClose, priceBookData }) => {
  const [isOpen, setIsOpen] = useState(true);
  const notification = useNotification();
  const translateMessage = Localization.useTranslateMessage();
  const { Update: useUpdatePriceBook } = usePriceBooks();
  const { mutateAsync: updatePriceBook } = useUpdatePriceBook();

  const handleDelete = async () => {
    try {
      if (priceBookData?.crn) {
        await updatePriceBook({ priceBookRef: priceBookData.crn, priceBook: { status: DELETED_STATUS } });
        notification.success(translateMessage('priceBooks_notification_priceBookDeleted'));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleOnClose = (isDeleteSuccess = false) => {
    setIsOpen(false);
    onClose(isDeleteSuccess);
  };

  return (
    <ConfirmationDialog
      open={isOpen}
      onConfirm={handleDelete}
      onClose={handleOnClose}
      confirmText="delete"
      color="delete"
    >
      <h3 className="delete-title">
        <Localization.Translate stringId="priceBooks_deleteDialog_title" />
      </h3>
      <p className="delete-description">
        <Localization.Translate
          stringId="priceBooks_deleteDialog_description"
          values={{
            strong: chunks => <strong>{trimWithDots(chunks, 100)}</strong>,
            name: priceBookData?.name,
          }}
        />
      </p>
    </ConfirmationDialog>
  );
};

DeletePriceBookDialog.propTypes = {
  onClose: func.isRequired,
  priceBookData: shape({
    crn: string.isRequired,
    name: string.isRequired,
  }),
};

export default DeletePriceBookDialog;
