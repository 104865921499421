import React from 'react';
import { Field } from 'formik';
import IconButton from '@mui/material/IconButton';
import { Delete } from '@mui/icons-material';
import { array, bool, func, number, object } from 'prop-types';
import { Localization } from 'connex-cds';

import {
  SelectWithFormikField,
  AutocompleteWithFormikField,
  TextInputWithFormikField,
  CheckboxWithFormikField,
} from '../../../../commons';
import { usePartners } from '../../../../api/hooks';
import { parseProductOptions, parseProjectOptions } from '../../../../api/adapters';
import { SectionIndex } from '../inventory-product-locations/styles';
import {
  MappingSection,
  MappingSectionControls,
  MappingData,
  MappingDataSection,
  MappingDataSectionLabel,
} from './styles';

const ProductMappingItem = ({
  inventoryLocationIndex,
  productMappingIndex,
  productMappingItem,
  partnerOptions,
  isLoadingPartners,
  setFieldValue,
  removeItem,
}) => {
  const partnerRef = productMappingItem?.partner;

  const { ListPartnerProjects: useProjectsList, ListPartnerProjectProducts: useProductsList } = usePartners();
  const { data: projectOptions, isLoading: isLoadingProjects } = useProjectsList({
    partnerRef,
    adapter: parseProjectOptions,
  });
  const { data: productOptions, isLoading: isLoadingProducts } = useProductsList({
    partnerRef,
    projectRefs: productMappingItem.projects?.map(project => project.value),
    adapter: parseProductOptions,
  });

  const productMappingItemControlName = `inventoryLocations.${inventoryLocationIndex}.productMappingList.${productMappingIndex}`;
  const projectsControlName = `${productMappingItemControlName}.projects`;
  const productsControlName = `${productMappingItemControlName}.products`;
  const purchaseOrdersControlName = `${productMappingItemControlName}.purchaseOrders`;

  const handlePartnerChange = (setFieldValue, prevValue) => (name, value) => {
    setFieldValue(name, value);
    if (prevValue !== value) {
      setFieldValue(projectsControlName, []);
      setFieldValue(productsControlName, []);
      setFieldValue(purchaseOrdersControlName, '');
    }
  };

  const handleProjectsChange = (setFieldValue, prevValue) => (name, value) => {
    setFieldValue(name, value);
    if (prevValue !== value) {
      setFieldValue(productsControlName, []);
    }
  };

  return (
    <MappingSection>
      <MappingSectionControls>
        <SectionIndex>{productMappingIndex + 1}</SectionIndex>
        <IconButton onClick={removeItem}>
          <Delete />
        </IconButton>
      </MappingSectionControls>
      <MappingData>
        <MappingDataSection>
          <Field
            testId="select-productmapping-partner"
            labelStringId="general_partner"
            component={SelectWithFormikField}
            name={`${productMappingItemControlName}.partner`}
            disabled={isLoadingPartners}
            required
            options={partnerOptions}
            customOnChange={handlePartnerChange(setFieldValue, productMappingItem?.partner)}
          />
          <Field
            testId="autocomplete-productmapping-projects"
            labelStringId="general_projects"
            component={AutocompleteWithFormikField}
            name={projectsControlName}
            disabled={!productMappingItem.partner || isLoadingProjects}
            options={projectOptions}
            isOptionEqualToValue={(option, value) => option?.value === value?.value}
            customOnChange={handleProjectsChange(setFieldValue, productMappingItem?.projects)}
          />
          <Field
            testId="autocomplete-productmapping-products"
            labelStringId="products"
            component={AutocompleteWithFormikField}
            multiple
            name={productsControlName}
            disabled={!productMappingItem.partner || !productMappingItem.projects?.length || isLoadingProducts}
            options={productOptions}
            isOptionEqualToValue={(option, value) => option?.value === value?.value}
          />
          <Field
            labelStringId="inventoryProducts_supply_purchaseOrders"
            tooltipInfoStringId="inventoryProducts_supply_purchaseOrders_tooltip"
            component={TextInputWithFormikField}
            variant="outlined"
            name={purchaseOrdersControlName}
            disabled={!productMappingItem.partner}
          />
        </MappingDataSection>
        <MappingDataSection>
          <MappingDataSectionLabel>
            <Localization.Translate stringId="inventoryProducts_supply_overrides" />
          </MappingDataSectionLabel>
          <Field
            labelStringId="inventoryProducts_supply_overrideSupplierId"
            component={TextInputWithFormikField}
            variant="outlined"
            name={`${productMappingItemControlName}.overrideSupplierId`}
          />
          <Field
            labelStringId="inventoryProducts_supply_overrideCarrierId"
            component={TextInputWithFormikField}
            variant="outlined"
            name={`${productMappingItemControlName}.overrideCarrierId`}
          />
          <Field
            labelStringId="inventoryProducts_supply_overrideVehicleId"
            component={TextInputWithFormikField}
            variant="outlined"
            name={`${productMappingItemControlName}.overrideVehicleId`}
          />
          <Field
            labelStringId="inventoryProducts_supply_overridePurchaseOrder"
            component={TextInputWithFormikField}
            variant="outlined"
            name={`${productMappingItemControlName}.overridePurchaseOrder`}
          />
          <Field
            labelStringId="inventoryProducts_supply_useVendorAsPurchaseOrder"
            component={CheckboxWithFormikField}
            name={`${productMappingItemControlName}.useVendorAsPurchaseOrder`}
          />
        </MappingDataSection>
      </MappingData>
    </MappingSection>
  );
};

ProductMappingItem.propTypes = {
  inventoryLocationIndex: number.isRequired,
  productMappingIndex: number.isRequired,
  productMappingItem: object.isRequired,
  partnerOptions: array.isRequired,
  isLoadingPartners: bool,
  setFieldValue: func.isRequired,
  removeItem: func.isRequired,
};

export default ProductMappingItem;
