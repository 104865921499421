import { useMemo, useState } from 'react';
import { Localization } from 'connex-cds';
import { array, bool, func, object } from 'prop-types';
import Box from '@mui/material/Box';
import { Form } from 'formik';

import { NUMBER_MAX_TWO_DECIMALS_REGEX } from '../../../../util/forms';
import { DialogTable, SearchFilter } from '../../../../commons';
import { getFilteredDataByProperties } from '../../../../commons/search-filter';
import { getProductListColumns } from './tableConfig';

import { PriceBookPricesContainer } from './styles';

const PriceBookPricesTab = ({ productList, isProductListLoading, priceBookData, setFieldValue }) => {
  const [searchQuery, setSearchQuery] = useState('');

  const translateMessage = Localization.useTranslateMessage();

  const activeProducts = useMemo(
    () => getFilteredDataByProperties({ data: productList, query: searchQuery, properties: ['name', 'type'] }),
    [productList, searchQuery]
  );

  const handlePriceChange = setFieldValue => (name, value) => {
    if (!value || NUMBER_MAX_TWO_DECIMALS_REGEX.test(value)) {
      setFieldValue(name, value);
    }
  };

  const columns = useMemo(
    () => getProductListColumns({ translateMessage, handlePriceChange, setFieldValue }),
    [setFieldValue, translateMessage]
  );

  const productWithPrices = activeProducts.map(product => {
    const matchingPrice = priceBookData?.prices?.find(productPrice => productPrice.productRef === product.crn);
    return matchingPrice ? { ...product, price: matchingPrice.price?.value } : product;
  });

  return (
    <PriceBookPricesContainer>
      <h3 className="price-book-profile-title">
        <Localization.Translate stringId="priceBooks_pricesTab_title" />
      </h3>
      <p className="pricebook-product-description">
        <Localization.Translate stringId="priceBooks_pricesTab_description" />
      </p>
      <Box mt={3} className="pricebook-product-list-search-filter">
        <SearchFilter
          onSearchChange={setSearchQuery}
          disabled={isProductListLoading}
          resultsCount={productWithPrices.length}
        />
      </Box>
      <Form className="price-book-prices-form">
        <Box mt={3}>
          <DialogTable
            checkboxSelection={false}
            isLoading={isProductListLoading}
            columns={columns}
            getRowId={row => row.crn}
            rows={productWithPrices}
            rowHeight={68}
          />
        </Box>
      </Form>
    </PriceBookPricesContainer>
  );
};

PriceBookPricesTab.propTypes = {
  productList: array,
  isProductListLoading: bool,
  priceBookData: object,
  setFieldValue: func,
};

export default PriceBookPricesTab;
