import { useState, useMemo } from 'react';
import { Localization, useNotification } from 'connex-cds';
import { func, object } from 'prop-types';

import { DIALOG_TYPES, STATUS_OPTIONS, BOOLEAN_VALUES } from '../../../../constants';
import { useOrderStatuses } from '../../../../api/hooks';
import OrderStatusFormView from '../order-status-form-view';

const EditOrderStatusDialog = ({ onClose, orderStatusData, handleOnDelete }) => {
  const notification = useNotification();
  const translateMessage = Localization.useTranslateMessage();
  const [isOpen, setIsOpen] = useState(true);
  const { Update: useUpdateOrderStatus } = useOrderStatuses();
  const { mutateAsync: updateOrderStatus } = useUpdateOrderStatus();

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  const handleOnSubmit = async (values, formikBag) => {
    formikBag.setSubmitting(true);

    const { name, status, defaultValue, enableTicketing, displayInPlanning } = values;

    const orderStatus = {
      name,
      status,
      defaultValue,
      enableTicketing,
      displayInPlanningGraphs: displayInPlanning ? BOOLEAN_VALUES.Yes : BOOLEAN_VALUES.No,
    };

    try {
      await updateOrderStatus({ data: orderStatus, orderStatusRef: orderStatusData?.crn });
      notification.success(translateMessage('notification_changesSaved'));
      formikBag.setSubmitting(false);
      formikBag.resetForm();
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  const getInitialValues = useMemo(() => {
    if (!orderStatusData) return null;
    const { id, name, defaultValue, enableTicketing, displayInPlanningGraphs } = orderStatusData;

    const editValues = {
      id,
      name,
      status: STATUS_OPTIONS.find(status => status.value === orderStatusData.status).value,
      defaultValue,
      enableTicketing,
      displayInPlanning: displayInPlanningGraphs === BOOLEAN_VALUES.Yes,
    };

    return editValues;
  }, [orderStatusData]);

  return (
    <OrderStatusFormView
      open={isOpen}
      onClose={handleClose}
      mode={DIALOG_TYPES.EDIT}
      initialValues={getInitialValues}
      onSubmit={handleOnSubmit}
      statusOptions={STATUS_OPTIONS}
      handleOnDelete={handleOnDelete}
    />
  );
};

EditOrderStatusDialog.propTypes = {
  orderStatusData: object,
  onClose: func.isRequired,
  handleOnDelete: func,
};

EditOrderStatusDialog.defaultProps = {
  onClose: () => {},
};

export default EditOrderStatusDialog;
