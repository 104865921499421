export const PHONE_REGEX = /^\d{9,14}$/;

export const sanitizePhoneNumber = value => value.replace(/\D/g, '');

export const maskPhoneNumber = value => {
  const [areaCode, ...phoneNumber] = value.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/).slice(1);
  const phoneNumberGroups = phoneNumber.filter(Boolean);
  if (!phoneNumberGroups.length) return areaCode;
  return `(${areaCode}) ${phoneNumberGroups.join('-')}`;
};

// Allow only numeric values and up to two decimal places
export const NUMBER_MAX_TWO_DECIMALS_REGEX = /^(?:\d+(?:\.\d{0,2})?|\.\d{1,2})?$/;

export const ONLY_INTEGER_NUMBERS_REGEX = /^\d*$/;

export const mapperYesNo = value => (value ? 'yes' : 'no');

export const yesNoToBoolean = value => value === 'yes';
