export const CURRENCY_CODE = {
  USD: 'USD',
};

export const CURRENCY_SYMBOL = {
  USD: '$',
};

export const DIALOG_TYPES = Object.freeze({
  DELETE: 'delete',
  EDIT: 'edit',
  ADD: 'add',
  NONE: null,
  UPLOAD_PHOTO: 'upload_photo',
});

export const STATUS_OPTIONS = [
  { value: 'ACTIVE', translationKey: 'active' },
  { value: 'INACTIVE', translationKey: 'inactive' },
];

export const DELETED_STATUS = 'DELETED';

export const BOOLEAN_VALUES = {
  Yes: 'YES',
  No: 'NO',
};

export const BOOLEAN_OPTIONS = [
  { value: BOOLEAN_VALUES.Yes, translationKey: 'yes' },
  { value: BOOLEAN_VALUES.No, translationKey: 'no' },
];

export const PRODUCT_TYPE_OPTIONS = [
  { value: 'CONCRETE', translationKey: 'general_productType_concrete' },
  { value: 'AGGREGATE', translationKey: 'general_productType_aggregate' },
  { value: 'ASPHALT', translationKey: 'general_productType_asphalt' },
];

export const ORDER_RESTRICTIONS_OPTIONS = [
  { value: 'no', translationKey: 'general_no_pascalCase' },
  { value: 'all', translationKey: 'all' },
  { value: 'primary', translationKey: 'general_primary' },
];

export const CONCRETE_CLASS_TYPE_AIR = 'air';
export const CONCRETE_CLASS_TYPE_CONSISTENCE = 'consistence';
export const CONCRETE_CLASS_TYPE_SLUMP_LOSS_RATE = 'slumpLossRate';
export const CONCRETE_CLASS_TYPE_TEMPERATURE = 'temperature';

export const COUNTRIES = [
  { value: 'AF', label: 'Afghanistan' },
  { value: 'AL', label: 'Albania' },
  { value: 'DZ', label: 'Algeria' },
  { value: 'AS', label: 'American Samoa' },
  { value: 'AD', label: 'Andorra' },
  { value: 'AQ', label: 'Antarctica' },
  { value: 'AR', label: 'Argentina' },
  { value: 'AM', label: 'Armenia' },
  { value: 'AU', label: 'Australia' },
  { value: 'AT', label: 'Austria' },
  { value: 'AZ', label: 'Azerbaijan' },
  { value: 'BS', label: 'Bahamas' },
  { value: 'BD', label: 'Bangladesh' },
  { value: 'BB', label: 'Barbados' },
  { value: 'BY', label: 'Belarus' },
  { value: 'BE', label: 'Belgium' },
  { value: 'BZ', label: 'Belize' },
  { value: 'BM', label: 'Bermuda' },
  { value: 'BT', label: 'Bhutan' },
  { value: 'BO', label: 'Bolivia (Plurinational State of)' },
  { value: 'BR', label: 'Brazil' },
  { value: 'IO', label: 'British Indian Ocean Territory' },
  { value: 'BN', label: 'Brunei Darussalam' },
  { value: 'BG', label: 'Bulgaria' },
  { value: 'CV', label: 'Cabo Verde' },
  { value: 'CA', label: 'Canada' },
  { value: 'TD', label: 'Chad' },
  { value: 'CL', label: 'Chile' },
  { value: 'CN', label: 'China' },
  { value: 'CX', label: 'Christmas Island' },
  { value: 'CC', label: 'Cocos (Keeling) Islands' },
  { value: 'CO', label: 'Colombia' },
  { value: 'CK', label: 'Cook Islands' },
  { value: 'CR', label: 'Costa Rica' },
  { value: 'CU', label: 'Cuba' },
  { value: 'CW', label: 'Curaçao' },
  { value: 'CY', label: 'Cyprus' },
  { value: 'CZ', label: 'Czechia' },
  { value: 'CI', label: "Côte d'Ivoire" },
  { value: 'DK', label: 'Denmark' },
  { value: 'DO', label: 'Dominican Republic' },
  { value: 'EC', label: 'Ecuador' },
  { value: 'EG', label: 'Egypt' },
  { value: 'SV', label: 'El Salvador' },
  { value: 'EE', label: 'Estonia' },
  { value: 'FK', label: 'Falkland Islands (Malvinas)' },
  { value: 'FO', label: 'Faroe Islands' },
  { value: 'FJ', label: 'Fiji' },
  { value: 'FI', label: 'Finland' },
  { value: 'FR', label: 'France' },
  { value: 'GF', label: 'French Guiana' },
  { value: 'PF', label: 'French Polynesia' },
  { value: 'TF', label: 'French Southern Territories' },
  { value: 'GE', label: 'Georgia' },
  { value: 'DE', label: 'Germany' },
  { value: 'GH', label: 'Ghana' },
  { value: 'GI', label: 'Gibraltar' },
  { value: 'GR', label: 'Greece' },
  { value: 'GL', label: 'Greenland' },
  { value: 'GU', label: 'Guam' },
  { value: 'GT', label: 'Guatemala' },
  { value: 'GW', label: 'Guinea-Bissau' },
  { value: 'GY', label: 'Guyana' },
  { value: 'HT', label: 'Haiti' },
  { value: 'HN', label: 'Honduras' },
  { value: 'HK', label: 'Hong Kong' },
  { value: 'HU', label: 'Hungary' },
  { value: 'IS', label: 'Iceland' },
  { value: 'IN', label: 'India' },
  { value: 'ID', label: 'Indonesia' },
  { value: 'IR', label: 'Iran (Islamic Republic of)' },
  { value: 'IQ', label: 'Iraq' },
  { value: 'IE', label: 'Ireland' },
  { value: 'IL', label: 'Israel' },
  { value: 'IT', label: 'Italy' },
  { value: 'JM', label: 'Jamaica' },
  { value: 'JP', label: 'Japan' },
  { value: 'JO', label: 'Jordan' },
  { value: 'KZ', label: 'Kazakhstan' },
  { value: 'KE', label: 'Kenya' },
  { value: 'KI', label: 'Kiribati' },
  { value: 'KP', label: "Korea (Democratic People's Republic of)" },
  { value: 'KR', label: 'Korea, Republic of' },
  { value: 'KG', label: 'Kyrgyzstan' },
  { value: 'LV', label: 'Latvia' },
  { value: 'LB', label: 'Lebanon' },
  { value: 'LR', label: 'Liberia' },
  { value: 'LY', label: 'Libya' },
  { value: 'LT', label: 'Lithuania' },
  { value: 'LU', label: 'Luxembourg' },
  { value: 'MO', label: 'Macao' },
  { value: 'MY', label: 'Malaysia' },
  { value: 'MV', label: 'Maldives' },
  { value: 'MT', label: 'Malta' },
  { value: 'MH', label: 'Marshall Islands' },
  { value: 'MQ', label: 'Martinique' },
  { value: 'MU', label: 'Mauritius' },
  { value: 'MX', label: 'Mexico' },
  { value: 'FM', label: 'Micronesia (Federated States of)' },
  { value: 'MD', label: 'Moldova, Republic of' },
  { value: 'MC', label: 'Monaco' },
  { value: 'MN', label: 'Mongolia' },
  { value: 'MA', label: 'Morocco' },
  { value: 'MZ', label: 'Mozambique' },
  { value: 'MM', label: 'Myanmar' },
  { value: 'NA', label: 'Namibia' },
  { value: 'NR', label: 'Nauru' },
  { value: 'NP', label: 'Nepal' },
  { value: 'NL', label: 'Netherlands' },
  { value: 'NC', label: 'New Caledonia' },
  { value: 'NZ', label: 'New Zealand' },
  { value: 'NI', label: 'Nicaragua' },
  { value: 'NG', label: 'Nigeria' },
  { value: 'NU', label: 'Niue' },
  { value: 'NF', label: 'Norfolk Island' },
  { value: 'NO', label: 'Norway' },
  { value: 'PK', label: 'Pakistan' },
  { value: 'PW', label: 'Palau' },
  { value: 'PS', label: 'Palestine, State of' },
  { value: 'PA', label: 'Panama' },
  { value: 'PG', label: 'Papua New Guinea' },
  { value: 'PY', label: 'Paraguay' },
  { value: 'PE', label: 'Peru' },
  { value: 'PH', label: 'Philippines' },
  { value: 'PN', label: 'Pitcairn' },
  { value: 'PL', label: 'Poland' },
  { value: 'PT', label: 'Portugal' },
  { value: 'PR', label: 'Puerto Rico' },
  { value: 'QA', label: 'Qatar' },
  { value: 'RO', label: 'Romania' },
  { value: 'RU', label: 'Russian Federation' },
  { value: 'RE', label: 'Réunion' },
  { value: 'PM', label: 'Saint Pierre and Miquelon' },
  { value: 'WS', label: 'Samoa' },
  { value: 'SA', label: 'Saudi Arabia' },
  { value: 'RS', label: 'Serbia' },
  { value: 'SC', label: 'Seychelles' },
  { value: 'SG', label: 'Singapore' },
  { value: 'SB', label: 'Solomon Islands' },
  { value: 'ZA', label: 'South Africa' },
  { value: 'GS', label: 'South Georgia and the South Sandwich Islands' },
  { value: 'SS', label: 'South Sudan' },
  { value: 'ES', label: 'Spain' },
  { value: 'LK', label: 'Sri Lanka' },
  { value: 'SD', label: 'Sudan' },
  { value: 'SR', label: 'Suriname' },
  { value: 'SE', label: 'Sweden' },
  { value: 'CH', label: 'Switzerland' },
  { value: 'SY', label: 'Syrian Arab Republic' },
  { value: 'TW', label: 'Taiwan, Province of China' },
  { value: 'TJ', label: 'Tajikistan' },
  { value: 'TH', label: 'Thailand' },
  { value: 'TL', label: 'Timor-Leste' },
  { value: 'TK', label: 'Tokelau' },
  { value: 'TO', label: 'Tonga' },
  { value: 'TT', label: 'Trinidad and Tobago' },
  { value: 'TN', label: 'Tunisia' },
  { value: 'TR', label: 'Turkey' },
  { value: 'TM', label: 'Turkmenistan' },
  { value: 'TC', label: 'Turks and Caicos Islands' },
  { value: 'TV', label: 'Tuvalu' },
  { value: 'UA', label: 'Ukraine' },
  { value: 'AE', label: 'United Arab Emirates' },
  { value: 'GB', label: 'United Kingdom of Great Britain and Northern Ireland' },
  { value: 'UM', label: 'United States Minor Outlying Islands' },
  { value: 'US', label: 'United States of America' },
  { value: 'UY', label: 'Uruguay' },
  { value: 'UZ', label: 'Uzbekistan' },
  { value: 'VU', label: 'Vanuatu' },
  { value: 'VE', label: 'Venezuela (Bolivarian Republic of)' },
  { value: 'VN', label: 'Viet Nam' },
  { value: 'WF', label: 'Wallis and Futuna' },
  { value: 'EH', label: 'Western Sahara' },
];

export const TIME_ZONES = [
  { countryCode: 'CI', label: 'Africa/Abidjan' },
  { countryCode: 'GH', label: 'Africa/Accra' },
  { countryCode: 'DZ', label: 'Africa/Algiers' },
  { countryCode: 'GW', label: 'Africa/Bissau' },
  { countryCode: 'EG', label: 'Africa/Cairo' },
  { countryCode: 'MA', label: 'Africa/Casablanca' },
  { countryCode: 'ES', label: 'Africa/Ceuta' },
  { countryCode: 'EH', label: 'Africa/El_Aaiun' },
  { countryCode: 'ZA', label: 'Africa/Johannesburg' },
  { countryCode: 'SS', label: 'Africa/Juba' },
  { countryCode: 'SD', label: 'Africa/Khartoum' },
  { countryCode: 'NG', label: 'Africa/Lagos' },
  { countryCode: 'MZ', label: 'Africa/Maputo' },
  { countryCode: 'LR', label: 'Africa/Monrovia' },
  { countryCode: 'KE', label: 'Africa/Nairobi' },
  { countryCode: 'TD', label: 'Africa/Ndjamena' },
  { countryCode: 'LY', label: 'Africa/Tripoli' },
  { countryCode: 'TN', label: 'Africa/Tunis' },
  { countryCode: 'NA', label: 'Africa/Windhoek' },
  { countryCode: 'US', label: 'America/Adak' },
  { countryCode: 'US', label: 'America/Anchorage' },
  { countryCode: 'BR', label: 'America/Araguaina' },
  { countryCode: 'AR', label: 'America/Argentina/Buenos_Aires' },
  { countryCode: 'AR', label: 'America/Argentina/Catamarca' },
  { countryCode: 'AR', label: 'America/Argentina/Cordoba' },
  { countryCode: 'AR', label: 'America/Argentina/Jujuy' },
  { countryCode: 'AR', label: 'America/Argentina/La_Rioja' },
  { countryCode: 'AR', label: 'America/Argentina/Mendoza' },
  { countryCode: 'AR', label: 'America/Argentina/Rio_Gallegos' },
  { countryCode: 'AR', label: 'America/Argentina/Salta' },
  { countryCode: 'AR', label: 'America/Argentina/San_Juan' },
  { countryCode: 'AR', label: 'America/Argentina/San_Luis' },
  { countryCode: 'AR', label: 'America/Argentina/Tucuman' },
  { countryCode: 'AR', label: 'America/Argentina/Ushuaia' },
  { countryCode: 'PY', label: 'America/Asuncion' },
  { countryCode: 'CA', label: 'America/Atikokan' },
  { countryCode: 'BR', label: 'America/Bahia' },
  { countryCode: 'MX', label: 'America/Bahia_Banderas' },
  { countryCode: 'BB', label: 'America/Barbados' },
  { countryCode: 'BR', label: 'America/Belem' },
  { countryCode: 'BZ', label: 'America/Belize' },
  { countryCode: 'CA', label: 'America/Blanc-Sablon' },
  { countryCode: 'BR', label: 'America/Boa_Vista' },
  { countryCode: 'CO', label: 'America/Bogota' },
  { countryCode: 'US', label: 'America/Boise' },
  { countryCode: 'CA', label: 'America/Cambridge_Bay' },
  { countryCode: 'BR', label: 'America/Campo_Grande' },
  { countryCode: 'MX', label: 'America/Cancun' },
  { countryCode: 'VE', label: 'America/Caracas' },
  { countryCode: 'GF', label: 'America/Cayenne' },
  { countryCode: 'US', label: 'America/Chicago' },
  { countryCode: 'MX', label: 'America/Chihuahua' },
  { countryCode: 'CR', label: 'America/Costa_Rica' },
  { countryCode: 'CA', label: 'America/Creston' },
  { countryCode: 'BR', label: 'America/Cuiaba' },
  { countryCode: 'CW', label: 'America/Curacao' },
  { countryCode: 'GL', label: 'America/Danmarkshavn' },
  { countryCode: 'CA', label: 'America/Dawson' },
  { countryCode: 'CA', label: 'America/Dawson_Creek' },
  { countryCode: 'US', label: 'America/Denver' },
  { countryCode: 'US', label: 'America/Detroit' },
  { countryCode: 'CA', label: 'America/Edmonton' },
  { countryCode: 'BR', label: 'America/Eirunepe' },
  { countryCode: 'SV', label: 'America/El_Salvador' },
  { countryCode: 'CA', label: 'America/Fort_Nelson' },
  { countryCode: 'BR', label: 'America/Fortaleza' },
  { countryCode: 'CA', label: 'America/Glace_Bay' },
  { countryCode: 'GL', label: 'America/Godthab' },
  { countryCode: 'CA', label: 'America/Goose_Bay' },
  { countryCode: 'TC', label: 'America/Grand_Turk' },
  { countryCode: 'GT', label: 'America/Guatemala' },
  { countryCode: 'EC', label: 'America/Guayaquil' },
  { countryCode: 'GY', label: 'America/Guyana' },
  { countryCode: 'CA', label: 'America/Halifax' },
  { countryCode: 'CU', label: 'America/Havana' },
  { countryCode: 'MX', label: 'America/Hermosillo' },
  { countryCode: 'US', label: 'America/Indiana/Indianapolis' },
  { countryCode: 'US', label: 'America/Indiana/Knox' },
  { countryCode: 'US', label: 'America/Indiana/Marengo' },
  { countryCode: 'US', label: 'America/Indiana/Petersburg' },
  { countryCode: 'US', label: 'America/Indiana/Tell_City' },
  { countryCode: 'US', label: 'America/Indiana/Vevay' },
  { countryCode: 'US', label: 'America/Indiana/Vincennes' },
  { countryCode: 'US', label: 'America/Indiana/Winamac' },
  { countryCode: 'CA', label: 'America/Inuvik' },
  { countryCode: 'CA', label: 'America/Iqaluit' },
  { countryCode: 'JM', label: 'America/Jamaica' },
  { countryCode: 'US', label: 'America/Juneau' },
  { countryCode: 'US', label: 'America/Kentucky/Louisville' },
  { countryCode: 'US', label: 'America/Kentucky/Monticello' },
  { countryCode: 'BO', label: 'America/La_Paz' },
  { countryCode: 'PE', label: 'America/Lima' },
  { countryCode: 'US', label: 'America/Los_Angeles' },
  { countryCode: 'BR', label: 'America/Maceio' },
  { countryCode: 'NI', label: 'America/Managua' },
  { countryCode: 'BR', label: 'America/Manaus' },
  { countryCode: 'MQ', label: 'America/Martinique' },
  { countryCode: 'MX', label: 'America/Matamoros' },
  { countryCode: 'MX', label: 'America/Mazatlan' },
  { countryCode: 'US', label: 'America/Menominee' },
  { countryCode: 'MX', label: 'America/Merida' },
  { countryCode: 'US', label: 'America/Metlakatla' },
  { countryCode: 'MX', label: 'America/Mexico_City' },
  { countryCode: 'PM', label: 'America/Miquelon' },
  { countryCode: 'CA', label: 'America/Moncton' },
  { countryCode: 'MX', label: 'America/Monterrey' },
  { countryCode: 'UY', label: 'America/Montevideo' },
  { countryCode: 'BS', label: 'America/Nassau' },
  { countryCode: 'US', label: 'America/New_York' },
  { countryCode: 'CA', label: 'America/Nipigon' },
  { countryCode: 'US', label: 'America/Nome' },
  { countryCode: 'BR', label: 'America/Noronha' },
  { countryCode: 'US', label: 'America/North_Dakota/Beulah' },
  { countryCode: 'US', label: 'America/North_Dakota/Center' },
  { countryCode: 'US', label: 'America/North_Dakota/New_Salem' },
  { countryCode: 'MX', label: 'America/Ojinaga' },
  { countryCode: 'PA', label: 'America/Panama' },
  { countryCode: 'CA', label: 'America/Pangnirtung' },
  { countryCode: 'SR', label: 'America/Paramaribo' },
  { countryCode: 'US', label: 'America/Phoenix' },
  { countryCode: 'TT', label: 'America/Port_of_Spain' },
  { countryCode: 'HT', label: 'America/Port-au-Prince' },
  { countryCode: 'BR', label: 'America/Porto_Velho' },
  { countryCode: 'PR', label: 'America/Puerto_Rico' },
  { countryCode: 'CL', label: 'America/Punta_Arenas' },
  { countryCode: 'CA', label: 'America/Rainy_River' },
  { countryCode: 'CA', label: 'America/Rankin_Inlet' },
  { countryCode: 'BR', label: 'America/Recife' },
  { countryCode: 'CA', label: 'America/Regina' },
  { countryCode: 'CA', label: 'America/Resolute' },
  { countryCode: 'BR', label: 'America/Rio_Branco' },
  { countryCode: 'BR', label: 'America/Santarem' },
  { countryCode: 'CL', label: 'America/Santiago' },
  { countryCode: 'DO', label: 'America/Santo_Domingo' },
  { countryCode: 'BR', label: 'America/Sao_Paulo' },
  { countryCode: 'GL', label: 'America/Scoresbysund' },
  { countryCode: 'US', label: 'America/Sitka' },
  { countryCode: 'CA', label: 'America/St_Johns' },
  { countryCode: 'CA', label: 'America/Swift_Current' },
  { countryCode: 'HN', label: 'America/Tegucigalpa' },
  { countryCode: 'GL', label: 'America/Thule' },
  { countryCode: 'CA', label: 'America/Thunder_Bay' },
  { countryCode: 'MX', label: 'America/Tijuana' },
  { countryCode: 'CA', label: 'America/Toronto' },
  { countryCode: 'CA', label: 'America/Vancouver' },
  { countryCode: 'CA', label: 'America/Whitehorse' },
  { countryCode: 'CA', label: 'America/Winnipeg' },
  { countryCode: 'US', label: 'America/Yakutat' },
  { countryCode: 'CA', label: 'America/Yellowknife' },
  { countryCode: 'AQ', label: 'Antarctica/Casey' },
  { countryCode: 'AQ', label: 'Antarctica/Davis' },
  { countryCode: 'AQ', label: 'Antarctica/DumontDUrville' },
  { countryCode: 'AU', label: 'Antarctica/Macquarie' },
  { countryCode: 'AQ', label: 'Antarctica/Mawson' },
  { countryCode: 'AQ', label: 'Antarctica/Palmer' },
  { countryCode: 'AQ', label: 'Antarctica/Rothera' },
  { countryCode: 'AQ', label: 'Antarctica/Syowa' },
  { countryCode: 'AQ', label: 'Antarctica/Troll' },
  { countryCode: 'AQ', label: 'Antarctica/Vostok' },
  { countryCode: 'KZ', label: 'Asia/Almaty' },
  { countryCode: 'JO', label: 'Asia/Amman' },
  { countryCode: 'RU', label: 'Asia/Anadyr' },
  { countryCode: 'KZ', label: 'Asia/Aqtau' },
  { countryCode: 'KZ', label: 'Asia/Aqtobe' },
  { countryCode: 'TM', label: 'Asia/Ashgabat' },
  { countryCode: 'KZ', label: 'Asia/Atyrau' },
  { countryCode: 'IQ', label: 'Asia/Baghdad' },
  { countryCode: 'AZ', label: 'Asia/Baku' },
  { countryCode: 'TH', label: 'Asia/Bangkok' },
  { countryCode: 'RU', label: 'Asia/Barnaul' },
  { countryCode: 'LB', label: 'Asia/Beirut' },
  { countryCode: 'KG', label: 'Asia/Bishkek' },
  { countryCode: 'BN', label: 'Asia/Brunei' },
  { countryCode: 'RU', label: 'Asia/Chita' },
  { countryCode: 'MN', label: 'Asia/Choibalsan' },
  { countryCode: 'LK', label: 'Asia/Colombo' },
  { countryCode: 'SY', label: 'Asia/Damascus' },
  { countryCode: 'BD', label: 'Asia/Dhaka' },
  { countryCode: 'TL', label: 'Asia/Dili' },
  { countryCode: 'AE', label: 'Asia/Dubai' },
  { countryCode: 'TJ', label: 'Asia/Dushanbe' },
  { countryCode: 'CY', label: 'Asia/Famagusta' },
  { countryCode: 'PS', label: 'Asia/Gaza' },
  { countryCode: 'PS', label: 'Asia/Hebron' },
  { countryCode: 'VN', label: 'Asia/Ho_Chi_Minh' },
  { countryCode: 'HK', label: 'Asia/Hong_Kong' },
  { countryCode: 'MN', label: 'Asia/Hovd' },
  { countryCode: 'RU', label: 'Asia/Irkutsk' },
  { countryCode: 'ID', label: 'Asia/Jakarta' },
  { countryCode: 'ID', label: 'Asia/Jayapura' },
  { countryCode: 'IL', label: 'Asia/Jerusalem' },
  { countryCode: 'AF', label: 'Asia/Kabul' },
  { countryCode: 'RU', label: 'Asia/Kamchatka' },
  { countryCode: 'PK', label: 'Asia/Karachi' },
  { countryCode: 'NP', label: 'Asia/Kathmandu' },
  { countryCode: 'RU', label: 'Asia/Khandyga' },
  { countryCode: 'IN', label: 'Asia/Kolkata' },
  { countryCode: 'RU', label: 'Asia/Krasnoyarsk' },
  { countryCode: 'MY', label: 'Asia/Kuala_Lumpur' },
  { countryCode: 'MY', label: 'Asia/Kuching' },
  { countryCode: 'MO', label: 'Asia/Macau' },
  { countryCode: 'RU', label: 'Asia/Magadan' },
  { countryCode: 'ID', label: 'Asia/Makassar' },
  { countryCode: 'PH', label: 'Asia/Manila' },
  { countryCode: 'RU', label: 'Asia/Novokuznetsk' },
  { countryCode: 'RU', label: 'Asia/Novosibirsk' },
  { countryCode: 'RU', label: 'Asia/Omsk' },
  { countryCode: 'KZ', label: 'Asia/Oral' },
  { countryCode: 'ID', label: 'Asia/Pontianak' },
  { countryCode: 'KP', label: 'Asia/Pyongyang' },
  { countryCode: 'QA', label: 'Asia/Qatar' },
  { countryCode: 'KZ', label: 'Asia/Qyzylorda' },
  { countryCode: 'SA', label: 'Asia/Riyadh' },
  { countryCode: 'RU', label: 'Asia/Sakhalin' },
  { countryCode: 'UZ', label: 'Asia/Samarkand' },
  { countryCode: 'KR', label: 'Asia/Seoul' },
  { countryCode: 'CN', label: 'Asia/Shanghai' },
  { countryCode: 'SG', label: 'Asia/Singapore' },
  { countryCode: 'RU', label: 'Asia/Srednekolymsk' },
  { countryCode: 'TW', label: 'Asia/Taipei' },
  { countryCode: 'UZ', label: 'Asia/Tashkent' },
  { countryCode: 'GE', label: 'Asia/Tbilisi' },
  { countryCode: 'IR', label: 'Asia/Tehran' },
  { countryCode: 'BT', label: 'Asia/Thimphu' },
  { countryCode: 'JP', label: 'Asia/Tokyo' },
  { countryCode: 'RU', label: 'Asia/Tomsk' },
  { countryCode: 'MN', label: 'Asia/Ulaanbaatar' },
  { countryCode: 'CN', label: 'Asia/Urumqi' },
  { countryCode: 'RU', label: 'Asia/Ust-Nera' },
  { countryCode: 'RU', label: 'Asia/Vladivostok' },
  { countryCode: 'RU', label: 'Asia/Yakutsk' },
  { countryCode: 'MM', label: 'Asia/Yangon' },
  { countryCode: 'RU', label: 'Asia/Yekaterinburg' },
  { countryCode: 'AM', label: 'Asia/Yerevan' },
  { countryCode: 'PT', label: 'Atlantic/Azores' },
  { countryCode: 'BM', label: 'Atlantic/Bermuda' },
  { countryCode: 'ES', label: 'Atlantic/Canary' },
  { countryCode: 'CV', label: 'Atlantic/Cape_Verde' },
  { countryCode: 'FO', label: 'Atlantic/Faroe' },
  { countryCode: 'PT', label: 'Atlantic/Madeira' },
  { countryCode: 'IS', label: 'Atlantic/Reykjavik' },
  { countryCode: 'GS', label: 'Atlantic/South_Georgia' },
  { countryCode: 'FK', label: 'Atlantic/Stanley' },
  { countryCode: 'AU', label: 'Australia/Adelaide' },
  { countryCode: 'AU', label: 'Australia/Brisbane' },
  { countryCode: 'AU', label: 'Australia/Broken_Hill' },
  { countryCode: 'AU', label: 'Australia/Currie' },
  { countryCode: 'AU', label: 'Australia/Darwin' },
  { countryCode: 'AU', label: 'Australia/Eucla' },
  { countryCode: 'AU', label: 'Australia/Hobart' },
  { countryCode: 'AU', label: 'Australia/Lindeman' },
  { countryCode: 'AU', label: 'Australia/Lord_Howe' },
  { countryCode: 'AU', label: 'Australia/Melbourne' },
  { countryCode: 'AU', label: 'Australia/Perth' },
  { countryCode: 'AU', label: 'Australia/Sydney' },
  { countryCode: 'NL', label: 'Europe/Amsterdam' },
  { countryCode: 'AD', label: 'Europe/Andorra' },
  { countryCode: 'RU', label: 'Europe/Astrakhan' },
  { countryCode: 'GR', label: 'Europe/Athens' },
  { countryCode: 'RS', label: 'Europe/Belgrade' },
  { countryCode: 'DE', label: 'Europe/Berlin' },
  { countryCode: 'BE', label: 'Europe/Brussels' },
  { countryCode: 'RO', label: 'Europe/Bucharest' },
  { countryCode: 'HU', label: 'Europe/Budapest' },
  { countryCode: 'MD', label: 'Europe/Chisinau' },
  { countryCode: 'DK', label: 'Europe/Copenhagen' },
  { countryCode: 'IE', label: 'Europe/Dublin' },
  { countryCode: 'GI', label: 'Europe/Gibraltar' },
  { countryCode: 'FI', label: 'Europe/Helsinki' },
  { countryCode: 'TR', label: 'Europe/Istanbul' },
  { countryCode: 'RU', label: 'Europe/Kaliningrad' },
  { countryCode: 'UA', label: 'Europe/Kiev' },
  { countryCode: 'RU', label: 'Europe/Kirov' },
  { countryCode: 'PT', label: 'Europe/Lisbon' },
  { countryCode: 'GB', label: 'Europe/London' },
  { countryCode: 'LU', label: 'Europe/Luxembourg' },
  { countryCode: 'ES', label: 'Europe/Madrid' },
  { countryCode: 'MT', label: 'Europe/Malta' },
  { countryCode: 'BY', label: 'Europe/Minsk' },
  { countryCode: 'MC', label: 'Europe/Monaco' },
  { countryCode: 'RU', label: 'Europe/Moscow' },
  { countryCode: 'CY', label: 'Asia/Nicosia' },
  { countryCode: 'NO', label: 'Europe/Oslo' },
  { countryCode: 'FR', label: 'Europe/Paris' },
  { countryCode: 'CZ', label: 'Europe/Prague' },
  { countryCode: 'LV', label: 'Europe/Riga' },
  { countryCode: 'IT', label: 'Europe/Rome' },
  { countryCode: 'RU', label: 'Europe/Samara' },
  { countryCode: 'RU', label: 'Europe/Saratov' },
  { countryCode: 'UA', label: 'Europe/Simferopol' },
  { countryCode: 'BG', label: 'Europe/Sofia' },
  { countryCode: 'SE', label: 'Europe/Stockholm' },
  { countryCode: 'EE', label: 'Europe/Tallinn' },
  { countryCode: 'AL', label: 'Europe/Tirane' },
  { countryCode: 'RU', label: 'Europe/Ulyanovsk' },
  { countryCode: 'UA', label: 'Europe/Uzhgorod' },
  { countryCode: 'AT', label: 'Europe/Vienna' },
  { countryCode: 'LT', label: 'Europe/Vilnius' },
  { countryCode: 'RU', label: 'Europe/Volgograd' },
  { countryCode: 'PL', label: 'Europe/Warsaw' },
  { countryCode: 'UA', label: 'Europe/Zaporozhye' },
  { countryCode: 'CH', label: 'Europe/Zurich' },
  { countryCode: 'IO', label: 'Indian/Chagos' },
  { countryCode: 'CX', label: 'Indian/Christmas' },
  { countryCode: 'CC', label: 'Indian/Cocos' },
  { countryCode: 'TF', label: 'Indian/Kerguelen' },
  { countryCode: 'SC', label: 'Indian/Mahe' },
  { countryCode: 'MV', label: 'Indian/Maldives' },
  { countryCode: 'MU', label: 'Indian/Mauritius' },
  { countryCode: 'RE', label: 'Indian/Reunion' },
  { countryCode: 'WS', label: 'Pacific/Apia' },
  { countryCode: 'NZ', label: 'Pacific/Auckland' },
  { countryCode: 'PG', label: 'Pacific/Bougainville' },
  { countryCode: 'NZ', label: 'Pacific/Chatham' },
  { countryCode: 'FM', label: 'Pacific/Chuuk' },
  { countryCode: 'CL', label: 'Pacific/Easter' },
  { countryCode: 'VU', label: 'Pacific/Efate' },
  { countryCode: 'KI', label: 'Pacific/Enderbury' },
  { countryCode: 'TK', label: 'Pacific/Fakaofo' },
  { countryCode: 'FJ', label: 'Pacific/Fiji' },
  { countryCode: 'TV', label: 'Pacific/Funafuti' },
  { countryCode: 'EC', label: 'Pacific/Galapagos' },
  { countryCode: 'PF', label: 'Pacific/Gambier' },
  { countryCode: 'SB', label: 'Pacific/Guadalcanal' },
  { countryCode: 'GU', label: 'Pacific/Guam' },
  { countryCode: 'US', label: 'Pacific/Honolulu' },
  { countryCode: 'KI', label: 'Pacific/Kiritimati' },
  { countryCode: 'FM', label: 'Pacific/Kosrae' },
  { countryCode: 'MH', label: 'Pacific/Kwajalein' },
  { countryCode: 'MH', label: 'Pacific/Majuro' },
  { countryCode: 'PF', label: 'Pacific/Marquesas' },
  { countryCode: 'NR', label: 'Pacific/Nauru' },
  { countryCode: 'NU', label: 'Pacific/Niue' },
  { countryCode: 'NF', label: 'Pacific/Norfolk' },
  { countryCode: 'NC', label: 'Pacific/Noumea' },
  { countryCode: 'AS', label: 'Pacific/Pago_Pago' },
  { countryCode: 'PW', label: 'Pacific/Palau' },
  { countryCode: 'PN', label: 'Pacific/Pitcairn' },
  { countryCode: 'FM', label: 'Pacific/Pohnpei' },
  { countryCode: 'PG', label: 'Pacific/Port_Moresby' },
  { countryCode: 'CK', label: 'Pacific/Rarotonga' },
  { countryCode: 'PF', label: 'Pacific/Tahiti' },
  { countryCode: 'KI', label: 'Pacific/Tarawa' },
  { countryCode: 'TO', label: 'Pacific/Tongatapu' },
  { countryCode: 'UM', label: 'Pacific/Wake' },
  { countryCode: 'WF', label: 'Pacific/Wallis' },
];

export const UPLOAD_IMAGE_DISPLAY = {
  CURRENT: 'curent_image',
  PICK: 'pick_image',
  EDIT: 'edit_image',
};
