import { http } from 'connex-cds';

export default {
  listOrderStatuses: ({ entityRef, queryParams }) =>
    http.get({ apiName: 'company-admin', path: `/${entityRef}/order-statuses`, queryParams }),
  createOrderStatus: ({ entityRef, data }) =>
    http.post({ apiName: 'company-admin', path: `/${entityRef}/order-statuses`, data }),
  updateOrderStatus: ({ entityRef, orderStatusRef, data }) =>
    http.patch({ apiName: 'company-admin', path: `/${entityRef}/order-statuses/${orderStatusRef}`, data }),
};
