import { http } from 'connex-cds';

export default {
  listShippingMethods: ({ entityRef, queryParams }) =>
    http.get({ apiName: 'company-admin', path: `/${entityRef}/shipping-methods`, queryParams }),
  createShippingMethods: ({ entityRef, data }) =>
    http.post({
      apiName: 'company-admin',
      path: `/${entityRef}/shipping-methods`,
      data,
    }),
  updateShippingMethods: ({ entityRef, shippingMethodRef, data }) =>
    http.patch({ apiName: 'company-admin', path: `/${entityRef}/shipping-methods/${shippingMethodRef}`, data }),
};
