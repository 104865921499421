import React from 'react';
import { string, bool, shape } from 'prop-types';

import Avatar from '../../../avatar';
import Icon from '../../../custom-icon';

import { IconCellStyled } from './styles';

const IconCell = ({ icon, value, profileImage, avatarInfo }) => {
  return (
    <IconCellStyled>
      {avatarInfo ? (
        <Avatar alt={value} src={profileImage} firstName={avatarInfo?.firstName} lastName={avatarInfo?.lastName} />
      ) : (
        <Icon iconName={icon} />
      )}
      <span className="value" title={value}>
        {value}
      </span>
    </IconCellStyled>
  );
};

IconCell.propTypes = {
  avatarInfo: shape({
    firstName: string,
    lastName: string,
  }),
  icon: string,
  isAvatar: bool,
  profileImage: string,
  value: string.isRequired,
};

IconCell.defaultProps = {
  avatarInfo: null,
  icon: '',
  isAvatar: false,
  profileImage: '',
};

export default IconCell;
