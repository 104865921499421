import React, { useMemo, useState } from 'react';
import { Localization, useNotification } from 'connex-cds';
import { func, shape, string } from 'prop-types';
import {
  useLocations,
  usePartners,
  useInventoryProducts,
  useInventoryProductLocations,
  useUom,
} from '../../../../api/hooks';
import InventoryProductFormView from '../inventory-product-form-view';
import { DIALOG_TYPES, STATUS_OPTIONS } from '../../../../constants';
import { getMappedInventoryProductLocations, getInventoryProductLocationsDataToSubmit } from '../../utils';
import {
  INVENTORY_SOURCE_OPTIONS,
  INVENTORY_PRODUCT_LOCATION_STATUSES,
} from '../inventory-product-form-view/form-config';
import { parseLocations, parsePartnerOptions, parseUomOptions } from '../../../../api/adapters';

const EditInventoryProductDialog = ({ onClose, onDelete, inventoryProductData }) => {
  const [isOpen, setIsOpen] = useState(true);
  const { List: useListUom } = useUom();
  const { data: uomOptions, isLoading: isLoadingUom } = useListUom({ adapter: parseUomOptions });
  const { List: useListLocations } = useLocations();
  const { data: locationOptions, isLoading: isLoadingLocations } = useListLocations({
    adapter: parseLocations,
    queryParams: { inventoryOnly: true },
  });
  const { List: listPartners } = usePartners();
  const { data: partnerOptions, isLoading: isLoadingPartners } = listPartners({ adapter: parsePartnerOptions });
  const { List: listInventoryProductLocations } = useInventoryProductLocations();
  const { data: inventoryProductLocationsData, isLoading: isInventoryProductLocationsDataLoading } =
    listInventoryProductLocations(inventoryProductData?.crn);
  const inventoryProductLocations = useMemo(() => {
    if (
      !isInventoryProductLocationsDataLoading &&
      inventoryProductLocationsData &&
      inventoryProductLocationsData.length
    ) {
      return inventoryProductLocationsData;
    }
    return [];
  }, [inventoryProductLocationsData, isInventoryProductLocationsDataLoading]);
  const { Update: useUpdateInventoryProduct } = useInventoryProducts();
  const { mutateAsync: updateInventoryProduct } = useUpdateInventoryProduct();

  const notification = useNotification();
  const translateMessage = Localization.useTranslateMessage();

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  const handleLocationsChange = (setFieldValue, value) => {
    const inventoryLocations = getMappedInventoryProductLocations(value, inventoryProductLocations);
    setFieldValue('inventoryLocations', inventoryLocations);
  };

  const handleOnSubmit = async (values, formikBag) => {
    const {
      inventoryProductId,
      inventoryProductName,
      inventoryProductStatus,
      inventoryProductUom,
      locations,
      inventorySource,
      inventoryLocations,
    } = values;

    const locationsObject = locations.reduce((accumulator, location) => {
      return { ...accumulator, [location.value]: true };
    }, {});

    formikBag.setSubmitting(true);

    try {
      const availableProductLocations = getInventoryProductLocationsDataToSubmit(
        inventoryLocations,
        partnerOptions,
        inventoryProductLocations,
        INVENTORY_PRODUCT_LOCATION_STATUSES.Active
      );
      const removedProductLocations = inventoryProductLocations
        .filter(x => !inventoryLocations?.some(inventoryLocation => x.locationRef === inventoryLocation.value))
        .map(x => ({ ...x, status: INVENTORY_PRODUCT_LOCATION_STATUSES.Deleted }));
      const inventoryProductLocationsList = [...availableProductLocations, ...removedProductLocations];

      const inventoryProduct = {
        id: inventoryProductId,
        name: inventoryProductName,
        inventoryUomCode: inventoryProductUom,
        locations: locations.length === locationOptions.length ? { all: true } : locationsObject,
        status: inventoryProductStatus,
        inventorySource: inventorySource,
        inventoryProductLocations: inventoryProductLocationsList,
      };

      await updateInventoryProduct({ inventoryProduct, inventoryProductRef: inventoryProductData.crn });
      notification.success(translateMessage('notification_changesSaved'));

      formikBag.setSubmitting(false);
      formikBag.resetForm();
      handleClose();
    } catch (error) {
      console.error(error);
    }
  };

  const getInitialValues = useMemo(() => {
    if (!inventoryProductData) return null;
    const {
      id,
      name,
      inventoryUomCode,
      inventorySource: inventorySourceValue,
      locations: editLocations,
      status,
    } = inventoryProductData;

    const inventoryProductUom = uomOptions.find(item => item.value === inventoryUomCode)?.value;
    const inventoryProductStatus = STATUS_OPTIONS.find(item => item.value === status)?.value;
    const areAllLocations = Object.keys(editLocations).length === 1 && Object.keys(editLocations)[0] === 'all';
    const newLocations = locationOptions.reduce((accumulator, current) => {
      const location = Object.keys(editLocations).filter(item => item === current.value);
      return location.length ? [...accumulator, current] : accumulator;
    }, []);
    const locations = areAllLocations ? locationOptions : newLocations;
    const inventorySource = INVENTORY_SOURCE_OPTIONS.find(item => item.value === inventorySourceValue)?.value;
    const inventoryLocations = getMappedInventoryProductLocations(locations, inventoryProductLocations, partnerOptions);

    const editValues = {
      inventoryProductId: id,
      inventoryProductName: name,
      inventoryProductUom,
      locations,
      inventoryProductStatus,
      inventorySource,
      inventoryLocations,
    };

    return editValues;
  }, [inventoryProductData, uomOptions, locationOptions, inventoryProductLocations, partnerOptions]);

  return (
    <InventoryProductFormView
      open={isOpen}
      onClose={handleClose}
      onDelete={onDelete}
      mode={DIALOG_TYPES.EDIT}
      initialValues={getInitialValues}
      onSubmit={handleOnSubmit}
      uomOptions={uomOptions}
      isLoadingUom={isLoadingUom}
      locationOptions={locationOptions}
      isLoadingLocations={isLoadingLocations}
      inventoryProductStatusOptions={STATUS_OPTIONS}
      inventorySourceOptions={INVENTORY_SOURCE_OPTIONS}
      onLocationsChange={handleLocationsChange}
      partnerOptions={partnerOptions}
      isLoadingPartners={isLoadingPartners}
      isInventoryProductLocationsDataLoading={isInventoryProductLocationsDataLoading}
    />
  );
};

EditInventoryProductDialog.propTypes = {
  onClose: func,
  onDelete: func,
  inventoryProductData: shape({
    crn: string.isRequired,
    id: string.isRequired,
    name: string.isRequired,
    status: string.isRequired,
  }),
};

EditInventoryProductDialog.defaultProps = {
  onClose: () => {},
  onDelete: () => {},
  inventoryProductData: {},
};

export default EditInventoryProductDialog;
