import React from 'react';
import { DIALOG_TYPES } from '../../../../constants';
import { CustomIcon, DialogActions, DialogContainer, DialogContent, DialogTitle } from '../../../../commons';
import { Localization } from 'connex-cds';
import { Formik } from 'formik';
import { Button } from '@mui/material';
import CancelReasonProfileTab from '../cancel-reason-profile-tab';
import { validationSchema } from './form-config';

const CancelReasonFormView = ({
  open,
  onClose,
  mode,
  initialValues,
  onSubmit,
  statusOptions,
  handleOnDelete,
  cancelReasonTypesOptions,
}) => {
  const handleClose = () => {
    onClose();
  };

  const handleCloseDialog = (_e, reason) => {
    if (reason === 'escapeKeyDown') {
      handleClose();
    }
  };

  const renderHeader = mode => {
    let icon;
    let stringId;

    switch (mode) {
      case DIALOG_TYPES.ADD:
        icon = 'addOutlinedIcon';
        stringId = 'cancelReasons_addCancelReason_title';
        break;

      case DIALOG_TYPES.EDIT:
        icon = 'editOutlinedIcon';
        stringId = 'cancelReasons_editCancelReason_title';
        break;

      default:
        icon = '';
        stringId = '';
        break;
    }

    return (
      <>
        <CustomIcon iconName={icon} size={'medium'} />
        <Localization.Translate stringId={stringId} />
      </>
    );
  };

  return (
    <DialogContainer open={open} maxWidth="md" fullWidth onClose={handleCloseDialog}>
      <DialogTitle onDelete={handleOnDelete} onClose={handleClose}>
        {renderHeader(mode)}
      </DialogTitle>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} enableReinitialize>
        {({ isSubmitting, dirty, isValid, handleSubmit }) => {
          return (
            <>
              <DialogContent>
                <CancelReasonProfileTab
                  statusOptions={statusOptions}
                  mode={mode}
                  cancelReasonTypesOptions={cancelReasonTypesOptions}
                />
              </DialogContent>
              <DialogActions
                rightSide={
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={isSubmitting || !dirty || !isValid}
                    onClick={handleSubmit}
                  >
                    <Localization.Translate stringId="general_dialogAction_save" />
                  </Button>
                }
              />
            </>
          );
        }}
      </Formik>
    </DialogContainer>
  );
};

export default CancelReasonFormView;
