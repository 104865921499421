import React, { useState } from 'react';
import { Localization, useNotification } from 'connex-cds';

import CustomerFormView from '../customer-form-view';
import { DIALOG_TYPES, STATUS_OPTIONS, ORDER_RESTRICTIONS_OPTIONS } from '../../../../constants';
import { initialValues, TAX_STATUS_OPTIONS } from '../customer-form-view/form-config';
import { useCustomers, useCreditCodes, usePriceBooks } from '../../../../api/hooks';
import { parseCreditCodesOptions, parsePriceBookOptions } from '../../../../api/adapters';
import { mapperYesNo } from '../../../../util/forms';

const AddCustomerDialog = ({ onClose }) => {
  const [isOpen, setIsOpen] = useState(true);

  const { Create: useCreateCustomers } = useCustomers();
  const { mutateAsync: createCustomer } = useCreateCustomers();

  const { List: useListPriceBooks } = usePriceBooks();
  const { data: priceBookOptions, isLoading: isLoadingPriceBookOptions } = useListPriceBooks({
    adapter: parsePriceBookOptions,
    queryParams: { showProductDetail: true },
  });

  const { List: useListCreditCodes } = useCreditCodes();
  const { data: creditCodeOptions, isLoading: isLoadingCreditCodeOptions } = useListCreditCodes({
    adapter: parseCreditCodesOptions,
  });

  const translateMessage = Localization.useTranslateMessage();
  const notification = useNotification();

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  const handleOnSubmit = async (values, formikBag) => {
    const {
      id,
      name,
      status,
      streetAddress,
      complement,
      city,
      state,
      postalCode,
      printPaperTickets,
      printWeights,
      showPricing,
      priceBook,
      taxable,
      creditCode,
      orderRestriction,
    } = values;

    formikBag.setSubmitting(true);

    try {
      const data = {
        id,
        name,
        status,
        address: {
          address1: streetAddress,
          address2: complement,
          city,
          countrySubDivision: state,
          postalCode,
        },
        pricingOptions: {
          showPricing: mapperYesNo(showPricing),
        },
        ticketOptions: {
          printPaperTickets: mapperYesNo(printPaperTickets),
          printWeights: mapperYesNo(printWeights),
        },
        taxStatus: taxable || undefined,
        costBookRef: priceBook || undefined,
        creditCodeRef: creditCode || undefined,
        restrictToPriceBook: orderRestriction || undefined,
      };

      await createCustomer({ data });
      notification.success(translateMessage('customers_notification_customerAdded'));

      formikBag.setSubmitting(false);
      formikBag.resetForm();
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <CustomerFormView
      open={isOpen}
      onClose={handleClose}
      mode={DIALOG_TYPES.ADD}
      initialValues={initialValues}
      onSubmit={handleOnSubmit}
      customerStatusOptions={STATUS_OPTIONS}
      priceBookOptions={priceBookOptions}
      isLoadingPriceBookOptions={isLoadingPriceBookOptions}
      creditCodeOptions={creditCodeOptions}
      isLoadingCreditCodeOptions={isLoadingCreditCodeOptions}
      taxableOptions={TAX_STATUS_OPTIONS}
      orderRestrictionOptions={ORDER_RESTRICTIONS_OPTIONS}
    />
  );
};

export default AddCustomerDialog;
