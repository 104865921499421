import { Grid } from '@mui/material';
import { Field, Form } from 'formik';

import { NumberInputWithFormikField, SelectWithFormikField } from '../../../../commons';

const VehicleSpecificationsTab = ({
  driversOptions,
  isLoadingDriversOptions,
  trailersOptions,
  isLoadingTrailersOptions,
}) => {
  return (
    <Form>
      <Grid container spacing={5} alignItems="flex-start">
        <Grid xs={6} item container gap={5}>
          <Grid item xs={12}>
            <Field
              fullWidth
              labelStringId="drivers_profileTab_driverName"
              component={SelectWithFormikField}
              name="driver"
              options={driversOptions}
              disabled={isLoadingDriversOptions}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              fullWidth
              labelStringId="trailers_trailerTab_fieldTrailerName_label"
              component={SelectWithFormikField}
              name="trailer"
              options={trailersOptions}
              disabled={isLoadingTrailersOptions}
            />
          </Grid>
        </Grid>
        <Grid xs={6} item container gap={5}>
          <Grid item xs={12}>
            <Field
              fullWidth
              labelStringId="vehicles_specificationTab_maxGrossVehicleWeight"
              component={NumberInputWithFormikField}
              variant="outlined"
              type="decimal"
              name="maxGrossVehicleWeight"
              tooltipInfoStringId="vehicles_specificationTab_maxGrossVehicleWeight_tooltip"
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              fullWidth
              labelStringId="vehicles_specificationTab_targetGrossVehicleWeight"
              component={NumberInputWithFormikField}
              variant="outlined"
              type="decimal"
              name="targetGrossVehicleWeight"
              tooltipInfoStringId="vehicles_specificationTab_targetGrossVehicleWeight_tooltip"
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              fullWidth
              labelStringId="vehicles_specificationTab_targetNetVehicleWeight"
              component={NumberInputWithFormikField}
              variant="outlined"
              type="decimal"
              name="targetNetVehicleWeight"
              tooltipInfoStringId="vehicles_specificationTab_targetNetVehicleWeight_tooltip"
            />
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );
};

export default VehicleSpecificationsTab;
