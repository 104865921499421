import React, { useState } from 'react';
import { Localization } from 'connex-cds';
import { Formik } from 'formik';
import { Button } from '@mui/material';

import {
  CustomIcon,
  CustomTabsControl,
  CustomTabsView,
  DialogActions,
  DialogContainer,
  DialogContent,
  DialogTitle,
} from '../../../../commons';
import { TAB_IDS, validationSchema } from './form-config';
import { DIALOG_TYPES } from '../../../../constants';
import MixProfileTab from '../mix-profile-tab';
import MixComponentsTab from '../mix-components-tab';

const MixFormView = ({
  open,
  initialValues,
  onClose,
  onSubmit,
  mode,
  mixDesignStatusOptions,
  uomOptions,
  isLoadingUom,
  handleOnDelete,
  mixComponentsOptions,
  isLoadingMixComponents,
}) => {
  const [currentTab, setCurrentTab] = useState(TAB_IDS.AddMixProfile);

  const handleTabChange = (_, value) => {
    setCurrentTab(value);
  };

  const goToStep = stepIndex => () => {
    setCurrentTab(stepIndex);
  };

  const handleClose = () => {
    onClose();
    setCurrentTab(TAB_IDS.AddMixProfile);
  };

  const handleCloseDialog = (e, reason) => {
    if (reason === 'escapeKeyDown') {
      handleClose();
    }
  };

  const renderHeader = mode => {
    let icon;
    let stringId;

    switch (mode) {
      case DIALOG_TYPES.ADD:
        icon = 'addOutlinedIcon';
        stringId = 'mixDesigns_addMixDesign';
        break;

      case DIALOG_TYPES.EDIT:
        icon = 'editOutlinedIcon';
        stringId = 'mixDesigns_editMixDesignTitle';
        break;

      default:
        icon = '';
        stringId = '';
        break;
    }

    return (
      <>
        <CustomIcon iconName={icon} size={'medium'} />
        <Localization.Translate stringId={stringId} />
      </>
    );
  };

  const TABS = [
    {
      tabIdentifier: TAB_IDS.AddMixProfile,
      label: <Localization.Translate stringId={'mixDesigns_profileTab_title'} />,
      icon: <CustomIcon iconName={'driveFileRenameOutlineRounded'} />,
    },
    {
      tabIdentifier: TAB_IDS.AddMixComponents,
      label: <Localization.Translate stringId={'mixComponents'} />,
      icon: <CustomIcon iconName={'listAltOutlinedIcon'} />,
    },
  ];

  return (
    <DialogContainer open={open} maxWidth="md" fullWidth onClose={handleCloseDialog}>
      <DialogTitle onDelete={handleOnDelete} onClose={handleClose}>
        {renderHeader(mode)}
      </DialogTitle>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} enableReinitialize>
        {({ isSubmitting, dirty, isValid, handleSubmit, setFieldValue, values }) => {
          return (
            <>
              <CustomTabsControl tabs={TABS} currentTab={currentTab} handleTabChange={handleTabChange} />
              <DialogContent>
                <CustomTabsView tab={currentTab}>
                  <MixProfileTab
                    mode={mode}
                    tabIdentifier={TAB_IDS.AddMixProfile}
                    mixDesignStatusOptions={mixDesignStatusOptions}
                    uomOptions={uomOptions}
                    isLoadingUom={isLoadingUom}
                  />
                  <MixComponentsTab
                    tabIdentifier={TAB_IDS.AddMixComponents}
                    mixComponentsOptions={mixComponentsOptions}
                    isLoadingMixComponents={isLoadingMixComponents}
                    setFieldValue={setFieldValue}
                    selectedComponents={values.mixComponents}
                    quantities={values.mixDesignComponentsQuantities}
                    isSubmitting={isSubmitting}
                  />
                </CustomTabsView>
              </DialogContent>
              <DialogActions
                lefSide={
                  currentTab !== 0 && (
                    <Button onClick={goToStep(currentTab - 1)} className="step-control">
                      <Localization.Translate stringId="general_dialogAction_back" />
                    </Button>
                  )
                }
                rightSide={
                  <>
                    {currentTab < TABS.length - 1 && (
                      <Button onClick={goToStep(currentTab + 1)} className="step-control">
                        <Localization.Translate stringId="general_dialogAction_next" />
                      </Button>
                    )}
                    {currentTab === TABS.length - 1 && (
                      <Button
                        type="submit"
                        variant="contained"
                        disabled={isSubmitting || !dirty || !isValid}
                        onClick={handleSubmit}
                      >
                        <Localization.Translate stringId="general_dialogAction_save" />
                      </Button>
                    )}
                  </>
                }
              />
            </>
          );
        }}
      </Formik>
    </DialogContainer>
  );
};

export default MixFormView;
