import { Formik } from 'formik';
import { Localization } from 'connex-cds';
import { Button } from '@mui/material';
import { array, bool, func, string, object } from 'prop-types';

import { DialogTitle, CustomIcon, DialogContent, DialogActions, DialogContainer } from '../../../../commons';
import { DIALOG_TYPES } from '../../../../constants';
import { validationSchema } from './form-config';
import ConcreteClassProfileTab from '../concrete-class-profile-tab';

const ConcreteClassesFormView = ({
  open,
  onClose,
  mode,
  initialValues,
  onSubmit,
  concreteClassesStatusOptions,
  uomTemperatureOptions,
  uomLengthOptions,
  concreteClassesTypeOptions,
  concreteClassesSubtypeOptions,
  handleOnDelete,
}) => {
  const handleClose = () => {
    onClose();
  };

  const handleCloseDialog = (e, reason) => {
    if (reason === 'escapeKeyDown') {
      handleClose();
    }
  };

  const renderHeader = mode => {
    let icon;
    let stringId;

    switch (mode) {
      case DIALOG_TYPES.ADD:
        icon = 'addOutlinedIcon';
        stringId = 'concreteClasses_addConcreteClass_title';
        break;
      case DIALOG_TYPES.EDIT:
        icon = 'editOutlinedIcon';
        stringId = 'concreteClasses_editConcreteClass_title';
        break;

      default:
        icon = '';
        stringId = '';
        break;
    }

    return (
      <>
        <CustomIcon iconName={icon} size={'medium'} />
        <Localization.Translate stringId={stringId} />
      </>
    );
  };
  return (
    <DialogContainer open={open} maxWidth="md" fullWidth onClose={handleCloseDialog}>
      <DialogTitle onDelete={handleOnDelete} onClose={handleClose}>
        {renderHeader(mode)}
      </DialogTitle>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} enableReinitialize>
        {({ values: { concreteClassType }, isSubmitting, dirty, isValid, handleSubmit, setFieldValue }) => {
          return (
            <>
              <DialogContent>
                <ConcreteClassProfileTab
                  concreteClassesStatusOptions={concreteClassesStatusOptions}
                  mode={mode}
                  uomTemperatureOptions={uomTemperatureOptions}
                  uomLengthOptions={uomLengthOptions}
                  concreteClassesTypeOptions={concreteClassesTypeOptions}
                  concreteClassType={concreteClassType}
                  concreteClassesSubtypeOptions={concreteClassesSubtypeOptions}
                  setFieldValue={setFieldValue}
                />
              </DialogContent>
              <DialogActions
                rightSide={
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={isSubmitting || !dirty || !isValid}
                    onClick={handleSubmit}
                  >
                    <Localization.Translate stringId="general_dialogAction_save" />
                  </Button>
                }
              />
            </>
          );
        }}
      </Formik>
    </DialogContainer>
  );
};

ConcreteClassesFormView.propTypes = {
  open: bool.isRequired,
  onClose: func.isRequired,
  handleOnDelete: func,
  mode: string.isRequired,
  initialValues: object.isRequired,
  onSubmit: func.isRequired,
  concreteClassesStatusOptions: array.isRequired,
  uomTemperatureOptions: array.isRequired,
  uomLengthOptions: array.isRequired,
  concreteClassesTypeOptions: array,
  concreteClassesSubtypeOptions: array,
};

export default ConcreteClassesFormView;
