import { useTableLogic } from '../../hooks';
import { TableLayout, EntityTable, SearchFilter } from '../../commons';
import { getListColumns } from './config';
import { usePdfTicketReports } from '../../api/hooks';

const TemplatesTable = () => {
  const openInNewTab = url => {
    window.open(url, '_blank', 'noreferrer');
  };

  const filteredPropsArr = ['name', 'languages'];
  const { setQuery, isLoading, filteredData, entityColumns, handleOnRowClick } = useTableLogic(
    usePdfTicketReports,
    getListColumns({ download: openInNewTab }),
    filteredPropsArr
  );

  return (
    <TableLayout.ListLayoutContainer>
      <TableLayout.TableControls>
        <SearchFilter onSearchChange={setQuery} disabled={isLoading} resultsCount={filteredData.length} />
      </TableLayout.TableControls>

      <TableLayout.TableBody>
        <EntityTable
          checkboxSelection={false}
          getRowId={row => row.crn}
          rows={filteredData}
          columns={entityColumns}
          onRowClick={handleOnRowClick}
          isLoading={isLoading}
        />
      </TableLayout.TableBody>
    </TableLayout.ListLayoutContainer>
  );
};

export default TemplatesTable;
