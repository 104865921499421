import { useMemo } from 'react';
import { Field, Form } from 'formik';
import { Localization } from 'connex-cds';
import { Grid } from '@mui/material';

import { LocationPricingFormContainer } from './styles';
import { DialogTable, SelectWithFormikField } from '../../../../commons';
import { getProductListColumns } from './tableConfig';

const LocationPricingTab = ({
  priceBookOptions,
  isLoadingPriceBookOptions,
  restrictToPricebookOptions,
  taxBasisOptions,
  currentPriceBook,
}) => {
  const translateMessage = Localization.useTranslateMessage();
  const { getCurrency } = Localization.useCurrency();

  const priceBookProducts = useMemo(
    () => priceBookOptions.find(item => item.value === currentPriceBook)?.products || [],
    [priceBookOptions, currentPriceBook]
  );

  return (
    <LocationPricingFormContainer>
      <h3 className="tab-title">
        <Localization.Translate stringId="locations_pricingTab_main_title" />
      </h3>
      <Form>
        <Grid container spacing={5} alignItems="baseline">
          <Grid item xs={12} md={6}>
            <Field
              fullWidth
              labelStringId="general_priceBook"
              component={SelectWithFormikField}
              name="costBookRef"
              options={priceBookOptions}
              disabled={isLoadingPriceBookOptions}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              fullWidth
              labelStringId="general_orderRestrictions"
              infoTooltipStringId="locations_pricingTab_orderRestrictions_field_tooltip"
              component={SelectWithFormikField}
              name="restrictToPriceBook"
              options={restrictToPricebookOptions}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Field
              fullWidth
              labelStringId="locations_pricingTab_taxBasis_field"
              component={SelectWithFormikField}
              name="taxBasis"
              options={taxBasisOptions}
            />
          </Grid>
          {priceBookProducts?.length > 0 && (
            <Grid item md={12} xs={12}>
              <DialogTable
                checkboxSelection={false}
                getRowId={row => row.productRef}
                rows={priceBookProducts}
                columns={getProductListColumns({ translateMessage, getCurrency })}
                isLoading={isLoadingPriceBookOptions}
              />
            </Grid>
          )}
        </Grid>
      </Form>
    </LocationPricingFormContainer>
  );
};

export default LocationPricingTab;
