import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Button } from '@mui/material';

export const ButtonWithPrev = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
  `}
`;

export const ButtonLabelText = styled.p`
  ${({ theme }) => css`
    margin: 0px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 6px;
  `}
`;

export const ButtonGroupStyled = styled(Button)`
  ${({ theme }) => css`
    box-shadow: ${theme.shadowCA(1)};
    border-radius: 12px;
    height: 60px;
    width: 162px;
    padding: 0px;
    gap: 0px !important;

    .MuiButtonBase-root:first-of-type {
      color: ${theme.validateMode({
        light: theme.colorsCA.neutral[40],
        dark: theme.colorsCA.neutral[95],
      })};
      border: 1px solid
        ${theme.validateMode({
          light: theme.colorsCA.neutral[90],
          dark: theme.colorsCA.neutral[50],
        })};
      border-inline-end: none;
      border-radius: 12px 0px 0px 12px;
    }

    .btn-icon {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${theme.validateMode({
        light: theme.colorsCA.neutral[40],
        dark: theme.colorsCA.neutral[95],
      })};
      border: 1px solid
        ${theme.validateMode({
          light: theme.colorsCA.neutral[90],
          dark: theme.colorsCA.neutral[50],
        })};
      border-inline-end: none;
      border-radius: 12px 0px 0px 12px;
    }

    .preview {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0px 12px 12px 0px;
      border-inline-end: none;
      color: ${theme.validateMode({
        light: theme.colorsCA.darkBlue[20],
        dark: theme.colorsCA.darkBlue[80],
      })};
      border: 1px solid
        ${theme.validateMode({
          light: theme.colorsCA.neutral[90],
          dark: theme.colorsCA.neutral[50],
        })};
    }
  `}
`;
