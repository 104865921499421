import * as yup from 'yup';

import { STATUS_OPTIONS } from '../../../../constants';

export const validationSchema = yup.object().shape({
  vehicleTypeId: yup.string().required('required'),
  vehicleTypeName: yup.string().required('required'),
  vehicleTypeStatus: yup.string().required('required'),
  defaultLoadSize: yup.number().required('required'),
  defaultLoadSizeUOM: yup.string().required('required'),
  maxLoadSize: yup.number().required('required'),
  maxLoadSizeUOM: yup.string().required('required'),
  trackingColor: yup
    .string()
    .matches(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/, 'vehicleTypes_profileTab_trackingColor_invalidValue')
    .required('required'),
});

export const initialValues = {
  vehicleTypeId: '',
  vehicleTypeName: '',
  vehicleTypeStatus: STATUS_OPTIONS[0].value,
  defaultValue: false,
  defaultLoadSize: '',
  defaultLoadSizeUOM: '',
  maxLoadSize: '',
  maxLoadSizeUOM: '',
  trackingColor: '#FFFF00',
};
