import { useState, useMemo } from 'react';
import { Localization, useNotification } from 'connex-cds';
import { func, object } from 'prop-types';

import { DIALOG_TYPES, STATUS_OPTIONS } from '../../../../constants';
import { useWorkTypes, useProductTypes } from '../../../../api/hooks';
import WorkTypeFormView from '../work-type-form-view';
import { parseProductTypesOptionsWithRef } from '../../../../api/adapters';

const EditWorkTypeDialog = ({ onClose, handleOnDelete, workTypeData }) => {
  const notification = useNotification();
  const translateMessage = Localization.useTranslateMessage();
  const [isOpen, setIsOpen] = useState(true);
  const { Update: useUpdateWorkType } = useWorkTypes();
  const { mutateAsync: updateWorkType } = useUpdateWorkType();
  const { List: useListProductTypes } = useProductTypes();
  const { data: productTypesOptions, isLoading: isLoadingProductTypes } = useListProductTypes({
    adapter: parseProductTypesOptionsWithRef,
  });

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  const handleOnSubmit = async (values, formikBag) => {
    formikBag.setSubmitting(true);

    const { name, status, productTypeRef } = values;

    const workType = {
      name,
      status,
      productTypeRef,
    };

    try {
      await updateWorkType({ data: workType, workTypeRef: workTypeData?.crn });
      notification.success(translateMessage('notification_changesSaved'));
      formikBag.setSubmitting(false);
      formikBag.resetForm();
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  const getInitialValues = useMemo(() => {
    if (!workTypeData) return null;
    const { id, name, productTypeRef } = workTypeData;

    const editValues = {
      id,
      name,
      status: STATUS_OPTIONS.find(status => status.value === workTypeData.status).value,
      productTypeRef: productTypesOptions?.find(type => type.value === productTypeRef)?.value,
    };

    return editValues;
  }, [workTypeData, productTypesOptions]);

  return (
    <WorkTypeFormView
      open={isOpen}
      onClose={handleClose}
      handleOnDelete={handleOnDelete}
      mode={DIALOG_TYPES.EDIT}
      initialValues={getInitialValues}
      onSubmit={handleOnSubmit}
      statusOptions={STATUS_OPTIONS}
      productTypesOptions={productTypesOptions}
      isLoadingProductTypes={isLoadingProductTypes}
    />
  );
};

EditWorkTypeDialog.propTypes = {
  workTypeData: object,
  onClose: func.isRequired,
  handleOnDelete: func,
};

EditWorkTypeDialog.defaultProps = {
  onClose: () => {},
};

export default EditWorkTypeDialog;
