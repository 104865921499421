import { useMemo, useState } from 'react';
import { Localization, useNotification } from 'connex-cds';
import { func, shape, bool } from 'prop-types';
import { mapValues } from 'lodash';

import {
  useInventoryProducts,
  useLocations,
  useMixes,
  useProductTypes,
  useProducts,
  useUom,
  useConcreteClasses,
} from '../../../../api/hooks';
import ProductFormView from '../product-form-view';
import {
  BOOLEAN_OPTIONS,
  DIALOG_TYPES,
  STATUS_OPTIONS,
  CONCRETE_CLASS_TYPE_AIR,
  CONCRETE_CLASS_TYPE_CONSISTENCE,
  CONCRETE_CLASS_TYPE_SLUMP_LOSS_RATE,
  CONCRETE_CLASS_TYPE_TEMPERATURE,
} from '../../../../constants';
import {
  parseInventoryProductOptions,
  parseLocations,
  parseMixOptions,
  parseProductTypesOptions,
  parseUomOptions,
  parseConcreteClassesOptions,
} from '../../../../api/adapters';
import { getCosts, isProductTypeConcrete } from '../../util';

const EditProductDialog = ({ onClose, productData, handleOnDelete }) => {
  const [isOpen, setIsOpen] = useState(true);
  const translateMessage = Localization.useTranslateMessage();
  const { List: useListProductTypes } = useProductTypes();
  const { data: productTypesOptions, isLoading: isLoadingProductTypes } = useListProductTypes({
    adapter: parseProductTypesOptions,
  });
  const { List: listMixes } = useMixes();
  const { List: useListUom } = useUom();
  const { data: uomOptions, isLoading: isLoadingUom } = useListUom({ adapter: parseUomOptions });
  const { List: useListLocations } = useLocations();
  const { data: locationOptions, isLoading: isLoadingLocations } = useListLocations({ adapter: parseLocations });
  const { List: useListInventoryProducts } = useInventoryProducts();
  const { data: inventoryProductOptions, isLoading: isLoadingInventoryProducts } = useListInventoryProducts({
    adapter: parseInventoryProductOptions,
  });
  const { Update: useUpdateProduct } = useProducts();
  const { mutateAsync: updateProduct } = useUpdateProduct();
  const notification = useNotification();
  const { data: mixesOptions, isLoading: isLoadingMixes } = listMixes({
    queryParams: { activeOnly: true },
    adapter: parseMixOptions,
  });
  const { List: useListConcreteClasses } = useConcreteClasses();
  const { data: concreteClassesOptions, isLoading: isLoadingConcreteClasses } = useListConcreteClasses({
    adapter: parseConcreteClassesOptions,
  });

  const consistenceClassOptions = concreteClassesOptions.filter(
    option => option.classType === CONCRETE_CLASS_TYPE_CONSISTENCE
  );
  const slumpLossClassOptions = concreteClassesOptions.filter(
    option => option.classType === CONCRETE_CLASS_TYPE_SLUMP_LOSS_RATE
  );
  const temperatureClassOptions = concreteClassesOptions.filter(
    option => option.classType === CONCRETE_CLASS_TYPE_TEMPERATURE
  );
  const airClassOptions = concreteClassesOptions.filter(option => option.classType === CONCRETE_CLASS_TYPE_AIR);

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  const handleOnSubmit = async (values, formikBag) => {
    const {
      productId,
      productName,
      productStatus,
      productType,
      uom,
      locations,
      inventoryProduct,
      consistenceClass,
      slumpLossClass,
      temperatureClass,
      airClass,
      defaultPrice,
      taxable,
      costs,
      mix,
    } = values;

    const locationsObject = locations.reduce((accumulator, location) => {
      return { ...accumulator, [location.value]: true };
    }, {});

    const costObject = getCosts(costs, locationsObject);

    formikBag.setSubmitting(true);

    try {
      const msg = 'notification_changesSaved';

      const product = {
        id: productId,
        name: productName,
        type: productType,
        uomCode: uom,
        locations: locations.length === locationOptions.length ? { all: true } : locationsObject,
        inventoryProductRef: inventoryProduct || '',
        status: productStatus,
        consistenceRef: '',
        slumpLossRateRef: '',
        temperatureRef: '',
        airRef: '',
        unitPrice: defaultPrice !== '' ? Number(defaultPrice) : 0,
        costs: costObject,
        mix: { mixRef: '' },
      };

      if (taxable !== '') {
        product.taxable = taxable;
      }

      if (isProductTypeConcrete(productType)) {
        product.consistenceRef = consistenceClass;
        product.slumpLossRateRef = slumpLossClass;
        product.temperatureRef = temperatureClass;
        product.airRef = airClass;
        product.mix = { mixRef: mix };
      }

      await updateProduct({ product, productRef: productData?.crn });
      notification.success(translateMessage(msg));
    } catch (error) {
      console.error(error);
    }

    formikBag.setSubmitting(false);
    formikBag.resetForm();
    handleClose();
  };

  const getInitialValues = useMemo(() => {
    if (!productData) return null;
    const { id, name, locations: editLocations = {}, unitPrice = 0, costs = {} } = productData;

    const productType = productTypesOptions.find(type => type.value === productData.type)?.value;
    const uom = uomOptions.find(uomItem => uomItem.value === productData.uomCode)?.value;
    const productStatus = STATUS_OPTIONS.find(status => status.value === productData.status).value;
    const areAllLocations = Object.keys(editLocations).length === 1 && Object.keys(editLocations)[0] === 'all';
    const newLocations = locationOptions.reduce((accumulator, current) => {
      const location = Object.keys(editLocations).filter(loc => loc === current.value);
      return location.length ? [...accumulator, current] : accumulator;
    }, []);
    const locations = areAllLocations ? locationOptions : newLocations;
    const inventoryProduct = inventoryProductOptions.find(x => x.value === productData.inventoryProductRef)?.value;
    const consistenceClass = consistenceClassOptions.find(option => option.value === productData.consistenceRef)?.value;
    const slumpLossClass = slumpLossClassOptions.find(option => option.value === productData.slumpLossRateRef)?.value;
    const temperatureClass = temperatureClassOptions.find(option => option.value === productData.temperatureRef)?.value;
    const airClass = airClassOptions.find(option => option.value === productData.airRef)?.value;
    const taxable = BOOLEAN_OPTIONS.find(option => option.value === productData.taxable)?.value;
    const mix = mixesOptions.find(option => option.value === productData?.mix?.mixRef)?.value;

    const editValues = {
      productId: id,
      productName: name,
      productType,
      uom,
      locations,
      productStatus,
      mix,
      inventoryProduct,
      consistenceClass,
      slumpLossClass,
      temperatureClass,
      airClass,
      defaultPrice: (unitPrice || '').toString(),
      taxable,
      costs: mapValues(costs, costValue => costValue.toString()),
    };

    return editValues;
  }, [
    productData,
    productTypesOptions,
    uomOptions,
    locationOptions,
    inventoryProductOptions,
    consistenceClassOptions,
    slumpLossClassOptions,
    temperatureClassOptions,
    airClassOptions,
    mixesOptions,
  ]);

  return (
    <ProductFormView
      handleOnDelete={handleOnDelete}
      open={isOpen}
      onClose={handleClose}
      mode={DIALOG_TYPES.EDIT}
      initialValues={getInitialValues}
      onSubmit={handleOnSubmit}
      productTypesOptions={productTypesOptions}
      isLoadingProductTypes={isLoadingProductTypes}
      mixesOptions={mixesOptions}
      isLoadingMixes={isLoadingMixes}
      uomOptions={uomOptions}
      isLoadingUom={isLoadingUom}
      locationOptions={locationOptions}
      isLoadingLocations={isLoadingLocations}
      productStatusOptions={STATUS_OPTIONS}
      inventoryProductOptions={inventoryProductOptions}
      isLoadingInventoryProducts={isLoadingInventoryProducts}
      isLoadingConcreteClasses={isLoadingConcreteClasses}
      consistenceClassOptions={consistenceClassOptions}
      slumpLossClassOptions={slumpLossClassOptions}
      temperatureClassOptions={temperatureClassOptions}
      airClassOptions={airClassOptions}
      taxableOptions={BOOLEAN_OPTIONS}
    />
  );
};

EditProductDialog.propTypes = {
  productData: shape({}),
  onClose: func.isRequired,
  handleOnDelete: func,
  open: bool,
};

EditProductDialog.defaultProps = {
  editProduct: null,
  open: false,
  onClose: () => {},
};

export default EditProductDialog;
