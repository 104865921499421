import * as yup from 'yup';

import { STATUS_OPTIONS } from '../../../../constants';

export const CANCEL_REASON_TYPES_OPTIONS = [
  {
    translationKey: 'order',
    value: 'order',
  },
  {
    translationKey: 'ticket',
    value: 'ticket',
  },
];

export const validationSchema = yup.object().shape({
  id: yup.string().required('required'),
  name: yup.string().required('required'),
  status: yup.string(),
  type: yup.string(),
});

export const initialValues = {
  id: '',
  name: '',
  type: '',
  status: STATUS_OPTIONS[0].value,
};
