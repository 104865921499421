import React, { useState } from 'react';
import { Formik } from 'formik';
import { Localization } from 'connex-cds';
import { Button } from '@mui/material';

import { TAB_IDS, validationSchema } from './form-config';
import {
  DialogTitle,
  CustomTabsControl,
  CustomTabsView,
  CustomIcon,
  DialogContent,
  DialogActions,
  DialogContainer,
} from '../../../../commons';
import { DIALOG_TYPES } from '../../../../constants';
import InventoryProductProfileTab from '../inventory-product-profile-tab';
import InventoryProductInventoryTab from '../inventory-product-inventory-tab';
import InventoryProductSupplyTab from '../inventory-product-supply-tab';

const InventoryProductFormView = ({
  open,
  onClose,
  onDelete,
  mode,
  initialValues,
  onSubmit,
  uomOptions,
  isLoadingUom,
  locationOptions,
  isLoadingLocations,
  inventoryProductStatusOptions,
  inventorySourceOptions,
  onLocationsChange,
  isInventoryProductLocationsDataLoading,
  partnerOptions,
  isLoadingPartners,
}) => {
  const [currentTab, setCurrentTab] = useState(TAB_IDS.InventoryProductProfile);

  const handleTabChange = (_, value) => {
    setCurrentTab(value);
  };

  const goToStep = stepIndex => () => {
    setCurrentTab(stepIndex);
  };

  const handleClose = () => {
    onClose();
    setCurrentTab(0);
  };

  const handleCloseDialog = (e, reason) => {
    if (reason === 'escapeKeyDown') {
      handleClose();
    }
  };

  const TABS = [
    {
      tabIdentifier: TAB_IDS.InventoryProductProfile,
      label: <Localization.Translate stringId={'general_profile'} />,
      icon: <CustomIcon iconName={'driveFileRenameOutlineRounded'} />,
    },
    {
      tabIdentifier: TAB_IDS.InventoryProductInventory,
      label: <Localization.Translate stringId={'general_inventory'} />,
      icon: <CustomIcon iconName={'inventoryOutlined'} />,
    },
    {
      tabIdentifier: TAB_IDS.InventoryProductSupply,
      label: <Localization.Translate stringId={'general_supply'} />,
      icon: <CustomIcon iconName={'powerInputOutlined'} />,
    },
  ];

  const renderHeader = mode => {
    let icon;
    let stringId;

    switch (mode) {
      case DIALOG_TYPES.ADD:
        icon = 'addOutlinedIcon';
        stringId = 'inventoryProducts_addInventoryProduct_title';
        break;
      case DIALOG_TYPES.EDIT:
        icon = 'editOutlinedIcon';
        stringId = 'inventoryProducts_editInventoryProduct_title';
        break;

      default:
        icon = '';
        stringId = '';
        break;
    }

    return (
      <>
        <CustomIcon iconName={icon} size={'medium'} />
        <Localization.Translate stringId={stringId} />
      </>
    );
  };

  return (
    <DialogContainer open={open} maxWidth="md" fullWidth onClose={handleCloseDialog}>
      <DialogTitle onClose={handleClose} onDelete={onDelete}>
        {renderHeader(mode)}
      </DialogTitle>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} enableReinitialize>
        {({ values, isSubmitting, dirty, isValid, handleSubmit, setFieldValue }) => {
          const { inventoryLocations } = values;
          return (
            <>
              <CustomTabsControl tabs={TABS} currentTab={currentTab} handleTabChange={handleTabChange} />
              <DialogContent>
                <CustomTabsView tab={currentTab}>
                  <InventoryProductProfileTab
                    tabIdentifier={TAB_IDS.InventoryProductProfile}
                    mode={mode}
                    isLoadingUom={isLoadingUom}
                    uomOptions={uomOptions}
                    locationOptions={locationOptions}
                    isLoadingLocations={isLoadingLocations}
                    inventoryProductStatusOptions={inventoryProductStatusOptions}
                    setFieldValue={setFieldValue}
                    onLocationsChange={onLocationsChange}
                  />
                  <InventoryProductInventoryTab
                    tabIdentifier={TAB_IDS.InventoryProductInventory}
                    inventorySourceOptions={inventorySourceOptions}
                    inventoryLocations={inventoryLocations}
                  />
                  <InventoryProductSupplyTab
                    tabIdentifier={TAB_IDS.InventoryProductSupply}
                    inventoryLocations={inventoryLocations}
                    isInventoryProductLocationsDataLoading={isInventoryProductLocationsDataLoading}
                    partnerOptions={partnerOptions}
                    isLoadingPartners={isLoadingPartners}
                    setFieldValue={setFieldValue}
                  />
                </CustomTabsView>
              </DialogContent>
              <DialogActions
                lefSide={
                  currentTab !== 0 && (
                    <Button onClick={goToStep(currentTab - 1)} className="step-control">
                      <Localization.Translate stringId="general_dialogAction_back" />
                    </Button>
                  )
                }
                rightSide={
                  <>
                    {currentTab < TABS.length - 1 && (
                      <Button onClick={goToStep(currentTab + 1)} className="step-control">
                        <Localization.Translate stringId="general_dialogAction_next" />
                      </Button>
                    )}
                    {currentTab === TABS.length - 1 && (
                      <Button
                        type="submit"
                        variant="contained"
                        disabled={isSubmitting || !dirty || !isValid}
                        onClick={handleSubmit}
                      >
                        <Localization.Translate stringId="general_dialogAction_save" />
                      </Button>
                    )}
                  </>
                }
              />
            </>
          );
        }}
      </Formik>
    </DialogContainer>
  );
};

export default InventoryProductFormView;
