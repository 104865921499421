import { useState } from 'react';
import { Localization, useNotification } from 'connex-cds';

import ShippingMethodsFormView from '../shipping-methods-form-view';
import { DIALOG_TYPES, STATUS_OPTIONS } from '../../../../constants';
import { initialValues } from '../shipping-methods-form-view/form-config';
import { useShippingMethods } from '../../../../api/hooks';

const AddShippingMethodsDialaog = ({ onClose }) => {
  const [isOpen, setIsOpen] = useState(true);
  const notification = useNotification();
  const { Create: useCreateShippingMethods } = useShippingMethods();
  const { mutateAsync: createCreateShippingMethods } = useCreateShippingMethods();
  const translateMessage = Localization.useTranslateMessage();

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  const handleOnSubmit = async (values, formikBag) => {
    formikBag.setSubmitting(true);
    const { id, name, status } = values;

    try {
      await createCreateShippingMethods({ id, name, status });
      notification.success(translateMessage('shippingMethods_notification_shippingMethodAdded'));
      formikBag.setSubmitting(false);
      formikBag.resetForm();
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <ShippingMethodsFormView
      open={isOpen}
      onClose={handleClose}
      mode={DIALOG_TYPES.ADD}
      initialValues={initialValues}
      onSubmit={handleOnSubmit}
      statusOptions={STATUS_OPTIONS}
    />
  );
};

export default AddShippingMethodsDialaog;
