import { useMemo } from 'react';

import { sanitizePhoneNumber } from '../../../../util/forms';

const useCompanyForm = (
  entitySetupData,
  usersData,
  createEntitySetup,
  updateEntitySetup,
  isExistingData,
  notification,
  translateMessage,
  companyName
) => {
  const initialValues = useMemo(() => {
    const { name, primaryContact, address, phone } = entitySetupData;

    const values = {
      companyName: companyName || '', // Not editable. This is the entity name.
      companyDisplayName: name || '',
      companyPrimaryContact: usersData.find(user => user.value === primaryContact?.profileRef)?.value,
      companyPhone: phone ? sanitizePhoneNumber(phone) : '',
      companyAddress: address,
    };

    return values;
  }, [entitySetupData, usersData, companyName]);

  const handleOnSubmit = async (values, formikBag) => {
    const { companyDisplayName = '', companyPrimaryContact, companyPhone = '', companyAddress } = values;

    formikBag.setSubmitting(true);

    try {
      const entitySetup = {
        name: companyDisplayName || companyName,
        primaryContact: { profileRef: companyPrimaryContact },
        phone: sanitizePhoneNumber(companyPhone),
        address: companyAddress,
      };

      // Setup Service requires id and name for creating entity setup data
      if (!isExistingData) {
        entitySetup.id = companyName;
      }

      if (isExistingData) {
        await updateEntitySetup({ data: entitySetup });
      } else {
        await createEntitySetup({ data: entitySetup });
      }
      notification.success(translateMessage('notification_changesSaved'));

      formikBag.setSubmitting(false);
      formikBag.resetForm();
    } catch (error) {
      console.error(error);
    }
  };

  return { initialValues, handleOnSubmit };
};

export default useCompanyForm;
