import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const H2Styled = styled.h2`
  ${({ theme }) => css`
    color: ${theme.validateMode({ light: theme.colorsCA.neutral[40], dark: theme.colorsCA.neutral[100] })};
    font-size: 22px;
    font-weight: 600;
    line-height: 28px;
  `}
`;

export const ParagraphStyled = styled.p`
  ${({ theme }) => css`
    width: 665px;
    margin-bottom: 30px;
    color: ${theme.validateMode({ light: theme.colorsCA.neutral[40], dark: theme.colorsCA.neutral[100] })};
    font-size: 17px;
    font-weight: 400;
    line-height: 28.5px;
  `}
`;

export const CardContainerStyled = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  `}
`;
