import { Localization } from 'connex-cds';
import { Field, Form } from 'formik';
import { array, bool } from 'prop-types';
import Grid from '@mui/material/Grid';

import { SelectWithFormikField } from '../../../../commons';
import { ProductConcreteClassesContainer } from './styles';

const ProductConcreteClassesTab = ({
  isLoadingConcreteClasses,
  consistenceClassOptions,
  slumpLossClassOptions,
  temperatureClassOptions,
  airClassOptions,
}) => {
  return (
    <ProductConcreteClassesContainer>
      <h3 className="tab-title">
        <Localization.Translate stringId="products_concreteClassesTab_title" />
      </h3>
      <Form>
        <Grid container spacing={5}>
          <Grid item xs={6}>
            <Field
              fullWidth
              testId="select-consistence-class"
              labelStringId="products_concreteClassesTab_consistenceClass"
              infoTooltipStringId="products_concreteClassesTab_consistenceClass_tooltip"
              component={SelectWithFormikField}
              name="consistenceClass"
              disabled={isLoadingConcreteClasses}
              options={consistenceClassOptions}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              fullWidth
              testId="select-slump-loss-class"
              labelStringId="products_concreteClassesTab_slumpLossClass"
              infoTooltipStringId="products_concreteClassesTab_slumpLossClass_tooltip"
              component={SelectWithFormikField}
              name="slumpLossClass"
              disabled={isLoadingConcreteClasses}
              options={slumpLossClassOptions}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              fullWidth
              testId="select-temperature-class"
              labelStringId="products_concreteClassesTab_temperatureClass"
              infoTooltipStringId="products_concreteClassesTab_temperatureClass_tooltip"
              component={SelectWithFormikField}
              name="temperatureClass"
              disabled={isLoadingConcreteClasses}
              options={temperatureClassOptions}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              fullWidth
              testId="select-air-class"
              labelStringId="products_concreteClassesTab_airClass"
              infoTooltipStringId="products_concreteClassesTab_airClass_tooltip"
              component={SelectWithFormikField}
              name="airClass"
              disabled={isLoadingConcreteClasses}
              options={airClassOptions}
            />
          </Grid>
        </Grid>
      </Form>
    </ProductConcreteClassesContainer>
  );
};

ProductConcreteClassesTab.propTypes = {
  isLoadingConcreteClasses: bool.isRequired,
  consistenceClassOptions: array.isRequired,
  slumpLossClassOptions: array.isRequired,
  temperatureClassOptions: array.isRequired,
  airClassOptions: array.isRequired,
};

export default ProductConcreteClassesTab;
