export default {
  uom_INH: 'IN',
  uom_FOT: 'FT',
  uom_YRD: 'YD',
  uom_MMT: 'MM',
  uom_CMT: 'CM',
  uom_MTR: 'M',
  uom_SMI: 'MI',
  uom_KMT: 'KM',
  uom_INK: 'SQ IN',
  uom_FTK: 'FT2',
  uom_YDK: 'SQ YD',
  uom_MIK: 'SQ MI',
  uom_ACR: 'AC',
  uom_MMK: 'MM2',
  uom_CMK: 'CM2',
  uom_MTK: 'M2',
  uom_KMK: 'KM2',
  uom_INQ: 'CU IN',
  uom_FTQ: 'FT3',
  uom_YDQ: 'CY',
  uom_MMQ: 'MM3',
  uom_CMQ: 'CM3',
  uom_MTQ: 'M3',
  uom_OZA: 'FL OZ',
  uom_PT: 'PT',
  uom_QT: 'QT',
  uom_GLL: 'GA',
  uom_LTR: 'L',
  uom_MLT: 'mL',
  uom_ONZ: 'OZ',
  uom_LBR: 'LB',
  uom_STN: 'TN',
  uom_MTN: null,
  uom_GTN: null,
  uom_GRM: 'G',
  uom_KGM: 'KG',
  uom_TNE: 'T',
  uom_PS: 'PSI',
  uom_MPA: 'MPa',
  uom_C56: 'Newtons Por Cuadrado',
  'uom_/NL': 'MPa',
  'uom_/EA': '/EA',
  'uom_/H/ + h': '/Hour',
  'uom_/YQ/ + YDQ': '/CY',
  'uom_/MQ/ + MTQ': '/M3',
  'uom_/MN/ + MIN': '/MIN',
  'uom_/MT': '/M',
  uom_DSG: 'Dósis',
  uom_P1: '%',
  'uom_/C': '/C',
  uom_CEL: 'C',
  uom_FAH: 'F',
  uom_BLL: 'Barril',
  uom_CLT: 'cL',
  uom_DLT: 'dL',
  uom_GLI: 'GAL',
  uom_2U: 'Mg',
  uom_SA: 'Saco',
  uom_NL: 'Carga',
  uom_EA: 'EA',
  uom_60: 'Peso Porcentual',
  uom_BG: 'Bolsa',
  uom_SEC: 'SEC',
  uom_MIN: 'MIN',
  uom_HUR: 'HR',
  uom_64: 'Libras por pulgada cuadrada, Absolutas',
  uom_64_2: 'Específico',
};
