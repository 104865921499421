import { Localization } from 'connex-cds';
import { Field, Form } from 'formik';
import { array, string, func } from 'prop-types';
import Grid from '@mui/material/Grid';

import { TextInputWithFormikField, SelectWithFormikField, CheckboxWithFormikField } from '../../../../commons';
import {
  DIALOG_TYPES,
  CONCRETE_CLASS_TYPE_AIR,
  CONCRETE_CLASS_TYPE_CONSISTENCE,
  CONCRETE_CLASS_TYPE_SLUMP_LOSS_RATE,
  CONCRETE_CLASS_TYPE_TEMPERATURE,
} from '../../../../constants';
import { ConcreteClassProfileTabContainer } from './styles';

const showConcreteClassSubtypeField = value => {
  return value === CONCRETE_CLASS_TYPE_CONSISTENCE;
};

const showConcreteClassUOMField = value => {
  return [
    CONCRETE_CLASS_TYPE_CONSISTENCE,
    CONCRETE_CLASS_TYPE_SLUMP_LOSS_RATE,
    CONCRETE_CLASS_TYPE_TEMPERATURE,
  ].includes(value);
};

const showConcreteClassNominalValueField = value => {
  return [CONCRETE_CLASS_TYPE_AIR, CONCRETE_CLASS_TYPE_CONSISTENCE, CONCRETE_CLASS_TYPE_SLUMP_LOSS_RATE].includes(
    value
  );
};

const showConcreteClassMinimumAndMaximumValueField = value => {
  return [CONCRETE_CLASS_TYPE_AIR, CONCRETE_CLASS_TYPE_CONSISTENCE, CONCRETE_CLASS_TYPE_TEMPERATURE].includes(value);
};

const ConcreteClassProfileTab = ({
  concreteClassesStatusOptions,
  mode,
  concreteClassesTypeOptions,
  concreteClassType,
  concreteClassesSubtypeOptions,
  uomTemperatureOptions,
  uomLengthOptions,
  setFieldValue,
}) => {
  const getUOMOptions = value => {
    let uomOptions = [];
    if ([CONCRETE_CLASS_TYPE_CONSISTENCE, CONCRETE_CLASS_TYPE_SLUMP_LOSS_RATE].includes(value)) {
      uomOptions = uomLengthOptions;
    } else if (value === CONCRETE_CLASS_TYPE_TEMPERATURE) {
      uomOptions = uomTemperatureOptions;
    }

    return uomOptions;
  };

  const handleClassTypeChange = setFieldValue => (name, value) => {
    // Reset concreteClassUOM if classType has different UOM options
    if (
      (value === CONCRETE_CLASS_TYPE_TEMPERATURE &&
        [CONCRETE_CLASS_TYPE_CONSISTENCE, CONCRETE_CLASS_TYPE_SLUMP_LOSS_RATE].includes(concreteClassType)) ||
      ([CONCRETE_CLASS_TYPE_CONSISTENCE, CONCRETE_CLASS_TYPE_SLUMP_LOSS_RATE].includes(value) &&
        concreteClassType === CONCRETE_CLASS_TYPE_TEMPERATURE)
    ) {
      setFieldValue('concreteClassUOM', '');
    }

    setFieldValue(name, value);
  };

  return (
    <ConcreteClassProfileTabContainer>
      <h3 className="concrete-classes-profile-title">
        <Localization.Translate stringId="concreteClasses_profileTab_title" />
      </h3>

      <Form className="concrete-classes-profile-form">
        <Grid container spacing={5}>
          <Grid item xs={6}>
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <Field
                  fullWidth
                  labelStringId="concreteClasses_profileTab_concreteClassId"
                  component={TextInputWithFormikField}
                  variant="outlined"
                  name="concreteClassId"
                  tooltipInfoStringId="concreteClasses_profileTab_concreteClassId_tooltip"
                  required
                  disabled={mode === DIALOG_TYPES.EDIT}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  fullWidth
                  labelStringId="concreteClasses_profileTab_concreteClassName"
                  component={TextInputWithFormikField}
                  variant="outlined"
                  name="concreteClassName"
                  required
                />
              </Grid>
              {concreteClassType && showConcreteClassSubtypeField(concreteClassType) && (
                <Grid item xs={12}>
                  <Field
                    fullWidth
                    testId="select-class-subtype"
                    labelStringId="concreteClasses_profileTab_concreteClassSubtype"
                    component={SelectWithFormikField}
                    name="concreteClassSubtype"
                    options={concreteClassesSubtypeOptions}
                  />
                </Grid>
              )}
              <Grid item sx={{ marginLeft: 2 }} xs={12}>
                <Field
                  labelStringId="concreteClasses_profileTab_defaultSettings"
                  component={CheckboxWithFormikField}
                  name="concreteClassesDefaultSettings"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <Field
                  fullWidth
                  testId="select-class-type"
                  labelStringId="concreteClasses_profileTab_concreteClassType"
                  component={SelectWithFormikField}
                  name="concreteClassType"
                  options={concreteClassesTypeOptions}
                  required
                  customOnChange={handleClassTypeChange(setFieldValue)}
                />
              </Grid>
              {concreteClassType && showConcreteClassUOMField(concreteClassType) && (
                <Grid item xs={12}>
                  <Field
                    fullWidth
                    testId="select-batch-uom"
                    labelStringId="concreteClasses_profileTab_concreteClassUOM"
                    component={SelectWithFormikField}
                    name="concreteClassUOM"
                    options={getUOMOptions(concreteClassType)}
                    required
                  />
                </Grid>
              )}
              {concreteClassType && showConcreteClassNominalValueField(concreteClassType) && (
                <Grid item xs={12}>
                  <Field
                    fullWidth
                    testId="select-nominal-value"
                    type="number"
                    labelStringId="concreteClasses_profileTab_concreteClassNominalValue"
                    tooltipInfoStringId="concreteClasses_profileTab_nominalValue_tooltip"
                    component={TextInputWithFormikField}
                    inputProps={{ min: 0 }}
                    name="concreteClassNominalValue"
                  />
                </Grid>
              )}
              {concreteClassType && showConcreteClassMinimumAndMaximumValueField(concreteClassType) && (
                <Grid item xs={12}>
                  <Field
                    fullWidth
                    testId="select-minimum-value"
                    type="number"
                    labelStringId="concreteClasses_profileTab_concreteClassMinimumValue"
                    component={TextInputWithFormikField}
                    inputProps={{ min: 0 }}
                    name="concreteClassMinimumValue"
                  />
                </Grid>
              )}
              {concreteClassType && showConcreteClassMinimumAndMaximumValueField(concreteClassType) && (
                <Grid item xs={12}>
                  <Field
                    fullWidth
                    testId="select-maximum-value"
                    type="number"
                    labelStringId="concreteClasses_profileTab_concreteClassMaximumValue"
                    component={TextInputWithFormikField}
                    inputProps={{ min: 0 }}
                    name="concreteClassMaximumValue"
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <Field
                  fullWidth
                  labelStringId="concreteClasses_profileTab_concreteClassStatus"
                  component={SelectWithFormikField}
                  name="concreteClassStatus"
                  options={concreteClassesStatusOptions}
                  infoTooltipStringId="concreteClasses_profileTab_concreteClassesStatus_tooltip"
                  testId="select-concrete-class-status"
                  required
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Form>
    </ConcreteClassProfileTabContainer>
  );
};

ConcreteClassProfileTab.propTypes = {
  concreteClassesStatusOptions: array.isRequired,
  mode: string.isRequired,
  uomTemperatureOptions: array.isRequired,
  uomLengthOptions: array.isRequired,
  concreteClassesTypeOptions: array.isRequired,
  concreteClassType: string.isRequired,
  concreteClassesSubtypeOptions: array.isRequired,
  setFieldValue: func.isRequired,
};

ConcreteClassProfileTab.defaultProps = {
  concreteClassType: '',
};

export default ConcreteClassProfileTab;
