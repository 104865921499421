import React from 'react';
import { Localization } from 'connex-cds';
import { ReactComponent as NoDataImage } from '../../../../assets/no-data-image.svg';
import { NoDataContainer, NoDataHeader, NoDataText } from './styles';

const InventoryProductLocationsNoData = () => (
  <NoDataContainer>
    <NoDataImage />
    <NoDataHeader>
      <Localization.Translate stringId="inventoryProducts_supply_noData" />
    </NoDataHeader>
    <NoDataText>
      <Localization.Translate stringId="inventoryProducts_supply_noDataText" />
    </NoDataText>
  </NoDataContainer>
);

export default InventoryProductLocationsNoData;
