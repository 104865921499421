import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const ProductPricingContainer = styled.div`
  ${css`
    .tab-title {
      font-size: 24px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.15px;
      margin-bottom: 25px;
    }

    .MuiDataGrid-cell {
      .MuiInputBase-root {
        width: 150px;
      }
    }
  `}
`;
