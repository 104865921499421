import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const PriceBookPricesContainer = styled.div`
  ${css`
    .price-book-profile-title {
      font-size: 24px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.15px;
      margin-bottom: 20px;
    }
    .pricebook-product-description {
      font-size: 16px;
    }
    .pricebook-product-list-search-filter {
      & > div {
        & > span {
          display: none;
        }
      }
      .MuiOutlinedInput-notchedOutline {
        border-radius: 4px;
      }
    }
  `}
`;
