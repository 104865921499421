import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { DataGridPro } from '@mui/x-data-grid-pro';

// TODO: Validate focus style as focus is used for accessibility

export const DataGridStyled = styled(DataGridPro)`
  ${({ theme, onRowClick }) => css`
    border: none;
    font-weight: 500;

    & > * {
      font-size: 16px;
      line-height: 24px;
    }

    .MuiDataGrid-columnSeparator {
      display: none;
    }

    .MuiDataGrid-columnHeaders {
      border: none;
    }

    .MuiDataGrid-columnHeaderTitle {
      font-weight: 400;
    }

    .MuiDataGrid-columnHeader,
    .MuiDataGrid-cell {
      border: none;
      &:focus,
      &:focus-within {
        outline: none;
      }
    }

    .MuiDataGrid-virtualScroller {
      overflow-x: hidden;
      overflow-y: auto;
      padding: 0 6px 20px 6px;
    }

    .MuiDataGrid-row {
      border: 1px solid #c4c4d0;
      border-radius: 12px;
      background-color: ${theme.validateMode({ light: theme.colorsCA.neutral[100] })};
      width: calc(100% - 13px);
      ${theme.validateMode({ light: `box-shadow: ${theme.shadowCA(2)} ;`, dark: theme.elevationCA[4] })};

      .MuiDataGrid-cell:first-of-type {
        padding-left: 24px;
      }
      .MuiDataGrid-cell:last-of-type {
        padding-right: 24px;
      }

      &:hover {
        cursor: ${!onRowClick ? 'initial' : 'pointer'};
        background-color: ${theme.validateMode({
          light: theme.colorsCA.neutral[95],
          dark: theme.colorsCA.neutral[40],
        })};
      }

      &.Mui-selected {
        background-color: ${theme.validateMode({
          light: theme.colorsCA.blue[95],
          dark: theme.colorsCA.darkBlue[60],
        })};
        ${theme.validateMode({ dark: `color: ${theme.colorsCA.blue[95]}` })}
      }
    }

    .table-actions {
      align-items: center;
      justify-content: center;
    }
  `}
`;

export const SkeletonContainer = styled.div`
  ${() => css`
    .header-skeleton {
      border-radius: 12px;
      margin-bottom: 14px;
      height: 20px;
    }

    .rows-skeleton-container {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .row-skeleton {
        height: 80px;
        border-radius: 12px;
      }
    }
  `}
`;
