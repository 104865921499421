export const parseMixComponentsOptions = data => {
  return (
    data?.map(item => ({
      value: item?.crn,
      label: item?.name || '',
      uomCode: item?.uomCode || '',
      name: item?.name || '',
      weight: item?.weight || 0,
      id: item?.id,
      batchUomCode: item?.batchUomCode || '',
    })) || []
  );
};
