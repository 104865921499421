import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const OrderStatusProfileFormContainer = styled.div`
  ${css`
    .custom-form-control-checkbox {
      .MuiFormControlLabel-root {
        padding-left: 12px;
      }
    }
  `}
`;
