import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Stack } from '@mui/material';

export const SwitchLabelStyled = styled(Stack)(
  ({ theme }) => css`
    flex-direction: row;
    align-items: center;

    .MuiTypography-root {
      text-align: right;
      margin-left: 10px;
      font-family: Work Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      color: ${theme.validateMode({ light: theme.colorsCA.neutral[40], dark: theme.colorsCA.neutral[70] })};
    }
  `
);
