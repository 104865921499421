import { useState, useMemo } from 'react';
import { Localization, useNotification } from 'connex-cds';
import { func, object } from 'prop-types';

import { maskPhoneNumber, sanitizePhoneNumber } from '../../../../util/forms';
import { useDrivers } from '../../../../api/hooks';
import { DIALOG_TYPES, STATUS_OPTIONS } from '../../../../constants';
import DriverFormView from '../driver-form-view';

const EditDriverDialog = ({ onClose, driverData, handleOnDelete }) => {
  const [isOpen, setIsOpen] = useState(true);
  const { Update: useUpdateDriver } = useDrivers();
  const { mutateAsync: updateDriver } = useUpdateDriver();
  const notification = useNotification();
  const translateMessage = Localization.useTranslateMessage();

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  const handleOnSubmit = async (values, formikBag) => {
    const { driverFirstName, driverLastName, driverMobile, driverPhone, driverEmail, driverStatus } = values;

    formikBag.setSubmitting(true);

    try {
      const driver = {
        firstName: driverFirstName,
        lastName: driverLastName,
        email: driverEmail,
        mobile: sanitizePhoneNumber(driverMobile),
        phone: sanitizePhoneNumber(driverPhone),
        status: driverStatus,
      };

      await updateDriver({ driver, driverRef: driverData?.crn });
      notification.success(translateMessage('notification_changesSaved'));

      formikBag.setSubmitting(false);
      formikBag.resetForm();
      handleClose();
    } catch (error) {
      console.error(error);
    }
  };

  const getInitialValues = useMemo(() => {
    if (!driverData) return null;
    const { id, firstName, lastName, mobile, phone, email } = driverData;

    const editValues = {
      driverId: id,
      driverFirstName: firstName,
      driverLastName: lastName,
      driverMobile: mobile ? maskPhoneNumber(sanitizePhoneNumber(mobile)) : '',
      driverPhone: phone ? maskPhoneNumber(sanitizePhoneNumber(phone)) : '',
      driverEmail: email || '',
      driverStatus: STATUS_OPTIONS.find(status => status.value === driverData.status).value,
    };

    return editValues;
  }, [driverData]);

  return (
    <DriverFormView
      open={isOpen}
      onClose={handleClose}
      mode={DIALOG_TYPES.EDIT}
      initialValues={getInitialValues}
      onSubmit={handleOnSubmit}
      statusOptions={STATUS_OPTIONS}
      handleOnDelete={handleOnDelete}
    />
  );
};

EditDriverDialog.propTypes = {
  driverData: object,
  onClose: func.isRequired,
  handleOnDelete: func,
};

EditDriverDialog.defaultProps = {
  onClose: () => {},
};

export default EditDriverDialog;
