import * as yup from 'yup';

import { STATUS_OPTIONS } from '../../../../constants';

export const validationSchema = yup.object().shape({
  id: yup.string().required('required'),
  name: yup.string().required('required'),
  status: yup.string().required('required'),
});

export const initialValues = {
  id: '',
  name: '',
  status: STATUS_OPTIONS[0].value,
};
