import React from 'react';
import { Field, Form } from 'formik';
import { string } from 'prop-types';
import Grid from '@mui/material/Grid';

import { DIALOG_TYPES, STATUS_OPTIONS } from '../../../../constants';
import { TextInputWithFormikField, SelectWithFormikField, CheckboxWithFormikField } from '../../../../commons';

import { OrderStatusProfileFormContainer } from './styles';

const OrderStatusProfileTab = ({ mode }) => {
  return (
    <OrderStatusProfileFormContainer>
      <Form>
        <Grid container spacing={5} alignItems="baseline">
          <Grid item xs={12} md={6}>
            <Field
              fullWidth
              labelStringId="orderStatuses_profileTab_fieldOrderStatusId_label"
              component={TextInputWithFormikField}
              name="id"
              tooltipInfoStringId="orderStatuses_profileTab_fieldOrderStatusId_tooltip"
              required
              disabled={mode === DIALOG_TYPES.EDIT}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              labelStringId="status"
              component={SelectWithFormikField}
              name="status"
              options={STATUS_OPTIONS}
              infoTooltipStringId="orderStatuses_profileTab_fieldStatus_tooltip"
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              fullWidth
              labelStringId="orderStatuses_profileTab_fieldOrderStatusName_label"
              component={TextInputWithFormikField}
              name="name"
              required
            />
          </Grid>
          <Grid item xs={12} className="custom-form-control-checkbox">
            <Field
              fullWidth
              labelStringId="general_default"
              infoTooltipStringId="orderStatuses_profileTab_fieldDefaultValue_tooltip"
              component={CheckboxWithFormikField}
              name="defaultValue"
              className="custom-form-control-checkbox"
            />
          </Grid>
          <Grid item xs={12} className="custom-form-control-checkbox">
            <Field
              fullWidth
              labelStringId="orderStatuses_profileTab_fieldEnableTicketing_label"
              infoTooltipStringId="orderStatuses_profileTab_fieldEnableTicketing_tooltip"
              component={CheckboxWithFormikField}
              name="enableTicketing"
            />
          </Grid>
          <Grid item xs={12} className="custom-form-control-checkbox">
            <Field
              fullWidth
              labelStringId="orderStatuses_profileTab_fieldDisplayInPlanning_label"
              component={CheckboxWithFormikField}
              name="displayInPlanning"
              className="custom-form-control-checkbox"
            />
          </Grid>
        </Grid>
      </Form>
    </OrderStatusProfileFormContainer>
  );
};

OrderStatusProfileTab.propTypes = {
  mode: string.isRequired,
};

export default OrderStatusProfileTab;
