import { useMemo } from 'react';
import { Formik } from 'formik';
import { defaults, pick } from 'lodash';
import { Localization, usePostMessageContext } from 'connex-cds';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Button from '@mui/material/Button';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import WorkOutlineRoundedIcon from '@mui/icons-material/WorkOutlineRounded';
import DriveFileRenameOutlineRoundedIcon from '@mui/icons-material/DriveFileRenameOutlineRounded';

import { DialogTitle } from '../../../../commons';
import EditContactInfoTab from '../edit-contact-info-tab';
import EditRoleTab from '../edit-role-tab';
import { maskPhoneNumber, sanitizePhoneNumber } from '../../../../util/forms';
import { useUsers } from '../../../../api/hooks';
import { validationSchema } from './form-config';

import { ContainerStyled, DialogActionsStyled, DialogContentStyled } from './styles';

const EditUserView = ({ userData, currentTab, setCurrentTab, onClose }) => {
  const { sendMessage } = usePostMessageContext();
  const { Update: useUsersUpdate } = useUsers();
  const { mutateAsync: updateUser } = useUsersUpdate();

  const initialValues = useMemo(() => {
    let values = {};

    if (userData) {
      values = pick(userData, ['firstName', 'lastName', 'email', 'phone', 'role']);
      values.phone = maskPhoneNumber(sanitizePhoneNumber(values.phone ?? ''));
      values.role = { roleRef: values.role?.roleRef ?? '' };
    }
    return defaults(values, {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      role: {
        roleRef: '',
      },
    });
  }, [userData]);

  const handleTabChange = (_, value) => {
    setCurrentTab(value);
  };

  const handleSubmit = async values => {
    try {
      await updateUser({ ...values, phone: sanitizePhoneNumber(values.phone), profileRef: userData.crn });
      onClose({ success: true });
      sendMessage({ type: 'save-profile-from-list' });
    } catch (error) {
      // TODO: user feedback on request error
      console.error('ERROR::', error);
    }
  };

  const EDIT_USER_TABS = [
    {
      stringId: 'editUserDialog_tabs_contactInfo',
      component: EditContactInfoTab,
      icon: <DriveFileRenameOutlineRoundedIcon />,
    },
    {
      stringId: 'general_role',
      component: EditRoleTab,
      icon: <WorkOutlineRoundedIcon />,
    },
  ];

  return (
    <ContainerStyled>
      <DialogTitle onClose={onClose}>
        <EditOutlinedIcon className="edit-icon" />
        <Localization.Translate stringId="editUserDialog_editUser" />
      </DialogTitle>
      <Tabs className="edit-user-dialog-tabs" value={currentTab} onChange={handleTabChange} variant="fullWidth">
        {EDIT_USER_TABS.map(({ stringId, icon }) => (
          <Tab key={stringId} icon={icon} label={<Localization.Translate stringId={stringId} />} />
        ))}
      </Tabs>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, dirty, isValid, handleSubmit, setFieldValue }) => {
          const TabComponent = EDIT_USER_TABS[currentTab].component;

          return (
            <>
              <DialogContentStyled dividers>{<TabComponent setFieldValue={setFieldValue} />}</DialogContentStyled>
              <DialogActionsStyled>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={isSubmitting || !dirty || !isValid}
                  onClick={handleSubmit}
                >
                  <Localization.Translate stringId="editUserDialog_tabs_contactInfo_save" />
                </Button>
              </DialogActionsStyled>
            </>
          );
        }}
      </Formik>
    </ContainerStyled>
  );
};

export default EditUserView;
