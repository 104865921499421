import { useMemo, useState } from 'react';
import { Localization, useNotification } from 'connex-cds';

import ShippingMethodsFormView from '../shipping-methods-form-view';
import { DIALOG_TYPES, STATUS_OPTIONS } from '../../../../constants';
import { useShippingMethods } from '../../../../api/hooks';

const EditShippingMethodsDialog = ({ onClose, itemData, handleOnDelete }) => {
  const [isOpen, setIsOpen] = useState(true);
  const notification = useNotification();
  const { Update: useUpdateShippingMethods } = useShippingMethods();
  const { mutateAsync: updateCreateShippingMethods } = useUpdateShippingMethods();
  const translateMessage = Localization.useTranslateMessage();

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  const handleOnSubmit = async (values, formikBag) => {
    formikBag.setSubmitting(true);
    const { name, status } = values;

    try {
      await updateCreateShippingMethods({ data: { name, status }, shippingMethodRef: itemData.crn });
      notification.success(translateMessage('shippingMethods_notification_shippingMethodUpdated'));
      formikBag.setSubmitting(false);
      formikBag.resetForm();
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  const getInitialValues = useMemo(() => {
    if (!itemData) return null;
    const { id, name, status } = itemData;

    const editValues = {
      id,
      name,
      status: STATUS_OPTIONS.find(item => item.value === status).value,
    };

    return editValues;
  }, [itemData]);

  return (
    <ShippingMethodsFormView
      open={isOpen}
      onClose={handleClose}
      mode={DIALOG_TYPES.EDIT}
      initialValues={getInitialValues}
      onSubmit={handleOnSubmit}
      statusOptions={STATUS_OPTIONS}
      handleOnDelete={handleOnDelete}
    />
  );
};

export default EditShippingMethodsDialog;
