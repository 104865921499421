import React from 'react';
import { Localization } from 'connex-cds';
import { Grid, Stack } from '@mui/material';
import { Field, Form } from 'formik';

import { SelectWithFormikField, TextInputWithFormikField, NumberInputWithFormikField } from '../../../../commons';
import { MixProfileFormContainer } from './styles';
import { DIALOG_TYPES } from '../../../../constants';

const MixProfileTab = ({ mode, mixDesignStatusOptions, uomOptions, isLoadingUom }) => {
  return (
    <MixProfileFormContainer>
      <h3 className="tab-title">
        <Localization.Translate stringId="mixDesigns_profileTab_title" />
      </h3>
      <Form>
        <Grid container spacing={5}>
          <Grid xs={6} item>
            <Stack gap={5}>
              <Field
                labelStringId="mixDesigns_profileTab_id_field_label"
                component={TextInputWithFormikField}
                variant="outlined"
                name="mixDesignId"
                required
                fullWidth
                disabled={mode === DIALOG_TYPES.EDIT}
              />
              <Field
                labelStringId="mixDesigns_profileTab_name_field_label"
                component={TextInputWithFormikField}
                variant="outlined"
                name="mixDesignName"
                required
                fullWidth
              />
              <Field
                labelStringId="mixDesigns_profileTab_status_field_label"
                component={SelectWithFormikField}
                name="mixDesignStatus"
                options={mixDesignStatusOptions}
                infoTooltipStringId="mixDesigns_profileTab_status_field_tooltip"
                required
                fullWidth
              />
            </Stack>
          </Grid>
          <Grid xs={6} item>
            <Stack gap={5}>
              <Stack direction="row" gap={5}>
                <Field
                  labelStringId="mixDesigns_profileTab_max_batch_size_field_label"
                  component={NumberInputWithFormikField}
                  variant="outlined"
                  name="maxBatchSize"
                  required
                  fullWidth
                />
                <Field
                  labelStringId="mixDesigns_profileTab_max_batch_uom_field_label"
                  component={SelectWithFormikField}
                  name="maxBatchUom"
                  disabled={isLoadingUom}
                  options={uomOptions}
                  fullWidth
                  required
                />
              </Stack>
              <Field
                labelStringId="mixDesigns_profileTab_mix_formula_id_field_label"
                tooltipInfoStringId="mixDesigns_profileTab_mix_formula_id_field_label_tooltip"
                component={TextInputWithFormikField}
                variant="outlined"
                name="mixFormulaId"
                required
                fullWidth
              />
            </Stack>
          </Grid>
        </Grid>
      </Form>
    </MixProfileFormContainer>
  );
};

export default MixProfileTab;
