import PropTypes from 'prop-types';
import { Localization } from 'connex-cds';

import CustomIcon from '../custom-icon';
import { ButtonGroupStyled, ButtonLabelText, ButtonWithPrev } from './styles';
import ImagePreview from '../image-preview';

const UploadImageButton = props => {
  const { imgSrc, ...orhersProps } = props;
  return (
    <ButtonWithPrev>
      <ButtonLabelText>
        <Localization.Translate stringId="company_form_logo" />
      </ButtonLabelText>
      <ButtonGroupStyled {...orhersProps} size="large" aria-label="large button group">
        <div className="btn-icon">
          <CustomIcon size="small" iconName="cameraAltOutlinedIcon" />
        </div>
        <div className="preview">
          {imgSrc ? (
            <ImagePreview size="xsmall" src={imgSrc} />
          ) : (
            <CustomIcon size="medium" iconName="businessOutlinedIcon" />
          )}
        </div>
      </ButtonGroupStyled>
    </ButtonWithPrev>
  );
};

UploadImageButton.propTypes = {
  onClick: PropTypes.func,
  color: PropTypes.string,
  token: PropTypes.string,
  imgSrc: PropTypes.string,
  disabled: PropTypes.bool,
};

export default UploadImageButton;
