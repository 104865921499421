import React, { useCallback } from 'react';
import { Grid } from '@mui/material';
import { Localization } from 'connex-cds';
import { useDropzone } from 'react-dropzone';

import { DragArea, LoadImageProfileContainer } from './styles';
import { ActionButton, CustomIcon } from '../../../../../commons';

const LoadImageView = ({ setNewImage }) => {
  const translateMessage = Localization.useTranslateMessage();

  const onDrop = useCallback(
    files => {
      const file = files[0];
      if (file) {
        if (file.size > 5 * 1024 * 1024) {
          alert(translateMessage('default_uploadImage_file_size_error'));
          return;
        }

        setNewImage(URL.createObjectURL(file));
      }
    },
    [setNewImage, translateMessage]
  );
  const { getRootProps, getInputProps, isDragActive, inputRef } = useDropzone({ onDrop });

  return (
    <LoadImageProfileContainer>
      <Grid container direction="column" alignItems="center" spacing={2}>
        <Grid className="drag-container" item>
          <DragArea isDragActive={isDragActive} {...getRootProps({ onClick: event => event.stopPropagation() })}>
            <input
              {...getInputProps({
                accept: 'image/jpg, image/jpeg, image/png',
                multiple: false,
              })}
            />
            <div
              className="instructions"
              sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}
            >
              <div className="icon-container">
                <CustomIcon className="icon" iconName="filterIcon" size="xlarge" />
              </div>
              <p className="drag-description">
                <Localization.Translate stringId="default_uploadImage_drag_photo_description" />
              </p>
              <p className="drag-description_or">
                <Localization.Translate stringId="default_uploadImage_drag_photo_or" />
              </p>
              <ActionButton
                icon="computerIcon"
                label={<Localization.Translate stringId="default_uploadImage_upload_from_computer_btn" />}
                onClick={() => inputRef.current.click()}
              />
            </div>
          </DragArea>
        </Grid>
      </Grid>
    </LoadImageProfileContainer>
  );
};

export default LoadImageView;
