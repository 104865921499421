import { Localization, useNotification } from 'connex-cds';
import { useMemo, useState } from 'react';
import { useCancelReasons } from '../../../../api/hooks';
import CancelReasonFormView from '../cancel-reason-form-view';
import { DIALOG_TYPES, STATUS_OPTIONS } from '../../../../constants';
import { CANCEL_REASON_TYPES_OPTIONS } from '../cancel-reason-form-view/form-config';

const EditCancelReasonDialog = ({ onClose, itemData, handleOnDelete }) => {
  const [isOpen, setIsOpen] = useState(true);
  const notification = useNotification();
  const { Update: useCancelReason } = useCancelReasons();
  const { mutateAsync: updateCancelReason } = useCancelReason();
  const translateMessage = Localization.useTranslateMessage();

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  const handleOnSubmit = async (values, formikBag) => {
    const { name, status, type } = values;

    formikBag.setSubmitting(true);

    try {
      const data = {
        name,
        status,
        type,
      };

      await updateCancelReason({ data, cancelReasonRef: itemData.crn });
      notification.success(translateMessage('cancelReasons_notification_cancelReasonUpdated'));

      formikBag.setSubmitting(false);
      formikBag.resetForm();
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  const getInitialValues = useMemo(() => {
    if (!itemData) return null;
    const { id, name, status, type } = itemData;

    const editValues = {
      id,
      name,
      status: STATUS_OPTIONS.find(item => item.value === status)?.value,
      type: CANCEL_REASON_TYPES_OPTIONS.find(item => item.value === type)?.value,
    };

    return editValues;
  }, [itemData]);

  return (
    <CancelReasonFormView
      open={isOpen}
      onClose={handleClose}
      mode={DIALOG_TYPES.EDIT}
      initialValues={getInitialValues}
      onSubmit={handleOnSubmit}
      statusOptions={STATUS_OPTIONS}
      cancelReasonTypesOptions={CANCEL_REASON_TYPES_OPTIONS}
      handleOnDelete={handleOnDelete}
    />
  );
};

export default EditCancelReasonDialog;
