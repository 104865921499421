import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Card } from '@mui/material';

export const CardStyled = styled(Card)(
  ({ theme }) => css`
    width: 360px;
    box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
    border-radius: 12px;
    border: 1px solid ${theme.validateMode({ light: '#cac4d0', dark: '#49454F' })};
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .MuiCardHeader-root {
      flex-direction: row;
      align-items: center;

      .title-icon {
        margin: 8px;
      }

      .MuiTypography-root {
        color: ${theme.validateMode({ light: theme.colorsCA.neutral[10], dark: theme.colorsCA.neutral[95] })};
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.15px;
      }

      .state-info {
        margin-top: 4px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;

        .icon {
          width: 15px;
          height: 15px;
        }

        .MuiTypography-root {
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0.25px;
        }
      }
    }

    .description {
      font-size: 14px;
      color: ${theme.validateMode({ light: '#49454F', dark: theme.colorsCA.neutral[95] })};
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.25px;
    }

    .MuiCardActions-root {
      padding: 16px;
      justify-content: flex-end;

      .MuiButtonBase-root {
        font-size: 14px;
        border: 1px solid ${theme.validateMode({ dark: '#938F99' })};
        background: ${theme.validateMode({ dark: theme.colorsCA.neutral[10] })};
        color: ${theme.validateMode({ light: theme.colorsCA.darkBlue[10], dark: theme.colorsCA.blue[80] })};

        &:hover {
          background: ${theme.validateMode({
            light: `linear-gradient(0deg, rgba(0, 43, 84, 0.05) 0%, rgba(0, 43, 84, 0.05) 100%), ${theme.colorsCA.neutral[99]}`,
            dark: 'linear-gradient(0deg, rgba(85, 187, 233, 0.05) 0%, rgba(85, 187, 233, 0.05) 100%)',
          })};
        }
      }
    }
  `
);
