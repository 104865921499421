import { Localization } from 'connex-cds';

import { useConcreteClasses } from '../../api/hooks';
import AddConcreteClassDialog from './components/add-concrete-class-dialog';
import EditConcreteClassDialog from './components/edit-concrete-class-dialog';
import DeleteConcreteClassDialog from './components/delete-concrete-class-dialog';
import { EntityTable, SearchFilter, TableLayout, DialogManager, ActionButton } from '../../commons';
import { getConcreteClassesListColumns } from './tableConfig';
import { DIALOG_TYPES } from '../../constants';
import { useTableLogic } from '../../hooks';

const ConcreteClasses = () => {
  const filteredPropsArr = ['id', 'name'];
  const {
    setQuery,
    currentEntity,
    dialog,
    setDialog,
    isLoading,
    filteredData,
    entityColumns,
    resetDialogs,
    handleOnRowClick,
    setIsOnEditDialog,
  } = useTableLogic(useConcreteClasses, getConcreteClassesListColumns, filteredPropsArr);

  return (
    <TableLayout.ListLayoutContainer>
      <TableLayout.TableControls>
        <SearchFilter onSearchChange={setQuery} disabled={isLoading} resultsCount={filteredData.length} />
        <ActionButton
          icon="addOutlinedIcon"
          label={<Localization.Translate stringId="concreteClasses_addConcreteClass_title" />}
          variant="outlined"
          onClick={() => setDialog(DIALOG_TYPES.ADD)}
        />
      </TableLayout.TableControls>
      <TableLayout.TableBody>
        <EntityTable
          checkboxSelection={false}
          getRowId={row => row.crn}
          rows={filteredData}
          columns={entityColumns}
          isLoading={isLoading}
          onRowClick={handleOnRowClick}
        />
      </TableLayout.TableBody>
      <DialogManager screen={dialog}>
        <AddConcreteClassDialog identifier={DIALOG_TYPES.ADD} onClose={resetDialogs} />
        <EditConcreteClassDialog
          identifier={DIALOG_TYPES.EDIT}
          handleOnDelete={() => {
            setIsOnEditDialog(true);
            setDialog(DIALOG_TYPES.DELETE);
          }}
          onClose={resetDialogs}
          concreteClassData={currentEntity}
        />
        ;
        <DeleteConcreteClassDialog
          identifier={DIALOG_TYPES.DELETE}
          onClose={resetDialogs}
          concreteClassData={currentEntity}
        />
      </DialogManager>
    </TableLayout.ListLayoutContainer>
  );
};

export default ConcreteClasses;
