import { http } from 'connex-cds';

export default {
  listInventoryProducts: ({ entityRef, queryParams }) =>
    http.get({ apiName: 'company-admin', path: `/${entityRef}/inventory-products`, queryParams }),
  createInventoryProduct: ({ entityRef, inventoryProduct }) =>
    http.post({
      apiName: 'company-admin',
      path: `/${entityRef}/inventory-products`,
      data: inventoryProduct,
    }),
  updateInventoryProduct: ({ entityRef, inventoryProductRef, inventoryProduct }) =>
    http.patch({
      apiName: 'company-admin',
      path: `/${entityRef}/inventory-products/${inventoryProductRef}`,
      data: inventoryProduct,
    }),
};
