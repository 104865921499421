import { useState } from 'react';
import { Localization, useNotification } from 'connex-cds';
import { func } from 'prop-types';

import { DIALOG_TYPES, STATUS_OPTIONS } from '../../../../constants';
import { useTrailers } from '../../../../api/hooks';
import TrailerFormView from '../trailer-form-view';
import { initialValues } from '../trailer-form-view/form-config';

const AddTrailerDialog = ({ onClose }) => {
  const [isOpen, setIsOpen] = useState(true);
  const notification = useNotification();
  const { Create: useCreateTrailer } = useTrailers();
  const { mutateAsync: createTrailer } = useCreateTrailer();
  const translateMessage = Localization.useTranslateMessage();

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  const handleOnSubmit = async (values, formikBag) => {
    formikBag.setSubmitting(true);

    try {
      await createTrailer({ trailer: { ...values, status: values.status } });
      notification.success(translateMessage('trailers_notification_trailerAdded'));
      formikBag.setSubmitting(false);
      formikBag.resetForm();
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <TrailerFormView
      open={isOpen}
      onClose={handleClose}
      mode={DIALOG_TYPES.ADD}
      initialValues={initialValues}
      onSubmit={handleOnSubmit}
      statusOptions={STATUS_OPTIONS}
    />
  );
};

AddTrailerDialog.propTypes = {
  onClose: func.isRequired,
};

AddTrailerDialog.defaultProps = {
  onClose: () => {},
};

export default AddTrailerDialog;
