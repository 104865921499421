import { http } from 'connex-cds';

export default {
  listCancelReasons: ({ entityRef, queryParams }) =>
    http.get({ apiName: 'company-admin', path: `/${entityRef}/cancel-reasons`, queryParams }),
  createCancelReasons: ({ entityRef, data }) =>
    http.post({
      apiName: 'company-admin',
      path: `/${entityRef}/cancel-reasons`,
      data,
    }),
  updateCancelReason: ({ entityRef, cancelReasonRef, data }) =>
    http.patch({
      apiName: 'company-admin',
      path: `/${entityRef}/cancel-reasons/${cancelReasonRef}`,
      data,
    }),
};
