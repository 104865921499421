import { Localization } from 'connex-cds';
import { string } from 'prop-types';

import { EntityTableStyled, EntityTableBorderlessHeaderStyled } from './styles';

const DialogTable = ({ variant, ...entityTableProps }) => {
  let EntityTable = EntityTableStyled;
  if (variant === 'borderless-header') {
    EntityTable = EntityTableBorderlessHeaderStyled;
  }

  return (
    <EntityTable
      columnHeaderHeight={38}
      rowHeight={40}
      getRowSpacing={params => 0}
      hideFooter={false}
      slots={{
        footerRowCount: ({ rowCount }) => {
          return (
            <div className="table-row-count">
              <Localization.Translate
                stringId="dialogRowCount"
                values={{
                  count: rowCount,
                }}
              />
            </div>
          );
        },
      }}
      {...entityTableProps}
    />
  );
};

DialogTable.propTypes = {
  variant: string,
};

DialogTable.defaultProps = {
  variant: '',
};

export default DialogTable;
