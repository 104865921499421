import * as yup from 'yup';

import { STATUS_OPTIONS } from '../../../../constants';

export const TAB_IDS = {
  AddProductProfile: 0,
  Pricing: 1,
  ConcreteClasses: 2,
};

export const validationSchema = yup.object().shape({
  productId: yup.string().required('required'),
  productName: yup.string().required('required'),
  productType: yup.string().required('required'),
  uom: yup.string().required('required'),
  locations: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string(),
        label: yup.string(),
      })
    )
    .min(1, 'required'),
  productStatus: yup.string().required('required'),
  inventoryProduct: yup.string(),
});

export const initialValues = {
  productId: '',
  productName: '',
  productType: '',
  uom: '',
  locations: [],
  productStatus: STATUS_OPTIONS[0].value,
  mix: '',
  inventoryProduct: '',
  consistenceClass: '',
  slumpLossClass: '',
  temperatureClass: '',
  airClass: '',
  defaultPrice: '',
  taxable: '',
  costs: {},
};
