import * as yup from 'yup';
import { STATUS_OPTIONS } from '../../../../constants';

export const TAB_IDS = {
  AddPriceBookProfile: 0,
  AddPrices: 1,
};

export const validationSchema = yup.object().shape({
  priceBookId: yup.string().required('required'),
  priceBookName: yup.string().required('required'),
  priceBookStatus: yup.string().required('required'),
});

export const initialValues = {
  priceBookId: '',
  priceBookName: '',
  priceBookStatus: STATUS_OPTIONS[0].value,
};
