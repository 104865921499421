import menuLogo from './assets/menu-logo.svg';

export default {
  menuLogo,
  sidebarOptions: [
    { id: 'home', labelStringId: 'home', path: '/home', iconName: 'HomeOutlined', defaultFeature: true },
    {
      id: 'users',
      labelStringId: 'users',
      path: '/users',
      iconName: 'PersonOutlineOutlined',
      hideSortControls: true,
      children: [
        {
          id: 'users-home',
          labelStringId: 'usersHome',
          path: '/users-home',
          iconName: 'PersonOutlineOutlined',
        },
        {
          id: 'roles',
          labelStringId: 'roles',
          path: '/roles',
          iconName: 'WorkOutline',
        },
        {
          id: 'active-users',
          labelStringId: 'activeUsers',
          path: '/active-users',
          iconName: 'PersonOutlineOutlined',
        },
      ],
    },
    {
      id: 'company',
      labelStringId: 'company',
      path: '/company',
      iconName: 'BusinessOutlined',
      permissions: ['*'],
    },
    {
      id: 'masterdata',
      labelStringId: 'master_data',
      path: '/masterdata',
      iconName: 'DataArrayOutlined',
      children: [
        { id: 'locations', labelStringId: 'locations', path: '/locations', iconName: 'FactoryOutlined' },
        { id: 'products', labelStringId: 'products', path: '/products', iconName: 'CategoryOutlined' },
        {
          id: 'product-types',
          labelStringId: 'productTypes',
          path: '/product-types',
          iconName: 'Inventory2Outlined',
        },
        {
          id: 'mixes',
          labelStringId: 'mixDesigns',
          path: '/mixes',
          iconName: 'ScienceOutlined',
        },
        {
          id: 'mix-components',
          labelStringId: 'mixComponents',
          path: '/mix-components',
          iconName: 'MixComponentsIcon',
        },
        { id: 'inventory-products', labelStringId: 'inventoryProducts', path: '/inventory-products' },
        {
          id: 'concrete-classes',
          labelStringId: 'concreteClasses',
          path: '/concrete-classes',
          iconName: 'LayersOutlined',
        },
        { id: 'price-books', labelStringId: 'priceBooks', path: '/price-books', iconName: 'LocalOfferOutlined' },
        { id: 'customers', labelStringId: 'customers', path: '/customers', iconName: 'StorefrontOutlined' },
        { id: 'contact-types', labelStringId: 'contactTypes', path: '/contact-types', iconName: 'ContactPageOutlined' },
        {
          id: 'contact-reasons',
          labelStringId: 'contactReasons',
          path: '/contact-reasons',
          iconName: 'ContentPasteSearchOutlined',
        },
        { id: 'carriers', labelStringId: 'carriers', path: '/carriers', iconName: 'LocalShippingOutlined' },
        { id: 'vehicles', labelStringId: 'vehicles', path: '/vehicles', iconName: 'WarehouseOutlined' },
        { id: 'vehicle-types', labelStringId: 'vehicleTypes', path: '/vehicle-types', iconName: 'VehicleTypesIcon' },
        { id: 'trailers', labelStringId: 'trailers', path: '/trailers', iconName: 'RvHookupOutlined' },
        { id: 'drivers', labelStringId: 'drivers', path: '/drivers', iconName: 'DriversIcon' },
        {
          id: 'pdf-ticket-form',
          labelStringId: 'pdfTicketForm',
          path: '/pdf-ticket-form',
          iconName: 'PictureAsPdf',
        },
        {
          id: 'order-statuses',
          labelStringId: 'orderStatuses',
          path: '/order-statuses',
          iconName: 'OrderStatusesIcon',
        },
        {
          id: 'shipping-methods',
          labelStringId: 'shippingMethods',
          path: '/shipping-methods',
          iconName: 'ShippingMethodsIcon',
        },
        {
          id: 'cancel-reasons',
          labelStringId: 'cancelReasons',
          path: '/cancel-reasons',
          iconName: 'Block',
        },
        {
          id: 'work-types',
          labelStringId: 'workTypes',
          path: '/work-types',
          iconName: 'HomeWorkOutlined',
        },
      ],
    },
    {
      id: 'roles',
      labelStringId: 'roles',
      path: '/roles',
      iconName: 'ManageAccountsOutlined',
    },
    {
      id: 'billing',
      labelStringId: 'billing',
      path: '/billing',
      iconName: 'PaymentsOutlined',
    },
  ],
};
