import common from '../common';
import errors from './errors';
import themes from './theme';
import uoms from './uoms';

export default {
  ...common,

  selectCompany: 'Seleccionar Empresa',
  'company-admin': 'Company Admin',
  'billing-exp': 'Billing',
  'dashboards-exp': 'Dashboards',
  assurance: 'Assurance',
  'cxp-admin': 'CP Admin',
  mt: 'Deliveries',
  admin: 'Platform Admin',
  support: 'Platform Support',
  all: 'Todas',
  active: 'ACTIVO',
  inactive: 'INACTIVO',
  yes: 'SI',
  no: 'NO',
  type: 'Tipo',
  roles: 'Roles',
  users: 'Usuarios',

  required: 'Requerido',

  activeUsers: 'Usuarios Activos',
  usersTable_header_displayName: 'Nombre',
  usersTable_header_email: 'Email',
  usersTable_header_role: 'Role',
  usersTable_header_products: 'Productos',
  activeUser_searchFilterPlaceholder: 'Buscar',
  searchFilter_resultsCount: 'Mostrando {count, plural, =0 {0 resultados} one {# resultado} other {# resultados}}',
  dialogRowCount: `{count, plural, =0 {0 resultados} one {# resultado} other {# resultados}}`,

  home: 'Inicio',
  home_welcomeDialog_next: 'Siguiente',
  home_welcomeDialog_title: 'Bienvenido',
  home_welcomeDialog_contentTitle: 'Inducción para administrador',
  home_welcomeDialog_contentSubTitle: 'Incluye',
  home_header_title: 'Bienvenido a',
  home_header_content:
    '¡Felicitaciones, ha obtenido la licencia de nuestro módulo de despacho! Cuando esté listo, presione el botón azul a continuación para comenzar su configuración.',
  home_filters_button: 'Administrar Configuraciones',
  home_filters_tooltip:
    'Haga clic en "Administrar configuraciones" para ver una descripción general de su proceso de configuración',
  home_cards_chip: 'Nuevo',
  home_cards_button_learnMore: 'Aprender Más',
  home_cards_button_buy: 'Comprar',
  home_cards_button_try: 'Intentar',
  home_cards_button_activate: 'Activar',

  userDetailsDialog_resetPassword: 'Reestablecer contraseña',
  userDetailsDialog_deleteUSer: 'Eliminar usuario',
  userDetailsDialog_blockSignIn: 'Bloquear inicio de sesión',
  userDetailsDialog_userInformation: 'Detalles del Usuario',
  userDetailsDialog_role: 'Rol',
  userDetailsDialog_internalId: 'ID de usuario interno',
  userDetailsDialog_manageRole: 'Gestionar Rol',
  userDetailsDialog_email: 'Email',
  userDetailsDialog_phone: 'Número de Teléfono',
  userDetailsDialog_firstName: 'Nombre',
  userDetailsDialog_lastName: 'Apellido',
  userDetailsDialog_manageContactInfo: 'Gestionar información de contacto',
  userDetailsDialog_nodeAdded: 'Ninguno agregado',

  userList_addUser: 'Agregar usuario',

  addUserDialog_addAUser: 'Agregar un usuario',
  addUserDialog_tabs_contactInfo: 'Información de Contacto',
  addUserDialog_tabs_role: 'Roles',
  addUserDialog_contactInfoTab_title: 'Configurar la información de contacto del usuario',
  addUserDialog_contactInfoTab_description:
    'Para comenzar, complete información básica sobre el usuario que está agregando.',
  addUserDialog_contactInfoTab_username: 'Email',
  addUserDialog_roleTab_title: 'Role',
  addUserDialog_roleTab_description:
    'Si es necesario, cambie el rol del usuario y complete la información de perfil adicional para el usuario.',

  addUserStepper_contactInfo: 'Información de Contacto',
  addUserStepper_role: 'Role',

  products: 'Productos',
  productsTable_header_productName: 'Nombre de Producto',
  productsTable_header_productId: 'ID de Producto',
  productsTable_header_uom: 'Unidad de Medida',
  productsTable_header_location: 'Disponibilidad de Ubicación',
  productsTable_header_productStatus: 'Estado de Producto',
  productsDialog_deleteProduct_title: '¿Eliminar producto?',
  productsDialog_deleteProduct_description:
    'Al eliminar este producto lo eliminará permanentemente de su lista de productos. Seguro de que quieres borrar el producto <strong>{name}</strong>?',
  productNotification_productDeleted: 'Producto eliminado exitosamente',
  products_addProduct_title: 'Agregar un Producto',
  products_addProduct_productProfile: 'Perfil de Producto',
  products_addProduct_productProfile_description:
    'Torem ipsum dolor it amet, consecteur adipiscing elit. Nunc vulputate libero velit.',
  products_addProduct_productTypes: 'Tipos de producto',
  products_addProduct_component: 'Componente',
  products_addProduct_mixDesign: 'Diseño de mezcla',
  products_addProduct_productId: 'ID de producto',
  products_addProduct_productName: 'Nombre de producto',
  products_addProduct_uom: 'Unidad de medida del producto',
  products_addProduct_productStatus: 'Estado de producto',
  products_addProduct_productStatus_tooltip:
    'Los productos activos están disponibles. Los productos archivados están ocultos de forma predeterminada.',
  products_addProduct_inventoryProduct: 'Producto de inventario',
  products_concreteClassesTab_title: 'Clases de Concreto',
  products_concreteClassesTab_consistenceClass: 'Clase de consistencia',
  products_concreteClassesTab_consistenceClass_tooltip:
    'Clasificación de consistencia aceptable para este producto. Este valor se comparte con los sistemas de control de calidad y entrada de pedidos.',
  products_concreteClassesTab_slumpLossClass: 'Clase de pérdida por asentamiento',
  products_concreteClassesTab_slumpLossClass_tooltip:
    'Clasificación de pérdida de asentamiento aceptable para este producto. Este valor se comparte con los sistemas de control de calidad y entrada de pedidos.',
  products_concreteClassesTab_temperatureClass: 'Clase de temperatura',
  products_concreteClassesTab_temperatureClass_tooltip:
    'Clasificación de temperatura aceptable para este producto. Este valor se comparte con los sistemas de control de calidad y entrada de pedidos.',
  products_concreteClassesTab_airClass: 'Clase de aire',
  products_concreteClassesTab_airClass_tooltip:
    'Clasificación de aire aceptable para este producto. Este valor se comparte con los sistemas de control de calidad y entrada de pedidos.',
  products_pricingTab_defaultPrice: 'Precio predeterminado',
  products_pricingTab_defaultPrice_tooltip:
    'Precio estándar para este producto. El precio predeterminado se utiliza a menos que se sobrescriba explícitamente durante el ingreso de la orden.',
  products_pricingTab_taxable_tooltip:
    'Indica si el impuesto sobre las ventas se aplica durante el ingreso de pedidos, la impresión de tickets y la facturación.',
  products_pricingTab_overrideCost: 'Sobreescribir costo',
  productNotification_productAdded: 'Nuevo producto agregado',

  products_editProduct_title: 'Editar un Producto',

  inventoryProducts: 'Productos de Inventario',
  inventoryProducts_tableHeader_uom: 'Unidad de Medida',
  inventoryProducts_addInventoryProduct_title: 'Agregar un Producto de Inventario',
  inventoryProducts_profileTab_inventoryProductId: 'ID de producto de inventario',
  inventoryProducts_profileTab_inventoryProductName: 'Nombre de producto de inventario',
  inventoryProducts_profileTab_inventoryProductUom: 'Unidad de medida del producto de inventario',
  inventoryProducts_profileTab_inventorySource: 'Fuente de inventario',
  inventoryProducts_inventory_inventoryContainers: 'Contenedores / Silos',
  inventoryProducts_supply_safetyLevel: 'Stock de seguridad',
  inventoryProducts_supply_reorderLevel: 'Reordenar',
  inventoryProducts_supply_maxLevel: 'Inventario máximo',
  inventoryProducts_supply_safetyLevelDescription:
    'Nivel de aviso para indicar al usuario que programe un pedido de más material para reponer existencias.',
  inventoryProducts_supply_reorderLevelDescription:
    'La cantidad al sistema debe tratar de mantener los niveles de inventario.',
  inventoryProducts_supply_maxLevelDescription:
    'La cantidad máxima de inventario que se puede mantener en esta ubicación.',
  inventoryProducts_supply_noData: 'Nada aquí todavía',
  inventoryProducts_supply_noDataText: 'Añadir ubicaciones desde el Perfil de Producto o seleccionar otro producto',
  inventoryProducts_supply_productMapping: 'Mapeo de productos',
  inventoryProducts_supply_purchaseOrders: 'Ordenes de compra',
  inventoryProducts_supply_purchaseOrders_tooltip:
    'Lista de ordenes de compra opcionales. Se pueden escribir varios valores separados por comas.',
  inventoryProducts_supply_overrides: 'Anulaciones',
  inventoryProducts_supply_overrideSupplierId: 'Referencia del proveedor',
  inventoryProducts_supply_overrideCarrierId: 'Referencia del portador',
  inventoryProducts_supply_overrideVehicleId: 'Referencia del vehículo',
  inventoryProducts_supply_overridePurchaseOrder: 'Orden de compra',
  inventoryProducts_supply_useVendorAsPurchaseOrder: 'Utilizar la referencia del proveedor como pedido',
  inventoryProducts_editInventoryProduct_title: 'Editar Producto de Inventario',
  inventoryProducts_deleteDialog_title: '¿Eliminar Producto del Inventario?',
  inventoryProducts_deleteDialog_description:
    'Al eliminar este producto de inventario lo eliminará permanentemente de su lista de productos. Seguro de que quieres borrar el producto <strong>{name}</strong>?',
  inventoryProducts_notification_added: 'Nuevo producto de inventario agregado',
  inventoryProducts_notification_deleted: 'Producto de inventario eliminado exitosamente',

  editUserDialog_editUser: 'Editar usuario',
  editUserDialog_tabs_contactInfo: 'Información de Contacto',
  editUserDialog_tabs_contactInfo_title: 'Información de Contacto',
  editUserDialog_tabs_contactInfo_firstName: 'Nombre',
  editUserDialog_tabs_contactInfo_lastName: 'Apellido',
  editUserDialog_tabs_contactInfo_email: 'Email',
  editUserDialog_tabs_contactInfo_phone: 'Número de teléfono',
  editUserDialog_tabs_contactInfo_save: 'Guardar',

  deleteUserDialog_title: '¿Eliminar usuario?',
  deleteUserDialog_description:
    'Estás a punto de desconectar a este usuario de su empresa. ¿Estás seguro de que quieres remover a <strong>{name} ({email})</strong>?',

  error_invalidEmailFormat: 'Formato de email inválido.',
  error_invalidPhone: 'Teléfono inválido.',
  notification_editSaved: 'Edición Guardada',
  notification_userDeleted: 'Usuario eliminada exitosamente',

  delete: 'Eliminar',

  productTypes: 'Tipos de Producto',

  general_cancel: 'Cancel',
  general_minimumZero: 'Valor mínimo 0',
  general_tooSmallValue: 'Valor demasiado pequeño',
  general_unique: 'El valor debe ser único',
  general_select_clearSelection: 'Borrar selección',
  general_confirm: 'Confirmar',
  general_roles: 'Roles',
  general_role: 'Rol',
  general_role_name: 'Nombre del rol',
  general_tableHeader_id: 'ID',
  general_tableHeader_name: 'Nombre',
  general_tableHeader_status: 'Estado',
  general_tableHeader_description: 'Descripción',
  general_tableHeader_pricing: 'Precio',
  general_tableHeader_priceBook: 'Libro de Precios',
  general_tableHeader_vehicleCount: 'Cantidad de Vehículos',
  general_tableHeader_address: 'Dirección',
  general_tableHeader_uom: 'UDM',
  general_tableHeader_weight: 'Peso',
  general_tableHeader_firstName: 'Nombre',
  general_tableHeader_lastName: 'Apellido',
  general_tableHeader_email: 'Email',
  general_tableHeader_mobile: 'Celular',
  general_tableHeader_alternatePhone: 'Teléfono Alternativo',
  general_tableHeader_carrier: 'Portador',
  general_tableHeader_vehicleType: 'Tipo de Vehiculo',
  general_tableHeader_productType: 'Tipo de Producto',
  general_tableHeader_product: 'Producto',
  general_tableHeader_price: 'Precio',
  general_primary: 'Primarios',
  general_no_pascalCase: 'No',

  general_dialogAction_save: 'Guardar',
  general_dialogAction_next: 'Siguiente',
  general_dialogAction_back: 'Atrás',
  general_notification_copy: 'Copiado al portapapeles',
  general_formLabel_address: 'Dirección',
  general_formLabel_state: 'Estado',
  general_formLabel_city: 'Ciudad',

  general_formPlaceholder_addPrice: 'Agregar precio',

  general_profile: 'Perfil',
  general_inventory: 'Inventario',
  general_supply: 'Suministrar',
  general_location: 'Ubicación',
  general_partner: 'Socio',
  general_projects: 'Proyectos',
  general_productType_concrete: 'CONCRETO',
  general_productType_aggregate: 'AGREGADOS',
  general_productType_asphalt: 'ASFALTO',
  general_save: 'Guardar',
  general_ticketSettingTab_title: 'Configuraciones de Ticket',
  general_hoursTab_title: 'Horas',
  general_time: 'Tiempo',
  general_day_sunday: 'Domingo',
  general_day_monday: 'Lunes',
  general_day_tuesday: 'Martes',
  general_day_wednesday: 'Miércoles',
  general_day_thursday: 'Jueves',
  general_day_friday: 'Viernes',
  general_day_saturday: 'Sábado',
  general_country: 'País',
  general_open: 'Apertura',
  general_close: 'Cierre',
  general_daysOfTheWeek: 'Días de la semana',
  general_pricing: 'Precios',
  general_taxable: 'Gravable',
  general_priceBook: 'Libro de precios',
  general_orderRestrictions: 'Restricciones de pedidos',
  general_start: 'Iniciar',
  general_resume: 'Continuar',
  general_view: 'Ver',
  general_notStarted: 'No Iniciado',
  general_started: 'Iniciado',
  general_completed: 'Completado',
  general_default: 'Predeterminado',
  general_air: 'Aire',
  general_consistence: 'Consistencia',
  general_slumpLossRate: 'Tasa de pérdida por asentamiento',
  general_temperature: 'Temperatura',
  general_flow: 'Flujo',
  general_slump: 'Asentamiento',
  general_spread: 'Propagación',
  general_degreesCelsius: 'Grados Celsius',
  general_degreesFahrenheit: 'Grados Fahrenheit',
  general_inches: 'Pulgadas',
  general_millimeters: 'Milimetros',
  general_scheduling: 'Programación',
  ticket: 'Ticket',
  order: 'Orden',

  locations: 'Ubicaciones',
  locations_addLocations_title: 'Agregar una Ubicación',
  locations_editLocations_title: 'Editar Ubicación',
  locations_addLocations_profileTab_title: 'Perfil de Ubicación',
  locations_addLocationsNotification_success_msg: 'Nueva Ubicación añadida',
  locations_profileTab_locationId_field_label: 'ID ubicación',
  locations_profileTab_locationDescription_field_label: 'Descripción',
  locations_profileTab_locationId_field_tooltip:
    'Identificador único para esta instalación de producción. Una identificación es un registro clave y es necesario para compartir información entre sistemas.',
  locations_profileTab_locationName_field_label: 'Nombre de la ubicación',
  locations_profileTab_streetAddress_field_label: 'Dirección',
  locations_profileTab_complement_field_label: 'Complemento.',
  locations_profileTab_city_field_label: 'Ciudad',
  locations_profileTab_state_field_label: 'Estado',
  locations_profileTab_postalCode_field_label: 'Código postal',
  locations_profileTab_primaryProductType_field_label: 'Tipo de producto primario',
  locations_profileTab_status_field_label: 'Estado de la ubicación',
  locations_profileTab_status_field_tooltip:
    'Las ubicaciones activas están disponibles para su uso. Las ubicaciones archivadas están ocultas de forma predeterminada.',
  locations_profileTab_track_inventory_label: 'Seguimiento del inventario',
  locations_ticketSettingTab_title: 'Configuraciones de ticket',
  locations_pricingTab_main_title: 'Libro de precios',
  locations_pricingTab_taxBasis_field: 'Base de impuestos',
  locations_pricingTab_orderRestrictions_field_tooltip: 'Limita los pedidos que se pueden cumplir en esta ubicación.',
  locations_ticketSettingTab_sendMixDesignEachTicket: 'Enviar diseño de mezcla con ticket',
  locations_ticketSettingTab_automaticallyPrintTicketPdf: 'Mensaje para imprimir el ticket',
  locations_ticketSettingTab_automaticallyPrintTicketPdf_tooltip:
    'Recibir un mensaje para imprimir el ticket cuando se crea un ticket.',
  locations_ticketSettingTab_mixSyncing: 'Usar sincronización de mezclas',
  locations_ticketSettingTab_mixSyncing_tooltip:
    'Permitir que la ubicación sincronice diseños de mezcla de forma remota.',
  locations_deleteDialog_title: '¿Eliminar ubicación?',
  locations_deleteDialog_description:
    'Al eliminar esta ubicación, se eliminará permanente de su lista de ubicaciones. ¿Está seguro de que desea eliminar la ubicación <strong>{name}</strong>?',
  locations_notification_locationDeleted: 'Ubicación eliminada exitosamente',
  locations_schedulingTab_orderEntry_title: 'Parametros de entrada de pedido',
  locations_schedulingTab_orderEntry_description:
    'Los parámetros de entrada de pedidos informan al conductor y los horarios de entrega mediante preajustes al tiempo previo a la entrega.',
  locations_schedulingTab_timeToPreload_field_label: 'Tiempo estimado para precargar (minutos)',
  locations_schedulingTab_timeToPreload_field_tooltip: 'Tiempo estimado dedicado a preparar el vehículo para la carga.',
  locations_schedulingTab_timeToDepart_field_label: 'Tiempo estimado de salida (minutos)',
  locations_schedulingTab_timeToDepart_field_tooltip:
    'Tiempo estimado dedicado a prepararse para partir, una vez cargado.',
  locations_schedulingTab_timeToLoad_field_label: 'Tiempo estimado para cargar (minutos)',
  locations_schedulingTab_timeToLoad_field_tooltip: 'Tiempo estimado dedicado a cargar el producto.',
  locations_schedulingTab_driver_callIn_title: 'Parámetros de llamaada del conductor',
  locations_schedulingTab_driver_callIn_description:
    'Los parámetros de llamada del conductor informan a los programadores qué conductores programar y cuándo para garantizar entregas a tiempo',
  locations_schedulingTab_dotCertification_field_label: 'Numero de certificación DOT',
  locations_schedulingTab_vehicleCount_field_label: 'Cantidad de vehículos',
  locations_schedulingTab_vehicleCount_field_tooltip: 'Número de vehículos disponibles en la ubicación.',
  locations_pricingTab_taxBasis_locations_option: 'Dirección de ubicación',
  locations_pricingTab_taxBasis_address_option: 'Dirección de entrega',

  productTypeForm_id: 'ID de tipo de producto',
  productTypeForm_id_tooltip:
    'Identificador único para este tipo de producto. Una identificación es un registro clave y se requiere para compartir información entre sistemas.',
  productTypeForm_status: 'Estado de tipo de producto',
  productTypeForm_status_tooltip:
    'Los tipos de productos activos están disponibles en la entrada de pedidos. Los tipos de productos archivados están ocultos de forma predeterminada.',
  productTypeForm_name: 'Nombre de tipo de producto',
  productTypeForm_category: '¿Cómo desea que se calculen las cantidades del boleto?',
  productTypeForm_category_associated: 'Incluye la cantidad pedida en cada carga',
  productTypeForm_category_associated_tooltip:
    'Para los artículos en lotes, incluya la cantidad pedida en cada carga (por ejemplo, tinte de color que debe estar en cada carga de concreto).',
  productTypeForm_category_other: 'Entregar hasta la cantidad pedida',
  productTypeForm_category_other_tooltip:
    'En el caso de productos no combinables incluidos en un pedido (p. ej., bridas), entregue solo hasta la cantidad solicitada.',
  productTypes_addProductType: 'Agregar un Tipo de Producto',
  productTypes_addProductType_profile: 'Perfil de Tipo de Producto',
  productTypes_editProductType: 'Detalles del tipo de producto',

  priceBooks: 'Libros de Precios',
  priceBooks_addPriceBook_title: 'Agregar un Libro de Precio',
  priceBooks_profileTab_title: 'Perfil de Libro de Precios',
  priceBooks_pricesTab_title: 'Precios',
  priceBooks_profileTab_priceBookId: 'ID del Libro de Precios',
  priceBooks_profileTab_priceBookId_tooltip:
    'Identificador único para este libro de precios. Una identificación es un registro clave y es necesario para compartir información entre sistemas.',
  priceBooks_profileTab_priceBookName: 'Nombre del Libro de Precios',
  priceBooks_profileTab_priceBookStatus: 'Estado del Libro de Precios',
  priceBooks_profileTab_priceBookStatus_tooltip:
    'Los libros de precios activos están actualmente disponibles para pedidos y facturación. Los libros de precios archivados están ocultos de forma predeterminada.',
  priceBooks_notification_priceBookAdded: 'Nuevo Libro de Precios agregado',
  priceBooks_editPriceBook_title: 'Detalles del Libro de Precios',
  priceBooks_deleteDialog_title: '¿Eliminar libro de precios?',
  priceBooks_deleteDialog_description:
    'Al eliminar este libro de precios, se eliminará permanente de su lista de precios. ¿Está seguro de que desea eliminar el libro de precios <strong>{name}</strong>?',
  priceBooks_notification_priceBookDeleted: 'Libro de precios eliminado exitosamente',
  priceBooks_pricesTab_description: 'Especifica precios de productos para agregarlos a este libro de precios.',

  customers: 'Clientes',
  customers_addCustomer_btn: 'Agregar un Cliente',
  customers_addCustomer_title: 'Agregar un Cliente',
  customers_editCustomer_title: 'Editar Cliente',
  customers_addCustomerProfile_tab: 'Perfil del Cliente',
  customers_id_field: 'ID del cliente',
  customers_assignedSalesPerson_field: 'Vendedor Asignado',
  customers_name_field: 'Nombre del cliente',
  customers_status_field: 'Estado del cliente',
  customers_addresss_field: 'Dirección',
  customers_address_complement_field: 'Apartamento, suite, etc.',
  customers_address_city_field: 'Ciudad',
  customers_address_state_field: 'Estado',
  customers_address_postalCode_field: 'Código postal',
  customers_notification_customerAdded: 'Nuevo cliente agregado',
  customers_notification_customerDeleted: 'Cliente eliminado exitosamente',
  customers_deleteDialog_title: '¿Eliminar cliente?',
  customers_deleteDialog_description:
    'Al eliminar este cliente, se eliminará permanentemente de su lista de clientes. ¿Está seguro de que desea eliminar el cliente <strong>{name}</strong>?',
  customers_ticketSettingTab_printPaperTickets: 'Imprimir los boletos en papel con cada pedido para este cliente',
  customers_ticketSettingTab_printWeights: 'Imprimir el peso en todos los boletos para este cliente',
  customers_ticketSettingTab_showPricing: 'Imprimir los precios en todos los boletos para este cliente',
  customers_taxable_field_tooltip:
    'Indica si se aplican o no impuestos sobre las ventas durante el ingreso de pedidos, la impresión de boletos y la facturación.',
  customers_creditCode_field: 'Código de crédito',
  customers_creditCode_field_tooltip:
    'Forma de pago de este proyecto. Estos valores provienen de la configuración de Códigos de crédito.',
  customers_orderRestrictions_field_tooltip: 'Limita qué productos se pueden seleccionar.',

  carriers: 'Portadores',
  carriers_addCarrier_title: 'Agregar un Portador',
  carriers_notification_carrierAdded: 'Nuevo portador agregado',
  carriers_profileTab_title: 'Perfil de Portador',
  carriers_editCarrier_title: 'Detalle del Portador',
  carriers_profileTab_carrierId: 'ID del portador',
  carriers_profileTab_carrierId_tooltip:
    'Identificador único para este portador. Una identificación es un registro clave y es necesario para compartir información entre sistemas.',
  carriers_profileTab_carrierName: 'Nombre del portador',
  carriers_profileTab_carrierStatus: 'Estado del portador',
  carriers_profileTab_carrierStatus_tooltip:
    'Los portadores activos están disponibles para asignaciones. Los portadores archivados están ocultos de forma predeterminada.',
  carriers_profileTab_carrierTrackItID: 'ID de referencia de TrackIt',
  carriers_profileTab_carrierTrackItID_tooltip:
    'ID de TrackIt para este registro de portador. Este campo no está integrado con TrackIt.',
  carriers_profileTab_carrierCompanyFleet: 'Pertenece a la flota de la compañía',
  carriers_notification_carrierDeleted: 'Portador eliminado exitosamente',
  carriers_deleteDialog_title: '¿Eliminar portador?',
  carriers_deleteDialog_description:
    'Al eliminar este portador, se eliminará permanentemente de su lista de portadores. ¿Está seguro de que desea eliminar el portador <strong>{name}</strong>?',

  drivers: 'Conductores',
  drivers_addDriver_title: 'Agregar un conductor',
  drivers_profileTab_title: 'Perfil del conductor',
  drivers_profileTab_driverId: 'ID del conductor',
  drivers_profileTab_driverId_tooltip:
    'Identificador único para este conductor. Una identificación es un registro clave y es necesario para compartir información entre sistemas.',
  drivers_profileTab_driverMobile: 'Número de teléfono del conductor',
  drivers_profileTab_driverFirstName: 'Nombre del conductor',
  drivers_profileTab_driverPhone: 'Número de teléfono alternativo del conductor',
  drivers_profileTab_driverName: 'Nombre del conductor',
  drivers_profileTab_driverLastName: 'Apellido del conductor',
  drivers_profileTab_driverEmail: 'Correo electrónico del conductor',
  drivers_profileTab_driverStatus: 'Estado del conductor',
  drivers_profileTab_driverStatus_tooltip:
    'Los conductores activos están disponibles para trabajos. Los registros con conductores archivados están ocultos de forma predeterminada.',
  drivers_notification_driverAdded: 'Nuevo conductor agregado',
  drivers_editDriver_title: 'Detalles del conductor',
  drivers_deleteDialog_title: '¿Eliminar conductor?',
  drivers_deleteDialog_description:
    'Al eliminar este conductor, se eliminará permanente de su lista de conductores. ¿Está seguro de que desea eliminar el conductor, <strong>{name}</strong>?',
  drivers_notification_driverDeleted: 'Conductor eliminado exitosamente',

  notification_productTypeAdded: 'Nuevo tipo de producto agregado',
  notification_changesSaved: 'Cambios guardados',
  notification_productTypeDeleted: 'Tipo de producto eliminado exitosamente',

  deleteProductTypeDialog_title: '¿Eliminar tipo de producto?',
  deleteProductTypeDialog_description:
    'Al eliminar este tipo de producto, se eliminará permanentemente de su lista de tipos de productos. ¿Está seguro de que desea eliminar el tipo de producto <strong>{name}</strong>?',
  master_data: 'Datos Maestros',

  vehicleTypes: 'Tipos de Vehículos',
  vehicleTypes_addVehicleType_title: 'Agregar un tipo de vehículo',
  vehicleTypes_editVehicleType_title: 'Detalle del tipo de vehículo',
  vehicleTypes_profileTab_title: 'Perfil de tipo de vehículo',
  vehicleTypes_profileTab_vehicleTypeId: 'ID del tipo de vehículo',
  vehicleTypes_profileTab_vehicleTypeId_tooltip:
    'Identificador único para este tipo de vehículo. Una identificación es un registro clave y es necesario para compartir información entre sistemas.',
  vehicleTypes_profileTab_defaultLoadSize: 'Tamaño de carga por defecto',
  vehicleTypes_profileTab_defaultLoadSize_tooltip:
    'Ayuda con la programación de vehículos. Cuando selecciona el tipo de vehículo durante el ingreso del pedido, el sistema utiliza el tamaño de carga predeterminado para garantizar que los vehículos estén programados para que estén llenos y de la manera más eficiente posible.',
  vehicleTypes_profileTab_defaultLoadSizeUom: 'Unidad de medida',
  vehicleTypes_profileTab_vehicleName: 'Descripción del tipo de vehículo',
  vehicleTypes_profileTab_vehicleName_tooltip: 'Descripción o nombre de este tipo de vehículo.',
  vehicleTypes_profileTab_maxLoadSize: 'Tamaño máximo de carga',
  vehicleTypes_profileTab_maxLoadSize_tooltip:
    'Se utiliza durante la emisión de multas para activar una advertencia de que puede llenar demasiado un vehículo.',
  vehicleTypes_profileTab_maxLoadSizeUom: 'Unidad de medida',
  vehicleTypes_profileTab_vehicleTypeStatus: 'Estado del tipo de vehículo',
  vehicleTypes_profileTab_vehicleTypeStatus_tooltip:
    'Los tipos de vehículos activos están disponibles para asignaciones. Los tipos de vehículos archivados están ocultos de forma predeterminada.',
  vehicleTypes_profileTab_trackingColor: 'Color de seguimiento',
  vehicleTypes_profileTab_trackingColor_tooltip:
    'Los tipos de vehículos asignados a esta ubicación aparecen en este color en su espacio de trabajo de Seguimiento.',
  vehicleTypes_profileTab_trackingColor_invalidValue: 'Formato de color de seguimiento no válido',
  vehicleTypes_profileTab_defaultValue: 'Hacer que este tipo de vehículo sea el predeterminado para todos los pedidos.',
  vehicleTypes_notification_vehicleTypeAdded: 'Nuevo tipo de vehículo agregado',
  vehicleTypes_notification_vehicleTypeDeleted: 'Tipo de vehículo eliminado exitosamente',
  vehicleTypes_deleteDialog_title: '¿Eliminar tipo de vehículo?',
  vehicleTypes_deleteDialog_description:
    'Al eliminar este tipo de vehículo, se eliminará permanentemente de su lista de tipos de vehículo. ¿Está seguro de que desea eliminar el tipo de vehículo, <strong>{name}</strong>?',

  mixComponents: 'Componentes de Mezcla',
  mixComponents_addMixComponent_title: 'Agregar un Componente de Mezcla',
  mixComponents_profileTab_title: 'Perfil de Componente de Mezcla',
  mixComponents_editMixComponent_title: 'Detalle del Componente de Mezcla',
  mixComponents_profileTab_mixComponentId: 'ID del componente de mezcla',
  mixComponents_profileTab_mixComponentId_tooltip:
    'Identificador único para este componente de mezcla. Una identificación es un registro clave y es necesario para compartir información entre sistemas.',
  mixComponents_profileTab_mixComponentUOM: 'Unidad de medida (UDM) del lote',
  mixComponents_profileTab_mixComponentName: 'Nombre del componente de mezcla',
  mixComponents_profileTab_mixComponentStatus: 'Estado del componente de mezcla',
  mixComponents_profileTab_mixComponentStatus_tooltip:
    'Los componentes de mezcla activos están disponibles para pedidos y facturación. Los componentes de mezcla archivados están ocultos de forma predeterminada.',
  mixComponents_profileTab_mixComponentInvUOM: 'Unidad de medida (UDM) del inventario',
  mixComponents_notification_mixComponentAdded: 'Nuevo componente de mezcla agregado',
  mixComponents_deleteDialog_title: '¿Eliminar componente de mezcla?',
  mixComponents_deleteDialog_description:
    'Al eliminar este componente de mezcla, se eliminará permanentemente de su lista de componente de mezclas. ¿Está seguro de que desea eliminar el componente de mezcla <strong>{name}</strong>?',
  mixComponents_notification_mixComponentDeleted: 'Componente de mezcla eliminado exitosamente',
  mixComponents_batchCodeTab_title: 'Código de Lote',
  mixComponents_batchCodeTab_noData: 'Nada aquí todavía',
  mixComponents_batchCodeTab_batchCode: 'Código de lote',

  mixDesigns: 'Diseños de la Mezcla',
  mixDesigns_tableHeader_hasFormulas: 'Tiene Formulas',
  mixDesigns_addMixDesign: 'Agregar Diseño de Mezcla',
  mixDesigns_editMixDesignTitle: 'Editar diseño de mezcla',
  mixDesigns_profileTab_title: 'Perfil de diseño de mezcla',
  mixDesigns_profileTab_id_field_label: 'Id de diseño de mezcla',
  mixDesigns_profileTab_mix_formula_id_field_label: 'Id formula de mezcla',
  mixDesigns_profileTab_mix_formula_id_field_label_tooltip:
    'Identificador alternativo del diseño de la mezcla que puede impreso en el ticket',
  mixDesigns_profileTab_max_batch_size_field_label: 'Tamaño máximo de lote',
  mixDesigns_profileTab_max_batch_uom_field_label: 'UDM',
  mixDesigns_profileTab_name_field_label: 'Nombre del diseño de mezcla',
  mixDesigns_profileTab_status_field_label: 'Estados ',
  mixDesigns_profileTab_status_field_tooltip:
    'Los diseños de mezcla activos estarán disponibles para ser usados, los diseños de mezcla archivados estarán ocultos por defecto',
  mixDesigns_addMixDesignNotification_success_msg: 'Nuevo diseño de mezcla añadido',
  mixDesigns_deleteMixDesignTitle: 'Eliminar diseño de mezcla?',
  mixDesigns_deleteMixDesign_description:
    'Al eliminar este diseño de mezcla, se eliminará permanente de su lista. ¿Está seguro de que desea eliminar el diseño de mezcla, <strong>{name}</strong>?',
  mixDesigns_deleteMixDesignNotification_success_msg: 'Diseño de mezcla eliminado',
  mixDesigns_addComponents: 'Agregar componente(s)',
  mixDesigns_tableHeader_sequence: 'Secuencia',
  mixDesigns_tableHeader_mixComponent: 'Componente de Mezcla',
  mixDesigns_tableHeader_quantity: 'Cantidad',
  mixDesigns_tableHeader_addQuantity: 'Agregar cantidad',
  mixDesigns_tableHeader_uomCode: 'Inventario consumido',

  vehicles: 'Vehículos',
  vehicles_addVehicle_title: 'Agregar un vehículo',
  vehicles_editVehicle_title: 'Detalles del vehículo',
  vehicles_profileTab_title: 'Perfil del vehículo',
  vehicles_specificationsTab_title: 'Especificaciones de vehículo',
  vehicles_profileTab_vehicleId: 'ID del vehículo',
  vehicles_profileTab_vehicleId_tooltip:
    'Identificador único para este vehículo. Una identificación es un registro clave y es necesario para compartir información entre sistemas.',
  vehicles_profileTab_vehicleName: 'Nombre del vehículo',
  vehicles_profileTab_vehicleStatus: 'Estado del vehículo',
  vehicles_profileTab_vehicleStatus_tooltip:
    'Los vehículos activos están actualmente disponibles para asignación. Los vehículos archivados están ocultos de forma predeterminada.',
  vehicles_profileTab_vehicleType: 'Tipo de vehículo',
  vehicles_profileTab_carrier: 'Portador',
  vehicles_profileTab_vehicleHomeLocation: 'Ubicación del vehículo',
  vehicles_profileTab_vehicleHomeLocation_tooltip:
    'Lugar donde se aparca el vehículo por la noche y desde donde habitualmente sale.',
  vehicles_specificationTab_maxGrossVehicleWeight: 'Peso bruto máximo del vehículo (Kg)',
  vehicles_specificationTab_targetGrossVehicleWeight: 'Peso bruto de carga (Kg)',
  vehicles_specificationTab_targetNetVehicleWeight: 'Peso neto de la mercancía (Kg)',
  vehicles_specificationTab_maxGrossVehicleWeight_tooltip:
    'Peso total máximo del vehículo. Esto significa el peso total del vehículo cuando está vacío más el peso de los pasajeros, el combustible, los accesorios y la carga. No incluye el peso de ningún remolque.',
  vehicles_specificationTab_targetGrossVehicleWeight_tooltip:
    'Peso de la mercancía más cualquier embalaje utilizado para el transporte.',
  vehicles_specificationTab_targetNetVehicleWeight_tooltip:
    'Peso de la mercancía menos el peso del contenedor, embalaje y equipo de protección como paletas.',
  vehicles_notification_vehicleAdded: 'Nuevo vehículo agregado',
  vehicles_notification_vehicleDeleted: 'Vehículo eliminado exitosamente',
  vehicles_deleteDialog_title: '¿Eliminar vehículo?',
  vehicles_deleteDialog_description:
    'Al eliminar este vehículo, se eliminará permanente de su lista de vehículos. ¿Está seguro de que desea eliminar el vehículo, <strong>{name}</strong>?',

  contactReasons: 'Motivos de Contacto',
  contactReasons_addContactReason_title: 'Agregar un Motivo de Contacto',
  contactReasons_editContactReason_title: 'Motivos de contacto',
  contactReasons_profileTab_title: 'Perfil de Motivo de Contacto',
  contactReasons_profileTab_contactReasonId: 'ID del motivo de contacto',
  contactReasons_profileTab_contactReasonName: 'Nombre del motivo de contacto',
  contactReasons_profileTab_contactReasonStatus: 'Estado',
  contactReasons_notification_contactReasonAdded: 'Nuevo motivo de contacto agregado',
  contactReasons_deleteDialog_title: '¿Eliminar motivo de contacto?',
  contactReasons_deleteDialog_description:
    'Al eliminar este motivo de contacto, se eliminará permanente de su lista de motivos de contacto. ¿Está seguro de que desea eliminar el motivo de contacto, <strong>{name}</strong>?',
  contactReasons_notification_contactReasonDeleted: 'Motivo de contacto eliminado exitosamente',

  concreteClasses: 'Clases de Concreto',
  concreteClasses_tableHeader_nominalValue: 'Valor Nominal',
  concreteClasses_tableHeader_minimumValue: 'Valor Mínimo',
  concreteClasses_tableHeader_maximumValue: 'Valor Máximo',
  concreteClasses_tableHeader_classType: 'Tipo de Clase',
  concreteClasses_deleteDialog_title: '¿Eliminar clase de concreto?',
  concreteClasses_deleteDialog_description:
    'Al eliminar este clase de concreto, se eliminará permanente de su lista de clases de concreto. ¿Está seguro de que desea eliminar la clase de concreto, <strong>{name}</strong>?',
  concreteClasses_notification_concreteClassDeleted: 'Clase de concreto eliminado exitosamente',

  concreteClasses_addConcreteClass_title: 'Agregar una Clase de Concreto',
  concreteClasses_profileTab_title: 'Perfil de Clase de Concreto',
  concreteClasses_editConcreteClass_title: 'Editar clase de concreto',
  concreteClasses_profileTab_concreteClassId: 'ID de clase de concreto',
  concreteClasses_profileTab_concreteClassId_tooltip:
    'Identificador único para esta clase de concreto. Una identificación es un registro clave y es necesario para compartir información entre sistemas.',
  concreteClasses_profileTab_concreteClassUOM: 'Unidad de medida (UDM)',
  concreteClasses_profileTab_concreteClassName: 'Nombre de la clase de concreto',
  concreteClasses_profileTab_concreteClassNominalValue: 'Valor nominal',
  concreteClasses_profileTab_concreteClassType: 'Tipo de clase',
  concreteClasses_profileTab_concreteClassMinimumValue: 'Valor mínimo',
  concreteClasses_profileTab_concreteClassSubtype: 'Subtipo de clase',
  concreteClasses_profileTab_concreteClassMaximumValue: 'Valor máximo',
  concreteClasses_profileTab_concreteClassStatus: 'Estado de clase de concreto',
  concreteClasses_profileTab_concreteClassesStatus_tooltip:
    'Las clases de concreto activas están disponibles. Los productos archivados están ocultos de forma predeterminada.',
  concreteClasses_profileTab_nominalValue_tooltip: 'Valor ideal para esta clase de concreto',
  concreteClasses_notification_concreteClassAdded: 'Nueva clase de concreto agregada',
  concreteClasses_profileTab_defaultSettings: 'Usar como configuración predeterminada',

  contactTypes: 'Tipos de Contacto',
  contactTypes_addContactTypes_title: 'Agregar Tipo de Contacto',
  contactTypes_editContactTypes_title: 'Detalles del Tipo de Contacto',
  contactTypes_notification_contactTypesAdded: 'Nuevo tipo de contacto agregado',

  contactTypeList_addContactType: 'Agregar tipo de contacto',
  contactTypes_profileTab_title: 'Perfil de tipos de contacto',
  contactTypes_profileTab_contactTypeId: 'ID del tipo de contacto',
  contactTypes_profileTab_contactTypeName: 'Nombre del tipo de contacto',
  contactTypes_profileTab_contactTypeStatus: 'Estado del tipo de contacto',
  contactTypes_profileTab_contactTypesId_tooltip:
    'Identificador único para este tipo de contacto. Una identificación es un registro clave y es necesario para compartir información entre sistemas.',
  contactTypes_profileTab_contactTypeStatus_tooltip:
    'Los tipos de contactos activos están actualmente disponibles para contacto. Los tipos de contacto inactivos están ocultos de forma predeterminada.',
  contactTypes_deleteDialog_title: '¿Eliminar tipo de contacto?',
  contactTypes_deleteDialog_description:
    'Al eliminar este tipo de contacto, se eliminará permanente de su lista de tipos de contacto. ¿Está seguro de que desea eliminar el tipo de contacto, <strong>{name}</strong>?',
  contactTypes_notification_contactTypeDeleted: 'Tipo de contacto eliminado exitosamente',

  trailers: 'Trailers',
  trailers_tableHeader_maxGross: 'Bruto Máximo',
  trailers_tableHeader_maxNet: 'Neto Máximo',
  trailers_tableHeader_targetGross: 'Objetivo Bruto',
  trailers_tableHeader_targetNet: 'Objetivo de Red',
  trailers_addTrailer_title: 'Agregar un Trailer',
  trailers_editTrailer_title: 'Editar Trailer',
  trailers_addTrailer_profile: 'Perfil de Trailer',
  trailers_trailerTab_fieldTrailerId_label: 'ID de Trailer',
  trailers_trailerTab_fieldTrailerId_tooltip:
    'Identificador único para este tráiler. Una identificación es un registro clave y es necesario para compartir información entre sistemas.',
  trailers_trailerTab_fieldTargetGross_label: 'Peso bruto de carga objetivo',
  trailers_trailerTab_fieldTargetGross_tooltip:
    'Peso de la mercancía más cualquier embalaje utilizado para el transporte.',
  trailers_trailerTab_fieldTrailerName_label: 'Nombre de trailer',
  trailers_trailerTab_fieldTargetNet_label: 'Peso neto objetivo de las mercancías',
  trailers_trailerTab_fieldTargetNet_tooltip:
    'Peso de la mercancía menos el peso del contenedor, embalaje y equipos de protección como pallets.',
  trailers_trailerTab_fieldMaxGross_label: 'Peso bruto máximo del trailer',
  trailers_trailerTab_fieldMaxGross_tooltip:
    'Peso total máximo seguro del trailer. Esto significa el peso total del trailer vacío más el peso de la carga. No incluye el peso del vehículo que tira del trailer.',
  trailers_trailerTab_fieldMaxNet_label: 'Peso neto máximo del trailer',
  trailers_trailerTab_fieldMaxNet_tooltip: 'Peso máximo de la carga del trailer.',
  trailers_trailerTab_fieldTrailerStatus_label: 'Estado del trailer',
  trailers_trailerTab_status_tooltip:
    'Los trailers activos están disponibles para uso. Los trailers archivados están ocultos de forma predeterminada.',
  trailers_notification_trailerAdded: 'Nuevo trailer agregado',
  trailers_notification_trailerDeleted: 'Trailer eliminado exitosamente',
  trailers_deleteDialog_title: '¿Eliminar trailer?',
  trailers_deleteDialog_description:
    'Al eliminar este trailer, se eliminará permanente de su lista de trailers. ¿Está seguro de que desea eliminar el trailer, <strong>{name}</strong>?',

  company: 'Empresa',
  company_summary_title: 'Perfil de la empresa',
  company_form_companyName: 'Nombre de la empresa',
  company_form_displayName: 'Nombre a mostrar',
  company_form_primaryContact: 'Contacto principal',
  company_form_addressOptional: 'Dirección 2 (opcional)',
  company_form_postalCode: 'Código postal',
  company_form_phoneNumber: 'Número telefónico',
  company_form_logo: 'Logo',

  // BILLING AND INVOICING
  billing: 'Facturación y Cobro',
  billing_home_title: 'Configure la facturación para sus clientes',
  billing_home_description:
    'Morem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per.',
  billing_home_button: 'Comenzar',
  billing_card_taxation_title: 'Impuestos',
  billing_card_taxation_description:
    'Añada la información fiscal de su empresa manualmente o conectando el software de cumplimiento fiscal preferido de su empresa.',

  // BILLING AND INVOICING -- TAXATION
  billing_tax_settings: 'Configuraciones fiscales',
  billing_hq_construction: 'Construcción CG',
  billing_tax_description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.',
  billing_tax_provider_type: 'Tipo de proveedor de impuestos',
  billing_tax_type_geocoder: 'US Geocoder',
  billing_tax_type_zip_tax: 'Zip Tax',
  billing_tax_type_vat_rate: 'Impuesto Fijo de la Empresa',
  billing_tax_per_product: 'Impuesto por producto',
  billing_tax_per_product_tooltip: 'Aplicar impuestos por línea de producto',
  billing_vat_rate: 'Tipo de IVA',
  billing_tax_Percentage_min_error: 'El porcentaje de impuestos debe ser igual o superior a 0.00',
  billing_tax_Percentage_max_error: 'El porcentaje de impuestos debe ser menor o igual a 999.99',

  companyLogoModal_title: 'Agregar logo',
  companyLogoModal_description: `Un logotipo de empresa ayuda a identificar fácilmente la empresa en la que ha iniciado sesión. Agregue o reemplace una imagen de logotipo para representar mejor su empresa en toda la aplicación.`,
  companyLogoModal_add_btn: 'Añadir logo',
  companyLogoModal_change_btn: 'Remplazar logo',
  companyLogoModal_title_edit: 'Editar Logo',
  default_uploadImage_change_btn: 'Cambiar',
  default_uploadImage_delete_btn: 'Eliminar',
  default_uploadImage_drag_photo_description: 'Arrastre una foto aquí',
  default_uploadImage_drag_photo_or: '- o -',
  default_uploadImage_upload_from_computer_btn: 'Subir desde el computador',
  default_uploadImage_rotate_btn: 'Rotar',
  default_uploadImage_file_size_error: 'El tamaño de la imagen no puede ser mayor a 5MB',
  default_uploadImage_update_sucess_msg: 'Imagen actualizada correctamente',
  default_uploadImage_update_error_msg: 'Error al actualizar la imagen',
  default_uploadImage_delete_sucess_msg: 'Imagén eliminada exitosamente',
  default_uploadImage_delete_error_msg: 'Hubo un error en la eliminación',

  usersHome: 'Inicio de usuarios',
  usersHome_title: 'Agregar usuarios',
  usersHome_description:
    'Agregue usuarios y defina roles de usuario para determinar quién tiene acceso a suscripciones y datos específicos de la empresa.',
  usersHome_activeUsers_card_description:
    'Agregue usuarios y asigne permisos por grupo y rol para determinar a qué información pueden acceder y las acciones que pueden realizar.',
  usersHome_roles_card_description:
    'Cree roles con privilegios y acceso a datos específicos para asignar rápidamente permisos a los usuarios por rol.',

  pdfTicketForm: 'Plantilla de Ticket en PDF',
  pdfTicketForm_download: 'Descargar',
  pdfTicketForm_import_ticket: 'Importar Ticket',
  pdfTicketForm_no_preview: 'Nada para previsualizar aun',
  pdfTicketForm_preview: 'Previsualizacion',
  pdfTicketForm_lang_selector: 'Selector de Idioma',
  pdfTicketForm_tableHeader_name: 'Nombre de la plantilla',
  pdfTicketForm_tableHeader_language: 'Idioma',
  pdfTicketForm_tableHeader_vehicleTypes: 'Tipo de Vehiculo',

  shippingMethods: 'Métodos de envío',
  shippingMethods_addShippingMethod_title: 'Agregar un método de envío',
  shippingMethods_editShippingMethod_title: 'Detalles del método de envío',
  shippingMethods_profileTab_id_label: 'ID del método de envío',
  shippingMethods_profileTab_id_label_tooltip:
    ' Identificador único para este método de envío. Una identificación es un registro clave y es necesario para compartir información entre sistemas.',
  shippingMethods_profileTab_name_label: 'Descripción del método de envío',
  shippingMethods_profileTab_status_label: 'Estado del método de envío',
  shippingMethods_profileTab_status_label_tooltip:
    'Los métodos de envío activos están disponibles para su uso durante la entrada de pedidos. Los métodos de envío archivados están ocultos de forma predeterminada.',
  shippingMethods_notification_shippingMethodAdded: 'Nuevo método de envío agregado',
  shippingMethods_notification_shippingMethodDeleted: 'Método de envío eliminado exitosamente',
  shippingMethods_notification_shippingMethodUpdated: 'Método de envío actualizado exitosamente',
  shippingMethods_deleteDialog_title: '¿Eliminar método de envío?',
  shippingMethods_deleteDialog_description:
    'Al eliminar este método de envío, se eliminará permanentemente de su lista de métodos de envío. ¿Está seguro de que desea eliminar el método de envío, <strong>{name}</strong>?',
  orderStatuses: 'Estados de los pedidos',
  orderStatuses_tableHeader_displayPlanning: 'Mostrar al planificar',
  orderStatuses_addOrderStatus_title: 'Agregar un estado de pedido',
  orderStatuses_notification_orderStatusAdded: 'Nuevo estado de pedido agregado',
  orderStatuses_editOrderStatus_title: 'Detalles del estado del pedido',
  orderStatuses_profileTab_fieldOrderStatusId_label: 'ID de estado del pedido',
  orderStatuses_profileTab_fieldOrderStatusId_tooltip:
    'Identificador único para el estado de este pedido. Una identificación es un registro clave y es necesario para compartir información entre sistemas.',
  orderStatuses_profileTab_fieldDefaultValue_tooltip:
    'Preestablece el campo de estado del pedido a este valor en todos los pedidos nuevos durante el ingreso del pedido.',
  orderStatuses_profileTab_fieldOrderStatusName_label: 'Nombre del estado del pedido',
  orderStatuses_profileTab_fieldStatus_tooltip:
    'Los estados de pedidos activos están disponibles para el ingreso de pedidos. Los estados de los pedidos archivados están ocultos de forma predeterminada.',
  orderStatuses_profileTab_fieldDisplayInPlanning_label:
    'Mostrar el estado del pedido en el espacio de trabajo de planificación',
  orderStatuses_profileTab_fieldEnableTicketing_label: 'Habilitar emisión de tickets',
  orderStatuses_profileTab_fieldEnableTicketing_tooltip:
    '¿Este estado del pedido se puede entregar y emitir un ticket?',
  orderStatuses_deleteDialog_title: '¿Eliminar el estado del pedido?',
  orderStatuses_deleteDialog_description:
    'Eliminar el estado de este pedido lo eliminará permanentemente de su lista de estados de pedido. ¿Estás seguro de que deseas eliminar el estado del pedido, <strong>{name}</strong>?',
  orderStatuses_notification_orderStatusDeleted: 'Estado del pedido eliminado correctamente',

  cancelReasons: 'Razones de cancelación',
  cancelReasons_addCancelReason_title: 'Agregar una razón de cancelación',
  cancelReasons_editCancelReason_title: 'Detalles de la razón de cancelación',
  cancelReasons_profileTab_fieldCancelReasonId_label: 'ID de razón de cancelación',
  cancelReasons_profileTab_fieldCancelReasonName_label: 'Nombre de la razón de cancelación',
  cancelReasons_profileTab_fieldCancelReasonStatus_label: 'Status',
  cancelReasons_profileTab_fieldCancelReasonType_label: 'Tipo de razón',
  cancelReasons_notification_cancelReasonAdded: 'Nueva razón de cancelación agregada',
  cancelReasons_notification_cancelReasonUpdated: 'Razón de cancelación actualizada',
  cancelReasons_notification_cancelReasonDeleted: 'Razón de cancelación eliminada',
  cancelReasons_deleteDialog_title: '¿Eliminar razón de cancelación?',
  cancelReasons_deleteDialog_description:
    'Al eliminar esta razón de cancelación, se eliminará permanentemente de su lista de razónes de cancelación. ¿Está seguro de que desea eliminar la razón de cancelación, <strong>{name}</strong>?',

  workTypes: 'Tipos de Trabajo',
  workTypes_addWorkType_title: 'Agregar un Tipo de Trabajo',
  workTypes_editWorkType_title: 'Detalles del tipo de trabajo',
  workTypes_deleteDialog_title: '¿Eliminar tipo de trabajo?',
  workTypes_deleteDialog_description:
    'Eliminar el tipo de trabajo lo eliminará permanentemente de su lista de tipos de trabajo. ¿Estás seguro de que deseas eliminar el tipo de trabajo, <strong>{name}</strong>?',
  workTypes_notification_workTypeAdded: 'Nuevo tipo de trabajo agregado',
  workTypes_notification_workTypeDeleted: 'Tipo de trabajo eliminado correctamente',
  workTypes_profileTab_fieldWorkTypeId_label: 'ID de tipo de trabajo',
  workTypes_profileTab_fieldWorkTypeId_tooltip:
    'Identificador único para este tipo de trabajo. Una identificación es un registro clave y es necesario para compartir información entre sistemas.',
  workTypes_profileTab_fieldWorkTypeProductType_label: 'Tipo de producto asociado',
  workTypes_profileTab_fieldWorkTypeProductType_tooltip:
    'Producto primario utilizado en este tipo de trabajo. Estos valores provienen de la configuración del Tipo de Producto.',
  workTypes_profileTab_fieldWorkTypeName_label: 'Nombre de tipo de trabajo',
  workTypes_profileTab_fieldWorkTypeStatus_label: 'Estado de tipo de trabajo',
  workTypes_profileTab_fieldWorkTypeStatus_tooltip:
    'Los tipos de trabajo activos están disponibles en la entrada de pedidos. Los tipos de trabajo archivados están ocultos de forma predeterminada.',

  ...uoms,
  errors,
  themes,
};
