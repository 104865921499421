import PhoneInput from 'react-phone-input-2';

import styled from '@emotion/styled';
import { useTheme } from '@mui/material';

import 'react-phone-input-2/lib/material.css';

/**
 * TODO:Refactor styling and fix the width of the dropdown menu.
 *
 */

export const ReactPhoneInput = styled(PhoneInput)(({ disabled, isInputFocused, hover, error }) => {
  const theme = useTheme();
  return {
    '.special-label': {
      fontFamily: 'Work Sans',
      left: '13px',
      top: '-10px',
      fontSize: '12.5px',
      color: error
        ? theme.validateMode({
            light: theme.colors.red[60],
            dark: theme.colors.red[80],
          })
        : isInputFocused
        ? theme.validateMode({
            light: theme.colors.darkBlue[50],
            dark: theme.colors.blue[95],
          })
        : hover & !error
        ? theme.validateMode({
            light: theme.colors.darkBlue[50],
            dark: theme.colors.blue[90],
          })
        : theme.validateMode({
            light: disabled ? theme.colors.neutral[70] : theme.colors.neutral[10],
            dark: disabled ? theme.colors.neutral[70] : theme.colors.neutral[95],
          }),
      background: theme.validateMode({
        light: theme.colors.neutral[100],
        dark: theme.colors.purple[80],
      }),
    },
    '.form-control': {
      width: '100% !important',
      height: 48,
      border: error
        ? `1px solid ${theme.validateMode({
            light: theme.colors.red[60],
            dark: theme.colors.red[80],
          })}`
        : `1px solid ${theme.validateMode({
            light: theme.colors.neutral[60],
            dark: theme.colors.neutral[100],
          })}`,
      padding: '19.5px 14px 19.5px 80px',
      fontFamily: 'Work Sans',
      color: theme.validateMode({
        light: theme.colors.neutral[40],
        dark: theme.colors.neutral[100],
      }),
      background: theme.validateMode({
        light: theme.colors.neutral[100],
        dark: theme.colors.purple[80],
      }),
      '&:disabled': {
        opacity: 0.38,
      },
      '&:hover': {
        border: error
          ? `1px solid ${theme.validateMode({
              light: theme.colors.red[60],
              dark: theme.colors.red[80],
            })}`
          : `1px solid ${theme.validateMode({
              light: theme.colors.darkBlue[50],
              dark: theme.colors.blue[90],
            })}`,
      },
      '&:focus': {
        border: theme.validateMode({
          light: `2px solid ${theme.colors.darkBlue[50]}`,
          dark: `2px solid ${theme.colors.blue[95]}`,
        }),
        boxShadow: 'none',
      },
    },
    '.country-list': {
      fontFamily: 'Work Sans',
      width: 406,
      color: theme.validateMode({
        light: theme.colors.neutral[0],
        dark: theme.colors.neutral[100],
      }),
      background: theme.validateMode({
        light: theme.colors.neutral[100],
        dark: theme.colors.purple[80],
      }),
      '& .country .dial-code': {
        color: theme.validateMode({
          light: `${theme.colors.neutral[70]} !important`,
          dark: `${theme.colors.neutral[60]} !important`,
        }),
      },
      '& .country:hover': {
        background: theme.validateMode({
          light: theme.colors.neutral[99],
          dark: theme.colors.neutral[30],
        }),
      },
      '& .country.highlight': {
        background: theme.validateMode({
          light: theme.colors.blue[95],
          dark: theme.colors.darkBlue[60],
        }),
      },
    },
    '.selected-flag': {
      padding: '0 0 0 19px',
      '.arrow': {
        left: '40px',
        borderBottomColor: theme.validateMode({
          light: `${theme.colors.neutral[0]} !important`,
          dark: `${theme.colors.neutral[99]} !important`,
        }),
        borderTopColor: theme.validateMode({
          light: `${theme.colors.neutral[0]} !important`,
          dark: `${theme.colors.neutral[99]} !important`,
        }),
      },
    },
  };
});
